import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNewStudentList, getNewStudentPDF } from "../../../crud/clients.crud";
import { getLocations } from "../../../crud/config.crud";
import { setLoader } from "../../../store/ducks/loader.duck";
import CustomButton from "../../../common/components/CustomButton";
import FullTable from "../../../common/components/FullTable";
import UtilInputs from "../../../common/components/UtilInputs";
import notice from "../../../utils/notice";
import {
  new_student_columns,
  defaultFilterNewStudent,
  filtersFormNewStudent,
} from "./utils";
import moment from "moment";

export const NewStudent = () => {
  const dispatch = useDispatch();
  const [newStudentList, setNewStudentList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filter, setFilter] = useState(defaultFilterNewStudent);

  useEffect(() => {
    getNewStudentList().then((res) => {
      if (res.Success[0].status === 200) {
        setNewStudentList(res.Success[0].details);
      }
    });
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });
        setLocations(res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id })));
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const handleDownloadPDF = () => {
    dispatch(setLoader(true));
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD");
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    if (location || start || end || !start || !end) {
      getNewStudentPDF(location, end, start)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Datos incompletos", "warning");
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleFilterData = () => {
    dispatch(setLoader(true));
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD");
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    if (location || start || end || !location || !start || !end) {
      getNewStudentList(location, end, start)
        .then((res) => {
          if (res.Success[0].status === 200) {
            setNewStudentList(res.Success[0].details);
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    }
  };
  //obtener pdf
  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  return (
    <>
      <div style={{ width: 650, marginBottom: 10, display: "flex" }}>
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={filtersFormNewStudent(locations)}
          className="mb-3"
        />
      </div>
      <CustomButton
        title="Filtrar"
        onClick={handleFilterData}
        style={{ marginRight: 3, marginBottom: 10 }}
      />
      <CustomButton
        title="Reporte"
        onClick={handleDownloadPDF}
        style={{ marginRight: 3, marginBottom: 10 }}
      />

      <FullTable
        title={"Alumnos nuevos"}
        data={newStudentList ? newStudentList : []}
        columns={new_student_columns}
      />
    </>
  );
};
