export const responsibleForm = [
  {
    name: "responsible[name]",
    label: "Nombre",
    type: "text",
  },
  {
    name: "responsible[last_name]",
    label: "Apellido paterno",
    type: "text",
  },
  {
    name: "responsible[last_name_2]",
    label: "Apellido Materno (opcional)",
    type: "text",
  },
  {
    name: "responsible[phone]",
    label: "Teléfono",
    type: "text",
  },
  {
    name: "responsible[landline]",
    label: "Teléfono de casa",
    type: "text",
  },
  {
    name: "responsible[email]",
    label: "Correo electrónico",
    type: "text",
  },
  {
    name: "responsible[membership_number]",
    label: "Número de socio",
    type: "text",
  },
  {
    name: "responsible[client_type]",
    label: "Tipo de cliente",
    type: "dropdown",
  },
];

export const errorMessages = {
  responsible: {
    name: "Campo de nombre requerido",
    last_name: "Campo de apellido requerido",
    phone: "Campo de teléfono requerido",
    email: "Campo de correo requerido",
    membership_number: "Campo de número de socio requerido",
    client_type: "Campo de tipo de cliente requerido",
  },
  student: {
    name: "Campo de nombre requerido",
    last_name: "Campo de apellido requerido",
    birthdate: "Campo de fecha de nacimiento requerido",
    gender: "Campo de genero requerido",
    location_id: "Campo de ubicación requerido",
  },
  main_contact: {
    email: "Campo de correo requerido",
    cellphone: "Campo de celular requerido",
  },
  contact: {
    email: "Campo de correo requrido",
    phone: "Campo de celular requrido",
    postal_code: "Campo de código postal requrido",
  },
};
