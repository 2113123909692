import React, { useEffect, useState } from "react";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  getAllGroupsByInstructorAndLevel,
  getAllInstructorsByLevel,
  getAllLevels,
  getScheduleByDay,
} from "../../../../../crud/clients.crud";
import PriceGroups from "./PriceGroups";
import Discounts from "./Discounts";
import { Delete } from "@material-ui/icons";
import { useStyles } from "./styles";
import Loader from "./Loader";
import notice from "../../../../../utils/notice";

const SelectGroup = ({
  groups,
  selectedGroups, //group_schedule_id
  setSelectedGroups, //group_schedule_id
  setCourtesyDesc, //desc_cortesia
  courtesyDesc, //desc_cortesia
  setAgeDesc, //desc_tercera_edad
  ageDesc, //desc_tercera_edad
  setCategory, //category_id, group_price_id
  category, //category_id, group_price_id
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  //INSTRUCTOR
  const [listInstructores, setListInstructores] = useState([]);
  const [instructorSelected, setInstructorsSelected] = useState([]);
  //NIVELES
  const [listLevels, setListLevels] = useState([]);
  const [levelSelected, setLevelSelected] = useState([]);
  //HORARIOS
  const [selectScheduleGroup, setSelectScheduleGroup] = useState("");
  const [selectLesson, setSelectLesson] = useState("");
  const [hourOfTheDay, setHourOfTheDay] = useState([]);
  const [filterGroupsList, setFilterGroupsList] = useState({
    categoryId: "",
    day: [],
    hour: [],
  });
  let { categoryId, day, hour } = filterGroupsList; //destructuracion del state

  const daysOfTheWeek = [
    { value: "Lunes", name: "Lunes" },
    { value: "Martes", name: "Martes" },
    { value: "Miercoles", name: "Miércoles" },
    { value: "Jueves", name: "Jueves" },
    { value: "Viernes", name: "Viernes" },
    { value: "Sabado", name: "Sábado" },
  ];

  //seleccionar la categoria
  const handleChangeSelectCategory = (e) => {
    setFilterGroupsList({
      ...filterGroupsList,
      categoryId: e.target.value,
    });
    setCategory(e.target.value);
  };
  //seleccionar dia
  const handleDay = (e) => {
    setFilterGroupsList({
      ...filterGroupsList,
      day: e.target.value,
    });
  };
  //seleccionar fecha
  const handleTimeStart = (e) => {
    setFilterGroupsList({
      ...filterGroupsList,
      hour: e.target.value,
    });
  };

  //OBTENTER LAS HORAS
  const handleGetHour = () => {
    setLoading(!loading);
    let days = day;
    let category_id = categoryId?.category_id;
    if (days && category_id) {
      try {
        getScheduleByDay(days, category_id).then((res) => {
          setLoading(false);

          if (res.Error) {
            setHourOfTheDay([]);
          } else if (res.Success) {
            setHourOfTheDay(res.Success[0].data);
          } else {
            setHourOfTheDay(res.Success[0].data);
          }
        });
      } catch (e) {
        setHourOfTheDay([]);
      }
    }
  };
  //OBTENER NIVELES A PARTIR DE LA CATEGORÍA, HORA Y DÍA
  const handleGetAllLevels = () => {
    setLoading(!loading);

    let days = day;
    let category_id = categoryId?.category_id;
    let timeStart = hour;
    if (days && timeStart && category_id) {
      try {
        getAllLevels(days, category_id, timeStart).then((res) => {
          setLoading(false);
          if (res.Error) {
            setListLevels([]);
          } else if (res.Success) {
            setListLevels(res.Success[0].data.levels);
          } else {
            setListLevels(res.Success[0].data.levels);
          }
        });
      } catch (e) {
        setListLevels([]);
      }
    }
  };

  //OBTENER INSTRUCTORES
  const handleGetInstructors = () => {
    setLoading(!loading);
    let category_id = categoryId?.category_id;
    let days = day;
    let timeStart = hour;
    let levels_id = levelSelected;
    if (days && timeStart && category_id && levels_id) {
      try {
        getAllInstructorsByLevel(days, category_id, timeStart, levels_id).then((res) => {
          setLoading(false);

          if (res.Error) {
            setListInstructores([]);
          } else if (res.Success) {
            setListInstructores(res.Success[0].data.instructores);
            setSelectScheduleGroup(res.Success[0].data.data);
          } else {
            setListInstructores(res.Success[0].data.instructores);
            setSelectScheduleGroup(res.Success[0].data.data);
          }
        });
      } catch (e) {
        setListLevels([]);
      }
    }
  };

  //HORARIO DEL GRUPO SELECICONAD
  const handleScheduleSelectGroup = () => {
    setLoading(!loading);

    let category_id = categoryId?.category_id;
    let days = day;
    let timeStart = hour;
    let instructor_id = instructorSelected.id;
    let levels_id = listLevels;
    if (category_id && days.length > 0 && timeStart && levels_id && instructor_id) {
      try {
        getAllGroupsByInstructorAndLevel(
          category_id,
          days,
          timeStart,
          instructor_id,
          levels_id
        ).then((res) => {
          setLoading(!loading);

          if (res.Error) {
            setSelectScheduleGroup([]);
          } else if (res.Success) {
            setSelectScheduleGroup(res.Success[0].data.data);
          } else {
            setSelectScheduleGroup(res.Success[0].data.data);
          }
        });
      } catch {
        setLoading(!loading);

        setSelectScheduleGroup([]);
      }
    }
  };

  // LEVELS SELECCIONADOS
  const handleSelectLevel = (event) => {
    const { value } = event.target;
    setLevelSelected(value);
  };
  // INSTRUCTOR SELECCIONAD
  const handleSelectInstructor = (instructor) => {
    setInstructorsSelected(instructor);
  };

  //SLECCIONAR CLASE
  const handleSelectLesson = (lesson) => {
    setSelectLesson(lesson);

    if (lesson) {
      let new_lesson = [
        ...selectedGroups,
        {
          group_id: lesson.group_id,
          name: lesson.group_category_name,
          group_price: lesson.client_group_price.price_per_class,
          additional_classes: 0,
          day: lesson.day,
          endTime: lesson.end_time,
          starTime: lesson.start_time,
          group_schedule_id: lesson.group_schedule_id,
          group_price_id: lesson.client_group_price.group_price_id,
          display: lesson.display,
        },
      ];
      const nonDuplicateItems = new_lesson.reduce((acc, current) => {
        acc.map((item) =>
          item.day === current.day
            ? notice("No se puede agregar más de una clase el mismo día", "warning")
            : ""
        );
        const x = acc.find((item) => item.day === current.day);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setSelectedGroups(nonDuplicateItems);
    }
  };

  const deleteGroup = (index) => {
    selectedGroups.splice(index, 1);
    setSelectedGroups([...selectedGroups]);
  };
  return (
    <>
      <Grid container xs={12} style={{ marginBottom: "45px" }}>
        {/* TIPO DE CLASE */}
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel htmlFor="group">Tipo de clase *</InputLabel>
            <Select
              labelId="group"
              onChange={handleChangeSelectCategory}
              value={categoryId}
              name="category_id"
            >
              {groups?.group_prices?.map((group) => (
                <MenuItem key={group.id} value={group}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* SELECCIONAR DIA */}
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel id="dia" htmlFor="group" shrink={true}>
              Días
            </InputLabel>
            <Select
              labelId="dia"
              value={day}
              name="day"
              onChange={handleDay}
              onBlur={handleGetHour}
              multiple
            >
              {daysOfTheWeek.map((day) => (
                <MenuItem key={day.value} value={day.value}>
                  {day.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* SELECCIONAR HORA */}
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel id="hora">Hora</InputLabel>
            <Select
              labelId="hora"
              value={hour}
              name="hora"
              onChange={handleTimeStart}
              onBlur={handleGetAllLevels}
              multiple
            >
              {hourOfTheDay?.length >= 1 ? (
                hourOfTheDay.map((hour, index) => (
                  <MenuItem key={index} value={hour.start_time}>
                    {hour.display}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{loading ? <Loader /> : "No hay resultados"}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        {/* Niveles */}
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel id="clase" htmlFor="group" shrink={true}>
              Niveles
            </InputLabel>
            <Select
              labelId="clase"
              value={levelSelected || []}
              name="levelSelected"
              onChange={handleSelectLevel}
              onBlur={handleGetInstructors}
              multiple
            >
              {listLevels.length > 0 ? (
                listLevels.map((level, index) => (
                  <MenuItem key={index} value={level.id}>
                    {level?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{loading ? <Loader /> : "No hay resultados"}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        {/* INSTRUCTORES */}
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel id="clase" htmlFor="group" shrink={true}>
              Instructores
            </InputLabel>
            <Select
              labelId="clase"
              value={instructorSelected}
              name="instructorSelected"
              onChange={(e) => handleSelectInstructor(e.target.value)}
              onBlur={handleScheduleSelectGroup}
            >
              {listInstructores?.length > 0 ? (
                listInstructores?.map((instructor, index) => (
                  <MenuItem key={index} value={instructor}>
                    {instructor?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{loading ? <Loader /> : "No hay resultados"}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4} style={{ padding: "0 25px 0 0" }}>
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel id="clase" htmlFor="group" shrink={true}>
              Clases disponibles
            </InputLabel>
            <Select
              labelId="clase"
              value={selectLesson}
              name="selectLesson"
              onChange={(e) => handleSelectLesson(e.target.value)}
            >
              {selectScheduleGroup?.length > 0 ? (
                selectScheduleGroup?.map((group) => (
                  <MenuItem
                    key={group.group_id}
                    value={group}
                    disabled={group.cupo === 0 ? true : false}
                  >
                    {group.display}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{loading ? <Loader /> : "No hay resultados"}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Discounts
          setCourtesyDesc={setCourtesyDesc}
          courtesyDesc={courtesyDesc}
          setAgeDesc={setAgeDesc}
          ageDesc={ageDesc}
        />
      </Grid>
      <div className={classes.cardContainer}>
        <div className={classes.cardText}>
          <Typography variant="h6">Clases seleccionadas</Typography>
          {selectedGroups
            ? selectedGroups?.map((selected_group, group_index) => (
                <Grid
                  key={selected_group.group_schedule_id}
                  className={classes.cardLesson}
                >
                  <p>{selected_group.display}</p>
                  <IconButton onClick={(e) => deleteGroup(group_index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              ))
            : ""}
        </div>
        <PriceGroups
          setSelectedGroups={setSelectedGroups}
          selectedGroups={selectedGroups}
          category={category}
          courtesyDesc={courtesyDesc}
          ageDesc={ageDesc}
        />
      </div>
    </>
  );
};

export default SelectGroup;
