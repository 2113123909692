import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import {
  baja_columns,
  defaultParcial,
  parcial,
  defaultTotal,
  total,
} from "../utils/bajas_data";
import SimpleCheckbox from "../../../../common/components/SimpleCheckbox";
import * as clientsCRUD from "../../../../crud/clients.crud";
import CustomButton from "../../../../common/components/CustomButton";
import FullTable from "../../../../common/components/FullTable";
import { Modal } from "@material-ui/core";
import UtilInputs from "../../../../common/components/UtilInputs";
import notice from "../../../../utils/notice";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../../store/ducks/loader.duck";
import { useHistory } from "react-router-dom";

const Students = ({ responsiblesWithClients, locations, updateClients }) => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);

  const dispatch = useDispatch();
  const { role } = useSelector((store) => store.auth.user.user);
  const [parcialForm, setParcialForm] = useState(defaultParcial);
  const [totalForm, setTotalForm] = useState(defaultTotal);
  const [openTotal, setOpenTotal] = useState(false);
  const [openParcial, setOpenParcial] = useState(false);
  const [openReactivar, setOpenReactivar] = useState(false);
  const [expectations, setExpectations] = useState([]);
  const [edit, setEdit] = useState(false);
  const [student, setStudent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentIdParcial, setCurrentIdParcial] = useState(0);
  const [currentIdTotal, setCurrentIdTotal] = useState(0);
  const [currentIdReactivar, setCurrentIdReactivar] = useState(0);
  const history = useHistory();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });

  useEffect(() => {
    //expectativas
    clientsCRUD
      .getCatalogExpectative()
      .then((res) => {
        setExpectations(res.data);
      })
      .catch(console.log);
  }, []);

  //REACTIVAR MODAL
  const handleOpenReactivar = (id) => {
    setCurrentIdReactivar(id);
    setOpenReactivar(true);
  };
  const handleCloseReactivar = () => {
    setCurrentIdReactivar(0);
    setOpenReactivar(false);
  };

  //TOTAL MODAL
  const handleOpenTotal = (id) => {
    setCurrentIdTotal(id);
    setOpenTotal(true);
  };
  const handleCloseTotal = () => {
    setCurrentIdTotal(0);
    setOpenTotal(false);
  };

  //PARCIAL MODAL
  const handleOpenParcial = (id) => {
    setCurrentIdParcial(id);
    setOpenParcial(true);
  };
  const handleCloseParcial = () => {
    setCurrentIdParcial(0);
    setOpenParcial(false);
  };

  const handleParcial = ({ target }) => {
    const { name, value } = target;
    setParcialForm({ ...parcialForm, [name]: value });
  };
  const handleTotal = ({ target }) => {
    const { name, value } = target;
    setTotalForm({ ...totalForm, [name]: value });
  };
  //RECATIVAR funcion
  const handleReactivar = (id) => {
    dispatch(setLoader(true));
    clientsCRUD
      .getReactivar(id)
      .then((res) => {
        if (res.success) {
          if (res.success[0].status === 200) {
            notice(res.success[0].title, "success");
            setTimeout(() => {
              history.push(`/punto-de-venta/${id}`);
            }, 3000);
          }
        } else {
          notice("No se puedo reactivar al cliente", "warning");
        }
      })
      .catch((err) => {
        notice("Ocurrio un error", "error");
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  // FUNCION TOTAL
  const handleBajaTotal = (id) => {
    dispatch(setLoader(true));
    let description = totalForm.description;
    if (description !== "") {
      clientsCRUD
        .getBajaTotal(id, description)
        .then((res) => {
          if (res.Success) {
            if (res.Success[0].status === 200) {
              notice(res.Success[0].title, "success");
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          } else {
            notice("No se puedo dar de baja al cliente", "warning");
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  };
  // FUNCION PARCIAL
  const handleBajaParcial = (id) => {
    dispatch(setLoader(true));
    let description = parcialForm.description;
    if (description !== "") {
      clientsCRUD
        .getBajaParcial(id, description)
        .then((res) => {
          if (res.success) {
            if (res.success[0].status === 200) {
              notice(res.success[0].title, "success");
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          } else {
            notice("No se puedo darde baja al cliente", "warning");
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  };

  const getLocationId = (name) => {
    let index = locations.data
      .map(function(e) {
        return e.name;
      })
      .indexOf(name);
    return locations.data[index].id;
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({
      paddingRight: "3.5rem",
    });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({
      paddingRight: "2.5rem",
    });
  };
  const update = (values) => {
    let n_responsible = {
      responsible: {
        ...responsiblesWithClients.responsible,
      },
    };
    delete n_responsible.responsible.clients;
    n_responsible.clients = values.clients;
    n_responsible.clients.forEach((element) => {
      if (element.location_id === undefined) {
        element.location_id = getLocationId(element.location);
      }
    });
    clientsCRUD
      .updateClient(n_responsible.responsible.id, n_responsible, updateClients)
      .then((res) => {
        window.location.reload();
      });
  };

  // eslint-disable-next-line no-unused-vars
  function handleCheckChange(event) {
    setStudent(event.target.checked);
  }
  function renderInputs(
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue
  ) {
    return (
      <Grid container spacing={2}>
        {values.clients.map((studentClient, i) => {
          return (
            <Grid
              key={studentClient.id}
              item
              xs={12}
              style={{
                marginTop: 10,
              }}
            >
              {student && i === 0 ? (
                ""
              ) : (
                <Typography
                  key={studentClient.id}
                  variant="h6"
                  style={{
                    padding: "15px 0px 15px 27px",
                  }}
                >
                  Alumno {i + 1}
                </Typography>
              )}
              <Grid container spacing={2}>
                {i > 0 ? (
                  <>
                    {/* NUMERO DE SOCIO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Número de socio
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Número de socio"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['membership_number']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].membership_number}
                        />
                      </Grid>
                    </Grid>
                    {/* TIPO DE CLIENTE */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Tipo de Cliente
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="client_type">Tipo de Cliente</InputLabel>
                          <Select
                            fullWidth
                            labelId="client_type"
                            value={values.clients[i].client_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`clients["${i}"]['client_type']`}
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/*NOMBRE*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Nombre
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Nombre"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['name']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].name}
                          helperText={touched.name && errors.name}
                          error={Boolean(touched.name && errors.name)}
                        />
                      </Grid>
                    </Grid>
                    {/* APELLIDO PATERNO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Apellido paterno
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Apellido paterno"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['last_name']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].last_name}
                          helperText={touched.last_name && errors.last_name}
                          error={Boolean(touched.last_name && errors.last_name)}
                        />
                      </Grid>
                    </Grid>
                    {/* APELLIDO MATERNO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Apellido materno
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Apellido materno"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['last_name_2']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].last_name_2}
                          helperText={touched.last_name_2 && errors.last_name_2}
                          error={Boolean(touched.last_name_2 && errors.last_name_2)}
                        />
                      </Grid>
                    </Grid>
                    {/* NOTAS */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Nota
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Nota"
                          margin="normal"
                          className="kt-width-full"
                          name={`clients["${i}"]['nota']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].nota}
                        />
                      </Grid>
                    </Grid>
                    {/* CARGO RECURRENTE */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <SimpleCheckbox
                        label="Cargo recurrente"
                        margin="normal"
                        className="kt-width-full"
                        name={`clients["${i}"]['recurrent_charge']`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.clients[i].recurrent_charge}
                      />
                    </Grid>
                  </>
                ) : i === 0 && !student ? (
                  <>
                    {/* NUMERO DE SOCIO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Número de socio
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Número de socio"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['membership_number']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].membership_number}
                        />
                      </Grid>
                    </Grid>
                    {/* TIPO DE CLIENTE */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Tipo de Cliente
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="client_type">Tipo de Cliente</InputLabel>
                          <Select
                            fullWidth
                            labelId="client_type"
                            value={values.clients[i].client_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`clients["${i}"]['client_type']`}
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/*NOMBRE*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Nombre
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Nombre"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['name']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].name}
                          helperText={touched.name && errors.name}
                          error={Boolean(touched.name && errors.name)}
                        />
                      </Grid>
                    </Grid>
                    {/* APELLIDO PATERNO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Apellido paterno
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Apellido paterno"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['last_name']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].last_name}
                          helperText={touched.last_name && errors.last_name}
                          error={Boolean(touched.last_name && errors.last_name)}
                        />
                      </Grid>
                    </Grid>
                    {/* APELLIDO MATERNO */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Apellido materno
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Apellido materno"
                          margin="normal"
                          className="kt-width-full"
                          // disabled={disableTexts}
                          name={`clients["${i}"]['last_name_2']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].last_name_2}
                          helperText={touched.last_name_2 && errors.last_name_2}
                          error={Boolean(touched.last_name_2 && errors.last_name_2)}
                        />
                      </Grid>
                    </Grid>
                    {/* NOTA */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid item sm={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "34px 0px 0px 27px",
                          }}
                        >
                          Nota
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="Nota"
                          margin="normal"
                          className="kt-width-full"
                          name={`clients["${i}"]['nota']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].nota}
                        />
                      </Grid>
                    </Grid>
                    {/* CARGO RECURRENTE */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid
                        item
                        sm={6}
                        style={{
                          paddingTop: "15px",
                        }}
                      >
                        <SimpleCheckbox
                          label="Cargo recurrente"
                          margin="normal"
                          className="kt-width-full"
                          name={`clients["${i}"]['recurrent_charge']`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.clients[i].recurrent_charge}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {/*FECHA DE NACIMIENTO */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Fecha de nacimiento
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    <TextField
                      label="Fecha de nacimiento"
                      name={`clients["${i}"]['birthdate']`}
                      type="date"
                      onChange={handleChange}
                      value={values.clients[i].birthdate}
                      fullWidth
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                {/*GENERO */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Genero
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="gender">Género</InputLabel>
                      <Select
                        labelId="gender"
                        value={values.clients[i].gender}
                        onChange={handleChange}
                        name={`clients["${i}"]['gender']`}
                      >
                        <MenuItem value="Femenino">Femenino</MenuItem>
                        <MenuItem value="Masculino">Masculino</MenuItem>
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*TIPO DE SANGRE */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    paddingTop: "15px",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Tipo de Sangre
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="blood_type">Tipo de Sangre</InputLabel>
                      <Select
                        labelId="blood_type"
                        value={values.clients[i].blood_type}
                        onChange={handleChange}
                        name={`clients["${i}"]['blood_type']`}
                      >
                        <MenuItem value="O+">O+</MenuItem>
                        <MenuItem value="O-">O-</MenuItem>
                        <MenuItem value="A+">A+</MenuItem>
                        <MenuItem value="A-">A-</MenuItem>
                        <MenuItem value="B+">B+</MenuItem>
                        <MenuItem value="B-">B-</MenuItem>
                        <MenuItem value="AB+">AB+</MenuItem>
                        <MenuItem value="AB-">AB-</MenuItem>
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*ENFERMEDADES */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Enfermedades / Alergias
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Enfermedades / Alergias"
                      margin="normal"
                      className="kt-width-full"
                      // disabled={disableTexts}
                      name={`clients["${i}"]['allergies_observations']`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.clients[i].allergies_observations}
                      helperText={
                        touched.allergies_observations && errors.allergies_observations
                      }
                      error={Boolean(
                        touched.allergies_observations && errors.allergies_observations
                      )}
                    />
                  </Grid>
                </Grid>
                {/* EXPECTATIVAS */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    paddingTop: "15px",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Expectativas
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Expectativas</InputLabel>

                      <Select
                        value={values.clients[i].ids_expectativas}
                        onChange={handleChange}
                        name={`clients["${i}"]['ids_expectativas']`}
                        multiple
                      >
                        {expectations.length > 0 ? (
                          expectations.map((expectation) => (
                            <MenuItem key={expectation.value} value={expectation.value}>
                              {expectation.text}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No hay resultados</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*UBICACION*/}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    paddingTop: "15px",
                  }}
                >
                  <Grid item sm={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "34px 0px 0px 27px",
                      }}
                    >
                      Ubicación
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingTop: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Ubicación editar</InputLabel>
                      <Select
                        value={values.clients[i].location_id}
                        onChange={handleChange}
                        name={`clients["${i}"]['location_id']`}
                      >
                        {locations.data.length > 0 ? (
                          locations.data.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                              {location.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No hay resultados</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/*UBICACION CHECK */}
                <Grid
                  item
                  sm={3}
                  style={{
                    display: "flex",
                    paddingTop: "15px",
                  }}
                >
                  <SimpleCheckbox
                    label="Ubicación"
                    margin="normal"
                    className="kt-width-full"
                    name={`clients["${i}"]['bubication']`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.clients[i].bubication}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SimpleCheckbox
                    label="Espectativas"
                    margin="normal"
                    className="kt-width-full"
                    name={`clients["${i}"]['bdiff_expectatives']`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.clients[i].bdiff_expectatives}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return (
    <>
      <Paper
        style={{
          marginTop: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
          }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              style={{
                color: "#001979",
                padding: "15px",
              }}
            >
              Detalles de alumnos
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {role?.name !== "Cliente" && !edit && (
              <Button
                style={{
                  marginRight: 25,
                }}
                color="primary"
                variant="contained"
                onClick={() => {
                  setEdit(true);
                }}
              >
                Editar informacion
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        {edit ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
            }}
          >
            <Formik
              initialValues={{
                clients: responsiblesWithClients.responsible.clients,
              }}
              validate={(values) => {
                const errors = {};
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                setTimeout(() => {
                  update(values);
                  window.scrollTo(0, 0);
                  setSubmitting(false);
                  setEdit(false);
                  disableLoading();
                }, 1000);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                  <div className="form-group">
                    {renderInputs(values, errors, touched, handleChange, handleBlur)}
                  </div>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEdit(false);
                      }}
                      variant="contained"
                      style={{
                        background: "#001979",
                        color: "white",
                        margin: "10px",
                        padding: "9.5px 20px",
                      }}
                    >
                      Cancelar
                    </Button>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        ) : (
          <>
            {responsiblesWithClients.responsible.clients.map((client, i) => (
              <div key={client.id}>
                <Typography
                  variant="h6"
                  style={{
                    padding: "15px 0px 15px 27px",
                  }}
                >
                  Alumno {i + 1}
                </Typography>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                  }}
                >
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Número de socio
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Tipo de cliente
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Nombre
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Apellido paterno
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Apellido Materno
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Nota
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Cargo recurrente
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Fecha de Nacimiento
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Genero
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Tipo de Sangre
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Enfermedades / Alergias
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Estatus
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Ubicación
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Expectativas
                    </Typography>
                    <div
                      style={{
                        paddingLeft: 11,
                      }}
                    >
                      <SimpleCheckbox
                        disabled
                        label="Ubicación"
                        margin="normal"
                        className="kt-width-full"
                        value={client.bubication}
                      />
                    </div>
                    <div
                      style={{
                        paddingLeft: 11,
                      }}
                    >
                      <SimpleCheckbox
                        disabled
                        label="Expectativas"
                        margin="normal"
                        className="kt-width-full"
                        value={client.bdiff_expectatives}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.membership_number ? client.membership_number : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.client_type ? client.client_type : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.name ? client.name : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.last_name ? client.last_name : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.last_name_2 ? client.last_name_2 : "-"}
                    </Typography>

                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.nota ? client.nota : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.recurrent_charge ? "Habilitado" : "Deshabilitado"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.birthdate ? client.birthdate : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.gender ? client.gender : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.blood_type ? client.blood_type : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.allergies_observations
                        ? client.allergies_observations
                        : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.discharge_status ? client.discharge_status : "-"}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.location ? client.location : "-"}
                      {/* {locations?.data.length > 0
                        ? locations?.data.find((x) => x.id === client.location_id)
                            ?.name || "-"
                        : "-"} */}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.ids_expectativas.length > 0
                        ? client.ids_expectativas
                            .map((expectativa) => {
                              for (let expectative of expectations) {
                                if (expectative.value === expectativa) {
                                  return expectative.text;
                                }
                              }
                            })
                            .join(", ")
                        : "-"}
                    </Typography>
                    {/* <SimpleCheckbox
                      disabled
                      label="Espectativas"
                      margin="normal"
                      className="kt-width-full"
                      value={client.bubication}
                    />
                    <SimpleCheckbox
                      disabled
                      label="Espectativas"
                      margin="normal"
                      className="kt-width-full"
                      value={client.bdiff_expectatives}
                    /> */}
                  </Grid>
                </Grid>

                {/* //BAJAS */}
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "15px 0px 15px 27px",
                    }}
                  >
                    Historial de bajas
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: 20,
                      }}
                    >
                      {permissions.includes("discharges:create") && (
                        <>
                          <CustomButton
                            title="Baja parcial"
                            onClick={() => handleOpenParcial(client.id)}
                            style={{
                              width: 150,
                              margin: 10,
                            }}
                            disabled={client.discharge_status === "ACTIVO" ? false : true}
                          />
                          <CustomButton
                            title="Baja total"
                            onClick={() => handleOpenTotal(client.id)}
                            style={{
                              width: 150,
                              margin: 10,
                            }}
                            disabled={
                              client.discharge_status === "ACTIVO" ||
                              client.discharge_status === "PARCIAL"
                                ? false
                                : true
                            }
                          />
                        </>
                      )}
                      <CustomButton
                        title="Reactivación"
                        onClick={() => handleOpenReactivar(client.id)}
                        style={{
                          width: 150,
                          margin: 10,
                        }}
                        disabled={client.discharge_status !== "ACTIVO" ? false : true}
                      />
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        padding: 20,
                      }}
                    >
                      <Paper>
                        <FullTable
                          title={"Bajas"}
                          data={
                            client.dicharges && client.dicharges.length > 0
                              ? client.dicharges
                              : []
                          }
                          columns={baja_columns}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* BAJA PARCIAL */}
                  <Modal open={openParcial} onClose={handleCloseParcial}>
                    <div
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: 120,
                        padding: 30,
                        borderRadius: 10,
                        background: "white",
                      }}
                    >
                      <Typography variant="h6">Baja parcial</Typography>
                      <UtilInputs
                        formValues={parcialForm}
                        onValuesChange={handleParcial}
                        inputValues={parcial}
                        className="mb-3"
                      />
                      <CustomButton
                        color="danger"
                        title="Cerrar"
                        onClick={handleCloseParcial}
                        style={{
                          marginRight: 3,
                        }}
                      />
                      <CustomButton
                        title="Dar de baja"
                        // onClick={handleDownloadPdf}
                        onClick={() => handleBajaParcial(currentIdParcial)}
                        style={{
                          marginRight: 3,
                        }}
                      />
                    </div>
                  </Modal>
                  {/* BAJA TOTAL */}
                  <Modal open={openTotal} onClose={handleCloseTotal}>
                    <div
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: 120,
                        padding: 30,
                        borderRadius: 10,
                        background: "white",
                      }}
                    >
                      <Typography variant="h6">Baja total</Typography>
                      <UtilInputs
                        formValues={totalForm}
                        onValuesChange={handleTotal}
                        inputValues={total}
                        className="mb-3"
                      />
                      <CustomButton
                        color="danger"
                        title="Cerrar"
                        onClick={handleCloseTotal}
                        style={{
                          marginRight: 3,
                        }}
                      />
                      <CustomButton
                        title="Dar de baja"
                        onClick={() => handleBajaTotal(currentIdTotal)}
                        style={{
                          marginRight: 3,
                        }}
                      />
                    </div>
                  </Modal>

                  {/*REACTIVAR */}
                  <Modal open={openReactivar} onClose={handleCloseReactivar}>
                    <div
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: 120,
                        padding: 30,
                        borderRadius: 10,
                        background: "white",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          paddingBottom: "15px",
                        }}
                      >
                        ¿Seguro que desea reactivar la cuenta?
                      </Typography>
                      <CustomButton
                        color="danger"
                        title="Cerrar"
                        onClick={handleCloseReactivar}
                        style={{
                          marginRight: 3,
                        }}
                      />
                      <CustomButton
                        title="Reactivar cuenta"
                        onClick={() => handleReactivar(currentIdReactivar)}
                      />
                    </div>
                  </Modal>
                </div>
              </div>
            ))}
          </>
        )}
      </Paper>
    </>
  );
};
export default Students;
