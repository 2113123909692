import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Formik } from "formik";
import clsx from "clsx";
import notice from "../../../utils/notice";
import * as config from "../../../store/ducks/config.duck";
import * as configCRUD from "../../../crud/config.crud";
import SimpleTime from "../../../common/components/SimpleTime";
import UtilInputs from "../../../common/components/UtilInputs";
import { defaultMeet, meetForm } from "./utils/newMeet_data";
import SimpleDropdown from "../../../common/components/SimpleDropdown";
import SimpleText from "../../../common/components/SimpleText";
import SimpleMultipleAutocomplete from "../../../common/components/SimpleMultipleAutocomplete";

const MeetingDialog = ({
  mode,
  setDialog,
  levels,
  categories,
  instructors,
  fetchEvents,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  // eslint-disable-next-line no-unused-vars
  const minHour = new Date("2000-01-01T08:00:00");

  function addDays(array, setterFunction) {
    let start =
      array.length !== 0
        ? array[array.length - 1]?.hours_start
        : new Date(2022, new Date().getMonth() + 1, new Date().getDate(), 8);
    let end =
      array.length !== 0
        ? array[array.length - 1]?.hours_end
        : new Date(2022, new Date().getMonth() + 1, new Date().getDate(), 10);
    let groupDummy = {
      day: "",
      hours_start: start,
      hours_end: end,
      students: [],
      track: "",
    };
    array.push(groupDummy);
    setterFunction("groups", array);
  }
  const open = mode === "creating";
  const title = mode === "editing" ? "Informacion de grupo:" : "Crear Clase";

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const weekdays = [
    { value: "Lunes", text: "Lunes" },
    { value: "Martes", text: "Martes" },
    { value: "Miercoles", text: "Miércoles" },
    { value: "Jueves", text: "Jueves" },
    { value: "Viernes", text: "Viernes" },
    { value: "Sabado", text: "Sábado" },
    { value: "Domingo", text: "Domingo" },
  ];

  function create(values) {
    let data = {
      // name: values.title,
      coup: values.coup,
      start: values.start,
      end: values.end,
      color: values.color,
      textColor: "white",
      description: values.description,
      category_id: values.category_id,
      instructor_id: values.instructor_id,
      levels: values.levels,
      group_schedules: values.group_schedules.map((day) => ({
        ...day,
        hours_start: new Date(
          day.hours_start.getTime() - day.hours_start.getTimezoneOffset() * 60000
        ).toJSON(),
        hours_end: new Date(
          day.hours_end.getTime() - day.hours_end.getTimezoneOffset() * 60000
        ).toJSON(),
      })),
    };

    enableLoading();
    configCRUD
      .saveGroup(data)
      .then(() => {
        setDialog("closed");
        fetchEvents();
        notice("Grupo creado con exito", "success");
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors.map((x) => x.title)
            : "Ha ocurrido un error con su solicitud"
        )
      )
      .finally(() => disableLoading());
  }

  function verifyNext(values) {
    if (
      values.group_schedules.every(
        (x) => x.day && x.hours_start && x.hours_end && x.track
      )
    )
      return false;
    else return true;
  }

  function deleteDay(index, data, deleteDay, setterFunction) {
    if (data[index] !== undefined) {
      deleteDay.push(data[index]);
      setterFunction("deleteDay", deleteDay);
    }
    data.splice(index, 1);
  }

  const closedDialog = (setterFunction) => {
    setterFunction((defaultMeet.group_schedules = []));
    // fetchEvents();
    setDialog("closed");
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={6}>
            {title}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            ...defaultMeet,
            deleteLevel: [],
            deleteDay: [],
            deleteDay_s: [],
          }}
          validate={(values) => {
            let errors = {};

            if (!values.title) {
              errors.title = "Campo requerido";
            }

            if (values.coup <= 0) {
              errors.coup = "El CUPO debe ser mayor a 0";
            }

            if (!values.start) {
              errors.start = "Campo requerido";
            }

            if (!values.end) {
              errors.end = "Campo requerido";
            }

            if (!values.color) {
              errors.color = "Campo requerido";
            }

            if (!values.description) {
              errors.description = "Campo requerido";
            }

            if (!values.category_id) {
              errors.category_id = "Campo requerido";
            }

            if (!values.instructor_id) {
              errors.instructor_id = "Campo requerido";
            }

            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <div className="form-group">
                <UtilInputs
                  width={"25%"}
                  formValues={values}
                  onValuesChange={(e) => {
                    handleChange(e);
                    if (e.target?.name === "category_id") {
                      let s_cat = categories.find((x) => x.id === e.target.value);
                      s_cat && setFieldValue("description", s_cat.description);
                    }
                  }}
                  inputValues={meetForm(
                    categories.map((cat) => ({
                      ...cat,
                      value: cat.id,
                      text: cat.name,
                    })),
                    instructors.users.map((inst) => ({
                      ...inst,
                      value: inst.id,
                      text: `${inst.name} ${inst.last_name}`,
                    }))
                  )}
                />
                <Grid container spacing={2} className="mt-3">
                  <Grid item xs={12} sm={6} md={6}>
                    <SimpleMultipleAutocomplete
                      checkboxOption
                      disableCloseOnSelect
                      label="Nivel /es (obligatorio)"
                      name="levels"
                      value={values.levels}
                      options={levels.map((lv) => ({
                        ...lv,
                        value: lv.id,
                        text: lv.name,
                      }))}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Grupos</h3>
                    <Divider />
                  </Grid>
                  {values.group_schedules.map((group, i) => (
                    <Grid key={`${group.day}_${i}`} container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <SimpleDropdown
                          label="Dia"
                          name={`group_schedules['${i}']['day']`}
                          onChange={handleChange}
                          value={values.group_schedules[i].day}
                          options={weekdays}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <SimpleTime
                          name={`group_schedules['${i}']['hours_start']`}
                          value={values.group_schedules[i].hours_start}
                          label="Hora inicial"
                          onChange={handleChange}
                          minTime={new Date(0, 0, 0, 6)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <SimpleTime
                          name={`group_schedules['${i}']['hours_end']`}
                          value={
                            values.group_schedules[i].hours_end
                              ? values.group_schedules[i].hours_end
                              : new Date("2020-01-01 10:00")
                          }
                          label="Hora final"
                          onChange={handleChange}
                          minTime={values.group_schedules[i].hours_start}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <SimpleText
                          name={`group_schedules['${i}']['track']`}
                          value={values.group_schedules[i].track}
                          label="Carril"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={1}
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{
                              backgroundColor: "#001979",
                              color: "white",
                            }}
                            onClick={() =>
                              deleteDay(
                                i,
                                values.group_schedules,
                                values.deleteDay,
                                setFieldValue
                              )
                            }
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      // disabled={value === null}
                      onClick={() => addDays(values.group_schedules, setFieldValue)}
                    >
                      + Agregar dia
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button
                  onClick={() => closedDialog(setFieldValue)}
                  color="primary"
                  className="mr-3"
                >
                  Cancelar
                </Button>
                <button
                  onClick={() => create(values)}
                  disabled={
                    // !values.title ||
                    !values.coup ||
                    !values.category_id ||
                    !values.instructor_id ||
                    values.levels.length === 0 ||
                    values.group_schedules.length === 0 ||
                    verifyNext(values)
                  }
                  id="kt_login_signin_submit"
                  type="submit"
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  Crear
                </button>
              </div>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  group: store.config.group,
});
export default connect(mapStateToProps, config.actions)(MeetingDialog);
