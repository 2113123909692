import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import FullTable from "../../../common/components/FullTable";

import * as clients from "../../../store/ducks/clients.duck";
import * as usersCRUD from "../../../crud/users.crud";
import * as clientsCRUD from "../../../crud/clients.crud";
import StudentsDialog from "./StudentsDialog";
import { options, student_columns, defaultFilter, filtersForm } from "./utils";
import Loading from "../../../utils/Loading";
import notice from "../../../utils/notice";
import UtilInputs from "../../../common/components/UtilInputs";
import CustomButton from "../../../common/components/CustomButton";
import { Backdrop, CircularProgress } from "@material-ui/core";

// import { instructorPermissions } from "../../../utils/permissions"

const Students = ({ students, setUser, setResponsible, setStudents, ...other }) => {
  const [dialog, setDialog] = useState("closed");
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);

  const [pagination, setPagination] = useState("");

  const [filter, setFilter] = useState(defaultFilter);

  //   const permit = instructorPermissions()
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };
  const changePage = (page1, rowsPerPage1, action) => {
    setLoader(true);
    setPage(page1);
    const status = filter.status;
    const query = searchText;
    clientsCRUD
      .getStudents(setStudents, page1, rowsPerPage1, status, query)
      .then(console.log)
      .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
      .finally(() => setLoader(false));
  };
  const handleFilter = () => {
    setLoading(true);
    const status = filter.status;
    const query = searchText;
    if (status) {
      clientsCRUD
        .getStudents(setStudents, 1, rowPerPage, status, query)
        .then(console.log)
        .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
        .finally(() => setLoading(false));
    } else {
      fetchStudents();
    }
  };
  const fetchStudents = (query) => {
    let status = filter.status;

    setLoading(true);
    clientsCRUD
      .getStudents(setStudents, page, rowPerPage, status, query)
      .then((res) => setPagination(res))
      .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchStudents();
    changePage(page, rowPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setPage(1);
    if (searchText === "") {
      changePage(1, rowPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  function clientID(id = null) {
    clientsCRUD.getResponsible(setResponsible, id).then(() => {
      history.push(`/client/${id}`);
    });
  }

  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        usersCRUD.getUser(setUser, id).then(() => setDialog(mode));
        break;
      case "creating":
        setUser(usersCRUD.defaultUser);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setUser(usersCRUD.defaultUser);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  }

  if (loading) return <Loading />;
  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 999, height: "100%" }}>
        <CircularProgress />
      </Backdrop>
      <div style={{ width: 300, marginBottom: 10 }}>
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={filtersForm}
          className="mb-3"
        />
        <CustomButton title="Filtrar" onClick={handleFilter} style={{ marginRight: 3 }} />
      </div>

      <FullTable
        title={"Alumnos"}
        data={students}
        columns={student_columns}
        options={options(
          clientID,
          searchText,
          setSearchText,
          fetchStudents,
          pagination,
          rowPerPage,
          changePage
        )}
      />
      <StudentsDialog mode={dialog} setDialog={openDialog} /*permit={permit}*/ />
    </>
  );
};

const mapStateToProps = (store) => ({
  students: store.clients.students.data,
});

export default connect(mapStateToProps, clients.actions)(Students);
