import React from 'react'
import { FormControl, Select, MenuItem, TextField, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, } from '@material-ui/core';

export const MantInfo = ({ users, values, handleChange }) => {
  const rows = [
    { id: 1, schedule: 'Madrugada (03:00)'},
    { id: 2, schedule: 'Primera Hora (07:00)'},
    { id: 3, schedule: 'Medio día (12:00)'},
    { id: 4, schedule: 'Tarde (16:00)'},
    { id: 5, schedule: 'Noche (20:00)'},
  ];

  return (
    <>
      <Table stickyHeader style={{marginTop: 20}}>
        <TableHead>
            <TableRow>
              <TableCell colSpan={8} align='center' style={{borderRight: 'solid 1px #e0e0e0'}} >
                  Todos los datos se toman de acuerdo a la NOM-245-SSA1-2010
              </TableCell>
              <TableCell colSpan={4} align='center'>Quimicos agregados</TableCell>
            </TableRow>
          <TableRow>
            <TableCell colSpan={3} align='right'>
              <span style={{paddingRight: 28}}>Cloro</span>
              <br/>
              <i>650 - 750 ORP</i>
            </TableCell>
            <TableCell style={{textAlign: 'center'}}>
              <span>PH</span>
              <br/>
              <i>7.2 - 7.6</i>
            </TableCell>
            {/* <TableCell>
                <span>Cloraminas</span>
            </TableCell> */}
            <TableCell align='center'>
              <span>Temp. Alberca</span>
              <br/>
              <i>31 -31.5 ºC</i>
            </TableCell>
            <TableCell colSpan={2} align='center' >
              <span>Temp. Termostato</span>
              <br/>
              <i>45 - 55 ºC</i>
            </TableCell>
            <TableCell>
              Cloro
            </TableCell>
            <TableCell>
              Acido
            </TableCell>
            <TableCell>
              Otros
            </TableCell>
            <TableCell>
              Responsable
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.general_data.sort((a, b) => a.time_period_id > b.time_period_id ? 1 : -1).map((gd, i) => {
            const time = rows.find(x => x.id === gd.time_period_id)
            return (
            <TableRow key={gd.time_period_id}>
              <TableCell>{time.schedule}</TableCell>
              <TableCell>
                <TextField
                name="chlorine"
                fullWidth
                type="number"
                onChange={e => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].chlorine}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="chlorine_dpd"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].chlorine_dpd}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="ph"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].ph}
              />
              </TableCell>
              {/* <TableCell>
                <TextField
                name="cloramina"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].pool_temperature_1}
              />
              </TableCell> */}
              <TableCell>
                <TextField
                name="pool_temperature_1"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].pool_temperature_1}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>
                }}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="pool_temperature_2"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].pool_temperature_2}
              />
              </TableCell>
              <TableCell>
                <TextField
                type='number'
                name="thermostat_temperature"
                fullWidth
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].thermostat_temperature}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>
                }}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="added_chlorine"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].added_chlorine}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lts</InputAdornment>
                }}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="added_acid"
                fullWidth
                type="number"
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: parseFloat(e.target.value)}}) }
                defaultValue={values.general_data[i].added_acid}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lts</InputAdornment>
                }}
              />
              </TableCell>
              <TableCell>
                <TextField
                name="others"
                fullWidth
                onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: e.target.value}}) }
                defaultValue={values.general_data[i].others}
              />
              </TableCell>
              <TableCell>
                <FormControl fullWidth >
                  <Select
                    labelId="simple-select-label"
                    name='user_id'
                    defaultValue={values.general_data[i].user_id ? values.general_data[i].user_id : users.data[0].id}
                    onChange={(e) => handleChange({target: values.general_data[i] = {...values.general_data[i], [e.target.name]: e.target.value}}) }
                    >
                    {users.data.map(user => (
                      <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </>
  )
}
