import React, { useState } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import styles from "../../tablas/Tablas.module.css";
import CategoryDialog from "./CategoryDialog";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";
import CustomButton from "../../../../common/components/CustomButton";

function ConfigurationStore({
  store_categories,
  setStoreCategories,
  setStoreCategory,
  permit,
}) {
  const [dialog, setDialog] = useState("closed"); //edit,create,closed
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "is_store",
      label: "Punto de venta",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data) => {
          return <span>{data ? "Tienda" : "Cafeteria"}</span>;
        },
      },
    },
  ];
  const options = {
    responsive: "scrollMaxHeight",
    pagination: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    textLabels: {
      body: { noMatch: "No se encontraron resultados" },
    },
    onRowClick: (data) => openDialog("edit", data[0]),
  };

  function openDialog(mode, id = null) {
    switch (mode) {
      case "edit":
        configCRUD.getStoreCategory(setStoreCategory, id).then(() => setDialog(mode, id));
        break;
      case "create":
        setStoreCategory(configCRUD.defaultStoreCategory);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setStoreCategory(configCRUD.defaultStoreCategory);
        }, 100);
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="text-right mb-4">
        {permit.storeCategoriesCreate && (
          <CustomButton title="Crear categoria" onClick={() => openDialog("create")} />
        )}
      </div>
      <Paper>
        <div className={styles.wrapper}>
          <MUIDataTable
            title="Categorías"
            data={store_categories.data ? store_categories.data : []}
            columns={columns}
            options={options}
          />
        </div>
      </Paper>
      <CategoryDialog mode={dialog} setDialog={openDialog} />
    </>
  );
}
const mapStateToProps = (store) => ({
  store_categories: store.config.store_categories,
});
export default connect(mapStateToProps, config.actions)(ConfigurationStore);
