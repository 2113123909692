import { CLIENTS_ATTENDANCE } from './helpers/routes'
import { getParams, handleError, handleResponse } from './helpers/validate'

export const fetchAttendance = (page=1, per_page=10, params) => {
  return fetch(`${CLIENTS_ATTENDANCE}?page=${page}&per_page=${per_page}&${params}`, getParams("GET"))
  .then(handleResponse)
  .catch(handleError)
}

export const updateAttendance = (data) => {
  return fetch(`${CLIENTS_ATTENDANCE}/update_attendances`, getParams("PUT", JSON.stringify(data)))
  .then(handleResponse)
  .catch(handleError)
}