import React, { useState, useEffect } from "react";
import CustomButton from "../../../../common/components/CustomButton";
import FullTable from "../../../../common/components/FullTable";
import { filtersFormReport, reportListColumn, defaultFilterReport } from "../utils";
import {
  getReportFilter,
  getReportInstructorsList,
  getReportPDFById,
  getReportPDFByLocation,
} from "../../../../crud/instructors.crud";
import { getLocations } from "../../../../crud/config.crud";
import { setLoader } from "../../../../store/ducks/loader.duck";
import { useDispatch } from "react-redux";
import ReportDialogNew from "./dialogReport/ReportDialogNew";
import ReportDialogEdit from "./dialogReport/ReportDialogEdit";
import UtilInputs from "../../../../common/components/UtilInputs";
import notice from "../../../../utils/notice";
import moment from "moment";

const ReportPage = () => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed");
  const [isEdit, setIsEdit] = useState("");
  const [filter, setFilter] = useState(defaultFilterReport);
  const [newReport, setNewReport] = useState("");
  const [reportList, setReportList] = useState([]);
  const [locationsInput, setLocationsInput] = useState([]);

  useEffect(() => {
    dispatch(setLoader(true));
    getReportInstructorsList()
      .then((res) => setReportList(res))
      .catch((e) => console.log(e))
      .finally(dispatch(setLoader(false)));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });

        setLocationsInput(
          res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id }))
        );
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, []);

  useEffect(() => {
    dispatch(setLoader(true));
    getReportFilter()
      .then((res) => setReportList(res))
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setLoader(false));
        setNewReport("");
      });
  }, [newReport]);

  const openDialog = (mode, data) => {
    if (mode === "editing") {
      setIsEdit(data);
      setDialog(mode);
    } else if (mode === "creating") {
      setDialog(mode);
    } else if (mode === "closed") {
      setDialog(mode);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const getFilterListReport = () => {
    dispatch(setLoader(true));
    let location_id;
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD"); //fecha innicio
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let idReport = filter.id_reporte_pago;
    let dateReport = moment(filter.date_report).format("YYYY-MM-DD");
    locationsInput.map((location) => {
      if (location.text === filter.client_location) {
        location_id = location.id;
      }
    });
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    let reportDate = dateReport === "Fecha inválida" ? "" : dateReport;
    if (
      location_id ||
      (end && start) ||
      (end === "" && start === "") ||
      idReport ||
      reportDate
    ) {
      getReportFilter(location_id, start, end, idReport, reportDate)
        .then((res) => {
          setReportList(res);
        })
        .catch((e) => notice("Ocurrió un error", "error"))
        .finally(() => dispatch(setLoader(false)));
    }
  };

  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const getReportPDF = (id) => {
    dispatch(setLoader(true));
    getReportPDFById(id)
      .then((res) => {
        if (res.status === 200) {
          res.blob().then((blob) => download(blob));
        } else if (res.status === 500) {
          notice("Ocurrio un error", "error");
        }
      })
      .catch((err) => notice("Ocurrio un error", "error"))
      .finally(() => {
        setNewReport("new");
        dispatch(setLoader(false));
      });
  };
  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: 10,
          }}
        >
          <UtilInputs
            formValues={filter}
            onValuesChange={handleChange}
            inputValues={filtersFormReport(locationsInput)}
            className="mb-3"
          />
          <CustomButton title="Filtrar" onClick={getFilterListReport} />
          <CustomButton
            title="Crear"
            onClick={() => openDialog("creating")}
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>
      <FullTable
        title={"Reporte instructores"}
        data={reportList.length > 0 ? reportList : []}
        columns={reportListColumn(openDialog, getReportPDF)}
        //   options={options(clientID, searchText, setSearchText, fetchStudents)}
      />
      {dialog === "editing" ? (
        <ReportDialogEdit
          // mode={dialog}
          setDialog={openDialog}
          idReport={isEdit}
          locationsInput={locationsInput}
        />
      ) : dialog === "creating" ? (
        <ReportDialogNew
          setDialog={openDialog}
          locationsInput={locationsInput}
          setNewReport={setNewReport}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ReportPage;
