import React, { useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import SimpleText from "../../../../common/components/SimpleText";
import { currencyFormat } from "../../../../utils/data_formatters";
import default_product from "../../../../../assets/images/default_product.png";
import { BASIC_URL } from "../../../../crud/helpers/routes";
import { useEffect } from "react";
import { searchProductsPointOfSale } from "../../../../crud/products.crud";
import notice from "../../../../utils/notice";

const StoreSaleTab = ({ summary, setSummary }) => {
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    searchProductsPointOfSale(query)
      .then(({ products }) => setProducts(products))
      .catch(console.log);
  }, [query]);

  const handleAddProduct = (prod) => {
    if (summary.client === null) {
      notice("Seleccionar cliente", "warning");
    } else {
      let updatedState = summary.products;
      let index = summary.products.findIndex((pr) => pr.id === prod.id);
      if (index !== -1) {
        updatedState[index].quantity += 1;
      } else {
        updatedState = [...updatedState, { ...prod, quantity: 1 }];
      }
      setSummary({ ...summary, products: updatedState });
    }
  };
  return (
    <Grid container spacing={2}>
      <>
        <Grid item xs={12}>
          <SimpleText
            label="Buscar productos"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
          />
        </Grid>
        {products.map((pr) => (
          <Grid item xs={12} sm={4} md={3} key={pr.id}>
            <Card>
              <CardActionArea onClick={() => handleAddProduct(pr)}>
                <CardMedia
                  height={100}
                  component="img"
                  alt={pr.name}
                  src={pr.image ? `${BASIC_URL}${pr.image}` : default_product}
                />
                <CardContent className="text-center">
                  <p className="font-weight-bold text-primary">{pr.name}</p>
                  <b>{pr.code}</b>
                  <p>{pr.description}</p>
                  <b>{pr.store_category?.name}</b>
                  <p>{currencyFormat.format(pr.price)}</p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </>
    </Grid>
  );
};

export default StoreSaleTab;
