import ActionsColumn from "./components/ActionsColumn";

export const columns = (setID, openDialog) => [
  {
    name: "name",
    label: "Nivel",
    options: {
     filter: false,
     sort: false,
    }
  },
  {
    name: "description",
    label: "Descripción",
    options: {
     filter: false,
     sort: false,
    }
  },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: value => ({className: 'text-center'}),
      customBodyRender:(data, dataRow, rowIndex) => ActionsColumn(data, setID, openDialog)
    }
  },
]
