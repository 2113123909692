import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./Dashboard";
import ConfiguracionWrapper from "./configuracion/ConfiguracionWrapper";
import MantenimientoWrapper from "./mantenimiento/MantenimientoWrapper";
import SugerenciasWrapper from "./sugerencias/SugerenciasWrapper";
import AyudaWrapper from "./ayuda/AyudaWrapper";
import { LayoutSplashScreen } from "../../../_metronic";
import UserWrapper from "./user/UserWrapper";
import ClientWrapper from "./clients/ClientWrapper";
import Instructors from "./Instructors";
import MeetingWrapper from "./meeting/MeetingWrapper";
import ClientProfile from "./clients/clientProfile/ClientProfile";
import ClientQuota from "./clients/clientProfile/ClientQuota";
import AttendancePage from "./Attendance/AttendancePage";
import AttendeceByGroupPage from "./Attendance/AttendeceByGroupPage";
import Debtors from "./students/Debtors";
import PointOfSalePage from "./PointOfSale/PointOfSalePage";
import PaymentSuccessful from "./PointOfSale/PaymentSuccessful";
import Students from "./students/Students";
import Products from "./products/Products";
import Incomes from "./Incomes";
import MPPayments from "./MPPayments";
import PaymentCut from "./PaymentCut";
import MinorBox from "./miniorBox/MinorBox";
import Discharges from "./Discharges/Discharges";
import ReportPage from "./Instructors/reportes/ReportPage";
import { NewStudent } from "./students/NewStudent";

//TODO: borrar routes con permisos
export default function HomePage() {
  const { permissions, role, responsible } = useSelector(({ auth }) => auth.user.user);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect
            exact
            from="/"
            to={
              role.name === "Cliente"
                ? `/mi-perfil/${responsible.id}`
                : role.name?.includes("Instructor")
                ? "/meetings"
                : role.id === 9
                ? "/punto-de-venta/:id?"
                : "/clients"
            }
          /> // Cambiar a dashboard cuando se este listo
        }

        <Route path="/dashboard" component={Dashboard} />
        {permissions.includes("config:read") && (
          <Route path="/configuracion" component={ConfiguracionWrapper} />
        )}
        <Route path="/mantenimiento" component={MantenimientoWrapper} />
        {permissions.includes("suggestions:read") && (
          <Route path="/sugerencias" component={SugerenciasWrapper} />
        )}
        <Route path="/ayuda" component={AyudaWrapper} />
        {permissions.includes("users:read") && (
          <Route path="/users" component={UserWrapper} />
        )}
        {permissions.includes("responsibles:read") && role.name !== "Cliente" && (
          <Route path="/clients" component={ClientWrapper} />
        )}
        <Route path="/mi-perfil/:id" component={ClientProfile} />
        <Route path="/client/:id" component={ClientProfile} />
        <Route path="/minor-box" component={MinorBox} />
        <Route path="/discharges" component={Discharges} />

        <Route path="/cuota" component={ClientQuota} />

        {permissions.includes("calendar:read") && (
          <Route path="/meetings" component={MeetingWrapper} />
        )}
        {permissions.includes("instructors:read") && (
          <Route path="/instructors" component={Instructors} />
        )}
        {permissions.includes("products:read") && (
          <Route path="/products" component={Products} />
        )}
        <Route path="/pago-exitoso" component={PaymentSuccessful} />
        {(permissions.includes("sale_point:read") || role.id == 9) && (
          <Route path="/punto-de-venta/:id?" component={PointOfSalePage} />
        )}
        <Route path="/report/list" component={ReportPage} />

        <Route path="/students/list" component={Students} />
        <Route path="/new-students/list" component={NewStudent} />

        <Route path="/students/debtors" component={Debtors} />
        <Route path="/asistencia" component={AttendancePage} />
        <Route path="/asistencia-por-grupo/:id" component={AttendeceByGroupPage} />
        <Route path="/ingresos" component={Incomes} />
        <Route path="/cortes" component={PaymentCut} />

        <Route path="/pagos-mercado-pago" component={MPPayments} />
        <Redirect to={"/error/error-v1"} />
      </Switch>
    </Suspense>
  );
}
