import React from 'react'
import { MenuItem, Select, FormControl, InputLabel, FormHelperText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
		paddingBottom: 4,
  },
}));

const SimpleDropdownChip = ({ label, name, onChange, value, options=[], variant="standard", disabled, color="primary", className="", containerClass="", style={}, margin='none', error, helperText="", onBlur=console.log }) => {
  const classes = useStyles()
  return (
    <FormControl error={error} margin={margin} variant={variant} fullWidth size="small" style={style} className={containerClass}>
			<InputLabel>{label}</InputLabel>
			<Select
				fullWidth
				displayEmpty
        margin={margin}
        onBlur={onBlur}
        className={className}
        classes={classes}
        label={label}
				color={color}
				value={value}
				onChange={onChange}
				variant={variant}
				size="small"
				name={name}
        disabled={disabled}
				renderValue={selected => (
          selected ? <span className={`label label-lg label-light-${options.find(op => op.value === selected)?.color} label-inline border border-${options.find(op => op.value === selected)?.color}`}>
            {options.find(op => op.value === selected)?.text}
          </span> : <span className={`label label-lg label-light-dark label-inline border border-dark`}>
            Seleccione una opción
          </span>
        )}
			>
        <MenuItem value="">
          <span className={`label label-lg label-light-dark label-inline`}>
            Seleccione una opción
          </span>
        </MenuItem>
				{options.map(option => option && (
					<MenuItem key={option.value.id ? option.value.id : option.value } value={option.value}>
            <span className={`label label-lg label-light-${option.color} label-inline`}>
						  {option.text}
            </span>
					</MenuItem>
				))}
			</Select>
      {helperText&&<FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
  )
}

export default SimpleDropdownChip
