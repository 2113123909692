import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import IconButton from '../../../../../../common/components/IconButton';

const actionsColumns = (
    data,
    setID = console.log,
    openDialog = console.log
) => {
    return (
        <div className='d-flex justify-content-around'>
            <IconButton
                tooltip='Borrar expectativa'
                color='danger'
                Icon={Delete}
                onClick={() => setID(data)}
            />
            <IconButton
                tooltip='Editar expectativa'
                color='primary'
                Icon={Edit}
                onClick={() => openDialog('editing', data)}
            />
        </div>
    );
};

export default actionsColumns;
