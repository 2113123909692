import React, { useState } from "react";
import BaseModal from "../../../common/components/BaseModal";
import UtilInputs from "../../../common/components/UtilInputs";
import { defaultPayment, paymentForm } from "./utils";

const PaymentModal = ({ open, setOpen }) => {
  const [form, setForm] = useState(defaultPayment);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };
  const handleConfirm = () => {
    setClose();
  };

  const setClose = () => {
    setOpen(false);
  };

  return (
    <BaseModal
      hideTitle
      open={open}
      onClose={setClose}
      onCancel={setClose}
      onConfirm={handleConfirm}
      confirmLabel="Si, proceder a pagar"
    >
      <UtilInputs
        width={"50%"}
        formValues={form}
        inputValues={paymentForm}
        onValuesChange={handleChange}
      />
    </BaseModal>
  );
};

export default PaymentModal;
