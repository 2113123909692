export const currencyFormat = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export const createURL = (file) => URL.createObjectURL(file);

export const downLoaderWithURL = (url, name) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  //a.download = name;
  a.setAttribute("download", name);
  a.click();
};
