import { TextField } from "@material-ui/core";
import React from "react";

const SimpleColor = ({
  label = "",
  name = "",
  onChange = () => {},
  value = "",
  variant = "standard",
  disabled = false,
  style = {},
  color = "primary",
  className = "",
  error = false,
  helperText = "",
  margin = "none",
  onBlur = console.log,
}) => {
  return (
    <TextField
      fullWidth
      type="color"
      size="small"
      name={name}
      label={label}
      style={style}
      margin={margin}
      onBlur={onBlur}
      color={color}
      className={className}
      variant={variant}
      onChange={onChange}
      value={value}
      disabled={disabled}
      helperText={helperText}
      error={error}
      InputLabelProps={{ shrink: Boolean(value) }}
    />
  );
};

export default SimpleColor;
