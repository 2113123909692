import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { defaultUser } from '../../crud/users.crud'

export const actionType = {
    GetUsers: '[Config] GET_USERS',
    GetUser: '[Config] GET_USER',
    UpdateUser: '[Config] UPDATE_USER'
}

const initialConfigState = {
    users: {data: [], isFetched: false},
    user: defaultUser
}

export const reducer = persistReducer(
    { storage, key: 'user', whitelist: []},
    (state = initialConfigState, action) => {
        switch (action.type) {
            case actionType.GetUsers: {
                return { ...state, users: {data: action.payload.users, isFetched: true} }
            }

            case actionType.GetUser: {
                return { ...state, user: action.payload.user }
            }

            case actionType.UpdateUser: {
                let newData
                const index = state.users.data.findIndex( x => x.id === action.payload.user.id)
                if (index === -1) {
                    newData = [action.payload.user, ...state.users.data]
                } else {
                    newData = state.users.data
                    newData[index] = action.payload.user
                }

                return {...state, users: {data: newData, isFetched: true}}
            }

            default:
                return state
        }
    }
)

export const actions = {
    setUsers: users => ({ type: actionType.GetUsers, payload: users}),
    setUser: user => ({ type: actionType.GetUser, payload: user }),
    updateUser: user => ({ type: actionType.UpdateUser, payload: user})
  };