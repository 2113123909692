import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import styles from './Ayuda.module.css';
import AyudaDialog from './AyudaDialog';
import FullScreenVideo from './FullScreenVideo';

import * as hlp from "../../../store/ducks/help.duck";
// import * as configCRUD from "../../../crud/config.crud";

function Ayuda({setHelpObject, setHelpVideo, help}) {

  const [dialog, setDialog] = useState('closed')
  const [videoDialog, setVideoDialog] = useState(false)
  const [title, setTitle] = useState("")

  //Aqui tenemos que definir los iconos que aparecen en el fondo del objeto de ayuda y se llama en el <Paper />
  function svgs(item){
    switch(item){
      case 'Sugerencias':
        return "url(/media/bg/sugerencias.svg)"
      case 'Mantenimiento':
        return "url(/media/bg/mantenimiento.svg)"
      case 'Usuarios':
        return "url(/media/bg/usuarios.svg)"
      default:
        return "url(/media/bg/sugerencias.svg)"
    }
  }

  function openDialog(mode, item = null){
    switch (mode) {
      case 'open':
        setDialog(mode)
        if(item != null){
          setHelpObject(item['videos'])
          setTitle(item['title'])
        }
        break;
      case 'closed':
        setDialog(mode)
        setTimeout(() => {
          setHelpObject([])
        }, 200);
        break;
      default:
        console.error('Invalid dialog mode');
    }

  }

  function openVideoDialog(mode, obj){
    setHelpVideo(obj)
    setVideoDialog(mode)
  }


  return (
    <div>
      <Grid container spacing={4}>
        {help.map((item) => {
          return(
            <Grid key={item['title']} item xs={4}>
              <Paper onClick={() => openDialog('open', item)} elevation={24} className={styles.paper} style={{background: svgs(item['title']), backgroundRepeat:"no-repeat"}}>
                {item['title']}
              </Paper>
            </Grid>
          )
        })}
      </Grid>

      <AyudaDialog mode={dialog} setDialog={openDialog} title={title} openVideoDialog={openVideoDialog}/>
      <FullScreenVideo mode={videoDialog} setVideoDialog={setVideoDialog}/>
    </div>
  )
}

const mapStateToProps = store => ({
  help: store.hlp.help.data
});

export default connect(mapStateToProps, hlp.actions)(Ayuda)
