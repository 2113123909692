import { customSearchInput } from '../../../common/components/FullTable';

export const defaultFilter = {
    client_location: '',
};
export const filtersForm = (locations = []) => [
    {
        name: 'client_location',
        label: 'Sucursal',
        type: 'dropdown',
        variant: 'filled',
        options: locations,
    },
];
export const columns = (
    areas,
    suggestion_types,
    suggestion_statuses,
    suggestion_sources,
    users,
    locations,
    user
) => [
    {
        name: 'id',
        label: 'id',
        options: {
            filter: false,
            sort: false,
            display: false,
        },
    },
    {
        name: 'title',
        label: 'Título',
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: 'description',
        label: 'Descripción',
        options: {
            display: false,
            filter: false,
            sort: false,
        },
    },
    {
        name: 'area_id',
        label: 'Área',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const area = areas.find((x) => x.id === data);
                return area?.name || '';
            },
        },
    },
    {
        name: 'suggestion_type_id',
        label: 'Tipo',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const type = suggestion_types.find(
                    (x) => x.id === data
                );
                return type?.name || '';
            },
        },
    },
    {
        name: 'suggestion_status_id',
        label: 'Estado',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const status = suggestion_statuses.find(
                    (x) => x.id === data
                );
                return status?.name || '';
            },
        },
    },
    {
        name: 'suggestion_source_id',
        label: 'Fuente',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const source = suggestion_sources.find(
                    (x) => x.id === data
                );
                return source?.name || '';
            },
        },
    },
    {
        name: 'user_id',
        label: 'Responsable',
        options: {
            sort: false,
            filter: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const resp = users.find((x) => x.id === data);
                return resp?.name || '';
            },
        },
    },
    {
        name: 'location_id',
        label: 'Ubicación',
        options: {
            display: user.location_id ? false : true,
            sort: false,
            filter: false,
            customBodyRender: (data, dataIndex, rowIndex) => {
                const location = locations.find((x) => x.id === data);
                return location?.name || '';
            },
        },
    },
];

const headerNames = [
    { name: 'Título', download: true },
    { name: 'Área', download: true },
    { name: 'Tipo', download: true },
    { name: 'Estado', download: true },
    { name: 'Fuente', download: true },
    { name: 'Responsable', download: true },
    { name: 'Ubicación', download: true },
];

export const options = (
    page,
    setPage,
    count,
    openDialog,
    searchText,
    setSearchText,
    fetchSuggestions,
    areas,
    suggestion_types,
    suggestion_statuses,
    suggestion_sources,
    users,
    locations
) => ({
    serverSide: true,
    count,
    page: page - 1,
    onChangePage: (newPage) => setPage(newPage),
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    onRowClick: (data) => openDialog('editing', data[0]),
    customSearchRender: () =>
        customSearchInput(
            searchText,
            setSearchText,
            fetchSuggestions
        ),
    downloadOptions: {
        filename: 'Sugerencias.csv',
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
        function replaceItems(obj) {
            obj.data[1] = areas.find(
                (x) => x.id === obj.data[1]
            ).name;
            obj.data[2] = suggestion_types.find(
                (x) => x.id === obj.data[2]
            ).name;
            obj.data[3] = suggestion_statuses.find(
                (x) => x.id === obj.data[3]
            ).name;
            obj.data[4] = suggestion_sources.find(
                (x) => x.id === obj.data[4]
            ).name;
            obj.data[5] = users.find(
                (x) => x.id === obj.data[5]
            ).name;
            obj.data[6] = locations.find(
                (x) => x.id === obj.data[5]
            ).name;
            return obj;
        }
        const table =
            buildHead(headerNames) +
            buildBody(data.map(replaceItems));
        return table;
    },
});

export const suggForm = (
    responsibles,
    areas,
    isAdmin,
    locations,
    statuses,
    types,
    sources,
    disabled
) => [
    {
        name: 'title',
        label: 'Titulo',
        type: 'text',
        disabled,
    },
    {
        name: 'user_id',
        label: 'Responsable',
        type: 'dropdown',
        disabled,
        options: responsibles.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
    {
        name: 'date',
        label: 'Fecha de reporte',
        type: 'date',
        disabled,
    },
    {
        name: 'area_id',
        label: 'Área',
        type: 'dropdown',
        disabled,
        options: areas.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
    {
        name: 'client_phone',
        label: 'Número de teléfono',
        type: 'text',
        disabled,
        mask: '999 999 9999',
    },
    {
        name: 'client_email',
        label: 'Correo',
        type: 'text',
        disabled,
    },
    isAdmin && {
        name: 'location_id',
        label: 'Ubicación',
        type: 'dropdown',
        disabled,
        options: locations.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
    // },
    {
        name: 'description',
        label: 'Descripción',
        variant: 'outlined',
        multiline: true,
        width: '100%',
        type: 'text',
        rows: 4,
        disabled,
    },
    {
        name: 'suggestion_status_id',
        label: 'Estado',
        type: 'dropdown',
        disabled,
        options: statuses.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
    {
        name: 'suggestion_type_id',
        label: 'Tipo',
        type: 'dropdown',
        disabled,
        options: types.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
    {
        name: 'suggestion_source_id',
        label: 'Fuente',
        type: 'dropdown',
        disabled,
        options: sources.map(({ id, name }) => ({
            value: id,
            text: name,
        })),
    },
];
