import React, { useState, createRef, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Fab } from "@material-ui/core";
import { Add, PrintOutlined } from "@material-ui/icons";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

import * as config from "../../../store/ducks/config.duck";
import * as configCRUD from "../../../crud/config.crud";
import store from "../../../store/store";
import MeetingDialog from "./MeetingDialog";
import InfoMeetDialog from "./components/InfoMeetDialog";
import { invertColor } from "../../../utils/colors";
import notice from "../../../utils/notice";
import { setLoader } from "../../../store/ducks/loader.duck";
import SimpleDropdown from "../../../common/components/SimpleDropdown";
import { getInstructorByLocation } from "../../../crud/meeting.crud";

const Meeting = ({
  levels,
  categories,
  setGroup,
  instructors,
  group,
  students,
  user,
}) => {
  const { role } = store.getState().auth.user.user;
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed");
  const [calendarType, setCalendarType] = useState("listWeek");
  const [instructor, setInstructor] = useState("");
  const [allInstructors, setAllInstructors] = useState([]);
  const [groups, setGroups] = useState([]);
  //tipo de curso
  const [typeCurso, setTypeCurso] = useState("");

  const [locationF, setLocationF] = useState("");
  const [locations, setLocations] = useState([]);
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  const [time_s, setTime_s] = useState(firstDay);
  const [time_e, setTime_e] = useState(lastDay);
  const [events, setEvents] = useState([]);
  const calendarRef = createRef();

  useEffect(() => {
    configCRUD.getLocations().then((res) => {
      setLocations(res);
      setLocationF(res[0].id);
      if (res.length > 0) {
        getInstructorByLocation(res[0].id)
          .then((res) => {
            setAllInstructors(res);
          })
          .catch((err) => console.log(err));
      }
    });
    configCRUD.getGroupPrices(setGroups);
    configCRUD.getAllIntructors(setAllInstructors);
  }, []);

  const handleDropDownLocation = (e) => {
    setLocationF(e.target.value);
    setInstructor("");
    if (e.target.value === "") {
      configCRUD.getAllIntructors(setAllInstructors);
    } else {
      getInstructorByLocation(e.target.value)
        .then((res) => {
          setAllInstructors(res);
        })
        .catch((err) => console.log(err));
    }
  };
  const fixEventsData = (data) => {
    let dummy_events =
      data &&
      data.map((event) =>
        event.schedules.map((schedule, i) => ({
          id: schedule.group_id,
          title: `${schedule.group_name} - Instructor: ${event.instructor} - Nivel: ${
            event.levels
          } - Edad Promedio: ${schedule.average_age} - Cupo: ${event.availability -
            schedule.free_places}/${event.availability}`,
          start: schedule.start_time,
          end: schedule.end_time,
          textColor: invertColor(schedule.group_color, true),
          color: schedule.group_color,
        }))
      );

    let arr = [];

    dummy_events &&
      dummy_events.forEach((element) =>
        element.forEach((e) => {
          arr.push({
            id: e.id,
            title: e.title,
            start: e.start,
            end: e.end,
            textColor: e.textColor,
            color: e.color,
          });
          return;
        })
      );
    setEvents(arr);
  };

  const fetchEvents = useCallback(() => {
    dispatch(setLoader(true));
    setEvents([]);
    if (instructor && typeCurso === "") {
      configCRUD
        .getGroupsInstructor(setGroup, instructor, time_s, time_e)
        .then((data) => {
          fixEventsData(data.payload.groups);
        })
        .catch(() => notice("Error cargando los eventos"))
        .finally(() => dispatch(setLoader(false)));
    } else if (typeCurso) {
      configCRUD
        .getGroupsByTypeCurse(setGroup, typeCurso, locationF, instructor)
        .then((data) => {
          fixEventsData(data.payload.groups);
        })
        .catch(() => notice("Error cargando los eventos"))
        .finally(() => dispatch(setLoader(false)));
      dispatch(setLoader(false));
    } else if (locationF && time_s && time_e) {
      configCRUD
        .getGroupsDate(setGroup, locationF, time_s, time_e)
        .then((data) => fixEventsData(data.payload.groups))
        .catch(() => notice("Error cargando los eventos"))
        .finally(() => dispatch(setLoader(false)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructor, typeCurso, time_e, locationF]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const changeView = (type) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(type);
    setCalendarType(type);
  };

  const openDialog = async (mode, id = null) => {
    switch (mode) {
      case "creating":
        setGroup(configCRUD.defaultGroup);
        setDialog(mode);
        break;
      case "editing":
        configCRUD.getGroup(setGroup, id).then(() => setDialog(mode));
        break;
      case "closed":
        await setGroup(configCRUD.defaultGroup);
        setDialog(mode);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  };

  const getDates = (arg) => {
    if (arg) {
      setTime_s(arg.view.activeStart);
      setTime_e(arg.view.activeEnd);
    }
  };

  return (
    <>
      {(user.role?.name?.includes("Admin") || user.role?.name?.includes("Operador")) && (
        <div
          style={{
            width: 500,
            float: "right",
            display: "flex",
            // paddingLeft: 15,
          }}
        >
          {/* <> */}
          {/* TIPO DE CURSO */}

          {/* {!instructorF && ( */}
          <SimpleDropdown
            containerClass="ml-3"
            label="Sucursal"
            value={locationF}
            options={locations?.map(({ id, name }) => ({
              value: id,
              text: name,
            }))}
            onChange={handleDropDownLocation}
          />
          {/* )}
            {!locationF && ( */}
          <SimpleDropdown
            containerClass="ml-3"
            label="Instructor"
            value={instructor}
            options={allInstructors?.users?.map(({ id, name }) => ({
              value: id,
              text: name,
            }))}
            onChange={(e) => setInstructor(e.target.value)}
          />
          <SimpleDropdown
            containerClass="ml-3"
            label="Tipo de curso"
            value={typeCurso}
            options={groups?.group_prices?.map(({ category_id, category_name }) => ({
              value: category_id,
              text: category_name,
            }))}
            onChange={(e) => setTypeCurso(e.target.value)}
          />
          <SimpleDropdown
            containerClass="ml-3"
            label="Vista"
            value={calendarType}
            options={[
              { value: "listWeek", text: "Lista" },
              { value: "timeGridDay", text: "Día" },
              { value: "timeGridWeek", text: "Semana" },
            ]}
            onChange={(e) => changeView(e.target.value)}
          />
          {/* )} */}
          {/* </> */}
        </div>
      )}
      {/* <div
        style={{ width: 100, float: "right", display: "flex", outline: "2px solid blue" }}
      >
        
      </div> */}
      <FullCalendar
        nowIndicator
        events={events}
        ref={calendarRef}
        locale="es"
        height="parent"
        timeZone="local"
        allDaySlot={false}
        minTime="06:00:00"
        maxTime="24:00:00"
        locales={esLocale}
        defaultView={"listWeek"}
        columnHeaderFormat={{
          weekday: "long",
          day: "numeric",
        }}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }}
        plugins={[timeGridPlugin, listPlugin, interactionPlugin]}
        header={{
          left: "prev,next today, myCustomButton",
          center: "title",
          right: "",
        }}
        datesRender={getDates}
        eventClick={(info) => openDialog("editing", info.event.id)}
      />
      <Fab
        aria-label="Print"
        className="fab-add-nested print"
        onClick={() => window.print()}
      >
        <PrintOutlined fontSize="large" />
      </Fab>
      {role.name !== "Cliente" && (
        <Fab
          aria-label="Add"
          className="fab-add"
          hidden={dialog !== "closed"}
          onClick={() => openDialog("creating")}
        >
          <Add />
        </Fab>
      )}

      <MeetingDialog
        mode={dialog}
        setDialog={openDialog}
        levels={levels}
        categories={categories}
        instructors={allInstructors}
        fetchEvents={fetchEvents}
      />
      {role.name !== "Cliente" && (
        <InfoMeetDialog
          open={dialog === "editing"}
          setOpen={openDialog}
          group={group}
          students={students}
          fetchEvents={fetchEvents}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user.user,
  levels: store.config.levels.data,
  categories: store.config.categories.data,
  groups: store.config.groups,
  group: store.config.group,
  instructors: store.config.instructors.data,
  students: store.clients.students.data,
});

export default connect(mapStateToProps, config.actions)(Meeting);
