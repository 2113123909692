import React from 'react'
import BaseModal from '../../../../common/components/BaseModal'

const DeleteModal = ({ id, setID, deleteInstrutor }) => {


  return (
    <BaseModal
      maxWidth='sm'
      open={Boolean(id)}
      onClose={() => setID(false)}
      onCancel={() => setID(false)}
      onConfirm={deleteInstrutor}
      confirmLabel="Si, eliminar"
      hideTitle
    >
      <h4 className='text-center'>¿Estas seguro que quieres borrar este instructor?</h4>
    </BaseModal>
  )
}

export default DeleteModal