import React from "react";
import { Info, PictureAsPdf, Receipt } from "@material-ui/icons";
import IconButton from "../../../../common/components/IconButton";

const InvoiceColumn = (
  data,
  dataIndex,
  setDownloadInvoice,
  setInvoice,
  loadInvoice,
  handleInvoicePDF,
  params,
  permissions
) => {
  const [
    id_payment,
    id_responsible,
    id_quickbooks,
    full_name_responsible,
    client,
    type_client,
    client_location,
    type,
    product,
    amount,
    payment_type,
    invoice_information,
  ] = dataIndex.rowData;
  const invoiceData = {
    ...invoice_information,
    ...data,
    id_payment,
    id_responsible,
    id_quickbooks,
    full_name_responsible,
    client,
    type_client,
    client_location,
    type,
    product,
    amount,
    payment_type: payment_type || "",
  };
  // return type_client !== "A" && payment_type === "cash" ? (
  //   "N/A"
  // ) :
  return (
    <div className={`d-flex justify-content-${id_quickbooks ? "center" : "between"}`}>
      {params && id_quickbooks && (
        <IconButton
          color={"info"}
          tooltip={"Descargar Factura"}
          Icon={PictureAsPdf}
          onClick={() => handleInvoicePDF(id_responsible, id_quickbooks?.quickbook_id)}
        />
      )}

      {!id_quickbooks && permissions.includes("incomes:individual_invoice") && (
        <IconButton
          color={"success"}
          tooltip={"Facturar"}
          Icon={Receipt}
          onClick={() => setInvoice(invoiceData)}
        />
      )}
      {params && data?.email && !id_quickbooks && (
        <IconButton
          color={"primary"}
          tooltip={"Consultar factura"}
          Icon={Info}
          onClick={() => loadInvoice(id_payment)}
        />
      )}
    </div>
  );
};

export default InvoiceColumn;
