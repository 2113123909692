import CategoriesColorColumn from "./components/CategoriesColorColumn";
import CategoriesActionsColumn from "./components/CategoriesActionsColumn";

export const categories_columns = (setID, openDialog) => [
  {
    name: "clave",
    label: "Clave",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "name",
    label: "Categoria",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "color",
    label: "Color",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => CategoriesColorColumn(data),
    },
  },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) => CategoriesActionsColumn(data, setID, openDialog),
    },
  },
];
