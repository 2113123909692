import React from "react";
import { Delete, Edit } from "@material-ui/icons";
import IconButton from "../../../../common/components/IconButton";

export const ActionsColumn = (data, openDialog, handleRemove) => {
  return (
    <div className="d-flex justify-content-between">
      <IconButton
        Icon={Edit}
        tooltip="Editar"
        onClick={() => openDialog("editing", data)}
        color="primary"
      />
      <IconButton
        Icon={Delete}
        tooltip="Borrar"
        onClick={() => handleRemove(data)}
        color="danger"
      />
    </div>
  );
};
