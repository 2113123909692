import React, { useState, useEffect } from "react";
import CustomButton from "../../../../../common/components/CustomButton";
import FullTable from "../../../../../common/components/FullTable";
import { columnsExpectation } from "./utils";
import ConfirmDialog from "../../../../../utils/ConfirmDialog";
import ExpectationDialog from "./ExpectationDialog";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import { useDispatch } from "react-redux";

export const ConfiguracionExpectation = ({ permit }) => {
  const dispatch = useDispatch();
  const [removeId, setRemoveId] = useState(false);
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const [idEdit, setIdEdit] = useState({});
  const [expectationList, setExpectationList] = useState([]);
  const [updateList, setUpdateList] = useState("");

  useEffect(() => {
    configCRUD.getExpectationList(setExpectationList);
  }, []);

  useEffect(() => {
    configCRUD.getExpectationList(setExpectationList).finally(() => {
      setUpdateList("");
    });
  }, [updateList]);

  const handleOpenRemoveModal = (id) => setRemoveId(id);

  const openDialog = (mode, id = null) => {
    if (mode === "editing") {
      expectationList.map((expectativa) => {
        if (expectativa.value === id) {
          setIdEdit(expectativa);
        }
      });
      setDialog(mode);
    } else if (mode === "creating") {
      setDialog(mode);
    } else {
      setDialog(mode);
      setIdEdit({});
    }
  };

  const handleRemove = () => {
    dispatch(setLoader(true));
    configCRUD
      .deleteExpectation(removeId)
      .then((res) => {
        notice("Se elimino correctamente la expectativa", "success");
        setUpdateList("delete");
      })
      .catch(() => {
        notice("No se pudo eliminar la expectativa", "error");
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
  return (
    <>
      <div className="text-right mb-4">
        <CustomButton title="Crear expectativa" onClick={() => openDialog("creating")} />
      </div>
      {/* <Paper>
        <div className={styles.wrapper}> */}
      <FullTable
        title="Expectativas"
        data={expectationList.data ? expectationList.data : []}
        columns={columnsExpectation(handleOpenRemoveModal, openDialog)}
      />
      <ConfirmDialog
        openConfirm={removeId !== false}
        setConfirm={() => setRemoveId(false)}
        functionConfirm={handleRemove}
        message={<h4>¿Estas seguro que deseas eliminar esta expectativa?</h4>}
      />
      <ExpectationDialog
        mode={dialog}
        setDialog={openDialog}
        idEdit={idEdit}
        setUpdateList={setUpdateList}
      />
    </>
  );
};
