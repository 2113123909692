export const formInputs = (permissions, hasLocation, locations) => [
  {
    name: "name",
    label: "Nombre del rol",
    type: "text",
    width: "100%",
  },
  {
    name: "description",
    label: "Descripción",
    type: "text",
    width: "100%",
  },
  {
    name: "color",
    label: "Color",
    type: "color",
    width: "50%",
  },
  !hasLocation && {
    name: "location_id",
    label: "Permisos",
    width: "50%",
    type: "dropdown",
    options: locations.map(({ id, name }) => ({
      value: id,
      text: name,
    })),
  },
  {
    name: "permission_ids",
    label: "Permisos",
    type: "dropdown",
    width: "100%",
    multiple: true,
    options: permissions.map(({ id, description }) => ({
      value: id,
      text: description,
    })),
  },
];
