import React, { useState } from 'react'
import { FormControl, Select, MenuItem, TextField, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { defaultWaterSupply } from '../../../../crud/maint.crud';

export const MantWaterSupply = ({values, handleChange, users}) => {
  const [items, setItems] = useState(values.water_supplies)
  
  const generateRow = () => {
    setItems([...items, defaultWaterSupply])
    return values.water_supplies.push(defaultWaterSupply)
  }

  return (
    <>
      <Table stickyHeader style={{marginTop: 20}}>
        <TableHead>
          <TableRow>
            <TableCell>Cloro</TableCell>
            <TableCell>PH</TableCell>
            <TableCell>Cloraminas</TableCell>
            <TableCell>Alcanicidad</TableCell>
            <TableCell>Dureza</TableCell>
            <TableCell>Fosfato</TableCell>
            <TableCell>SDT</TableCell>
            <TableCell>Lts. agregados</TableCell>
            <TableCell>Responsable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.water_supplies.map((supply, i) => (
            <TableRow key={supply.id ? supply.id : i +1 }>
              <TableCell>
                <TextField
                  name='chlorine'
                  type='number'
                  defaultValue={supply.chlorine}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='ph'
                  type='number'
                  defaultValue={supply.ph}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='chloramines'
                  type='number'
                  defaultValue={supply.chloramines}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='alkalinity'
                  type='number'
                  defaultValue={supply.alkalinity}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='hardness'
                  type='number'
                  defaultValue={supply.hardness}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='phosphate'
                  type='number'
                  defaultValue={supply.phosphate}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='sdt'
                  type='number'
                  defaultValue={supply.sdt}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name='added_lts'
                  type='number'
                  defaultValue={supply.added_lts}
                  onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                />
              </TableCell>
              <TableCell>
                <FormControl margin='normal' >
                    <Select
                      name='user_id'
                      defaultValue={supply.user_id}
                      onChange={e => handleChange({target: values.water_supplies[i] = {...values.water_supplies[i], [e.target.name]: parseFloat(e.target.value)}})}
                    >
                      {users.data.map(user => (
                        <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className='fab-add-row-content' >
        <Button fullWidth style={{border: 'dashed 2px'}} color='primary' onClick={() => generateRow()} >
          <AddIcon />
        </Button> 
      </div>
    </>
  )
}
