import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import * as clientsCRUD from "../../../../crud/clients.crud";

const InvoiceInformation = ({
  invoiceInformation,
  setInvoiceInformation,
  responsible,
}) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fiscal, setFiscal] = useState([]);
  const [cdfis, setCdfis] = useState([]);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });
  const States = [
    { value: "Aguascalientes", label: "Aguascalientes" },
    { value: "Baja California", label: "Baja California" },
    { value: "Baja California Sur", label: "Baja California Sur" },
    { value: "Campeche", label: "Campeche" },
    { value: "Chiapas", label: "Chiapas" },
    { value: "Coahuila", label: "Coahuila" },
    { value: "Colima", label: "Colima" },
    { value: "Ciudad de México", label: "Ciudad de México" },
    { value: "Durango", label: "Durango" },
    { value: "Guanajuato", label: "Guanajuato" },
    { value: "Guerrero", label: "Guerrero" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Jalisco", label: "Jalisco" },
    { value: "Estado de Mexico", label: "Estado de Mexico" },
    { value: "Michoacan", label: "Michoacan" },
    { value: "Morelos", label: "Morelos" },
    { value: "Nayarit", label: "Nayarit" },
    { value: "Nuevo Leon", label: "Nuevo Leon" },
    { value: "Oaxaca", label: "Oaxaca" },
    { value: "Puebla", label: "Puebla" },
    { value: "Queretaro", label: "Queretaro" },
    { value: "Quintana Roo", label: "Quintana Roo" },
    { value: "San Luis Potosi", label: "San Luis Potosi" },
    { value: "Sinaloa", label: "Sinaloa" },
    { value: "Sonora", label: "Sonora" },
    { value: "Tabasco", label: "Tabasco" },
    { value: "Tamaulipas", label: "Tamaulipas" },
    { value: "Tlaxcala", label: "Tlaxcala" },
    { value: "Veracruz", label: "Veracruz" },
    { value: "Yucatan", label: "Yucatan" },
    { value: "Zacatecas", label: "Zacatecas" },
  ];

  useEffect(() => {
    //catalogo
    clientsCRUD
      .getCatalogCFDI()
      .then((res) => {
        setCdfis(res);
      })
      .catch(console.log);

    //regimen
    clientsCRUD
      .getCatalogFiscal()
      .then((res) => {
        setFiscal(res);
      })
      .catch(console.log);
  }, []);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  return (
    <>
      <Paper>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
              Detalles de Facturacion
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {role?.name !== "Cliente" && !edit && (
              <Button
                style={{ marginRight: 25 }}
                color="primary"
                variant="contained"
                onClick={() => setEdit(true)}
              >
                Editar informacion
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        {edit ? (
          <Grid item xs={12} style={{ display: "flex" }}>
            <Formik
              initialValues={{
                invoice_person_type: invoiceInformation?.invoice_person_type,
                name: invoiceInformation?.name,
                email: invoiceInformation?.email,
                street: invoiceInformation?.street,
                interior_number: invoiceInformation?.interior_number,
                outdoor_number: invoiceInformation?.outdoor_number,
                suburb: invoiceInformation?.suburb,
                municipality: invoiceInformation?.municipality,
                state: invoiceInformation?.state,
                locality: invoiceInformation?.locality,
                country: invoiceInformation?.country,
                postal_code: invoiceInformation?.postal_code,
                rfc: invoiceInformation?.rfc,
                regimen_fiscal: invoiceInformation?.regimen_fiscal,
                cfdi_use: invoiceInformation?.cfdi_use?.code,
              }}
              validate={(values) => {
                const errors = {};
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                clientsCRUD
                  .updateInvoiceInformation(
                    values,
                    setInvoiceInformation,
                    responsible.responsible.id
                  )
                  .then(() => {
                    disableLoading();
                    setSubmitting(false);
                    window.scrollTo(0, 0);
                    setEdit(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} validate autoComplete="off">
                  <div className="form-group">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ display: "flex", marginTop: "25px" }}
                      >
                        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                          <Grid item sm={3}>
                            <Typography
                              variant="body1"
                              style={{
                                color: "#868686",
                                padding: "34px 0px 0px 27px",
                              }}
                            >
                              Persona
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            style={{ marginTop: "16px", marginBottom: "8px" }}
                          >
                            <FormControl fullWidth variant="standard">
                              <InputLabel id="invoice_person_type">
                                Tipo de Persona
                              </InputLabel>
                              <Select
                                labelId="invoice_person_type"
                                value={values.invoice_person_type}
                                onChange={handleChange}
                                name="invoice_person_type"
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "8px",
                                }}
                              >
                                <MenuItem value="Moral">Moral</MenuItem>
                                <MenuItem value="Fisica">Fisica</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Nombre
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Nombre"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Correo electronico
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Correo electronico"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Calle
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Calle"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="street"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            helperText={touched.street && errors.street}
                            error={Boolean(touched.street && errors.street)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Número Int.
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Número Int."
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="interior_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.interior_number}
                            helperText={touched.interior_number && errors.interior_number}
                            error={Boolean(
                              touched.interior_number && errors.interior_number
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Número Ext.
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Número Ext."
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="outdoor_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.outdoor_number}
                            helperText={touched.outdoor_number && errors.outdoor_number}
                            error={Boolean(
                              touched.outdoor_number && errors.outdoor_number
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Colonia
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Colonia"
                            margin="normal"
                            className="kt-width-full"
                            name="suburb"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.suburb}
                            helperText={touched.suburb && errors.suburb}
                            error={Boolean(touched.suburb && errors.suburb)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Municipio
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Municipio"
                            margin="normal"
                            className="kt-width-full"
                            name="municipality"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.municipality}
                            helperText={touched.municipality && errors.municipality}
                            error={Boolean(touched.municipality && errors.municipality)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ display: "flex", marginTop: "25px" }}
                      >
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Estado
                          </Typography>
                        </Grid>
                        <Grid item sm={6} style={{ paddingTop: "10px" }}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="state">Estado (opcional)</InputLabel>
                            <Select
                              labelId="state"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {States.map((b) => (
                                <MenuItem key={b.value} value={b.value}>
                                  {b.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Localidad
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Localidad"
                            margin="normal"
                            className="kt-width-full"
                            name="locality"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.locality}
                            helperText={touched.locality && errors.locality}
                            error={Boolean(touched.locality && errors.locality)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            País
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="País"
                            margin="normal"
                            className="kt-width-full"
                            name="country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.country}
                            helperText={touched.country && errors.country}
                            error={Boolean(touched.country && errors.country)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Código postal
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Código postal"
                            margin="normal"
                            className="kt-width-full"
                            name="postal_code"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.postal_code}
                            helperText={touched.postal_code && errors.postal_code}
                            error={Boolean(touched.postal_code && errors.postal_code)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            RFC
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="RFC"
                            margin="normal"
                            className="kt-width-full"
                            name="rfc"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.rfc}
                            helperText={touched.rfc && errors.rfc}
                            error={Boolean(touched.rfc && errors.rfc)}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Régimen fiscal
                          </Typography>
                        </Grid>
                        <Grid item sm={6} style={{ paddingTop: "10px" }}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="regimen_fiscal"> Régimen fiscal</InputLabel>
                            <Select
                              labelId="regimen_fiscal"
                              value={values.regimen_fiscal}
                              onChange={handleChange}
                              name="regimen_fiscal"
                            >
                              {fiscal.map((fisc) => {
                                return (
                                  <MenuItem key={fisc.value} value={fisc.value}>
                                    {fisc.value} | {fisc.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Uso de CFDI
                          </Typography>
                        </Grid>
                        <Grid item sm={6} style={{ paddingTop: "10px" }}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="cfdi_use">Uso de CFDI</InputLabel>
                            <Select
                              labelId="cfdi_use"
                              value={values.cfdi_use}
                              onChange={handleChange}
                              name="cfdi_use"
                            >
                              {cdfis.map((cdfi) => {
                                return (
                                  <MenuItem key={cdfi.value} value={cdfi.value}>
                                    {cdfi.text}
                                  </MenuItem>
                                );
                              })}
                              {/* <MenuItem value="G03">G03 - GASTOS EN GENERAL</MenuItem>
                              <MenuItem value="D10">
                                D10 - PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURA)
                              </MenuItem>
                              <MenuItem value="D02">
                                D02 - GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD
                              </MenuItem>
                              <MenuItem value="D01">
                                D01 - HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS
                              </MenuItem> */}
                            </Select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => setEdit(false)}
                      variant="contained"
                      style={{
                        background: "#001979",
                        color: "white",
                        margin: "10px",
                        padding: "9.5px 20px",
                      }}
                    >
                      Cancelar
                    </Button>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        ) : (
          invoiceInformation && (
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid item xs={3}>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Persona
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Nombre
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Correo electronico
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Calle
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Número Int.
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Número Ext.
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Colonia
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Municipio
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Estado
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Localidad
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  País
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Código postal
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  RFC
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Régimen fiscal
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                >
                  Uso de CFDI
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.invoice_person_type ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.name ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.email ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.street ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.interior_number ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.outdoor_number ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.suburb ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.municipality ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.state ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.locality ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.country ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.postal_code ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.rfc ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.regimen_fiscal_name ?? "-"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "15px 0px 15px 27px", fontWeight: 600, height: 48 }}
                >
                  {invoiceInformation?.cfdi_use?.name ?? "-"}
                </Typography>
              </Grid>
            </Grid>
          )
        )}
      </Paper>
    </>
  );
};
export default InvoiceInformation;
