import {
  customFilterConfirm,
  customSearchInput,
} from "../../../common/components/FullTable";
import { LessonsRowFormatter } from "./components/LessonsRowFormatter";
export const defaultFilter = {
  status: "",
};
export const filtersForm = [
  {
    name: "status",
    label: "Estatus",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "ACTIVO", text: "Activo" },
      { value: "PARCIAL", text: "Baja parcial" },
      { value: "TOTAL", text: "Baja total" },
    ],
  },
];
export const defaultFilterNewStudent = {
  start_date: null,
  end_date: null,
  client_location: "",
};
export const filtersFormNewStudent = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
];
export const student_columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },

  {
    name: "responsible_id",
    label: "responsable id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: false,
      sort: true,
      sortDirection: "asc",
    },
  },
  {
    name: "last_name",
    label: "Apellido",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "discharge_status",
    label: "Estatus",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "membership_number",
    label: "N de Socio",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "classes_enrolled",
    label: "Clases contratadas",
    options: {
      filter: true,
      sort: false,
      filterType: "textField",
    },
  },
  {
    name: "available_classes",
    label: "Clases disponibles",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "groups",
    label: "Grupo inscrito",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "category",
    label: "Categoría",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "level",
    label: "Nivel",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "age",
    label: "Edad",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const new_student_columns = [
  {
    name: "name_client",
    label: "Nombre",
  },
  {
    name: "numero_socio",
    label: "No socio",
  },
  {
    name: "group",
    label: "Grupo",
  },
  {
    name: "fecha_inscripcion",
    label: "Fecha de inscripción",
  },
  {
    name: "hora_inicio",
    label: "Hora de inicio",
  },
  {
    name: "hora_fin",
    label: "Hora fin",
  },
  {
    name: "location",
    label: "Ubicación",
  },
];

export const options = (
  clientID,
  searchText,
  setSearchText,
  fetchStudents,
  pagination,
  rowPerPage,
  changePage
) => ({
  serverSide: true,
  pagination: true,
  count: pagination?.pagination?.count,
  rowsPerPage: rowPerPage,
  rowsPerPageOptions: [10, 20, 50],
  onTableChange: (action, tableState) => {
    switch (action) {
      case "changePage":
        changePage(tableState.page + 1, tableState.rowsPerPage, action);
        break;
      case "changeRowsPerPage":
        changePage(tableState.page + 1, tableState.rowsPerPage, action);
        break;
      default:
        console.log("action not handled.");
    }
  },
  customSearchRender: () => customSearchInput(searchText, setSearchText, fetchStudents),
  customRowRender: (data) => LessonsRowFormatter(data, clientID),
  customFilterDialogFooter: customFilterConfirm,
});
