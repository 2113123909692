import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DateIncription from "./DateIncription";
import SelectGroup from "./SelectGroup";

import * as configCRUD from "../../../../../crud/config.crud";
import { getAddNewLesson } from "../../../../../crud/clients.crud";
import notice from "../../../../../utils/notice";

const LessonDialog = ({
  open,
  mode,
  setOpen,
  handleAdd,
  setDateInscription,
  dateInscription,
  idStudent,
  setChangeList,
}) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [category, setCategory] = useState("");
  const [courtesyDesc, setCourtesyDesc] = useState(0);
  const [ageDesc, setAgeDesc] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    configCRUD.getGroupPrices(setGroups);
  }, []);
  const addNewLesson = () => {
    setLoader(true);
    if (selectedGroups.length !== 0 && category !== 0) {
      const body = new FormData();
      selectedGroups.map((group, index) => {
        body.append(`data[${index}][date]`, dateInscription);
        body.append(`data[${index}][id]`, idStudent);
        body.append(`data[${index}][desc_tercera_edad]`, ageDesc);
        body.append(`data[${index}][desc_cortecia]`, courtesyDesc);

        Object.keys(group).map((key) => {
          if (key === "group_price_id") {
            body.append(`data[${index}][group_price_id]`, group?.group_price_id);
          }
          if (key === "group_schedule_id") {
            body.append(`data[${index}][group_schedule_id]`, group?.group_schedule_id);
          }
        });
      });

      getAddNewLesson(body)
        .then((res) => {
          if (res.status == 200) {
            notice("Cliente inscrito correctamente", "success");
          } else if (res.status === 422) {
            notice("Este grupo ya no tiene cupo disponible", "error");
          } else {
            notice("No se ha podido inscribir al cliente correctamente", "error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setSelectedGroups([]);
          setChangeList("Se añadio un nuevo grupo");

          setLoader(false);
          setOpen(false);
        });
    } else {
      notice("Completar todos los campos", "warning");
      setLoader(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={"lg"}>
      <Backdrop open={loader} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop>

      <DialogTitle id="form-dialog-title">Nueva clase</DialogTitle>
      <DialogContent dividers>
        <DateIncription
          setDateInscription={setDateInscription}
          dateInscription={dateInscription}
        />
        <SelectGroup
          groups={groups}
          setSelectedGroups={setSelectedGroups}
          selectedGroups={selectedGroups}
          setCategory={setCategory}
          category={category}
          setCourtesyDesc={setCourtesyDesc}
          courtesyDesc={courtesyDesc}
          setAgeDesc={setAgeDesc}
          ageDesc={ageDesc}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={addNewLesson} autoFocus>
          Añadir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LessonDialog;
