export const debtorsColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "membership_number",
    label: "No. socio",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "name",
    label: "Cliente",
    options: {
      filter: true,
      sort: true,
      sortDirection: "asc",
    },
  },
  {
    name: "debts_inscriptions",
    label: "Debe anualidad/inscripción",
    options: {
      filter: true,
      sort: true,
      sortDirection: "asc",
    },
  },
  {
    name: "debts",
    label: "Deudas por pagar",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "quantity",
    label: "Cantidad",
    options: {
      filter: true,
      sort: false,
    },
  },
];
