import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultDailyReport, defaultGeneralData, defaultGeneralData2, defaultGeneralData3, defaultGeneralData4, defaultGeneralData5, defaultWaterTransparency, defaultTotalDissolvedSolids, defaultWaterSupply, defaultBiweeklyReport } from '../../crud/maint.crud'

export const actionTypes = {
  //------- Daily Reports Actions
  GetDailyReports: '[Config] GET_DAILY_REPORTS',
  GetDailyReport: '[Config] GET_DAILY_REPORT',
  UpdateDailyReports: '[Config] UPDATE_DAILY_REPORTS',
  DeleteDailyReports: '[Config] DELETE_DAILY_REPORTS',
  
  //------- Biweekly Reports Actions
  GetBiweeklyReports: '[Config] GET_BIWEEKLY_REPORTS',
  GetBiweeklyReport: '[Config] GET_BIWEEKLY_REPORT',
  UpdateBiweeklyReports: '[Config] UPDATE_BIWEEKLY_REPORTS',
  DeleteBiweeklyReports: '[Config] DELETE_BIWEEKLY_REPORTS',
}

const initialConfigState = {
  daily_reports: {data: [], count: 0, isFetched: false},
  daily_report: defaultDailyReport,
  general_datas: {data: [], isFetched: false},
  general_data: [defaultGeneralData, defaultGeneralData2, defaultGeneralData3, defaultGeneralData4, defaultGeneralData5],
  water_transparencies: {data: [], isFetched: false},
  water_transparency: defaultWaterTransparency,
  disolved_solids: {data: [], isFetched: false},
  disolved_solid: defaultTotalDissolvedSolids,
  water_supplies: {data: [], isFetched: false},
  water_supply: defaultWaterSupply,
  biweekly_reports: {data: [], count: 0, isFetched: false},
  biweekly_report: defaultBiweeklyReport,
}

export const reducer = persistReducer(
  { storage, key: 'maint', whitelist: [] },
  (state = initialConfigState, action) => {
    switch (action.type) {
      // ================= DAILY REPORTS =================
      case actionTypes.GetDailyReports: {

        return { ...state, daily_reports: {data: action.payload.daily_reports, isFetched: true} }
      }
      
      case actionTypes.GetDailyReport: {
        return { ...state, daily_report: action.payload.daily_report }
      }

      case actionTypes.UpdateDailyReports: {
        let newData;
        const index = state.daily_reports.data.findIndex(x => x.id === action.payload.daily_report.id)

        if (index === -1) {
          newData = [action.payload.daily_report, ...state.daily_reports.data]
        } else {
          newData = state.daily_reports.data
          newData[index] = action.payload.daily_report
        }

        return  { ...state, daily_reports: {data: newData, isFetched: true} }
      }
      case actionTypes.DeleteDailyReports: {

        const index = state.daily_reports.data.findIndex(x => x.id === action.payload)
        // eslint-disable-next-line
        let newData = state.daily_reports.data.splice(index, 1)
        
        return  { ...state, daily_reports: {data: state.daily_reports.data, isFetched: true} }
      }

      // ================= BIWEEKLY REPORTS =================
      case actionTypes.GetBiweeklyReports: {

        return { ...state, biweekly_reports: {data: action.payload.biweekly_reports, isFetched: true} }
      }
      
      case actionTypes.GetBiweeklyReport: {

        return { ...state, biweekly_report: action.payload.biweekly_report }
      }

      case actionTypes.UpdateBiweeklyReports: {
        let newData;
        const index = state.biweekly_reports.data.findIndex(x => x.id === action.payload.biweekly_report.id)

        if (index === -1) {
          newData = [action.payload.biweekly_report, ...state.biweekly_reports.data]
        } else {
          newData = state.biweekly_reports.data
          newData[index] = action.payload.biweekly_report
        }

        return  { ...state, biweekly_reports: {data: newData, isFetched: true} }
      }
      case actionTypes.DeleteBiweeklyReports: {

        const index = state.biweekly_reports.data.findIndex(x => x.id === action.payload)
        // eslint-disable-next-line
        let newData = state.biweekly_reports.data.splice(index, 1)
        
        return  { ...state, biweekly_reports: {data: state.biweekly_reports.data, isFetched: true} }
      }

      default: return state;
    }
  }
)

export const actions = {
  setDailyReports: daily_reports => ({ type: actionTypes.GetDailyReports, payload: daily_reports }),
  setDailyReport: daily_report => ({ type: actionTypes.GetDailyReport, payload: daily_report }),
  updateDailyReports: daily_reports => ({ type: actionTypes.UpdateDailyReports, payload: daily_reports }),
  deleteDailyReports: id_daily_reports => ({ type: actionTypes.DeleteDailyReports, payload: id_daily_reports }),
  
  setBiweeklyReports: biweekly_reports => ({ type: actionTypes.GetBiweeklyReports, payload: biweekly_reports }),
  setBiweeklyReport: biweekly_report => ({ type: actionTypes.GetBiweeklyReport, payload: biweekly_report }),
  updateBiweeklyReports: biweekly_reports => ({ type: actionTypes.UpdateBiweeklyReports, payload: biweekly_reports }),
  deleteBiweeklyReports: id_biweekly_reports => ({ type: actionTypes.DeleteBiweeklyReports, payload: id_biweekly_reports }),
}