import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import * as clientsCRUD from "../../../../crud/clients.crud";
import Contact from "./Contact";
import Students from "./Students";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const Details = ({
  responsible,
  setResponsiblesWithClients,
  responsibleContact,
  updateResponsibleContact,
  responsiblesWithClients,
  locations,
}) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });
  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const States = [
    { value: "Aguascalientes", label: "Aguascalientes" },
    { value: "Baja California", label: "Baja California" },
    { value: "Baja California Sur", label: "Baja California Sur" },
    { value: "Campeche", label: "Campeche" },
    { value: "Chiapas", label: "Chiapas" },
    { value: "Coahuila", label: "Coahuila" },
    { value: "Colima", label: "Colima" },
    { value: "Ciudad de México", label: "Ciudad de México" },
    { value: "Durango", label: "Durango" },
    { value: "Guanajuato", label: "Guanajuato" },
    { value: "Guerrero", label: "Guerrero" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Jalisco", label: "Jalisco" },
    { value: "Estado de Mexico", label: "Estado de Mexico" },
    { value: "Michoacan", label: "Michoacan" },
    { value: "Morelos", label: "Morelos" },
    { value: "Nayarit", label: "Nayarit" },
    { value: "Nuevo Leon", label: "Nuevo Leon" },
    { value: "Oaxaca", label: "Oaxaca" },
    { value: "Puebla", label: "Puebla" },
    { value: "Queretaro", label: "Queretaro" },
    { value: "Quintana Roo", label: "Quintana Roo" },
    { value: "San Luis Potosi", label: "San Luis Potosi" },
    { value: "Sinaloa", label: "Sinaloa" },
    { value: "Sonora", label: "Sonora" },
    { value: "Tabasco", label: "Tabasco" },
    { value: "Tamaulipas", label: "Tamaulipas" },
    { value: "Tlaxcala", label: "Tlaxcala" },
    { value: "Veracruz", label: "Veracruz" },
    { value: "Yucatan", label: "Yucatan" },
    { value: "Zacatecas", label: "Zacatecas" },
  ];

  const UploadComponent = (props) => {
    const { setFieldValue } = props;
    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/png",
      minSize: 0,
      maxSize: 5242880,
      onDrop: (acceptedFiles) => {
        setFieldValue("profile_image", acceptedFiles[0]);
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
    return (
      <section
        style={{
          border: "1px dashed grey",
          padding: "50px",
          borderRadius: "10px",
        }}
        className="container"
      >
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p style={{ padding: "0 33%" }}>
            <PhotoCameraOutlinedIcon fontSize="large" color="primary" />
          </p>
          <p style={{ color: "#3f51b5" }}>Agregar Foto</p>
        </div>
      </section>
    );
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const thumbs = files.map((file) => (
    <img
      style={{ width: "155px", height: "155px", borderRadius: "10px" }}
      src={file.preview}
      alt="cropped"
    />
  ));

  const deleteImg = () => {
    setFiles([]);
  };

  const returns = () => {
    setFiles([]);
    setEdit(false);
  };

  const update = (values) => {
    const responsibleCheck = {
      id: responsible.responsible.id,
      name: values.name,
      last_name: values.last_name,
      last_name_2: values.last_name_2,
      email: values.email,
      phone: values.phone,
      membership_number: values.membership_number,
      profile_image: values.profile_image,
      country: values.country,
      district: values.district,
      number: values.number,
      state: values.state,
      street_name: values.street_name,
      suburb: values.suburb,
    };
    clientsCRUD
      .updateResponsibles(responsibleCheck, setResponsiblesWithClients)
      .then(() => window.scrollTo(0, 0), setFiles([]));
  };
  return (
    <>
      <Paper>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
              Detalles del responsable
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {role?.name !== "Cliente" && !edit && (
              <Button
                style={{ marginRight: 25 }}
                color="primary"
                variant="contained"
                onClick={() => {
                  setEdit(true);
                }}
              >
                Editar informacion
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        {edit ? (
          <Grid item xs={12} style={{ display: "flex" }}>
            <Formik
              initialValues={{
                name: responsible.responsible.name,
                last_name: responsible.responsible.last_name,
                last_name_2: responsible.responsible.last_name_2,
                email: responsible.responsible.email,
                phone: responsible.responsible.phone,
                membership_number: responsible.responsible.membership_number,
                profile_image: responsible.responsible.profile_image,
                country: responsible.responsible.country,
                district: responsible.responsible.district,
                number: responsible.responsible.number,
                state: responsible.responsible.state,
                street_name: responsible.responsible.street_name,
                suburb: responsible.responsible.suburb,
              }}
              validate={(values) => {
                let errors = {};

                if (!values.name || values.name === "") errors.name = "Campo requerido";

                if (!values.last_name || values.last_name === "")
                  errors.last_name = "Campo requerido";

                if (
                  values.email !== "" &&
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    values.email
                  ) === false
                )
                  errors.email = "Correo inválido";

                if (!values.membership_number || values.membership_number === "")
                  errors.membership_number = "Campo requerido";

                // if (!values.country || values.country === "")
                //   errors.country = "Campo requerido";

                // if (!values.district || values.district === "")
                //   errors.district = "Campo requerido";

                // if (!values.number || values.number === "")
                //   errors.number = "Campo requerido";

                if (!values.state || values.state === "")
                  errors.state = "Campo requerido";

                return errors;
              }}
              validator={() => ({})}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                setTimeout(() => {
                  update(values);
                  setSubmitting(false);
                  setEdit(false);
                  disableLoading();
                }, 1000);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} validate autoComplete="off">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                  <div className="form-group">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            imagen
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Grid
                            container
                            alignItems="center"
                            style={{ marginTop: "10px" }}
                          >
                            <Grid item>
                              {files.length === 0 ? (
                                <UploadComponent setFieldValue={setFieldValue} />
                              ) : (
                                <div>
                                  {/* <img style={{ width: "155px", height: "155px", borderRadius: '10px' }} src={cropData} alt="cropped" /> */}
                                  <aside style={thumbsContainer}>{thumbs}</aside>
                                  <div>
                                    <Button
                                      style={{ marginTop: "13px" }}
                                      color="primary"
                                      onClick={deleteImg}
                                    >
                                      Quitar Imagen
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Nombre
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Nombre"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Apellido paterno
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Apellido paterno"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="last_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            helperText={touched.last_name && errors.last_name}
                            error={Boolean(touched.last_name && errors.last_name)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Apellido materno
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Apellido materno"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="last_name_2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name_2}
                            helperText={touched.last_name_2 && errors.last_name_2}
                            error={Boolean(touched.last_name_2 && errors.last_name_2)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Correo electronico
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Correo electronico"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Teléfono
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Teléfono"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            helperText={touched.phone && errors.phone}
                            error={Boolean(touched.phone && errors.phone)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Numero de socio
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Numero de socio"
                            margin="normal"
                            className="kt-width-full"
                            // disabled={disableTexts}
                            name="membership_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.membership_number}
                            helperText={
                              touched.membership_number && errors.membership_number
                            }
                            error={Boolean(
                              touched.membership_number && errors.membership_number
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item sm={12}>
                        <Typography
                          variant="h6"
                          style={{ color: "#001979", padding: "15px" }}
                        >
                          Dirección
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Calle y número
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Calle y número"
                            margin="normal"
                            className="kt-width-full"
                            name="street_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street_name}
                            helperText={touched.street_name && errors.street_name}
                            error={Boolean(touched.street_name && errors.street_name)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Número Int.
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Numero Int"
                            margin="normal"
                            className="kt-width-full"
                            name="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.number}
                            helperText={touched.number && errors.number}
                            error={Boolean(touched.number && errors.number)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Colonia
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Colonia"
                            margin="normal"
                            className="kt-width-full"
                            name="suburb"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.suburb}
                            helperText={touched.suburb && errors.suburb}
                            error={Boolean(touched.suburb && errors.suburb)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Delegación
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="Delegación"
                            margin="normal"
                            className="kt-width-full"
                            name="district"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.district}
                            helperText={touched.district && errors.district}
                            error={Boolean(touched.district && errors.district)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            Estado
                          </Typography>
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "15px" }}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="state">Estado (opcional)</InputLabel>
                            <Select
                              labelId="state"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {States.map((b) => (
                                <MenuItem key={b.value} value={b.value}>
                                  {b.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                        <Grid item sm={3}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#868686",
                              padding: "34px 0px 0px 27px",
                            }}
                          >
                            País
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            label="País"
                            margin="normal"
                            className="kt-width-full"
                            name="country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.country}
                            helperText={touched.country && errors.country}
                            error={Boolean(touched.country && errors.country)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={returns}
                      variant="contained"
                      style={{
                        background: "#001979",
                        color: "white",
                        margin: "10px",
                        padding: "9.5px 20px",
                      }}
                    >
                      Cancelar
                    </Button>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        ) : (
          responsible && (
            <>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Nombre
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Apellido paterno
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Apellido Materno
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Correo electronico
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Teléfono
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Numero de socio
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.name ? responsible.responsible.name : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.last_name
                      ? responsible.responsible.last_name
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.last_name_2
                      ? responsible.responsible.last_name_2
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.email ? responsible.responsible.email : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.phone ? responsible.responsible.phone : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.membership_number
                      ? responsible.responsible.membership_number
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
                  Dirección
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Calle y número
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Número Int.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Colonia
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Delegación
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Estado
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    País
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.street_name
                      ? responsible.responsible.street_name
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.number
                      ? responsible.responsible.number
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.suburb
                      ? responsible.responsible.suburb
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.district
                      ? responsible.responsible.district
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.state ? responsible.responsible.state : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsible.responsible.country
                      ? responsible.responsible.country
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )
        )}
      </Paper>
      {responsiblesWithClients && (
        <Students
          responsiblesWithClients={responsiblesWithClients}
          locations={locations}
          updateClients={setResponsiblesWithClients}
        />
      )}
      {responsibleContact && (
        <Contact
          responsibleContact={responsibleContact}
          responsible={responsible}
          updateResponsibleContact={updateResponsibleContact}
        />
      )}
    </>
  );
};

export default Details;
