import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

export const actionType = {
    GetEvents: '[CALENDAR] GET_EVENTS',
    GetEvent: '[CALENDAR] GET_EVENT',
    UpdateEvents: '[CALENDAR] UPDATE_EVENT'
}

const initialCalendarState = {
    events: {data: [], isFetched: false},
    event: null
}

export const reducer = persistReducer(
    { storage, key: 'calendar', whitelist: []},
    (state = initialCalendarState, action) => {
        switch (action.type) {
          case actionType.GetEvents: {              
            return {
              ...state,
              events: { data: action.payload, isFetched: true },
            };
          }
          case actionType.GetEvent: {
            return {
              ...state,
              event: action.payload,
            };
          }
          case actionType.UpdateEvents: {
            let newData
            const index = state.events.data.findIndex( x => x.id === action.payload.id)
            if (index === -1) {
                newData = [action.payload, ...state.events.data]
            } else {
                newData = state.events.data
                newData[index] = action.payload
            }

            return {...state, events: {data: newData, isFetched: true}}
          }
          default:
            return state;
        }
    }
)

export const actions = {
    setEvents: events => ({ type: actionType.GetEvents, payload: events}),
    setEvent: event => ({ type: actionType.GetEvent, payload: event}),
    updateEvents: event => ({ type: actionType.UpdateEvents, payload: event}),
}