import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload;
        
        return { user: user, authToken: authToken };
      }

      case actionTypes.Logout: {
        localStorage.clear()
        routerHelpers.forgotLastLocation();
        window.location.reload()
        return initialAuthState;
      }

      case actionTypes.UserRequested: {

        return { ...state, user: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user, authToken) => ({ type: actionTypes.Login, payload: { user: user, authToken: authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: user }),
};

// export function* saga() {
//   yield takeLatest(actionTypes.Login, function* loginSaga() {
//     yield put(actions.requestUser());
//   });
//
//   yield takeLatest(actionTypes.Register, function* registerSaga() {
//     yield put(actions.requestUser());
//   });
//
//   yield takeLatest(actionTypes.UserRequested, function* userRequested() {
//     const { data: user } = yield getUserByToken();
//
//     yield put(actions.fulfillUser(user));
//   });
// }
