import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  defaultRole,
  defaultLocation,
  defaultSuggestionType,
  defaultSuggestionStatus,
  defaultArea,
  defaultCategory,
  defaultProgram,
  defaultLevel,
  defaultSuggestionSource,
  defaultPool,
  defaultPoolType,
  defaultGroup,
  defaultGroupPrice,
  defaultStoreCategory,
} from "../../crud/config.crud";

export const actionTypes = {
  GetRoles: "[Config] GET_ROLES",
  UpdateRoles: "[Config] UPDATE_ROLES", //This is used after creating or editing an element, to update the stored data
  GetRole: "[Config] GET_ROLE",
  GetPermissions: "[Config] GET_PERMISIONS",
  GetLocations: "[Config] GET_LOCATIONS",
  UpdateLocations: "[Config] UPDATE_LOCATIONS",
  GetLocation: "[Config] GET_LOCATION",
  GetSuggestionTypes: "[Config] GET_SUGGESTION_TYPES",
  UpdateSuggestionTypes: "[Config] UPDATE_SUGGESTION_TYPES",
  GetSuggestionType: "[Config] GET_SUGGESTION_TYPE",
  GetSuggestionStatuses: "[Config] GET_SUGGESTION_STATUSES",
  UpdateSuggestionStatuses: "[Config] UPDATE_SUGGESTION_STATUSES",
  GetSuggestionStatus: "[Config] GET_SUGGESTION_STATUS",
  GetAreas: "[Config] GET_AREAS",
  UpdateAreas: "[Config] UPDATE_AREAS",
  GetArea: "[Config] GET_AREA",
  GetCategories: "[Config] GET_CATEGORIES",
  UpdateCategories: "[Config] UPDATE_CATEGORIES",
  GetCategory: "[Config] GET_CATEGORY",
  RemoveCategory: "[Config] REMOVE_CATEGORY",
  GetPrograms: "[Config] GET_PROGRAMS",
  UpdatePrograms: "[Config] UPDATE_PROGRAMS",
  GetProgram: "[Config] GET_PROGRAM",
  GetLevels: "[Config] GET_LEVELS",
  UpdateLevels: "[Config] UPDATE_LEVELS",
  GetLevel: "[Config] GET_LEVEL",
  RemoveLevel: "[Config] DELETE_LEVEL",
  GetIntructors: "[Config] GET_INSTRUCTOR",
  GetSuggestionSources: "[Config] GET_SUGGESTION_SOURCES",
  UpdateSuggestionSources: "[Config] UPDATE_SUGGESTION_SOURCES",
  GetSuggestionSource: "[Config] GET_SUGGESTION_SOURCE",

  GetPools: "[Config] GET_POOLS",
  GetPool: "[Config] GET_POOL",
  UpdatePools: "[Config] UPDATE_POOLS",
  GetPoolTypes: "[Config] GET_POOL_TYPES",
  GetPoolType: "[Config] GET_POOL_TYPE",
  UpdatePoolTypes: "[Config] UPDATE_POOL_TYPES",
  UpdateLevelRequirements: "[Config] UPDATE_LEVEL_REQUIREMENTS",

  GetGroups: "[Config] GET_GROUPS",
  GetGroup: "[Config] GET_GROUP",
  UpdateGroups: "[Config] UPDATE_GROUPS",
  DeactiveGroup: "[Config] DEACTIVE_GROUPS",
  UpdateStudent: "[Config] UPDATE_STUDENT",

  GetGroupPrices: "[Config] GET_GROUP_PRICES",
  GetGroupPrice: "[Config] GET_GROUP_PRICE",
  UpdateGroupPrices: "[Config] UPDATE_GROUP_PRICES",

  GetStoreCategories: "[Config] GET_STORE_CATEGORIES",
  GetStoreCategory: "[Config] GET_STORE_CATEGORY",
  UpdateStoreCategories: "[Config] UPDATE_STORE_CATEGORIES",
};

const initialConfigState = {
  roles: { data: [], isFetched: false },
  role: defaultRole,
  permissions: { data: [], isFetched: false },
  locations: { data: [], isFetched: false },
  location: defaultLocation,
  suggestion_types: { data: [], isFetched: false },
  suggestion_type: defaultSuggestionType,
  suggestion_statuses: { data: [], isFetched: false },
  suggestion_status: defaultSuggestionStatus,
  areas: { data: [], isFetched: false },
  area: defaultArea,
  categories: { data: [], isFetched: false },
  category: defaultCategory,
  programs: { data: [], isFetched: false },
  program: defaultProgram,
  levels: { data: [], isFetched: false },
  level: defaultLevel,
  suggestion_sources: { data: [], isFetched: false },
  suggestion_source: defaultSuggestionSource,
  pools: { data: [], isFetched: false },
  pool: defaultPool,
  pool_types: { data: [], isFetched: false },
  pool_type: defaultPoolType,

  groups: { data: [], isFetched: false },
  group: defaultGroup,

  instructors: { data: [], isFetched: false },
  instructor: defaultGroup.group,

  group_prices: { data: [], isFetched: false },
  group_price: defaultGroupPrice,

  store_categories: { data: [], isFetched: false },
  store_category: defaultStoreCategory,
};

export const reducer = persistReducer(
  { storage, key: "config", whitelist: ["permissions", "areas"] },
  (state = initialConfigState, action) => {
    switch (action.type) {
      // ================= ROLES =================
      case actionTypes.GetRoles: {
        return {
          ...state,
          roles: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateRoles: {
        let newData;
        const index = state.roles.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.roles.data];
        } else {
          newData = state.roles.data;
          newData[index] = action.payload;
        }

        return { ...state, roles: { data: newData, isFetched: true } };
      }
      case actionTypes.GetRole: {
        return { ...state, role: action.payload };
      }

      // ================= PERMISSIONS =================
      case actionTypes.GetPermissions: {
        return {
          ...state,
          permissions: { data: action.payload, isFetched: true },
        };
      }

      // ================= LOCATIONS =================
      case actionTypes.GetLocations: {
        return {
          ...state,
          locations: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateLocations: {
        let newData;
        const index = state.locations.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.locations.data];
        } else {
          newData = state.locations.data;
          newData[index] = action.payload;
        }

        return { ...state, locations: { data: newData, isFetched: true } };
      }
      case actionTypes.GetLocation: {
        return { ...state, location: action.payload };
      }

      // ================= SUGGESTION TYPES =================
      case actionTypes.GetSuggestionTypes: {
        return {
          ...state,
          suggestion_types: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateSuggestionTypes: {
        let newData;
        const index = state.suggestion_types.data.findIndex(
          (x) => x.id === action.payload.id
        );
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.suggestion_types.data];
        } else {
          newData = state.suggestion_types.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          suggestion_types: { data: newData, isFetched: true },
        };
      }
      case actionTypes.GetSuggestionType: {
        return { ...state, suggestion_type: action.payload };
      }

      // ================= SUGGESTION STATUSES =================
      case actionTypes.GetSuggestionStatuses: {
        return {
          ...state,
          suggestion_statuses: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateSuggestionStatuses: {
        let newData;
        const index = state.suggestion_statuses.data.findIndex(
          (x) => x.id === action.payload.id
        );
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.suggestion_statuses.data];
        } else {
          newData = state.suggestion_statuses.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          suggestion_statuses: { data: newData, isFetched: true },
        };
      }
      case actionTypes.GetSuggestionStatus: {
        return { ...state, suggestion_status: action.payload };
      }

      // ================= SUGGESTION AREAS =================
      case actionTypes.GetAreas: {
        return {
          ...state,
          areas: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateAreas: {
        let newData;
        const index = state.areas.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.areas.data];
        } else {
          newData = state.areas.data;
          newData[index] = action.payload;
        }

        return { ...state, areas: { data: newData, isFetched: true } };
      }
      case actionTypes.GetArea: {
        return { ...state, area: action.payload };
      }

      // ================= CATEGORIES =================
      case actionTypes.GetCategories: {
        return {
          ...state,
          categories: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateCategories: {
        let newData;
        const index = state.categories.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.categories.data];
        } else {
          newData = state.categories.data;
          newData[index] = action.payload;
        }

        return { ...state, categories: { data: newData, isFetched: true } };
      }
      case actionTypes.GetCategory: {
        return { ...state, category: action.payload };
      }

      case actionTypes.RemoveCategory: {
        let newData = state.categories.data;
        let index = newData?.findIndex((x) => x.id === action.payload);
        newData.splice(index, 1);

        return { ...state, category: { data: newData, isFetched: true } };
      }

      // ================= CATEGORIES =================
      case actionTypes.GetPrograms: {
        return {
          ...state,
          programs: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdatePrograms: {
        let newData;
        const index = state.programs.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.programs.data];
        } else {
          newData = state.programs.data;
          newData[index] = action.payload;
        }

        return { ...state, programs: { data: newData, isFetched: true } };
      }
      case actionTypes.GetProgram: {
        return { ...state, program: action.payload };
      }

      // ================= LEVELS =================
      case actionTypes.GetLevels: {
        return {
          ...state,
          levels: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateLevels: {
        let newData;
        const index = state.levels.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.levels.data];
        } else {
          newData = state.levels.data;
          newData[index] = action.payload;
        }
        return { ...state, levels: { data: newData, isFetched: true } };
      }
      case actionTypes.GetLevel: {
        return { ...state, level: action.payload };
      }
      case actionTypes.RemoveLevel: {
        let newData = state.levels.data;
        let index = newData?.findIndex((x) => x.id === action.payload);
        newData.splice(index, 1);
        return { ...state, levels: { data: newData } };
      }

      case actionTypes.UpdateLevelRequirements: {
        const { level } = state;
        let newData;
        let newLevel;
        const index = state.level.level_requirements.findIndex(
          (x) => x.id === action.payload.id
        );
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...level.level_requirements];
          newLevel = { ...level, level_requirements: newData };
        } else {
          newData = state.level.level_requirements;
          newData[index] = action.payload;
        }

        return { ...state, level: newLevel };
      }

      // ================= SUGGESTION SOURCES =================
      case actionTypes.GetSuggestionSources: {
        return {
          ...state,
          suggestion_sources: { data: action.payload, isFetched: true },
        };
      }
      case actionTypes.UpdateSuggestionSources: {
        let newData;
        const index = state.suggestion_sources.data.findIndex(
          (x) => x.id === action.payload.id
        );
        if (index === -1) {
          // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.suggestion_sources.data];
        } else {
          newData = state.suggestion_sources.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          suggestion_sources: { data: newData, isFetched: true },
        };
      }
      case actionTypes.GetSuggestionSource: {
        return { ...state, suggestion_source: action.payload };
      }

      // =============== POOLS ===================
      case actionTypes.GetPools: {
        return {
          ...state,
          pools: { data: action.payload, isFetched: true },
        };
      }

      case actionTypes.GetPool: {
        return { ...state, pool: action.payload };
      }

      case actionTypes.UpdatePools: {
        let newData;
        const index = state.pools.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          newData = [action.payload, ...state.pools.data];
        } else {
          newData = state.pools.data;
          newData[index] = action.payload;
        }

        return { ...state, pools: { data: newData, isFetched: true } };
      }

      // =============== INSTRUCTOR ===================
      case actionTypes.GetIntructors: {
        return {
          ...state,
          instructors: { data: action.payload, isFetched: true },
        };
      }

      case actionTypes.GetIntructor: {
        return { ...state, instructor: action.payload };
      }

      case actionTypes.UpdateIntructors: {
        let newData;
        const index = state.intructors.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          newData = [action.payload, ...state.intructors.data];
        } else {
          newData = state.intructors.data;
          newData[index] = action.payload;
        }

        return { ...state, intructors: { data: newData, isFetched: true } };
      }

      // =============== POOLS TYPES ===================
      case actionTypes.GetPoolTypes: {
        return {
          ...state,
          pool_types: { data: action.payload, isFetched: true },
        };
      }

      case actionTypes.GetPoolType: {
        return { ...state, pool_type: action.payload };
      }

      case actionTypes.UpdatePoolTypes: {
        let newData;
        const index = state.pool_types.data.findIndex((x) => x.id === action.payload.id);
        if (index === -1) {
          newData = [action.payload, ...state.pool_types.data];
        } else {
          newData = state.pool_types.data;
          newData[index] = action.payload;
        }

        return { ...state, pool_types: { data: newData, isFetched: true } };
      }

      // =============== GROUPS ===================
      case actionTypes.GetGroups: {
        return {
          ...state,
          groups: { data: action.payload.groups, isFetched: true },
        };
      }

      case actionTypes.GetGroup: {
        return { ...state, group: action.payload.group };
      }

      case actionTypes.UpdateGroups: {
        let newData;
        const index = state.groups.data.findIndex(
          (x) => x.id === action.payload.group.id
        );
        if (index === -1) {
          newData = [...state.groups.data, action.payload.group];
        } else {
          newData = state.groups.data;
          newData[index] = action.payload.group;
        }

        return { ...state, groups: { data: newData, isFetched: true } };
      }

      case actionTypes.DeactiveGroup: {
        let newData;
        const index = state.groups.data.findIndex(
          (x) => x.id === action.payload.group.id
        );
        if (index === -1) {
          newData = [...state.groups.data, action.payload.group];
        } else {
          newData = state.groups.data;
          newData[index] = action.payload.group;
        }

        return { ...state, groups: { data: newData, isFetched: true } };
      }

      // =============== GROUP PRICES ===================

      case actionTypes.GetGroupPrices: {
        return {
          ...state,
          group_prices: {
            data: action.payload.group_prices,
            isFetched: true,
          },
        };
      }
      case actionTypes.GetGroupPrice: {
        return { ...state, group_price: action.payload };
      }
      case actionTypes.UpdateGroupPrices: {
        let newData;
        const index = state.group_prices.data.findIndex(
          (x) => x.id === action.payload.group_price.id
        );

        if (index === -1) {
          newData = [...state.group_prices.data, action.payload.group_price];
        } else {
          newData = state.group_prices.data;
          newData[index] = action.payload.group_price;
        }

        return {
          ...state,
          group_prices: { data: newData, isFetched: true },
        };
      }

      // =============== STORE CATEGORIES ===================

      case actionTypes.GetStoreCategories: {
        return {
          ...state,
          store_categories: { data: action.payload, isFetched: true },
        };
      }

      case actionTypes.GetStoreCategory: {
        return { ...state, store_category: action.payload };
      }

      case actionTypes.UpdateStoreCategories: {
        let newData;
        const index = state.store_categories.data.findIndex(
          (x) => x.id === action.payload.id
        );

        if (index === -1) {
          newData = [...state.store_categories.data, action.payload];
        } else {
          newData = state.store_categories.data;
          newData[index] = action.payload;
        }

        return {
          ...state,
          store_categories: { data: newData, isFetched: true },
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setPermissions: (permissions) => ({
    type: actionTypes.GetPermissions,
    payload: permissions,
  }),

  setRoles: (roles) => ({ type: actionTypes.GetRoles, payload: roles }),
  updateRoles: (role) => ({ type: actionTypes.UpdateRoles, payload: role }),
  setRole: (role) => ({ type: actionTypes.GetRole, payload: role }),

  setLocations: (locations) => ({
    type: actionTypes.GetLocations,
    payload: locations,
  }),
  updateLocations: (location) => ({
    type: actionTypes.UpdateLocations,
    payload: location,
  }),
  setLocation: (location) => ({
    type: actionTypes.GetLocation,
    payload: location,
  }),

  setSuggestionTypes: (suggestion_types) => ({
    type: actionTypes.GetSuggestionTypes,
    payload: suggestion_types,
  }),
  updateSuggestionTypes: (suggestion_type) => ({
    type: actionTypes.UpdateSuggestionTypes,
    payload: suggestion_type,
  }),
  setSuggestionType: (suggestion_type) => ({
    type: actionTypes.GetSuggestionType,
    payload: suggestion_type,
  }),

  setSuggestionStatuses: (suggestion_statuses) => ({
    type: actionTypes.GetSuggestionStatuses,
    payload: suggestion_statuses,
  }),
  updateSuggestionStatuses: (suggestion_status) => ({
    type: actionTypes.UpdateSuggestionStatuses,
    payload: suggestion_status,
  }),
  setSuggestionStatus: (suggestion_status) => ({
    type: actionTypes.GetSuggestionStatus,
    payload: suggestion_status,
  }),

  setAreas: (areas) => ({ type: actionTypes.GetAreas, payload: areas }),
  updateAreas: (areas) => ({ type: actionTypes.UpdateAreas, payload: areas }),
  setArea: (area) => ({ type: actionTypes.GetArea, payload: area }),

  setCategories: (categories) => ({
    type: actionTypes.GetCategories,
    payload: categories,
  }),
  updateCategories: (categories) => ({
    type: actionTypes.UpdateCategories,
    payload: categories,
  }),
  setCategory: (category) => ({
    type: actionTypes.GetCategory,
    payload: category,
  }),
  removeCategory: (id) => ({ type: actionTypes.RemoveCategory, payload: id }),

  setPrograms: (programs) => ({
    type: actionTypes.GetPrograms,
    payload: programs,
  }),
  updatePrograms: (programs) => ({
    type: actionTypes.UpdatePrograms,
    payload: programs,
  }),
  setProgram: (program) => ({ type: actionTypes.GetProgram, payload: program }),

  setLevels: (levels) => ({ type: actionTypes.GetLevels, payload: levels }),
  updateLevels: (levels) => ({
    type: actionTypes.UpdateLevels,
    payload: levels,
  }),
  setLevel: (level) => ({ type: actionTypes.GetLevel, payload: level }),
  removeLevel: (levelID) => ({
    type: actionTypes.RemoveLevel,
    payload: levelID,
  }),

  setSuggestionSources: (suggestion_sources) => ({
    type: actionTypes.GetSuggestionSources,
    payload: suggestion_sources,
  }),
  updateSuggestionSources: (suggestion_source) => ({
    type: actionTypes.UpdateSuggestionSources,
    payload: suggestion_source,
  }),
  setSuggestionSource: (suggestion_source) => ({
    type: actionTypes.GetSuggestionSource,
    payload: suggestion_source,
  }),

  updateLevelRequirements: (levels) => ({
    type: actionTypes.UpdateLevelRequirements,
    payload: levels,
  }),

  setPools: (pools) => ({ type: actionTypes.GetPools, payload: pools }),
  setPool: (pool) => ({ type: actionTypes.GetPool, payload: pool }),
  updatePools: (pools) => ({ type: actionTypes.UpdatePools, payload: pools }),

  setIntructors: (intructors) => ({
    type: actionTypes.GetIntructors,
    payload: intructors,
  }),
  setIntructor: (intructor) => ({
    type: actionTypes.GetIntructor,
    payload: intructor,
  }),
  updateIntructors: (intructors) => ({
    type: actionTypes.UpdateIntructors,
    payload: intructors,
  }),

  setPoolTypes: (pool_types) => ({
    type: actionTypes.GetPoolTypes,
    payload: pool_types,
  }),
  setPoolType: (pool_type) => ({
    type: actionTypes.GetPoolType,
    payload: pool_type,
  }),
  updatePoolTypes: (pool_types) => ({
    type: actionTypes.UpdatePoolTypes,
    payload: pool_types,
  }),

  setGroups: (groups) => ({ type: actionTypes.GetGroups, payload: groups }),
  setGroup: (group) => ({ type: actionTypes.GetGroup, payload: group }),
  updateGroups: (groups) => ({
    type: actionTypes.UpdateGroups,
    payload: groups,
  }),
  deactiveGroup: (groups) => ({
    type: actionTypes.DeactiveGroup,
    payload: groups,
  }),
  updateStudent: (groups) => ({
    type: actionTypes.UpdateStudent,
    payload: groups,
  }),

  setGroupPrices: (group_prices) => ({
    type: actionTypes.GetGroupPrices,
    payload: group_prices,
  }),
  setGroupPrice: (group_price) => ({
    type: actionTypes.GetGroupPrice,
    payload: group_price,
  }),
  updateGroupPrices: (group_prices) => ({
    type: actionTypes.UpdateGroupPrices,
    payload: group_prices,
  }),

  setStoreCategories: (store_categories) => ({
    type: actionTypes.GetStoreCategories,
    payload: store_categories,
  }),
  setStoreCategory: (store_category) => ({
    type: actionTypes.GetStoreCategory,
    payload: store_category,
  }),
  updateStoreCategories: (store_categories) => ({
    type: actionTypes.UpdateStoreCategories,
    payload: store_categories,
  }),
};
