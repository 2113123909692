import StatusCheckboxColumn from "../components/StatusCheckboxColumn";
import OweColumn from "../components/OweColumn";
import moment from "moment";

export const defaultStudentGroup = { student: null, days: [] };

export const studentGroupForm = (students = [], days = [], newStudent) => [
  {
    label: "Alumno",
    name: "student",
    type: "search",
    options: students,
  },
  {
    label: "Dia",
    name: "days",
    type: "dropdown",
    options: days,
    multiple: true,
  },
  {
    label: "Agregar alumno",
    type: "button",
    onClick: newStudent,
  },
];

export const attendance_columns = (attendances, setAttendances) => [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "client.name",
    label: "Nombre",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "client.bubication",
    label: "Aplicar exámen",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data) => (data ? "Sí" : "No"),
    },
  },
  {
    name: "client.bdiff_expectatives",
    label: "Expectativas diferentes",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data) => (data ? "Sí" : "No"),
    },
  },
  {
    name: "client.name",
    label: "Nombre",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "client.level",
    label: "Nivel",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "client.category",
    label: "Categoría",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "client.age",
    label: "Edad",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "adeudo",
    label: "Adeudo",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data) => OweColumn(data),
    },
  },
  {
    name: "status",
    label: "Asistencia",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data, tableMeta, updateFunction) =>
        StatusCheckboxColumn(
          data,
          tableMeta,
          updateFunction,
          attendances,
          setAttendances
        ),
    },
  },
  {
    name: "registered_at",
    label: "Inscrito desde",
    options: {
      customBodyRender: (data) =>
        moment(data)
          .utc()
          .format("DD/MM/YYYY"),
    },
  },
];

export const options = (date) => ({
  setRowProps: (row, dataIndex) => {
    let dateRegister = moment(row[10], "DD/MM/YYYY").format("MM/DD/YYYY");
    let dateToday = moment(new Date());
    let difference = dateToday.diff(dateRegister, "days");
    return {
      style: { background: `${difference < 14 ? "#D5FFD2" : ""}` },
    };
  },
});
