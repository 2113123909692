import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Paper, Tabs, Tab } from "@material-ui/core";

import * as config from "../../../store/ducks/config.duck";
import * as configCRUD from "../../../crud/config.crud";

import { getUsers } from "../../../crud/users.crud";
import * as user from "../../../store/ducks/users.duck";

import { getClients } from "../../../crud/clients.crud";
import * as clients from "../../../store/ducks/clients.duck";

import Loading from "../../../utils/Loading";
//import ConfiguracionExample from "./ConfiguracionExample";

import ConfiguracionSuggestions from "./suggestions/ConfiguracionSuggestions";
import ConfiguracionGeneral from "./general/ConfiguracionGeneral";
import ConfiguracionClases from "./clases/ConfiguracionClases";
import { configPermissions } from "../../../utils/permissions";
import ConfiguracionPayments from "./payments/ConfiguracionPayments";
import ConfigurationStore from "./store/ConfigurationStore";
import { ConfiguracionExpectation } from "./general/expectations/ConfiguracionExpectation";

class ConfiguracionWrapper extends Component {
  state = {
    tabValue: 0,
    permissions: configPermissions(),
  };

  componentDidMount() {
    const { configActions, userActions } = this.props;
    const {
      setRoles,
      setLocations,
      setSuggestionTypes,
      setSuggestionStatuses,
      setAreas,
      setCategories,
      setPrograms,
      setLevels,
      setSuggestionSources,
      setPools,
      setPoolTypes,
      setGroups,
      setStoreCategories,
    } = configActions;
    configCRUD.getRoles(setRoles);
    configCRUD.getLocations(setLocations);
    configCRUD.getSuggestionTypes(setSuggestionTypes);
    configCRUD.getSuggestionStatuses(setSuggestionStatuses);
    configCRUD.getAreas(setAreas);
    configCRUD.getCategories(setCategories);
    configCRUD.getPrograms(setPrograms);
    configCRUD.getLevels(setLevels);
    configCRUD.getSuggestionSources(setSuggestionSources);
    configCRUD.getPools(setPools);
    configCRUD.getPoolTypes(setPoolTypes);
    configCRUD.getGroups(setGroups);
    configCRUD.getStoreCategories(setStoreCategories);
    configCRUD.getIntructors(this.props.configActions.setIntructors);
    getUsers(userActions.setUsers);
    getClients(this.props.clientActions.setClients);
  }

  renderComponent(permit) {
    const { tabValue } = this.state;
    let component;
    switch (tabValue) {
      case 0:
        component = <ConfiguracionGeneral permit={permit} />;
        break;
      case 1:
        component = <ConfiguracionClases permit={permit} />;
        break;
      case 2:
        component = <ConfiguracionPayments permit={permit} />;
        break;
      case 3:
        component = <ConfigurationStore permit={permit} />;
        break;
      case 4:
        component = <ConfiguracionSuggestions permit={permit} />;
        break;
      case 5:
        component = <ConfiguracionExpectation permit={permit} />;
        break;
      default:
        component = <ConfiguracionGeneral />;
    }
    return component;
  }

  render() {
    const { isFetched, data } = this.props.roles; //Define wich variable contains the necessary data to load the view
    const { permissions } = this.state;

    if (isFetched || data.length) {
      //If we choose to persist data locally, we can load the view without having fetched
      return (
        <>
          <Paper style={{ flexGrow: 1, marginBottom: 20 }}>
            <Tabs
              value={this.state.tabValue}
              onChange={(e, value) => this.setState({ tabValue: value })}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              centered
            >
              {/* Poner chequeo de permisios para c/uno */}
              {/* <Tab label="Explicación" />
            {permissions.rolesRead && <Tab label="Roles" />}
            {permissions.locationsRead && <Tab label="Ubicaciones" />}
            {permissions.areasRead && <Tab label="Áreas" />}
            {permissions.categoriesRead && <Tab label='Categorías'/>}
            {permissions.programsRead && <Tab label='Programas'/>}
            {permissions.levelsRead && <Tab label='Niveles'/>}
            {<Tab label='Albercas' />} */}
              <Tab label="General" />
              <Tab label="Clases" />
              <Tab label="Pagos" />
              <Tab label="Tienda" />
              {permissions.suggestionsRead && <Tab label="Sugerencias" />}
              <Tab label="Expectativa" />
            </Tabs>
          </Paper>
          {this.renderComponent(permissions)}
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (store) => ({
  roles: store.config.roles,
});

function mapDispatchToProps(dispatch) {
  return {
    configActions: bindActionCreators(config.actions, dispatch),
    userActions: bindActionCreators(user.actions, dispatch),
    clientActions: bindActionCreators(clients.actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionWrapper);
