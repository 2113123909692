import notice from "../utils/notice";
import { PRODUCTS_URL, VARIANTS_URL } from "./helpers/routes";
import {
  getParams,
  handleResponse,
  getMultipartParams,
  handleError,
} from "./helpers/validate";

export const defaultProduct = {
  product: {
    id: null,
    name: "",
    description: "",
    price: "",
    image: "",
    store_category_id: "",
    brand: "",
    code: "",
    variants: [],
  },
};

export function getProducts(setterFunction, page = 1) {
  return fetch(`${PRODUCTS_URL}?page=${page}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

export function searchProductsPointOfSale(query) {
  return fetch(`${PRODUCTS_URL}/filter_product?search=${query}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function searchProducts(setterFunction, query) {
  return fetch(`${PRODUCTS_URL}?name=${query}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

export function getProduct(setterFunction, id) {
  return fetch(`${PRODUCTS_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateProduct(data) {
  const body = new FormData();
  const id = data.id;

  body.append("product[clave_sat]", data.clave_sat);
  body.append("product[descripcion_sat]", data.descripcion_sat);
  body.append("product[unidad]", data.unidad);
  body.append("product[descrip_unidad]", data.descrip_unidad);
  body.append("product[name]", data.name);
  body.append("product[description]", data.description);
  body.append("product[price]", data.price);
  body.append("product[store_category_id]", data.store_category_id);
  body.append("product[brand]", data.brand);
  body.append("product[code]", data.code);
  if (data.image instanceof File) {
    body.append("product[image]", data.image);
  }

  return fetch(`${PRODUCTS_URL}/${id}`, getMultipartParams("PUT", body))
    .then(handleResponse)
    .catch(handleError);
}

export function saveProduct(data) {
  const body = new FormData();

  body.append("product[clave_sat]", data.clave_sat);
  body.append("product[descripcion_sat]", data.descripcion_sat);
  body.append("product[unidad]", data.unidad);
  body.append("product[descrip_unidad]", data.descrip_unidad);
  body.append("product[name]", data.name);
  body.append("product[description]", data.description);
  body.append("product[price]", data.price);
  body.append("product[store_category_id]", data.store_category_id);
  body.append("product[brand]", data.brand);
  body.append("product[code]", data.code);
  if (data.image instanceof File) {
    body.append("product[image]", data.image);
  }

  return fetch(PRODUCTS_URL, getMultipartParams("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

export const removeProduct = (id) => {
  return fetch(`${PRODUCTS_URL}/delete_logic`, getParams("PUT", JSON.stringify({ id })))
    .then(handleResponse)
    .catch(handleError);
};

export const createVariant = (data) => {
  return fetch(VARIANTS_URL, getParams("POST", JSON.stringify(data)))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteVariant = (id) => {
  return fetch(`${VARIANTS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};
