import React from 'react'
import { Radio, FormControlLabel, FormControl, FormLabel, RadioGroup } from '@material-ui/core'

const SimpleRadioButton = ({ label, name, value, onChange, disabled=false, options=[], color="primary", row=false }) => {
  return (
    <FormControl component="fieldset" disabled={disabled} color={color}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={onChange} row={row}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            control={<Radio />}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            labelPlacement={option.labelPlace}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SimpleRadioButton
