import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionType = {
  SetLoader: '[LOADER] SET_LOADER',
};

const initialState = {
  loader: false,
};

export const reducer = persistReducer(
  { storage, key: 'loader', whitelist: [] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionType.SetLoader:
        return { ...state, loader: action.payload }
      
      default:
        return state
    }
  }
)

export const setLoader = data => ({ type: actionType.SetLoader, payload: data })