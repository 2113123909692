import React, { Fragment, useEffect, useState } from 'react'
import { Avatar, Checkbox, Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const SimpleMultipleAutocomplete = ({ label, value=[], name, onChange, options=[], searchFunction, placeholder, disabled, showAvatars=false, limitTags=-1, variant="standard", color="primary", className="", style={}, margin='none', error, helperText="", onBlur=console.log, filterSelectedOptions=false, disableCloseOnSelect=false, checkboxOption=false }) => {
  const [touched, setTouched] = useState(false);
  const [query, setQuery] = useState('')
  const [reqOptions, setReqOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (touched && searchFunction) {
      setLoading(true)
      searchFunction(query)
      .then(res => setReqOptions(res))
      .catch(console.log)
      .finally(() => setLoading(false))
    }
  }, [query, searchFunction, touched])

  const handleInputChange = (e, newInputValue="") => setQuery(newInputValue);

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect={disableCloseOnSelect}
        filterSelectedOptions={filterSelectedOptions}
        limitTags={limitTags}
        disabled={disabled}
        loading={loading}
        options={reqOptions?.length > 0 ? reqOptions : options}
        loadingText={query && loading ? "Cargando" : `Ingresa búsqueda de ${label?.toLowerCase()}`}
        getOptionLabel={(option) => option?.text}
        getOptionSelected={(option, value) => option.value === value.value}
        value={value}
        inputValue={query}
        onChange={(e, newValue) => {
          onChange({ target: { name, value: newValue || '' } });
          setQuery("");
        }}
        onInputChange={handleInputChange}
        renderTags={(value, getTagProps) => (
          value.map((option, index) => {
            return (
            <Chip key={option.value} avatar={showAvatars && <Avatar className='bg-info text-white'>{option.text && option.text[0]}</Avatar>} label={option.text} {...getTagProps({ index })} />
          )})
        )}
        renderOption={(option, { selected }) => (
          <>
            {checkboxOption&&<Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
            />}
            {option.text}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            error={error}
            helperText={helperText}
            margin={margin}
            size='small'
            color={color}
            style={style}
            className={className}
            variant={variant}
            placeholder={value?.length === 0 && (placeholder || label)}
            onBlur={e => {setTouched(false); onBlur(e)}}
            onFocus={e => setTouched(true)}
            InputProps={{
              ...params.InputProps,
              className: `overflow-hidden`,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color={"inherit"} size={20}/> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default SimpleMultipleAutocomplete
