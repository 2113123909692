import React from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";

const SimpleTime = ({
  label,
  name,
  onChange,
  value,
  variant = "standard",
  disabled,
  style = {},
  className = "",
  color = "primary",
  margin = "none",
  error,
  helperText = "",
  onBlur = console.log,
  minTime,
  maxTime,
}) => {
  return (
    <KeyboardTimePicker
      autoOk
      fullWidth
      name={name}
      color={color}
      disabled={disabled}
      label={label}
      value={value}
      style={style}
      size="small"
      error={error}
      onBlur={onBlur}
      helperText={helperText}
      margin={margin}
      variant="inline"
      className={className}
      inputVariant={variant}
      format="hh:mm a"
      placeholder="HH:mm"
      onChange={(date) => {
        if (minTime && new Date(minTime).getHours() > new Date(date).getHours()) return;
        if (maxTime && new Date(maxTime).getHours() < new Date(date).getHours()) return;
        onChange({ target: { value: date, name } });
      }}
    />
  );
};

export default SimpleTime;
