import ActionsColumn from "./components/ActionsColumn";
import StatusColumn from "./components/StatusColumn";
import { customSearchInput } from "../../../common/components/FullTable";
import ReportesColumn from "./components/ReportesColumn";
import { AllReportColumn } from "./components/AllReportColumn";
import { StatusReport } from "./components/StatusReport";
import { ReportStatusColumn } from "./components/ReportStatusColumn";

export const defaultFilter = {
  client_location: "",
  // start_date: null,
  // end_date: null,
};
export const filtersForm = (locationsInput = []) => [
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locationsInput,
  },
  // {
  //   name: "start_date",
  //   label: "Fecha de inicio",
  //   type: "date",
  //   variant: "filled",
  // },
  // {
  //   name: "end_date",
  //   label: "Fecha de fin",
  //   type: "date",
  //   variant: "filled",
  // },
];

export const defaultReport = {
  client_location: "",
  start_date: null,
  end_date: null,
};
export const filtersReport = (locationsInput = []) => [
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locationsInput,
  },
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
];

export const defaultFilterReport = {
  client_location: "",
  start_date: null,
  end_date: null,
  date_report: null,
  id_reporte_pago: null,
};
export const filtersFormReport = (locationsInput = []) => [
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locationsInput,
  },
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "date_report",
    label: "Fecha del reporte",
    type: "date",
    variant: "filled",
  },
  {
    name: "id_reporte_pago",
    label: "Reporte pago",
    type: "text",
    variant: "filled",
  },
];

export const columnInstructorNew = (handleSelectId) => [
  {
    name: "clave",
    label: "Clave",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "last_name",
    label: "Apellido",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "cargo",
    label: "Cargo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status_guardar",
    label: "Estatus",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (data) => ReportStatusColumn(data),
    },
  },
  {
    name: "instructor_id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) => ReportesColumn(handleSelectId, data),
    },
  },
];
export const columnInstructorEdit = (handleSelectId) => [
  {
    name: "clave",
    label: "Clave",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "last_name",
    label: "Apellido",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "cargo",
    label: "Cargo",
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: "active",
  //   label: "Estatus",
  //   options: {
  //     filter: true,
  //     sort: true,
  //   },
  // },
  {
    name: "instructor_id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) => ReportesColumn(handleSelectId, data),
    },
  },
];
export const reportListColumn = (openDialog, getReportPDF) => [
  {
    name: "id",
    label: "Id",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date_reporte",
    label: "Fecha del reporte",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "amount",
    label: "Monto",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: "status_reporte",
  //   label: "Estatus",
  //   options: {
  //     filter: true,
  //     sort: true,
  //     customBodyRender: (data) => StatusReport(data),
  //   },
  // },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) => AllReportColumn(data, openDialog, getReportPDF),
    },
  },
];
export const columns = (locations, updateStatus, setID, openDialog) => [
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "last_name",
    label: "Apellido",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "role.name",
    label: "Tipo de instructor",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "location_id",
    label: "Ubicación",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (data, dataIndex, rowIndex) => {
        const location = locations.find((x) => x.id === data);
        return location?.name || "";
      },
    },
  },
  {
    name: "active",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (data, tableMeta, updateFunction) =>
        StatusColumn(data, tableMeta, updateFunction, updateStatus, openDialog),
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      setCellProps: (value) => ({ className: "text-center" }),
    },
  },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data, tableMeta, updateFunction) =>
        ActionsColumn(data, openDialog, setID),
      setCellHeaderProps: (value) => ({ className: "text-center" }),
    },
  },
];

const headerNames = [
  { name: "Nombre", download: true },
  { name: "Ubicación", download: true },
  { name: "Status", download: true },
  { name: "Categoria", download: true },
];

export const options = (
  page,
  setPage,
  count,
  searchText,
  setSearchText,
  fetchInstructorsQuery
) => ({
  serverSide: true,
  filter: false,
  pagination: true,
  count,
  searchText,
  setSearchText,
  customSearchRender: () =>
    customSearchInput(searchText, setSearchText, fetchInstructorsQuery),

  onChangePage: (newPage) => setPage(newPage + 1),
  page: page - 1,
  rowsPerPage: 50,
  rowsPerPageOptions: [],
  responsive: "scrollMaxHeight",
  sort: false,
  print: false,
  viewColumns: false,
  selectableRows: "none",
  downloadOptions: {
    filename: "Instructores.csv",
    filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true },
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    function replaceItems(obj) {
      obj.data[3] = obj.data[3] ? "Activo" : "Inactivo";
      return obj;
    }
    const table = buildHead(headerNames) + buildBody(data.map(replaceItems));
    return table;
  },
});

export const instructorsForm = (
  instructorRoles = [],
  locations = [],
  categories = [],
  disabled
) => [
  {
    name: "name",
    label: "Nombre",
    type: "text",
    margin: "normal",
    disabled,
  },
  {
    name: "last_name",
    label: "Apellido",
    type: "text",
    margin: "normal",
    disabled,
  },
  {
    name: "date_of_birth",
    label: "Fecha de nacimiento",
    type: "date",
    margin: "normal",
    disabled,
  },
  {
    name: "phone",
    label: "Número de telefono",
    type: "text",
    margin: "normal",
    mask: "999 999 9999",
  },
  {
    name: "email",
    label: "Correo electrónico",
    type: "text",
    margin: "normal",
    disabled,
  },
  {
    name: "password",
    label: "Contraseña",
    type: "password",
    margin: "normal",
    disabled,
  },
  {
    name: "blood_type",
    label: "Tipo de sangre",
    type: "dropdown",
    margin: "normal",
    disabled,
    options: [
      { value: "O+", text: "O Positivo" },
      { value: "O-", text: "O Negativo" },
      { value: "A+", text: "A Positivo" },
      { value: "A-", text: "A Negativo" },
      { value: "B+", text: "B Positivo" },
      { value: "B-", text: "B Negativo" },
      { value: "AB+", text: "AB Positivo" },
      { value: "AB-", text: "AB Negativo" },
    ],
  },
  {
    name: "address",
    label: "Dirección",
    type: "text",
    margin: "normal",
  },
  {
    name: "instructor_id",
    label: "Clave instructor",
    type: "text",
    margin: "normal",
  },
  {
    name: "role_id",
    label: "Tipo de instructor",
    type: "dropdown",
    margin: "normal",
    options: instructorRoles,
    disabled,
  },
  {
    name: "location_id",
    label: "Ubicaciones",
    type: "dropdown",
    margin: "normal",
    options: locations.map(({ id, name, ...other }) => ({ value: id, text: name })),
    disabled,
  },
  {
    name: "category_ids",
    label: "Categorias",
    type: "dropdown",
    margin: "normal",
    options: categories,
    multiple: true,
  },
  {
    name: "hour_price",
    label: "Pago por hora",
    type: "number",
    margin: "normal",
    decimals: 2,
    isPrice: true,
  },
  {
    name: "half_hour_price",
    label: "Pago por media hora",
    type: "number",
    margin: "normal",
    decimals: 2,
    isPrice: true,
  },
  {
    name: "tree_quarters_hour_price",
    label: "Pago por 3/4 de hora",
    type: "number",
    margin: "normal",
    decimals: 2,
    isPrice: true,
  },
  {
    name: "active",
    label: "Estado",
    type: "checkbox",
    disabled,
  },
];
