import { ActionsColumn } from "./components/ActionsColumn";
import { currencyFormat } from "../../../utils/data_formatters";
import { customSearchInput } from "../../../common/components/FullTable";

export const columns = (openDialog, handleRemove) => [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "store_category.name",
    label: "Categoría",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "price",
    label: "Precio",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => currencyFormat.format(data),
    },
  },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data, dataRow) => ActionsColumn(data, openDialog, handleRemove),
    },
  },
];

export const options = (
  onChangePage,
  page,
  count,
  searchText,
  setSearchText,
  handleProductQuery
) => ({
  serverSide: true,
  filter: false,
  pagination: true,
  count,
  searchText,
  setSearchText,
  customSearchRender: () =>
    customSearchInput(searchText, setSearchText, handleProductQuery),
  onChangePage,
  page: page - 1,
  rowsPerPageOptions: [],
  rowsPerPage: 50,
});

export const formProducts = (categories) => [
  {
    name: "clave_sat",
    label: "Clave SAT",
    type: "text",
    // required,
  },
  {
    name: "descripcion_sat",
    label: "Descripción SAT",
    type: "text",
    width: "100%",
    multiline: true,
  },
  {
    name: "unidad",
    label: "Unidad SAT",
    type: "dropdown",
    options: [
      { value: "H87", text: "H87" },
      { value: "EA", text: "EA" },
      { value: "E48", text: "E48" },
      { value: "ACT", text: "ACT" },
      { value: "KGM", text: "KGM" },
      { value: "E51", text: "E51" },
      { value: "A9", text: "A9" },
      { value: "MTR", text: "MTR" },
      { value: "AB", text: "AB" },
      { value: "BB", text: "BB" },
      { value: "KT", text: "KT" },
      { value: "SET", text: "SET" },
      { value: "LTR", text: "LTR" },
      { value: "XBX", text: "XBX" },
      { value: "MON", text: "MON" },
      { value: "MTK", text: "MTK" },
      { value: "11", text: "11" },
      { value: "MGM", text: "MGM" },
      { value: "XPK", text: "XPK" },
      { value: "XKI", text: "XKI" },
      { value: "AS", text: "AS" },
      { value: "GRM	", text: "GRM	" },
      { value: "PR", text: "PR" },
      { value: "DPC", text: "DPC" },
      { value: "xun", text: "xun" },
      { value: "DAY	", text: "DAY" },
      { value: "XLT", text: "XLT" },
      { value: "10		", text: "10	" },
      { value: "MLT", text: "MLT" },
      { value: "E54", text: "E54" },
    ],
  },
  {
    name: "descrip_unidad",
    label: "Descripción unidad SAT",
    type: "dropdown",
    options: [
      { value: "H87", text: "Pieza" },
      { value: "EA", text: "Elemento" },
      { value: "E48", text: "Unidad de Servicio" },
      { value: "ACT", text: "Actividad" },
      { value: "KGM", text: "Kilogramo" },
      { value: "E51", text: "Trabajo" },
      { value: "A9", text: "Tarifa" },
      { value: "MTR", text: "Metro" },
      { value: "AB", text: "Paquete a granel" },
      { value: "BB", text: "Caja base" },
      { value: "KT", text: "Kit" },
      { value: "SET", text: "Conjunto" },
      { value: "LTR", text: "Litro" },
      { value: "XBX", text: "Caja" },
      { value: "MON", text: "Mes" },
      { value: "HUR", text: "Hora" },
      { value: "MTK", text: "Metro cuadrado" },
      { value: "11", text: "Equipos" },
      { value: "MGM", text: "Miligramo" },
      { value: "XPK", text: "Paquete" },
      { value: "XKI", text: "Kit (Conjunto de piezas)" },
      { value: "AS", text: "Variedad" },
      { value: "GRM	", text: "Gramo	" },
      { value: "PR", text: "Par" },
      { value: "DPC", text: "Docenas de piezas" },
      { value: "xun", text: "Unidad" },
      { value: "DAY	", text: "Día" },
      { value: "XLT", text: "Lote" },
      { value: "10		", text: "Grupos	" },
      { value: "MLT", text: "Mililitro" },
      { value: "E54", text: "Viaje" },
    ],
  },
  {
    name: "name",
    label: "Nombre",
    type: "text",
  },
  {
    name: "store_category_id",
    label: "Categoría",
    type: "dropdown",
    options: categories.map((cat) => ({ value: cat.id, text: cat.name })),
  },
  {
    name: "price",
    label: "Precio",
    type: "number",
    isPrice: true,
    decimals: 2,
  },
  {
    name: "brand",
    label: "Marca",
    type: "text",
  },
  {
    name: "code",
    label: "Código",
    type: "text",
  },
  {
    name: "description",
    label: "Descripción",
    type: "text",
    width: "100%",
    multiline: true,
  },
];
