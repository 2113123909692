import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";
import notice from "../../../../utils/notice";

function SSourcesDialog({mode, suggestion_source, setSuggestionSource, setDialog, setSuggestionSources, updateSuggestionSources, permit}) {

    const open = mode === 'editing' || mode === 'creating'
    const title = suggestion_source.id ? 'Editar fuente' : 'Nueva fuente'
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
      paddingRight: "2.5rem"
    });
  
    const enableLoading = () => {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };
  
    const disableLoading = () => {
      setLoading(false);
      setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
  
    let disableTexts = true
    if(permit.suggestionsSources){
      disableTexts = false
    }
  
    function create(values) {
      const sugg = {
        name: values.name,
        description: values.description,
        color: values.color
      }
      configCRUD.saveSuggestionSource(sugg, updateSuggestionSources)
      .then(() => {
        setDialog('closed')
        notice("Sugerencia guardada", "success")
      })
      .catch(e => notice(e.join('\n') || "Error con su solicitud"))
      .finally(disableLoading)
    }
  
    function update(values) {
      const sugg = {
        id: suggestion_source.id,
        name: values.name,
        description: values.description,
        color: values.color
      }
      configCRUD.updateSuggestionSource(sugg, updateSuggestionSources)
      .then(() => {
        setDialog('closed')
        notice("Sugerencia actualizada", "success")
      })
      .catch(e => notice(e.join('\n') || "Error con su solicitud"))
      .finally(disableLoading)
    }
  
    return (
      <Dialog open={open} fullWidth={true} maxWidth={'md'}
        onClose={() => setDialog('closed')}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ name: suggestion_source.name, description: suggestion_source.description, color: suggestion_source.color }}
            validate={values => {
              const errors = {}
              if(!values.name){
                errors.name = 'Campo requerido'
              }
              if(!values.description){
                errors.description = 'Campo requerido'
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              enableLoading();
              suggestion_source.id ? update(values) : create(values)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete='off'
                onSubmit={handleSubmit}
                className="kt-form"
              >
                <div className="form-group">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Nombre"
                        margin="normal"
                        className="kt-width-full"
                        disabled={disableTexts}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="color"
                        label="Color"
                        margin="normal"
                        name="color"
                        inputProps={{style: {
                          width: "200px"
                        }}}
                        disabled={disableTexts}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.color}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Descripción"
                        margin="normal"
                        className="kt-width-full"
                        disabled={disableTexts}
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        helperText={touched.description && errors.description}
                        error={Boolean(touched.description && errors.description)}
                        fullWidth
                        variant='outlined'
                        multiline
                        rowsMax="4"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className='btn-dialog'>
                  <Button onClick={() => setDialog('closed')} color="primary">
                    Cancelar
                  </Button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    {suggestion_source.id ? 'Editar' : 'Crear'}
                  </button>
                </div>
              </form>
            )
            }
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
  
  const mapStateToProps = store => ({
    suggestion_source: store.config.suggestion_source
  });
  
  export default connect(mapStateToProps, config.actions)(SSourcesDialog)