import moment from "moment";
import { StatusColumn } from "./components/StatusColumn";

export const table_options = (showGroup, page) => ({
  onRowClick: data => showGroup(data[1]),
  downloadOptions: {
    filename: 'Asistencias.csv',
    filterOptions: {useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true}
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    function replaceItems(obj){
      
      obj.data[8] = obj.data[8] ? "Asistió" : "No asistió"
      return obj
    }
    const table = buildHead([
      {name:'Nombre', download: true },
      {name:'Edad', download: true },
      {name:'Nivel', download: true },
      {name:'Categoria', download: true },
      {name:'Grupo', download: true },
      {name:'Instructor', download: true },
      {name:'Horario', download: true },
      {name:'Fecha', download: true },
      {name:'Asistencia', download: true },
    ]) +
      buildBody(data.map(replaceItems))
    return table;
  },
})

export const attendance_columns = [
  {
    name: 'id',
    label: 'id',
    options:{
      filter:false,
      sort: false,
      display: false
    }
  },
  {
    name: 'group_schedule_id',
    label: 'id',
    options:{
      filter:false,
      sort: false,
      display: false
    }
  },
  {
    name: "group",
    label: "Grupo",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.name",
    label: "Cliente",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.age",
    label: "Edad",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.level",
    label: "Nivel",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.category",
    label: "Categoria",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "instructor",
    label: "Instructor",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "group_schedule",
    label: "Horario",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "registered_at",
    label: "Fecha de registro",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (data) => `${moment(data).format('L LT')}`
    }
  },
  {
    name: "status",
    label: "Asistencia",
    options: {
      filter: true,
      sort: true,
      customBodyRender: StatusColumn
    }
  },
];

export const attendance_group_columns = [
  {
    name: 'id',
    label: 'id',
    options:{
      filter:false,
      sort: false,
      display: false
    }
  },
  {
    name: "client.name",
    label: "Cliente",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.age",
    label: "Edad",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.level",
    label: "Nivel",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.category",
    label: "Categoria",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "instructor",
    label: "Instructor",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "registered_at",
    label: "Fecha de registro",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (data) => `${moment(data).format('L LT')}`
    }
  },
  {
    name: "status",
    label: "Asistencia",
    options: {
      filter: true,
      sort: true,
      customBodyRender: StatusColumn
    }
  },
];