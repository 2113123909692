import React, { Fragment, useState } from "react";
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import * as clientsCRUD from "../../../../crud/clients.crud";

const Contact = ({ responsibleContact, updateResponsibleContact, responsible }) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  return (
    <>
      <Paper style={{ marginTop: "20px" }}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
              Detalles de contacto
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {role?.name !== "Cliente" && !edit && (
              <Button
                style={{ marginRight: 25 }}
                color="primary"
                variant="contained"
                onClick={() => {
                  setEdit(true);
                }}
              >
                Editar informacion
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        {edit ? (
          <Grid item xs={12} style={{ display: "flex" }}>
            <Formik
              initialValues={
                responsibleContact.share_contact
                  ? {
                      share_contact: responsibleContact.share_contact,
                      ...responsibleContact.main_contact,
                    }
                  : {
                      clients: responsibleContact.clients,
                      share_contact: responsibleContact.share_contact,
                    }
              }
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                clientsCRUD
                  .updateResponsibleContact(
                    values,
                    updateResponsibleContact,
                    responsible.responsible.id
                  )
                  .then(() => {
                    disableLoading();
                    setSubmitting(false);
                    window.scrollTo(0, 0);
                    setEdit(false);
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                  <div className="form-group">
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ marginTop: 20, paddingLeft: "27px" }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={values.share_contact}
                                onChange={handleChange}
                                name="share_contact"
                              />
                            }
                          />
                          <Typography variant="h6">
                            ¿Los alumnos comparten información de contacto?
                          </Typography>
                        </Grid>
                      </Grid>

                      {values.share_contact ? (
                        <>
                          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                            <Grid item sm={3}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#868686",
                                  padding: "34px 0px 0px 27px",
                                }}
                              >
                                Correo electrónico
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                label="Correo electrónico"
                                margin="normal"
                                className="kt-width-full"
                                // disabled={disableTexts}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                helperText={touched.email && errors.email}
                                error={Boolean(touched.email && errors.email)}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                            <Grid item sm={3}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#868686",
                                  padding: "34px 0px 0px 27px",
                                }}
                              >
                                Teléfono Celular
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                label="Teléfono Celular"
                                margin="normal"
                                className="kt-width-full"
                                // disabled={disableTexts}
                                name="cellphone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cellphone}
                                helperText={touched.cellphone && errors.cellphone}
                                error={Boolean(touched.cellphone && errors.cellphone)}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                            <Grid item sm={3}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#868686",
                                  padding: "34px 0px 0px 27px",
                                }}
                              >
                                Teléfono Fijo
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                label="Teléfono Fijo"
                                margin="normal"
                                className="kt-width-full"
                                // disabled={disableTexts}
                                name="landline"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.landline}
                                helperText={touched.landline && errors.landline}
                                error={Boolean(touched.landline && errors.landline)}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                            <Grid item sm={3}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#868686",
                                  padding: "34px 0px 0px 27px",
                                }}
                              >
                                Código Postal
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <TextField
                                label="Código Postal"
                                margin="normal"
                                className="kt-width-full"
                                // disabled={disableTexts}
                                name="postal_code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.postal_code}
                                helperText={touched.postal_code && errors.postal_code}
                                error={Boolean(touched.postal_code && errors.postal_code)}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        responsibleContact.clients.map((student, i) => (
                          <Fragment key={student.id}>
                            <Typography
                              variant="h6"
                              style={{ padding: "15px 0px 15px 27px" }}
                            >
                              Alumno {i + 1} - {student.name} {student.last_name}
                            </Typography>
                            <Grid container xs={12} sm={12}>
                              <Grid item sm={3}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#868686",
                                    padding: "34px 0px 0px 27px",
                                  }}
                                >
                                  Correo electrónico
                                </Typography>
                              </Grid>
                              <Grid item sm={6}>
                                <TextField
                                  label="Correo electrónico"
                                  margin="normal"
                                  className="kt-width-full"
                                  // disabled={disableTexts}
                                  name={`clients["${i}"]['contact']['email']`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.clients[i].contact
                                      ? values.clients[i].contact.email
                                      : ""
                                  }
                                  helperText={touched.email && errors.email}
                                  error={Boolean(touched.email && errors.email)}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                              <Grid item sm={3}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#868686",
                                    padding: "34px 0px 0px 27px",
                                  }}
                                >
                                  Teléfono Celular
                                </Typography>
                              </Grid>
                              <Grid item sm={6}>
                                <TextField
                                  label="Teléfono Celular"
                                  margin="normal"
                                  className="kt-width-full"
                                  // disabled={disableTexts}
                                  name={`clients["${i}"]['contact']['cellphone']`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.clients[i].contact
                                      ? values.clients[i].contact.cellphone
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                              <Grid item sm={3}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#868686",
                                    padding: "34px 0px 0px 27px",
                                  }}
                                >
                                  Teléfono Fijo
                                </Typography>
                              </Grid>
                              <Grid item sm={6}>
                                <TextField
                                  label="Teléfono Fijo"
                                  margin="normal"
                                  className="kt-width-full"
                                  name={`clients["${i}"]['contact']['landline']`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.clients[i].contact
                                      ? values.clients[i].contact.landline
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                              <Grid item sm={3}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#868686",
                                    padding: "34px 0px 0px 27px",
                                  }}
                                >
                                  Código Postal
                                </Typography>
                              </Grid>
                              <Grid item sm={6}>
                                <TextField
                                  label="Código Postal"
                                  margin="normal"
                                  className="kt-width-full"
                                  // disabled={disableTexts}
                                  name={`clients["${i}"]['contact']['postal_code']`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.clients[i].contact
                                      ? values.clients[i].contact.postal_code
                                      : ""
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Fragment>
                        ))
                      )}
                    </Grid>
                  </div>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEdit(false);
                      }}
                      variant="contained"
                      style={{
                        background: "#001979",
                        color: "white",
                        margin: "10px",
                        padding: "9.5px 20px",
                      }}
                    >
                      Cancelar
                    </Button>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Guardar Cambios
                    </button>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} style={{ marginTop: 20, paddingLeft: "27px" }}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Checkbox
                  color="primary"
                  checked={responsibleContact.share_contact}
                  // onChange={handleChange}
                  name="share_contact"
                  disabled
                />
                <Typography variant="h6">
                  ¿Los alumnos comparten información de contacto?
                </Typography>
              </Grid>
            </Grid>
            {responsibleContact.share_contact ? (
              <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Correo electrónico
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Teléfono Celular
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Teléfono Fijo
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                  >
                    Código Postal
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsibleContact.main_contact.email
                      ? responsibleContact.main_contact.email
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsibleContact.main_contact.cellphone
                      ? responsibleContact.main_contact.cellphone
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsibleContact.main_contact.landline
                      ? responsibleContact.main_contact.landline
                      : "-"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
                  >
                    {responsibleContact.main_contact.postal_code
                      ? responsibleContact.main_contact.postal_code
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              responsibleContact.clients.map((student, i) => (
                <div key={student.id}>
                  <Typography variant="h6" style={{ padding: "15px 0px 15px 27px" }}>
                    Alumno {i + 1} - {student.name} {student.last_name}
                  </Typography>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#868686",
                          padding: "15px 0px 15px 27px",
                        }}
                      >
                        Correo electrónico
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#868686",
                          padding: "15px 0px 15px 27px",
                        }}
                      >
                        Teléfono Celular
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#868686",
                          padding: "15px 0px 15px 27px",
                        }}
                      >
                        Teléfono Fijo
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#868686",
                          padding: "15px 0px 15px 27px",
                        }}
                      >
                        Código Postal
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        style={{
                          padding: "15px 0px 15px 0px",
                          fontWeight: 600,
                        }}
                      >
                        {student?.contact?.email ? student.contact.email : "-"}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          padding: "15px 0px 15px 0px",
                          fontWeight: 600,
                        }}
                      >
                        {student?.contact?.cellphone ? student.contact.cellphone : "-"}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          padding: "15px 0px 15px 0px",
                          fontWeight: 600,
                        }}
                      >
                        {student?.contact?.landline ? student.contact.landline : "-"}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          padding: "15px 0px 15px 0px",
                          fontWeight: 600,
                        }}
                      >
                        {student?.contact?.postal_code
                          ? student.contact.postal_code
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))
            )}
          </>
        )}
      </Paper>
    </>
  );
};
export default Contact;
