import React, { Fragment, useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

const SimpleAutocomplete = ({ label, value=null, name, onChange, options=[], searchFunction, placeholder, disabled, variant="standard", margin='none', error, helperText="", onBlur=console.log }) => {
  const [query, setQuery] = useState('')
  const [touched, setTouched] = useState(false);
  const [reqOptions, setReqOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (touched && searchFunction) {
      setLoading(true)
      searchFunction(query)
      .then(res => setReqOptions(res))
      .catch(console.log)
      .finally(() => setLoading(false))
    }
  }, [query, searchFunction, touched])

  const handleInputChange = (e, newInputValue="") => setQuery(newInputValue);

  return (
    <>
      <Autocomplete
        autoSelect
        autoComplete
        autoHighlight
        includeInputInList
        noOptionsText="Sin resultados"
        disabled={disabled}
        loading={loading}
        inputValue={query}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option?.text}
        options={options?.length > 0 ? options : reqOptions}
        getOptionSelected={(option, value) => option.id === value.id}
        loadingText={query && loading ? "Cargando" : `Ingresa búsqueda de ${label?.toLowerCase()}`}
        value={value}
        onChange={(e, newValue) => onChange({ target: { name, value: newValue || null } })}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            name={name}
            size='small'
            error={error}
            helperText={helperText}
            label={label}
            margin={margin}
            variant={variant}
            placeholder={placeholder || label}
            onBlur={e => {setTouched(false); onBlur(e)}}
            onFocus={e => setTouched(true)}
            InputProps={{
              ...params.InputProps,
              className: `overflow-hidden`,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color={"inherit"} size={20}/> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default SimpleAutocomplete
