import StatusColumnFormatter from "./components/StatusColumnFormatter";

export const columns = (locations, loggedUser) => [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "name",
    label: "Nombre",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "location_id",
    label: "Ubicación",
    options: {
      filter: false,
      sort: false,
      display: !loggedUser.role.location_id,
      customBodyRender: (data, dataIndex, rowIndex) => {
        const location = locations.find((x) => x.id === data);
        return location ? location.name : "Todos";
      },
    },
  },
  {
    name: "role.name",
    label: "Rol",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "active",
    label: "Estado",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => {
        return StatusColumnFormatter(data);
      },
    },
  },
];

const headerNames = [
  { name: "Nombre", download: true },
  { name: "Email", download: true },
  { name: "Ubicación", download: true },
  { name: "Rol", download: true },
  { name: "Estado", download: true },
];

export const options = (openDialog) => ({
  onRowClick: (data) => openDialog("editing", data[0]),
  downloadOptions: {
    filename: "Usuarios.csv",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    function replaceItems(obj) {
      obj.data[2] = obj.data[2] ? obj.data[2] : "Todos";
      obj.data[4] = obj.data[4] ? "activo" : "inactivo";
      return obj;
    }
    const table = buildHead(headerNames) + buildBody(data.map(replaceItems));
    return table;
  },
});
