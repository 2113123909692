import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Paper } from '@material-ui/core';

import styles from '../../tablas/Tablas.module.css'
import SSourcesDialog from './SSourcesDialog';
import CustomButton from '../../../../common/components/CustomButton';

export const SSourcesTable = (props) => {
    const { suggestion_sources, configCRUD, setSuggestionSource, permit } = props
    const [dialog, setDialog] = useState('closed')
    const columns = [
        {
            name: 'id',
            label: 'id',
            options:{
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: 'name',
            label: 'Fuente',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'description',
            label: 'Descripción',
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "color",
            label: "Color",
            options: {
                filter: false,
                sort: false,
                customBodyRender:(data, dataIndex, rowIndex) => {
                    return <div
                    style={{
                    width: "30px",
                    height: "10px",
                    borderRadius: "10px",
                    backgroundColor: data
                    }}
                />
                }
            }
        },
    ]

    const openDialog = (mode, id = null) => {
        switch(mode){
            case 'editing':
                configCRUD.getSuggestionSource(setSuggestionSource,id).then(() => setDialog(mode))
                break;
            case 'creating':
                setSuggestionSource(configCRUD.defaultSuggestionSource)
                setDialog(mode)
                break;
            case 'closed':
                setDialog(mode)
                setTimeout(() => {
                setSuggestionSource(configCRUD.defaultSuggestionSource)
                },200);
                break;
            default:
                console.error('Invalid dialog mode');
        }
    }

    const options = {
        responsive: 'scrollMaxHeight',
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: 'none',
        onRowClick: data => openDialog('editing', data[0]),
        textLabels: {
            body: {
                noMatch: 'No hay información disponible',
            },
        },
    };

    return (
        <>
            <div className="text-right mb-4">
              {permit.suggestionsSources && <CustomButton title="Crear sugerencia" onClick={() => openDialog('creating')} />}
            </div>
            <Paper>
                <div className = {styles.wrapper}>
                    <MUIDataTable
                        title={"Fuentes de sugerencia"}
                        data={suggestion_sources.data}
                        columns={columns}
                        options={options}/>
                </div>
            </Paper>
            <SSourcesDialog mode={dialog} setDialog={openDialog} permit={permit}/>
        </>
    )
}