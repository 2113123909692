import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CategoryDialog from "./CategoryDialog";
import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import CustomButton from "../../../../../common/components/CustomButton";
import FullTable from "../../../../../common/components/FullTable";
import { categories_columns } from "./utils";
import ConfirmDialog from "../../../../../utils/ConfirmDialog";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import notice from "../../../../../utils/notice";

const ConfiguracionCategories = ({ categories, setCategory, permit, removeCategory }) => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const [removeId, setRemoveId] = useState(false);
  const [categoryWithoutPrice, setCategoryWithoutPrice] = useState([]);
  const [categoryTable, setCategoryTable] = useState(false);

  useEffect(() => {
    configCRUD.getCategoriesWithouPrice(setCategoryWithoutPrice);
  }, [dispatch, categoryTable]);

  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        dispatch(setLoader(true));
        configCRUD
          .getCategory(setCategory, id)
          .then(() => setDialog(mode))
          .finally(() => dispatch(setLoader(false)));
        break;
      case "creating":
        setCategory(configCRUD.defaultCategory);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setCategory(configCRUD.defaultCategory);
        }, 200);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  }

  const handleOpenRemoveModal = (id) => setRemoveId(id);

  const handleRemove = () => {
    dispatch(setLoader(true));
    return configCRUD
      .deleteCategory(removeId)
      .then(async (res) => {
        notice("Categoria eliminada correctamente", "success");
        await removeCategory(removeId);
        setRemoveId(false);
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurriddo un error al eliminar la categoria"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };
  return (
    <>
      <div className="text-right mb-4">
        {permit.categoriesCreate && (
          <CustomButton title="Crear categoria" onClick={() => openDialog("creating")} />
        )}
      </div>
      <FullTable
        title={"Categorias"}
        data={categoryWithoutPrice || []}
        columns={categories_columns(handleOpenRemoveModal, openDialog)}
      />
      <CategoryDialog
        mode={dialog}
        setDialog={openDialog}
        permit={permit}
        setCategoryTable={setCategoryTable}
        categoryTable={categoryTable}
      />
      <ConfirmDialog
        openConfirm={removeId !== false}
        setConfirm={() => setRemoveId(false)}
        functionConfirm={handleRemove}
        message={<h4>¿Estas seguro que deseas eliminar esta categoria?</h4>}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  categories: store.config.categories,
});

export default connect(mapStateToProps, config.actions)(ConfiguracionCategories);
