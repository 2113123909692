import actionsColumn from "./components/actionsColumns";

export const columnsExpectation = (setID, openDialog) => [
  {
    name: "value",
    label: "id",
  },
  {
    name: "text",
    label: "Nombre",
  },
  {
    name: "value",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) => actionsColumn(data, setID, openDialog),
    },
  },
];
