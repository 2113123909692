import { CORTES_URL, DISCHARGES_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const getDischargesPDF = async (estatus, start, end, location) => {
  let fechaQuery = start && end ? `&date_start=${start}&date_end=${end}` : "";
  let status = estatus ? `&tipo_baja=${estatus}` : "";
  let locationId = location ? `&location_id=${location}` : "";
  let url = `descargar_pdf.pdf?${fechaQuery + status + locationId}`;
  url = url.slice(0, 18) + url.slice(19);
  return fetch(`${CORTES_URL}/${url}`, getParams("GET"));
  // .catch(handleError);
};
export const getDischargesCSV = async (estatus, start, end, location) => {
  let fechaQuery = start && end ? `&date_start=${start}&date_end=${end}` : "";
  let status = estatus ? `&tipo_baja=${estatus}` : "";
  let locationId = location ? `&location_id=${location}` : "";
  let url = `descargar_csv.csv?${fechaQuery + status + locationId}`;
  url = url.slice(0, 18) + url.slice(19);
  return fetch(`${CORTES_URL}/${url}`, getParams("GET"));
  // .catch(handleError);
};
export const getDischargesFilter = async (estatus, start, end, location) => {
  let fechaQuery = start && end ? `&date_start=${start}&date_end=${end}` : "";
  let status = estatus ? `&tipo_baja=${estatus}` : "";
  let locationId = location ? `&location_id=${location}` : "";
  let url = `lista_bajas?${fechaQuery + status + locationId}`;
  url = url.slice(0, 12) + url.slice(13);
  return fetch(`${DISCHARGES_URL}/${url}`, getParams("GET")).then(handleResponse);
  // .catch(handleError);
};

export const getDischarges = async () => {
  let startDate = "2022-01-01";
  let endDate = "2022-09-08";
  let status = "PARCIAL";
  let locationId = 18;
  //date_start=${startDate}&date_end=${endDate}&tipo_baja=${status}&location_id=${locationId}
  return fetch(`${DISCHARGES_URL}/lista_bajas?`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
