import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import UtilInputs from "../../../common/components/UtilInputs";

import * as sugg from "../../../store/ducks/suggestions.duck";
import * as suggestionsCRUD from "../../../crud/suggestions.crud";
import notice from "../../../utils/notice";
import { suggForm } from "./utils";

function SuggestionsDialog({
  mode,
  suggestion,
  locations,
  setDialog,
  updateSuggestions,
  permit,
  user,
  suggestionsTypes,
  suggestionsStatuses,
  suggestionsSources,
  areas,
  users,
}) {
  const open = mode === "editing" || mode === "creating";
  const title = suggestion.id ? "Editar sugerencia" : "Nueva sugerencia";
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  let disableTexts = true;
  if (
    (suggestion.id && permit.suggestionsUpdate) ||
    (!suggestion.id && permit.suggestionsCreate)
  ) {
    disableTexts = false;
  }

  function create(values) {
    const suggestionCheck = {
      id: suggestion.id,
      title: values.title,
      date: values.date,
      area_id: values.area_id,
      suggestion_type_id: values.suggestion_type_id,
      client_phone: values.client_phone,
      client_email: values.client_email,
      description: values.description,
      suggestion_status_id: values.suggestion_status_id,
      suggestion_source_id: values.suggestion_source_id,
      user_id: values.user_id,
      location_id: values.location_id,
    };
    suggestionsCRUD
      .saveSuggestion(suggestionCheck, updateSuggestions)
      .then(() => {
        setDialog("closed");
        notice("Sugerencia guardada", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }

  function update(values) {
    const suggestionCheck = {
      id: suggestion.id,
      title: values.title,
      date: values.date,
      area_id: values.area_id,
      suggestion_type_id: values.suggestion_type_id,
      client_phone: values.client_phone,
      client_email: values.client_email,
      description: values.description,
      suggestion_status_id: values.suggestion_status_id,
      suggestion_source_id: values.suggestion_source_id,
      user_id: values.user_id,
      location_id: values.location_id,
    };
    suggestionsCRUD
      .updateSuggestion(suggestionCheck, updateSuggestions)
      .then(() => {
        setDialog("closed");
        notice("Sugerencia actualizada", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }
  let isAdmin = user?.role?.name === "Admin";
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
        </DialogContentText>
        <Formik
          initialValues={{
            title: suggestion.title,
            date: suggestion.date,
            area_id: suggestion.area_id,
            client_phone: suggestion.client_phone,
            client_email: suggestion.client_email,
            description: suggestion.description,
            suggestion_type_id: suggestion.suggestion_type_id,
            suggestion_status_id: suggestion.suggestion_status_id,
            suggestion_source_id: suggestion.suggestion_source_id,
            user_id: suggestion.user_id,
            location_id: suggestion.location_id,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Campo requerido";
            }
            if (!values.date) {
              errors.date = "Campo requerido";
            }
            if (!values.area_id) {
              errors.area_id = "Campo requerido";
            }
            if (!values.user_id) {
              errors.user_id = "Campo requerido";
            }
            if (!values.suggestion_type_id) {
              errors.suggestion_type_id = "Campo requerido";
            }
            if (!values.description) {
              errors.description = "Campo requerido";
            }
            if (!values.suggestion_status_id) {
              errors.suggestion_status_id = "Campo requerido";
            }
            if (!values.suggestion_source_id) {
              errors.suggestion_source_id = "Campo requerido";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            suggestion.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <UtilInputs
                  formValues={values}
                  onValuesChange={handleChange}
                  inputValues={suggForm(
                    users,
                    areas,
                    isAdmin,
                    locations,
                    suggestionsStatuses,
                    suggestionsTypes,
                    suggestionsSources,
                    disableTexts
                  )}
                  width="33%"
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {suggestion.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store) => ({
  suggestion: store.sugg.suggestion,
  users: store.user.users.data,
  user: store.auth.user.user,
  suggestionsTypes: store.config.suggestion_types.data,
  suggestionsStatuses: store.config.suggestion_statuses.data,
  suggestionsSources: store.config.suggestion_sources.data,
  areas: store.config.areas.data,
  locations: store.config.locations.data,
});

export default connect(mapStateToProps, sugg.actions)(SuggestionsDialog);
