import React from 'react'
import { Table, TableBody, TableRow, TableCell, FormControl, Select, MenuItem, TextField, InputAdornment, TableHead } from '@material-ui/core'

export const MantPoolState = ({values, handleChange}) => {

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Acciones</TableCell>
          <TableCell>Estado</TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Checar bomba de recirculación de 2hp y filtro</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='bomb_state'
                value={values.bomb_state}
                onChange={handleChange}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="bomb_measure"
              value={values.bomb_measure}
              InputProps={{
                endAdornment: <InputAdornment position="end">Libras</InputAdornment>
              }}
            />
          </TableCell>
          <TableCell>18 a 28</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Termo tanque de agua caliente</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='water_tank_thermo_state'
                value={values.water_tank_thermo_state}
                onChange={handleChange}
                >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="water_tank_thermo_measure"
              value={values.water_tank_thermo_measure}
              InputProps={{
                  endAdornment: <InputAdornment position="end">Grados</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>50 a 55</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Suavizador de agua en general</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='water_softener_state'
                value={values.water_softener_state}
                onChange={handleChange}
                >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="water_softener_measure"
              value={values.water_softener_measure}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>0 a 200</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Presión de hidroneumatico</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='hydropneumatic_pressure_state'
                value={values.hydropneumatic_pressure_state}
                onChange={handleChange}
                >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="hydropneumatic_pressure_measure"
              value={values.hydropneumatic_pressure_measure}
              InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>3,5 kg o 50 lb</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Suministro de Cloro Concentrado Densidad 23
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="concentrated_chlorine_supply"
              value={values.concentrated_chlorine_supply}
            />
          </TableCell>
          <TableCell>22 a 24</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Suminstro de Acido Concentrado Densidad 20
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              fullWidth
              type='number'
              onChange={handleChange}
              name="concentrated_acid_supply"
              value={values.concentrated_acid_supply}
            />
          </TableCell>
          <TableCell>19 A 21</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Revisar estructura de alberca</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='pool_structure_state'
                value={values.pool_structure_state}
                onChange={handleChange}
                >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Revisar dosificador</TableCell>
          <TableCell>
            <FormControl margin='normal'>
              <Select
                name='dispenser_state'
                value={values.dispenser_state}
                onChange={handleChange}
                >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>OK</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
