import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import IconButton from "../../../../../../common/components/IconButton";

const FindOutsColumnsActions = (data, dataRow, openDialog, handleRemove) => {
  return (
    <div className="d-flex justify-content-between">
      <IconButton
        color={"success"}
        tooltip={"Editar"}
        Icon={Edit}
        onClick={() => openDialog(data)}
      />
      <IconButton
        color={"danger"}
        tooltip={"Remover"}
        Icon={Delete}
        onClick={() => handleRemove(data)}
      />
    </div>
  );
};

export default FindOutsColumnsActions;
