import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const SimpleCheckbox = ({
  label,
  labelPos = "top",
  name,
  value,
  onChange,
  disabled = false,
  color = "primary",
}) => {
  return (
    <FormControlLabel
      disabled={disabled}
      label={label}
      labelPlacement={labelPos}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      style={{ alignItems: `${labelPos === "top" ? "start" : ""}` }}
      control={
        <Checkbox
          disabled={disabled}
          onChange={(e) => onChange({ target: { name, value: e.target.checked } })}
          checked={value}
          name={name}
          color={color}
        />
      }
    />
  );
};

export default SimpleCheckbox;
