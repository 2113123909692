import { combineReducers } from "redux";
import { metronic } from "../../_metronic";

import * as auth from "./ducks/auth.duck";
import * as config from "./ducks/config.duck";
import * as sugg from './ducks/suggestions.duck';
import * as hlp from './ducks/help.duck';
import * as user from './ducks/users.duck';
import * as maint from './ducks/maint.duck';
import * as client from './ducks/clients.duck';
import * as products from './ducks/products.duck';
import * as calendar from './ducks/calendar.duck';
import { reducer as attendance } from "./ducks/attendance.duck";
import { reducer as loader } from "./ducks/loader.duck";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  config: config.reducer,
  sugg: sugg.reducer,
  hlp: hlp.reducer,
  user: user.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  maint: maint.reducer,
  clients: client.reducer,
  products: products.reducer,
  calendar: calendar.reducer,
  attendance,
  loader,
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
