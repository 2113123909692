import React, { useState } from "react";
import { connect } from "react-redux";
import { Paper } from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import styles from '../../../tablas/Tablas.module.css'
import AreaDialog from './AreaDialog';
import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import CustomButton from "../../../../../common/components/CustomButton";

function ConfiguracionAreas({areas, setArea, user, permit, locations, permissions}) {
  const [dialog, setDialog] = useState('closed') // closed, editing, creating

  const columns =[
    {
      name: 'id',
      label: 'id',
      options:{
        filter:false,
        sort: false,
        display: false
      }
    },
    {
      name: "name",
      label: "Ubicación",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "description",
      label: "Descripción",
      options: {
       filter: false,
       sort: false,
      }
     },
    {
      name: "color",
      label: "Color",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => {
          return <div
            style={{
            width: "30px",
            height: "10px",
            borderRadius: "10px",
            backgroundColor: data
          }}
        />
        }
      }
    },
  ]

  const options = {
    responsive: 'scrollMaxHeight',
    download: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    onRowClick: data => openDialog('editing', data[0]),
    textLabels:{
      body:{
        noMatch: "No se encontraron resultados"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },toolbar: {
        search: "Buscar",
      }
    }
  };

  function openDialog(mode, id = null){
    switch (mode) {
      case 'editing':
        configCRUD.getArea(setArea, id).then(() => setDialog(mode))
        break;
      case 'creating':
        setArea(configCRUD.defaultArea)
        setDialog(mode)
        break;
      case 'closed':
        setDialog(mode)
        setTimeout(() => {
          setArea(configCRUD.defaultArea)
        }, 200);
        break;
      default:
        console.error('Invalid dialog mode');
    }
  }
  return (
    <>
      <div className="text-right mb-4">
        {permit.areasCreate && <CustomButton title="Crear área" onClick={() => openDialog('creating')} />}
      </div>
      <Paper>
        <div className={styles.wrapper}>
          <MUIDataTable
            title={"Areas"}
            data={areas.data}
            columns={columns}
            options={options}
          />
        </div>
      </Paper>

      <AreaDialog mode={dialog} setDialog={openDialog} permit={permit}/>
  </>
  )

}

const mapStateToProps = store => ({
  areas: store.config.areas,
  permissions: store.config.permissions,
  user: store.auth.user.user,
  locations : store.config.locations
});

export default connect(mapStateToProps, config.actions)(ConfiguracionAreas)
