import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Paper, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from 'mui-datatables'

import styles from '../../../tablas/Tablas.module.css'
import ConfGroupsDialog from './ConfGroupsDialog'

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import CustomButton from '../../../../../common/components/CustomButton';

const ConfGroups = ({ groups, setGroup, categories, levels, users }) => {
  const [dialog, setDialog] = useState('closed') // closed, editing, creating

  const columns =[
    {
      name: 'id',
      label: 'id',
      options:{
        filter:false,
        sort: false,
        display: false
      }
    },
    {
      name: "name",
      label: "Grupo",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "user",
      label: "Instructor",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => (
          <p>{`${data.name} ${data.last_name}`}</p>
        )
      }
     },
    {
      name: "availability",
      label: "Cupo",
      options: {
       filter: false,
       sort: false,
      }
     },
    {
      name: "category_id",
      label: "Categoria",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => {
          const category = categories.find(x=>(x.id === data))
          return(
            <div>{category ? category.name : ""}</div>
          )
        }
      }
     },
    {
      name: "level_ids",
      label: "Niveles",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => {
          let level = levels.filter(x => data.includes(x.id))
          return (
            level ? level.map(lvl => (
              <p key={lvl.id}>{lvl.name}</p>
            )) : ''
          )
        }
      }
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => {
          return data ? 
          <div className='active'>
            <p>Active</p>
          </div> : 
          <div className='inactive'>
            <p>Inactivo</p>
          </div>
        }
      }
    },
  ]

  const options = {
    responsive: 'scrollMaxHeight',
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    onRowClick: data => openDialog('editing', data[0]),
    download: false,
    textLabels:{
      body:{
        noMatch: "No se encontraron resultados"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },toolbar: {
        search: "Buscar",
      }
    }
  };

  function openDialog(mode, id = null){
    switch (mode) {
      case 'editing': 
        configCRUD.getGroup(setGroup, id).then(() => setDialog(mode))
        break;
      case 'creating':
        setGroup(configCRUD.defaultGroup)
        setDialog(mode)
        break;
      case 'closed':
        setDialog(mode)
        setTimeout(() => {
          setGroup(configCRUD.defaultGroup)
        }, 200);
        break;
      default:
        console.error('Invalid dialog mode');
    }
  }

  return (
    <>
      <div className="text-right mb-4">
        <CustomButton title="Crear Grupo" onClick={() => openDialog('creating')} />
      </div>
      <Paper>
        <div className={styles.wrapper}>
          <MUIDataTable
            title={"Grupos"}
            data={groups}
            columns={columns}
            options={options}
          />
        </div>
      </Paper>

      <ConfGroupsDialog mode={dialog} setDialog={openDialog} users={users} />

      <Fab color="primary" aria-label="Add" className="fab-add"
        hidden={dialog !== 'closed'}
        onClick={() => openDialog('creating')}
      >
        <AddIcon />
      </Fab>
    </>
  )
}

const mapStateToProps = store => ({
  groups: store.config.groups.data,
  categories: store.config.categories.data,
  levels: store.config.levels.data,
  users: store.user.users.data,
})

export default connect(mapStateToProps, config.actions)(ConfGroups)
