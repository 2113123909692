import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  GetHelp: "[Config] GET_HELP",
  GetHelpVideo: "[Config] GET_HELP_VIDEO",
  GetHelpObject: "[Config] GET_HELP_OBJECT"
};

const initialConfigState = {
  help: {data: [], isFetched: false},
  helpVideo: {title: '', link: ''},
  helpObject: []
};

export const reducer = persistReducer(
  { storage, key: "hlp", whitelist: [] },
  (state = initialConfigState, action) => {
    switch (action.type) {

      case actionTypes.GetHelp: {

        return { ...state, help: { data: action.payload, isFetched: true } };
      }

      case actionTypes.GetHelpVideo: {

        return { ...state, helpVideo: action.payload };
      }

      case actionTypes.GetHelpObject: {

        return { ...state, helpObject: action.payload }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setHelp: help => ({ type: actionTypes.GetHelp, payload: help }),
  setHelpVideo: helpVideo => ({ type: actionTypes.GetHelpVideo, payload: helpVideo }),
  setHelpObject: helpObject => ({ type: actionTypes.GetHelpObject, payload: helpObject })
};