import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, Button, DialogContent } from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import UtilInputs from "../../../common/components/UtilInputs";
import notice from "../../../utils/notice";
import { instructorsForm } from "./utils";
import { useSelector } from "react-redux";

import * as user from "../../../store/ducks/users.duck";
import * as UsersCRUD from "../../../crud/users.crud";
import { getCategories, getRoles } from "../../../crud/config.crud";

const InstructorsDialog = ({
  mode,
  setDialog,
  instructor,
  updateUser,
  permit,
  locations,
  setNewInstructors,
  store,
}) => {
  const [roles, setRoles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  useEffect(() => {
    getRoles()
      .then((res) =>
        setRoles(
          res
            .filter((r) => r.name.includes("Instructor"))
            .map((r) => ({ ...r, value: r.id, text: r.name }))
        )
      )
      .catch(console.log);
  }, []);

  useEffect(() => {
    getCategories()
      .then((res) => setCategories(res.map((c) => ({ ...c, value: c.id, text: c.name }))))
      .catch(console.log);
  }, []);

  const open = mode === "editing" || mode === "creating";
  const title = instructor.id ? "Editar instructor" : "Nuevo instructor";

  // eslint-disable-next-line
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  // eslint-disable-next-line
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const create = (values) => {
    const userCheck = {
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role_id: values.role_id,
      active: values.active,
      date_of_birth: values.date_of_birth,
      blood_type: values.blood_type,
      address: values.address,
      location_id: values.location_id,
      category_ids: values.category_ids,
      instructor_id: values.instructor_id,
      hour_price: values.hour_price,
      half_hour_price: values.half_hour_price,
      tree_quarters_hour_price: values.tree_quarters_hour_price,
    };

    UsersCRUD.saveUser(userCheck, updateUser)
      .then(() => {
        setDialog("closed");
        notice("Instructor guardado", "success");
        setNewInstructors("nuevo");
      })
      .catch((e) => {
        notice("El correo ya se encuentra registrado", "error");
      })
      .finally(disableLoading);
  };

  const update = (values) => {
    const userCheck = {
      id: instructor.id,
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role_id: values.role_id,
      active: values.active,
      date_of_birth: values.date_of_birth,
      blood_type: values.blood_type,
      address: values.address,
      location_id: values.location_id,
      category_ids: values.category_ids,
      instructor_id: values.instructor_id,
      hour_price: values.hour_price,
      half_hour_price: values.half_hour_price,
      tree_quarters_hour_price: values.tree_quarters_hour_price,
    };
    UsersCRUD.updateUser(userCheck, updateUser)
      .then(() => {
        setDialog("closed");
        notice("Instructor actualizado", "success");
        setNewInstructors("actualizado");
      })
      .catch((e) => notice(e?.errors?.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  };

  let disableTexts = true;
  if (
    (instructor.id && permit.instructorsUpdate) ||
    (!instructor.id && permit.instructorsCreate)
  ) {
    disableTexts = false;
  }
  let selectedDate = new Date(instructor.date_of_birth);
  selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset());
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            ...instructor,
            date_of_birth: instructor.id ? selectedDate : instructor.date_of_birth,
            hour_price: instructor.hour_price,
            half_hour_price: instructor.half_hour_price,
            hree_quarters_hour_price: instructor.three_quarters_hour_price,
            tree_quarters_hour_price: instructor.tree_quarters_hour_price
              ? instructor.tree_quarters_hour_price
              : "",

            phone: instructor.phone || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) errors.name = "Campo Requerido";
            if (!values.last_name) errors.last_name = "Campo Requerido";
            if (!values.email) errors.email = "Campo Requerido";
            if (!values.phone) errors.phone = "Campo Requerido";
            if (!instructor?.id && !values.password) errors.password = "Campo Requerido";

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            instructor.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <UtilInputs
                  width={"calc(34% - 15px)"}
                  formValues={values}
                  onValuesChange={handleChange}
                  inputValues={instructorsForm(
                    roles,
                    locations,
                    categories,
                    disableTexts
                  )}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {instructor.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  store: store,
  instructor: store.user.user.user ?? store.user.user,
});

export default connect(mapStateToProps, user.actions)(InstructorsDialog);
