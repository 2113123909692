import React from "react";
import SimpleCheckbox from "../../../../common/components/SimpleCheckbox";

const StatusCheckboxColumn = (
  data,
  tableMeta,
  updateFunction,
  attendances,
  setAttendances
) => {
  let currentIdx = attendances.findIndex((att) => att.id === tableMeta.rowData[0]);

  const handleChange = ({ target }) => {
    const { value, name } = target;
    let updatedState = attendances;
    updatedState[currentIdx][name] = value;
    setAttendances(updatedState);
    updateFunction(value); // necesary for update current state
  };

  if (currentIdx === -1) return null;

  return (
    <>
      <SimpleCheckbox
        value={attendances[currentIdx].status}
        name="status"
        onChange={handleChange}
      />
    </>
  );
};

export default StatusCheckboxColumn;
