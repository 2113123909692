import React, { useState } from 'react'
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const SimplePassword = ({ label, variant="standard", placeholder, name, onChange=()=>{}, value, disabled=false, style={}, color="primary", className="", margin='none', error, helperText="", onBlur=console.log }) => {
	const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      fullWidth
      style={style}
      size='small'
      margin={margin}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      color={color}
      className={className}
      variant={variant}
      type={showPassword ? "text" : "password"}
      label={label}
      placeholder={placeholder ? placeholder : label}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      InputProps={{
        endAdornment: 
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>,
      }}
    />
  )
}

export default SimplePassword