import React, { useState } from 'react'
import { Paper, Tabs, Tab } from '@material-ui/core'
import ConfiguracionCategories from "./categories/ConfiguracionCategories";
import ConfiguracionPrograms from "./programs/ConfiguracionPrograms"; // eslint-disable-line
import ConfiguracionLevels from "./levels/ConfiguracionLevels";
import ConfGroups from './groups/ConfGroups';
import ConfClass from './clases/ConfClass';

const ConfiguracionClases = ({permit}) => {
  const [tabValue, setTabValue] = useState(0)

  const renderComponent = () => {
    let component
    switch (tabValue) {
      case 0:
        component = <ConfiguracionCategories permit={permit} />
        break;
      case 1:
        component = <ConfiguracionLevels permit={permit} />
        break;
      case 2:
        component = <ConfClass />
        break;
      case 3:
        component = <ConfGroups />
        break;
      case 4:
        component = <onfiguracionPrograms permit={permit} />
        break;
      default:
        component = <ConfiguracionCategories />
    }
    return component
  }

  return (
    <>
      <Paper style={{marginBottom: 20}}>
        <Tabs
          value={tabValue}
          onChange={(e,value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered
        >
          {permit.categoriesRead && <Tab label='Categorias'/>}
          {permit.levelsRead && <Tab label='Niveles'/>}
          <Tab label='Clases' />
          {/* <Tab label='Grupos' /> */}
          {/* permit.programsRead && <Tab label='Programas'/> */}
        </Tabs>
      </Paper>
      {renderComponent()}
    </>
  )
}
export default ConfiguracionClases
