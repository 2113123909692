import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

/**
 * It's a dialog that asks the user to confirm an action
 * @returns A function that returns a component
 */
const ConfirmDialog = ({
  openConfirm,
  setConfirm,
  functionConfirm,
  message = "¿Está seguro que desea continuar?",
  maxWidth = "sm",
}) => {
  return (
    <Dialog
      open={openConfirm}
      onClose={setConfirm}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={setConfirm} color="secondary">
          Cancelar
        </Button>
        <Button onClick={functionConfirm} color="primary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
