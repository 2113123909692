import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Backdrop, Button, CircularProgress, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import styles from "../../../tablas/Tablas.module.css";
import ConfClassDialog from "./ConfClassDialog";
import SimpleSwitch from "../../../../../common/components/SimpleSwitch";
import BaseModal from "../../../../../common/components/BaseModal";
import { useDispatch } from "react-redux";
import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import notice from "../../../../../utils/notice";
import UtilInputs from "../../../../../common/components/UtilInputs";
import FullTable from "../../../../../common/components/FullTable";

const ConfClass = ({
  // groups,
  setGroup,
  categories,
  levels,
  instructors,
  deactiveGroup,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const [idCloseClass, setIdCloseClass] = useState(0);
  const [inactiveGroup, setInactiveGroup] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);

  useEffect(() => {
    changePage(page, rowPerPage);
  }, []);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Grupo",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "start_date",
      label: "Fecha Inicio",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return `${moment(data).format("DD/MM/YY")}`;
        },
      },
    },
    {
      name: "end_date",
      label: "Fecha Fin",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return `${moment(data).format("DD/MM/YY")}`;
        },
      },
    },
    {
      name: "instructor",
      label: "Instructor",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     // customBodyRender:(data, dataIndex, rowIndex) => (
      //     //   <p>{`${data.name} ${data.last_name}`}</p>
      //     // )
      //   },
    },
    {
      name: "availability",
      label: "Cupo",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "category_id",
      label: "Categoria",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          const category = categories.find((x) => x.id === data);
          return <div>{category ? category.name : ""}</div>;
        },
      },
    },
    {
      name: "levels",
      label: "Niveles",
      options: {
        filter: false,
        sort: false,
        // customBodyRender:(data, dataIndex, rowIndex) => {
        //   let level = levels.filter(x => data.includes(x.id))
        //   return (
        //     level ? level.map(lvl => (
        //       <p key={lvl.id}>{lvl.name}</p>
        //     )) : ''
        //   )
        // }
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (data, tableMeta) => {
        //     let class_id = Number(tableMeta.rowData[0]);
        //     return (
        //         <div>
        //             <Switch
        //                 id='status'
        //                 size="small"
        //                 checked={data}
        //                 onChange={(e, value) => { active_class(class_id, value)}}
        //             />
        //         </div>
        //     );
        // },
        customBodyRender: (data, dataIndex, rowIndex) => {
          return data ? (
            <div className="active">
              <p>Active</p>
            </div>
          ) : (
            <div className="inactive">
              <p>Inactivo</p>
            </div>
          );
        },
      },
    },

    //ADESACTIVAR CLASE

    {
      name: "active",
      label: "Cambiar estatus",
      options: {
        // filter: true,
        // sort: false,
        // onRowClick: false,
        customBodyRender: (data, tableMeta) => {
          let class_id = Number(tableMeta.rowData[0]);
          return (
            <>
              <div onClick={click}>
                <SimpleSwitch
                  label="Activo"
                  disabled={data === false ? true : false}
                  value={data}
                  onChange={({ target }) => {
                    setInactiveGroup(true);
                    setIdCloseClass(class_id);
                  }}
                />
              </div>
            </>
          );
        },
      },
    },
  ];

  const defaultFilter = {
    day: [],
    start_time: null,
    end_time: null,
    status: "",
  };

  const filtersForm = [
    {
      name: "day",
      label: "Días",
      type: "dropdown",
      variant: "filled",
      options: [
        { value: "Lunes", text: "Lunes" },
        { value: "Martes", text: "Martes" },
        { value: "Miercoles", text: "Miércoles" },
        { value: "Jueves", text: "Jueves" },
        { value: "Viernes", text: "Viernes" },
        { value: "Sabado", text: "Sábado" },
      ],
      multiple: true,

      // width: 200,
    },
    {
      name: "start_time",
      label: "Hora de incio",
      type: "dropdown",
      variant: "filled",
      options: [
        { value: "06:00", text: "06:00" },
        { value: "06:30", text: "06:30" },
        { value: "07:00", text: "07:00" },
        { value: "07:30", text: "07:30" },
        { value: "08:00", text: "08:00" },
        { value: "08:30", text: "08:30" },
        { value: "09:00", text: "09:00" },
        { value: "09:00", text: "09:00" },
        { value: "10:00", text: "10:00" },
        { value: "10:30", text: "10:30" },
        { value: "11:00", text: "11:00" },
        { value: "11:30", text: "11:30" },
        { value: "12:00", text: "12:00" },
        { value: "12:30", text: "12:30" },
        { value: "13:00", text: "13:00" },
        { value: "13:30", text: "13:30" },
        { value: "14:00", text: "14:00" },
        { value: "14:30", text: "14:30" },
        { value: "15:00", text: "15:00" },
        { value: "15:30", text: "15:30" },
        { value: "16:00", text: "16:00" },
        { value: "16:30", text: "16:30" },
        { value: "17:00", text: "17:00" },
        { value: "17:30", text: "17:30" },
        { value: "18:00", text: "18:00" },
        { value: "18:30", text: "18:30" },
        { value: "19:00", text: "19:00" },
        { value: "19:30", text: "19:30" },
        { value: "20:00", text: "20:00" },
        { value: "20:30", text: "20:30" },
        { value: "21:00", text: "21:00" },
        { value: "22:00", text: "22:00" },
      ],
      // width: 200,
    },
    {
      name: "end_time",
      label: "Hora final",
      type: "dropdown",
      variant: "filled",
      options: [
        { value: "06:00", text: "06:00" },
        { value: "06:30", text: "06:30" },
        { value: "07:00", text: "07:00" },
        { value: "07:30", text: "07:30" },
        { value: "08:00", text: "08:00" },
        { value: "08:30", text: "08:30" },
        { value: "09:00", text: "09:00" },
        { value: "09:00", text: "09:00" },
        { value: "10:00", text: "10:00" },
        { value: "10:30", text: "10:30" },
        { value: "11:00", text: "11:00" },
        { value: "11:30", text: "11:30" },
        { value: "12:00", text: "12:00" },
        { value: "12:30", text: "12:30" },
        { value: "13:00", text: "13:00" },
        { value: "13:30", text: "13:30" },
        { value: "14:00", text: "14:00" },
        { value: "14:30", text: "14:30" },
        { value: "15:00", text: "15:00" },
        { value: "15:30", text: "15:30" },
        { value: "16:00", text: "16:00" },
        { value: "16:30", text: "16:30" },
        { value: "17:00", text: "17:00" },
        { value: "17:30", text: "17:30" },
        { value: "18:00", text: "18:00" },
        { value: "18:30", text: "18:30" },
        { value: "19:00", text: "19:00" },
        { value: "19:30", text: "19:30" },
        { value: "20:00", text: "20:00" },
        { value: "20:30", text: "20:30" },
        { value: "21:00", text: "21:00" },
        { value: "22:00", text: "22:00" },
      ],
      // width: 200,
    },
    {
      name: "status",
      label: "Estatus",
      type: "dropdown",
      variant: "filled",
      options: [
        { value: "0", text: "Inactivo" },
        { value: "1", text: "Activo" },
      ],

      // width: 200,
    },
  ];

  const [filter, setFilter] = useState(defaultFilter);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };
  const handleFilter = () => {
    setLoader(true);

    let dia = filter.day;
    let start_time = filter.start_time;
    let end_time = filter.end_time;
    let status = filter.status;
    if (
      dia ||
      (start_time && end_time) ||
      (start_time === "" && end_time === "") ||
      status
    ) {
      configCRUD
        .filterGroups(dia, start_time, end_time, status, setAllGroups)
        .catch(console.log)
        .finally(() => setLoader(false));
    }
  };

  const handleClearFilterData = () => {
    setLoader(true);

    setFilter({
      ...filter,
      start_date: null,
      end_date: null,
      dia: null,
      status: null,
    });
    configCRUD
      .getGroups(setAllGroups, page, rowPerPage)
      .catch(console.log)
      .finally(() => setLoader(false));
  };

  const click = (event) => {
    event.stopPropagation();
  };

  const close = () => {
    setInactiveGroup(false);
  };
  //desactivar
  const inactive_group = () => {
    let idClass = idCloseClass;
    setLoader(true);

    configCRUD
      .desactiveGroup(idClass)
      .then((res) => {
        configCRUD.getGroups(setAllGroups, page, rowPerPage);
        notice("Clase desactivada con exito", "success");
      })
      .catch((e) =>
        notice(
          Array.isArray(e.errors)
            ? e.errors.map((err) => err.title).join("\n")
            : "Error con su solicitud"
        )
      )
      .finally(() => {
        close();
        setLoader(false);
      });
  };

  const changePage = (page1, rowsPerPage1, action) => {
    setLoader(true);
    setPage(page1);

    configCRUD
      .getGroups(setAllGroups, page1, rowsPerPage1)
      .finally(() => setLoader(false));
  };
  const options = {
    responsive: "scrollMaxHeight",
    filter: true,
    serverSide: true,
    count: allGroups?.pagy ? allGroups?.pagy?.count : 10,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: [10, 20, 50],
    onRowClick: (data) => openDialog("editing", data[0]),
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page + 1, tableState.rowsPerPage, action);

          break;
        case "changeRowsPerPage":
          changePage(tableState.page + 1, tableState.rowsPerPage, action);
          break;

        default:
          console.log("action not handled.");
      }
    },
    download: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
      },
    },
  };
  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        configCRUD.getGroup(setGroup, id).then(() => setDialog(mode));
        break;
      case "creating":
        setGroup(configCRUD.defaultGroup);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setGroup(configCRUD.defaultGroup);
        }, 200);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  }

  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 999, height: "100%" }}>
        <CircularProgress />
      </Backdrop>
      <>
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={filtersForm}
          className="mb-3"
        />
        <div style={{ display: "flex", gap: 5, marginTop: 15, marginBottom: 15 }}>
          <Button color="primary" variant="contained" onClick={handleFilter}>
            Filtrar
          </Button>
          <Button color="primary" variant="contained" onClick={handleClearFilterData}>
            Limpiar filtros
          </Button>
        </div>
        <Paper>
          <FullTable
            title={"Grupos"}
            data={allGroups?.groups}
            columns={columns}
            options={options}
          />
        </Paper>

        <ConfClassDialog mode={dialog} setDialog={openDialog} instructors={instructors} />
        <BaseModal
          open={inactiveGroup}
          confirmColor="secondary"
          onCancel={() => setInactiveGroup(false)}
          onConfirm={() => inactive_group()}
          title="Esta seguro que desea desactivar este grupo"
        />
      </>
    </>
  );
};

const mapStateToProps = (store) => ({
  groups: store.config.groups.data,
  categories: store.config.categories.data,
  levels: store.config.levels.data,
  instructors: store.config.instructors.data,
});

export default connect(mapStateToProps, config.actions)(ConfClass);
