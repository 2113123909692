import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

const SimpleDate = ({
  label,
  name,
  onChange,
  value,
  variant = "standard",
  disabled,
  style = {},
  className = "",
  color = "primary",
  format = "dd/MM/yyyy",
  mask = "__/__/____",
  views = ["date"],
  initialView = "date",
  minDate = new Date(0),
  maxDate = new Date(10000000000000),
  margin = "none",
  error,
  helperText = "",
  onBlur = console.log,
}) => {
  return (
    <KeyboardDatePicker
      autoOk
      fullWidth
      invalidDateMessage="Fecha invalida"
      label={label}
      disabled={disabled}
      name={name}
      value={value}
      style={style}
      color={color}
      size="small"
      margin={margin}
      variant="inline"
      format={format}
      mask={mask}
      className={className}
      inputVariant={variant}
      placeholder={format}
      minDate={minDate}
      maxDate={maxDate}
      openTo={initialView}
      views={views}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      onChange={(date) => onChange({ target: { value: date, name } })}
    />
  );
};

export default SimpleDate;
