import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";

import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/auth.crud";
import notice from '../../utils/notice';

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      // <div className={"kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper " + styles.outerDiv}>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                Recupera tu contraseña
              </h3>
            </div>

            <Formik
              initialValues={{ password: "", password_confirmation: ""}}
              validate={values => {
                const errors = {};

                if (values.password.length < 6) {
                  errors.password = "La contraseña no puede ser menor a 6 caracteres"
                }

                if (values.password_confirmation.length < 6) {
                  errors.password_confirmation = "La contraseña no puede ser menor a 6 caracteres"
                }

                if ( values.password !== values.password_confirmation) {
                  errors.password_confirmation = "Las contraseñas no son iguales"
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                resetPassword({reset_password_token: this.props.match.params.token,
                                password: values.password,
                                password_confirmation: values.password_confirmation})
                  .then(() => {
                    this.setState({ isRequested: true });
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    notice(e.errors);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">

                  <div className="form-group">
                    <TextField
                      type="password"
                      label="Contraseña"
                      margin="normal"
                      fullWidth={true}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                    <TextField
                      type="password"
                      label="Confirmar contraseña"
                      margin="normal"
                      fullWidth={true}
                      name="password_confirmation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password_confirmation}
                      helperText={touched.password_confirmation && errors.password_confirmation}
                      error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link
                      to="/auth"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      style={{paddingTop:"13.5px", paddingBottom:"13px"}}
                    >
                      Atrás
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      Recuperar
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      // </div>
    );
  }
}

export default connect(null, auth.actions)(ForgotPassword)
