import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, FormHelperText, Typography } from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import SimpleCheckbox from "../../../../../common/components/SimpleCheckbox";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import { useDispatch } from "react-redux";
import notice from "../../../../../utils/notice";
import { updateReportData } from "../../../../../crud/instructors.crud";

export const FormReportEdit = ({ setDialog, dataInstructor, setDataInstructor }) => {
  let disableTexts = true;
  const dispatch = useDispatch();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const update = (values) => {
    if (Object.keys(dataInstructor).length !== 0) {
      let idReport = dataInstructor.id;
      dispatch(setLoader(true));
      let instructorValues = { ...values };
      instructorValues.location_id = dataInstructor.location_id;
      instructorValues.instructor_id = dataInstructor.instructor_id;
      updateReportData(instructorValues, idReport)
        .then((res) => {
          notice("Reporte editado con éxito", "success");
        })
        .catch((e) => notice("No se pudo editar el reporte", "error"))
        .finally(() => {
          dispatch(setLoader(false));
          setDataInstructor({});
        });
    } else {
      notice("Seleccionar instructor", "warning");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        clave: dataInstructor.clave,
        name: dataInstructor.name,
        last_name: dataInstructor.last_name,
        email: dataInstructor.email,
        active: dataInstructor.active,
        location: dataInstructor.location_name,
        phone: dataInstructor.phone,
        salario_hour: dataInstructor.salario_hour,
        salario_half_hour: dataInstructor.salario_half_hour,
        salario_tree_quarte_hour: dataInstructor.salario_tree_quarte_hour,
        // data_reporte: dataInstructor.data_reporte,
        nota: dataInstructor.nota,
        cargo: dataInstructor.cargo,
        percep_n_hour: dataInstructor.percep_n_hour,
        percep_n_half_hour: dataInstructor.percep_n_half_hour,
        percep_n_tree_q_hour: dataInstructor.percep_n_tree_q_hour,
        persepciones: dataInstructor.persepciones,
        deduc_n_hour: dataInstructor.deduc_n_hour,
        deduc_n_half_hour: dataInstructor.deduc_n_half_hour,
        deduc_n_tree_q_hour: dataInstructor.deduc_n_tree_q_hour,
        deducciones: dataInstructor.deducciones,
      }}
      // validate={(values) => {
      //   const errors = {};
      //   if (!values.name) {
      //     errors.name = "Campo requerido";
      //   }

      //   if (!values.description) {
      //     errors.description = "Campo requerido";
      //   }
      //   if (!values.location_id) {
      //     errors.location_id = "Campo requerido";
      //   }
      //   return errors;
      // }}
      onSubmit={(values, { setSubmitting }) => {
        update(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          noValidate={true}
          autoComplete="off"
          onSubmit={handleSubmit}
          className="kt-form"
        >
          <Typography>{values.name}</Typography>
          <div className="form-group">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Clave"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="clave"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.clave || ""}
                  // helperText={touched.name && errors.name}
                  // error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Nombre"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name || ""}
                  // helperText={touched.name && errors.name}
                  // error={Boolean(touched.name && errors.name)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Apellido"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name || ""}
                  // helperText={touched.lastName && errors.lastName}
                  // error={Boolean(touched.lastName && errors.lastName)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Correo"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  // helperText={touched.email && errors.email}
                  // error={Boolean(touched.email && errors.email)}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Ubicación"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location || ""}
                  // helperText={touched.location && errors.location}
                  // error={Boolean(touched.location && errors.location)}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Teléfono"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone || ""}
                  // helperText={touched.phone && errors.phone}
                  // error={Boolean(touched.phone && errors.phone)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Salario x Hora"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="salario_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.salario_hour || ""}
                  // helperText={touched.salario_hour && errors.salario_hour}
                  // error={Boolean(touched.salario_hour && errors.salario_hour)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Salario por 1/2 Hora"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="salario_half_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.salario_half_hour || ""}
                  // helperText={touched.salario_half_hour && errors.salario_half_hour}
                  // error={Boolean(touched.salario_half_hour && errors.salario_half_hour)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Salario x 3/4 Hora"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="salario_tree_quarte_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.salario_tree_quarte_hour || ""}
                  // helperText={
                  //   touched.salario_tree_quarte_hour && errors.salario_tree_quarte_hour
                  // }
                  // error={Boolean(
                  //   touched.salario_tree_quarte_hour && errors.salario_tree_quarte_hour
                  // )}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Monto"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount || ""}
                  // helperText={touched.amount && errors.amount}
                  // error={Boolean(touched.amount && errors.amount)}
                />
              </Grid> */}

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Notas"
                  margin="normal"
                  className="kt-width-full"
                  // disabled={disableTexts}
                  name="nota"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nota || ""}
                  // helperText={touched.note && errors.note}
                  // error={Boolean(touched.note && errors.note)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Cargo"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="cargo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cargo || ""}
                  // helperText={touched.cargo && errors.cargo}
                  // error={Boolean(touched.cargo && errors.cargo)}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SimpleCheckbox
                  label="Activo"
                  labelPos="end"
                  name="active"
                  value={values.active || ""}
                  onChange={handleChange}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Typography variant="h6" style={{ marginTop: 20 }}>
              Percepciones
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Percepción x por hora"
                  margin="normal"
                  className="kt-width-full"
                  name="percep_n_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.percep_n_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Percepción x 1/2 Hora"
                  margin="normal"
                  className="kt-width-full"
                  name="percep_n_half_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.percep_n_half_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Percepción x 3/4 Hora"
                  margin="normal"
                  className="kt-width-full"
                  name="percep_n_tree_q_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.percep_n_tree_q_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Percepciones"
                  margin="normal"
                  className="kt-width-full"
                  name="persepciones"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.persepciones || ""}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ marginTop: 20 }}>
              Deducciones
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Deducción x por hora"
                  margin="normal"
                  className="kt-width-full"
                  name="deduc_n_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deduc_n_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Deducción x 1/2 Hora"
                  margin="normal"
                  className="kt-width-full"
                  name="deduc_n_half_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deduc_n_half_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Deducción x 3/4 Hora"
                  margin="normal"
                  className="kt-width-full"
                  name="deduc_n_tree_q_hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deduc_n_tree_q_hour || ""}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="Deducciones"
                  margin="normal"
                  className="kt-width-full"
                  name="deducciones"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deducciones || ""}
                />
              </Grid>
            </Grid>
          </div>
          <div className="btn-dialog">
            <Button onClick={() => setDialog("closed")} color="primary">
              Cancelar
            </Button>
            <button
              id="kt_login_signin_submit"
              type="submit"
              className={`btn btn-primary btn-elevate kt-login__btn-primary    `}
              style={loadingButtonStyle}
            >
              Guardar
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
