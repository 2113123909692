import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import ProductsDialog from "./ProductsDialog";
import FullTable from "../../../common/components/FullTable";
import CustomButton from "../../../common/components/CustomButton";
import notice from "../../../utils/notice";
import ConfirmDialog from "../../../utils/ConfirmDialog";

import * as products from "../../../store/ducks/products.duck";
import * as productsCRUD from "../../../crud/products.crud";
import { setLoader } from "../../../store/ducks/loader.duck";
import { columns, options } from "./utils";

const Products = ({ products, setProduct, setProducts, count }) => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed");
  const [page, setPage] = useState(1);
  const [removeId, setRemoveId] = useState(0);
  const [searchText, setSearchText] = useState("");

  const fetchProducts = () => {
    dispatch(setLoader(true));
    return productsCRUD
      .getProducts(setProducts, page)
      .then(console.log)
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProducts, page]);

  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        dispatch(setLoader(true));
        productsCRUD
          .getProduct(setProduct, id)
          .then(() => setDialog(mode, id))
          .catch((e) =>
            notice(
              e.errors && Array.isArray(e.errors)
                ? e.errors
                : "Ha ocurriddo un error en su solicitud"
            )
          )
          .finally(() => dispatch(setLoader(false)));
        break;
      case "creating":
        setProduct(productsCRUD.defaultProduct);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setProduct(productsCRUD.defaultProduct);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  }

  const onChangePage = (newPage) => setPage(newPage + 1);

  const handleOpenRemoveDialog = (id) => setRemoveId(id);

  const handleRemove = () => {
    dispatch(setLoader(true));
    productsCRUD
      .removeProduct(removeId)
      .then(async (res) => {
        await fetchProducts();
        notice("Producto eliminado correctamente", "success");
        setRemoveId(false);
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurriddo un error al eliminar el producto"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleProductQuery = (query) => {
    dispatch(setLoader(true));

    if (query) {
      productsCRUD
        .searchProducts(setProducts, query)
        .then(console.log)
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    } else {
      fetchProducts();
    }
  };
  return (
    <>
      <div className="text-right mb-4">
        <CustomButton title="Crear producto" onClick={() => openDialog("creating")} />
      </div>
      <FullTable
        title={"Productos"}
        data={products}
        columns={columns(openDialog, handleOpenRemoveDialog)}
        options={options(
          onChangePage,
          page,
          count,
          searchText,
          setSearchText,
          handleProductQuery
        )}
      />
      <ProductsDialog mode={dialog} setDialog={openDialog} />
      <ConfirmDialog
        openConfirm={Boolean(removeId)}
        setConfirm={() => setRemoveId(0)}
        functionConfirm={handleRemove}
        message={<h4>¿Estas seguro que deseas eliminar este producto?</h4>}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  products: store.products.products.data,
  count: store.products.meta?.pagination?.total_objects,
});

export default connect(mapStateToProps, products.actions)(Products);
