import React, { useEffect, useState } from "react";
import CustomButton from "../../../../common/components/CustomButton";
import UtilInputs from "../../../../common/components/UtilInputs";
import { defaultPackageSale, packageSaleForm } from "../utils";

const PackSaleTab = ({
  packageSale,
  setPackageSale,
  packages,
  paymentTypes,
  summary,
  setSummary,
}) => {
  let periods =
    packageSale?.group_price?.payments?.map(
      ({ period, name, status, quantity, quantity_with_recharge }) => ({
        value: period,
        text: name,
        disabled: status === "Payed" || status === "NA",
        quantity: quantity ? quantity : 0,
        quantity_with_recharge: quantity_with_recharge ? quantity_with_recharge : 0,
        text2:
          status === "Pending"
            ? "Pendiente"
            : status === "Overdue"
            ? "Atrasado"
            : status === "NA"
            ? "No aplica"
            : "Pagado",
        status,
        text2_className: `badge badge-pill badge-${
          status === "Pending"
            ? "warning"
            : status === "Overdue"
            ? "danger"
            : status === "NA"
            ? "dark"
            : "success"
        } text-light font-weight-bold`,
      })
    ) || [];

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === "payment_type") {
      let other_fee = paymentTypes.find((pt) => pt.id === value);

      setPackageSale({
        ...packageSale,
        other_fee,
        [name]: value,
        amount: other_fee?.price || 0,
      });
    } else if (name === "package") {
      const group_price = packages?.find((pg) => pg.id === value);

      const overdue_period = packages
        ?.find((pg) => pg.id === value)
        ?.payments?.find((p) => p.status === "Overdue");
      setPackageSale({
        ...packageSale,
        group_price,
        [target.name]: target.value,
        amount: overdue_period?.period ? group_price?.quantity_with_recharge : 0,
      });
    } else if (name === "period") {
      if (value.length > 0) {
        let period = periods.find((p) => p.value === value[0]);
        setPackageSale({
          ...packageSale,
          [name]: value,
          amount:
            period.status === "Overdue"
              ? period?.quantity_with_recharge
              : period?.quantity,
        });
        handleAddPackage(value);
      } else {
        setSummary({
          ...summary,
          payment_groups: [],
        });
        setPackageSale(defaultPackageSale);
      }
    } else {
      setPackageSale({ ...packageSale, [name]: value });
    }
  };
  const handleAddPackage = (periodsList) => {
    const paymentGroups = [];
    for (const period of periods) {
      for (const periodo of periodsList) {
        if (period.value === periodo) {
          paymentGroups.push({
            ...packageSale.group_price,
            period,
            quantity:
              period.status === "Overdue"
                ? period?.quantity_with_recharge
                : period?.quantity,
          });
        }
      }
    }
    if (packageSale?.other_fee) {
      if (packageSale?.other_fee?.name === "Inscripción") {
        packageSale.group_price = "";
      }
    }
    setSummary({
      ...summary,
      payment_groups: packageSale.group_price ? paymentGroups : summary.payment_groups,
      other_fees: packageSale.other_fee
        ? [...summary.other_fees, packageSale.other_fee]
        : summary.other_fees,
    });
  };
  return (
    <>
      <UtilInputs
        inputValues={packageSaleForm(
          packages,
          paymentTypes,
          packageSale.payment_type,
          periods
        )}
        formValues={packageSale}
        onValuesChange={handleChange}
      />
      <CustomButton
        title="Añadir a la bolsa"
        className="mt-4"
        onClick={handleAddPackage}
        disabled={!packageSale.amount}
      />
    </>
  );
};

export default PackSaleTab;
