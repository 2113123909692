import React, { useEffect, useState } from "react";
import FullTable from "../../../../common/components/FullTable";
import { class_columns } from "../utils/lession_client_data";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import * as clientsCRUD from "../../../../crud/clients.crud";
import CustomButton from "../../../../common/components/CustomButton";
import LessonDialog from "./LessonDialog/LessonDialog";
import { DialogAction } from "../components/DialogAction";
import notice from "../../../../utils/notice";
import Loading from "../../../../utils/Loading";
import { getAllIntructors } from "../../../../crud/config.crud";
import { useSelector } from "react-redux";

const LessonClientTable = ({ studentClass, student, levels }) => {
  let idStudent = student?.id;
  const { role } = useSelector(({ auth }) => auth.user.user);
  const [dateInscription, setDateInscription] = useState("");
  const [dialog, setDialog] = useState("closed");
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [changeList, setChangeList] = useState("");
  const [removeId, setRemoveId] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allInstructors, setAllInstructors] = useState([]);

  const handleOpenRemoveModal = (id) => {
    setRemoveId(id);
  };

  const handleAdd = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    if (idStudent !== undefined) {
      clientsCRUD.getListGroupByClient(idStudent, setList);
    }
    getAllIntructors(setAllInstructors);
    setLoading(false);
  }, [idStudent]);

  useEffect(() => {
    setLoading(true);
    if (idStudent !== undefined) {
      clientsCRUD.getListGroupByClient(idStudent, setList);
    }
    setChangeList("");
    setLoading(false);
  }, [changeList]);

  const handleRemove = () => {
    setLoading(true);
    let id = removeId;
    let priceGroupRemove;
    list.result.map((group) => {
      if (id === group.group_schedule_id) {
        priceGroupRemove = group.client_group_price.id;
      }
    });
    if (priceGroupRemove) {
      clientsCRUD
        .deleteGroupByClient(priceGroupRemove, id, idStudent)
        .then((res) => {
          if (res.status === 200) {
            notice("Grupo eliminado correctamente", "success");
          } else {
            notice("No se pudo eliminar el grupo", "error");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setChangeList("Se elimino el grupo dentro de la funcion");

          setLoading(false);
          setRemoveId(false);
        });
    } else {
      setRemoveId(false);
      notice("No se pudo eliminar el grupo", "error");
    }
  };
  if (loading) return <Loading />;
  return (
    <>
      <Paper>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
              Clases información
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <>
          <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
            {role.name === "Admin" && (
              <CustomButton
                title="Añadir clase"
                onClick={() => setOpen(true)}
                style={{ width: 200, margin: 20 }}
                disabled={studentClass.includes(undefined) ? true : false}
              />
            )}

            <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Paper>
                <FullTable
                  title={"Clases"}
                  data={list?.result || []}
                  columns={class_columns(handleOpenRemoveModal, setOpenDialogDelete)}
                />
              </Paper>
            </Grid>
            <LessonDialog
              open={open}
              mode={dialog}
              setOpen={setOpen}
              handleAdd={handleAdd}
              setDateInscription={setDateInscription}
              dateInscription={dateInscription}
              idStudent={idStudent}
              setChangeList={setChangeList}
            />
          </Grid>
        </>
      </Paper>
      <DialogAction
        openConfirm={removeId !== false}
        setConfirm={() => setRemoveId(false)}
        functionConfirm={handleRemove}
      />
    </>
  );
};

export default LessonClientTable;
