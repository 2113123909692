import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";

function CategoryDialog({
  mode,
  category,
  setCategory,
  setDialog,
  setCategories,
  updateCategories,
  permit,
  user,
  locations,
  setCategoryTable,
  categoryTable,
}) {
  const open = mode === "editing" || mode === "creating";
  const title = category.id ? "Editar categoría" : "Nueva categoría";
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  let disableTexts = true;
  if (
    (category.id && permit.categoriesUpdate) ||
    (!category.id && permit.categoriesCreate)
  ) {
    disableTexts = false;
  }

  function create(values) {
    const cat = {
      name: values.name,
      clave: values.clave,
      description: values.description,
      location_id: values.location_id,
      color: values.color,
    };

    configCRUD
      .saveCategory(cat, updateCategories)
      .then((res) => {
        setDialog("closed");
        notice("Categoria guardada", "success");
        setCategoryTable(!categoryTable);
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }
  function update(values) {
    const cat = {
      id: category.id,
      clave: values.clave,
      name: values.name,
      description: values.description,
      location_id: values.location_id,

      color: values.color,
    };
    configCRUD
      .updateCategory(cat, updateCategories)
      .then((res) => {
        setDialog("closed");
        notice("Categoria actualizada", "success");
        setCategoryTable(!categoryTable);
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
        </DialogContentText>
        <Formik
          initialValues={{
            name: category.name,
            clave: category.clave,
            description: category.description,
            location_id: category.location_id
              ? category.location_id
              : locations.data[0].id,
            color: category.color,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Campo requerido";
            }

            if (!values.description) {
              errors.description = "Campo requerido";
            }
            if (!values.location_id) {
              errors.location_id = "Campo requerido";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            category.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Clave"
                      margin="normal"
                      className="kt-width-full"
                      disabled={disableTexts}
                      name="clave"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.clave}
                      // helperText={touched.name && errors.name}
                      // error={Boolean(touched.name && errors.name)}
                    />
                    <TextField
                      label="Nombre"
                      margin="normal"
                      className="kt-width-full"
                      disabled={disableTexts}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched?.location_id && errors?.location_id)}
                    >
                      <InputLabel htmlFor="simple-select-label">Ubicaciones</InputLabel>
                      <Select
                        value={values.location_id}
                        onChange={handleChange}
                        labelId="simple-select-label"
                        name="location_id"
                        onBlur={handleBlur}
                        disabled={disableTexts}
                        defaultValue={locations.data[0].id}
                      >
                        {locations.data.length > 0 ? (
                          locations.data.map((loc) => (
                            <MenuItem key={loc.id} value={loc.id}>
                              {loc.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No hay resultados</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>
                        {touched?.location_id && errors?.location_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="color"
                      label="Color"
                      margin="normal"
                      name="color"
                      inputProps={{
                        style: {
                          width: "200px",
                        },
                      }}
                      disabled={disableTexts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color}
                    />
                  </Grid>
                  <Grid item xs={12} sm={user.role.location_id == null ? 6 : 12}>
                    <TextField
                      label="Descripción"
                      margin="normal"
                      className="kt-width-full"
                      name="description"
                      disabled={disableTexts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      helperText={touched.description && errors.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      variant="outlined"
                      multiline
                      rowsMax="4"
                    />
                  </Grid>
                  {/* {user.role.location_id == null ? ( */}

                  {/* ) : null} */}
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {category.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store) => ({
  category: store.config.category,
  user: store.auth.user.user,
  locations: store.config.locations,
});

export default connect(mapStateToProps, config.actions)(CategoryDialog);
