import React from 'react'
import BaseModal from '../../../../common/components/BaseModal'
import CustomButton from '../../../../common/components/CustomButton'

const DownloadInvoiceModal = ({ open, setOpen }) => {
  return (
    <BaseModal title='Consultar factura' open={open} onClose={() => setOpen(false)} maxWidth="xs">
      <div className='d-flex justify-content-around'>
        <CustomButton color="primary" title='Descargar PDF' />
        <CustomButton color="success" title='Descargar XML' />
      </div>
    </BaseModal>
  )
}

export default DownloadInvoiceModal