import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment } from '@material-ui/core'

export const MantSolutions = ({values, handleChange}) => {

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>A Checar</TableCell>
          <TableCell>Muestra</TableCell>
          <TableCell>Aplicación</TableCell>
          <TableCell># Liquido</TableCell>
          <TableCell>Color Muestra</TableCell>
          <TableCell>Multiplicador</TableCell>
          <TableCell>Resultado</TableCell>
          <TableCell>Rango</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell rowSpan={3}>CL Libre</TableCell>
          <TableCell>25 ml</TableCell>
          <TableCell>2 cucharaditas</TableCell>
          <TableCell>R-0870</TableCell>
          <TableCell>Rosa</TableCell>
          <TableCell></TableCell>
          <TableCell rowSpan={3}>
            <TextField
              type='number'
              name="free_cl"
              value={values.free_cl}
              onChange={handleChange}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell rowSpan={3}> 1 a 3 ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Gotas</TableCell>
          <TableCell>R-0871</TableCell>
          <TableCell>Transparente</TableCell>
          <TableCell>0.2</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>5 Gotas</TableCell>
          <TableCell>R-0003</TableCell>
          <TableCell>Rosa</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cloraminas</TableCell>
          <TableCell></TableCell>
          <TableCell>Gotas</TableCell>
          <TableCell>R-0871</TableCell>
          <TableCell>Transparente</TableCell>
          <TableCell>0.2</TableCell>
          <TableCell>
            <TextField
              type='number'
              name="chloramines"
              onChange={handleChange}
              value={values.chloramines}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>0.5 a 1.8 ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Acido</TableCell>
          <TableCell>44 ml</TableCell>
          <TableCell>5 gotas</TableCell>
          <TableCell>R-0004</TableCell>
          <TableCell>Comparador</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TextField
              type='number'
              name="acid"
              onChange={handleChange}
              value={values.acid}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>6.8 a 7.6 ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={3}>Alcalinidad</TableCell>
          <TableCell>25 ml</TableCell>
          <TableCell>2 gotas</TableCell>
          <TableCell>R-0007</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell rowSpan={3}>
            <TextField
              type='number'
              name="alkalinity"
              onChange={handleChange}
              value={values.alkalinity}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell rowSpan={3}>80-120 ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>5 gotas</TableCell>
          <TableCell>R-0008</TableCell>
          <TableCell>Verde</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Gotas</TableCell>
          <TableCell>R-0009</TableCell>
          <TableCell>Rojo</TableCell>
          <TableCell>10</TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={3}>Dureza</TableCell>
          <TableCell>25 ml</TableCell>
          <TableCell>20 gotas</TableCell>
          <TableCell>R-0010</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell rowSpan={3}>
            <TextField
              type='number'
              name="hardness"
              onChange={handleChange}
              value={values.hardness}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell rowSpan={3}>200-400ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>5 gotas</TableCell>
          <TableCell>R-0011L</TableCell>
          <TableCell>Rosa</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Gotas</TableCell>
          <TableCell>R-0012</TableCell>
          <TableCell>Azul</TableCell>
          <TableCell>10</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Fosfato</TableCell>
          <TableCell>14 ml</TableCell>
          <TableCell>30 seg</TableCell>
          <TableCell>Sobre</TableCell>
          <TableCell>Incoloro</TableCell>
          <TableCell>Comparador</TableCell>
          <TableCell>
            <TextField
              type='number'
              name="phosphate"
              onChange={handleChange}
              value={values.phosphate}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>0 a 200 ppm</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>SDT</TableCell>
          <TableCell>Jarra</TableCell>
          <TableCell>15 seg</TableCell>
          <TableCell>Pluma</TableCell>
          <TableCell>-</TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            <TextField
              type='number'
              name="sdt"
              onChange={handleChange}
              value={values.sdt}
              InputProps={{
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>
                }}
            />
          </TableCell>
          <TableCell>1500 + como llega</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
