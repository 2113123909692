import React from 'react'
import SimpleCheckbox from '../../../../common/components/SimpleCheckbox'

const StatusColumn = (data, tableMeta, updateFunction, updateStatus) => {
  
  const handleChange = async ({ target }) => {
    try {
      const { value } = target
      await updateStatus(tableMeta?.rowIndex, value)
      updateFunction(value) // necesary for update current state
    } catch (error) {
      
    }
  }

  return (
    <>
      <SimpleCheckbox
        value={data}
        name="status"
        onChange={handleChange}
        />
    </>
  )
}

export default StatusColumn