import React, { useState } from 'react'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import SimpleText from './SimpleText'

const MY_API_KEY = process.env.REACRT_GOOGLE_KEY

const SimpleAddressInput = ({ name, value, label, variant="standard", onChange, placeholder, disabled, width, style, className, color, margin="none", error, helperText="", onBlur=console.log }) => {
  const [query, setQuery] = useState('')

  const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    setQuery("")
    onChange({target: {name: name, value: geocodedPrediction.formatted_address}})
  }

  return (
    <ReactGoogleMapLoader
      params={{
        key: MY_API_KEY,
        libraries: "places",
      }}
      render={googleMaps =>
        googleMaps && (
          <ReactGooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                  input: query,
                  // Optional options
                  // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
              }}
              // Optional props
              onNoResult={console.log}
              onSelectSuggest={handleSelectSuggest}
              textNoResults="Sin resultados" // null or "" if you want to disable the no results item
              customRender={prediction => (
                <div className="customWrapper">
                  {prediction
                    ? prediction.description
                    : "Sin resultados"}
                </div>
              )}
          >
            <SimpleText
              name={name}
              label={label}
              value={value}
              width={width}
              style={style}
              color={color}
              variant={variant}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              margin={margin}
              onBlur={onBlur}
              helperText={helperText}
              error={error}
              onChange={({ target }) => setQuery(target.value)}
            />
          </ReactGooglePlacesSuggest>
        )
      }
    />
  )
}

export default SimpleAddressInput