import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";

const MaskInput = ({ inputRef, value, onChange, name, mask, onBlur, ...other }) => {
  return (
    <InputMask
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      maskChar=""
      mask={mask}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

const input = (props) => <input {...props} />;

const textarea = (props) => <textarea {...props} />;

const SimpleText = ({
  label = "",
  placeholder = "",
  required = "",
  name = "",
  onChange = () => {},
  value = "",
  variant = "standard",
  disabled = false,
  multiline = false,
  rows = 0,
  isPassword = false,
  style = {},
  startAdornment,
  endAdornment,
  color = "primary",
  className = "",
  error = false,
  helperText = "",
  mask = "",
  margin = "none",
  onBlur = console.log,
}) => {
  return (
    <TextField
      fullWidth
      required={required}
      label={label}
      style={style}
      size="small"
      margin={margin}
      onBlur={onBlur}
      color={color}
      className={className}
      variant={variant}
      type={isPassword ? "password" : "text"}
      placeholder={placeholder ? placeholder : label}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      helperText={helperText}
      error={error}
      InputLabelProps={{ shrink: Boolean(value) }}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        inputComponent: mask ? MaskInput : multiline ? textarea : input,
        inputProps: {
          mask,
          onBlur,
          name,
          value,
          onChange,
        },
      }}
    />
  );
};

export default SimpleText;
