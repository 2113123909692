import React from 'react'
import PropTypes from 'prop-types';
import { CircularProgress } from "@material-ui/core";

const CustomButton = ({ onClick=()=>{}, title="", color="primary", outlined=false, style={}, disabled=false, loading = false, className = "", startIcon, endIcon }) => {
  return (
    <button
      type='button'
      className={`btn ${outlined ? `btn-outline-${color}` : `btn-${color}` } ${disabled ? "disabled" : ""} ${className}`}
      style={{...style, cursor: disabled && 'not-allowed' }}
      disabled={disabled}
      onClick={onClick}
    >
      {startIcon}
      <span>{title}</span>
      {loading && <CircularProgress size={14} className="text-white" />}
      {endIcon}
    </button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.object
};

export default CustomButton;