import React from "react";
import { useDispatch } from "react-redux";
import BaseModal from "../../../../../../common/components/BaseModal";
import SimpleText from "../../../../../../common/components/SimpleText";
import { createFindout, updateFindout } from "../../../../../../crud/findout.crud";
import { setLoader } from "../../../../../../store/ducks/loader.duck";
import notice from "../../../../../../utils/notice";
import { defaultFindout } from "../utils";

const FindOutModal = ({ open, setOpen, findout = null, setFindout, findoutfetch }) => {
  const dispatch = useDispatch();

  const onCancel = () => {
    setOpen(false);
    setFindout(defaultFindout);
  };

  const onConfirm = () => {
    dispatch(setLoader(true));
    let requestFuction = findout.id ? updateFindout : createFindout;
    requestFuction(findout)
      .then(() => {
        findoutfetch();
        onCancel();
        notice(
          `Se ha ${findout.id ? "editado" : "guardado"} correctamente el elemento`,
          "success"
        );
      })
      .catch((e) => {
        notice("Ha ocurrido un error, por favor vuelva a intentarlo");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <BaseModal
      open={open}
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={findout?.id ? "Editar" : "Nuevo"}
    >
      <SimpleText
        name="name"
        label="Descripción"
        value={findout?.name}
        onChange={({ target }) => setFindout({ ...findout, [target.name]: target.value })}
      />
    </BaseModal>
  );
};

export default FindOutModal;
