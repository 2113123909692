import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {Paper, Tabs, Tab} from '@material-ui/core';

import MantReporteDiario from "./Daily/MantReporteDiario";
import MantReporteQuincenal from "./Biweekly/MantReporteQuincenal";

import * as maint from "../../../store/ducks/maint.duck";
import * as maintCRUD from "../../../crud/maint.crud";

import * as config from "../../../store/ducks/config.duck";
import * as configCRUD from '../../../crud/config.crud'

import * as user from '../../../store/ducks/users.duck'
import { getUsers } from '../../../crud/users.crud';

class MantenimientoWrapper extends Component {
  state = {
    tabValue: 0
  }
  
  componentDidMount() {
    const { setUsers, maintActions, setPoolTypes, setPools } = this.props
    getUsers(setUsers)
    configCRUD.getPoolTypes(setPoolTypes)
    configCRUD.getPools(setPools)
    maintCRUD.getDailyReports(maintActions.setDailyReports)
    maintCRUD.getBiweeklyReports(maintActions.setBiweeklyReports)
  }

  renderComponent(){
    const { tabValue } = this.state
    const { daily_reports, pool_types, maintActions, pools } = this.props
    
    let component;
    switch (tabValue) {
      case 0:
        component = <MantReporteDiario daily_reports={daily_reports} pool_types={pool_types} maintActions={maintActions} />
      break;
      case 1: 
        component = <MantReporteQuincenal pool_types={pool_types} pools={pools} />
      break;
      default:
        component = <MantReporteDiario />
    }
    return component
  }
  
  render () {
    const { tabValue } = this.state
    return (
      <>
        <Paper style={{flexGrow: 1, marginBottom: 20}}>
          <Tabs
            value={tabValue}
            onChange={(e,value) => this.setState({tabValue: value})}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            centered>

            <Tab label="Reportes Diarios" />
            <Tab label="Reportes Quincenales" />
          </Tabs>
        </Paper>
        {this.renderComponent()}
      </>
    )
  }

}

const mapStateToProps = store => ({
  daily_reports: store.maint.daily_reports,
  pool_types: store.config.pool_types,
  pools: store.config.pools,
});

function mapDispatchToProps(dispatch) {
  return {
    maintActions: bindActionCreators(maint.actions, dispatch),
    setPoolTypes: bindActionCreators(config.actions.setPoolTypes, dispatch),
    setPools: bindActionCreators(config.actions.setPools, dispatch),
    setUsers: bindActionCreators(user.actions.setUsers, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MantenimientoWrapper)
