import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../../common/components/FullTable";
import UtilInputs from "../../../common/components/UtilInputs";
import {
  CutColumns,
  defaultFilter,
  filtersForm,
  defaultCutFilterA,
  defaultCutFilterB,
} from "./utils";
import { setLoader } from "../../../store/ducks/loader.duck";
import { getLocations } from "../../../crud/config.crud";
import {
  getPaymentCutTypeA,
  getPaymentCutTypeB,
  generarCutTypeA,
  generarCutTypeB,
  getListCuts,
  filterPaymentCut,
} from "../../../crud/payments.crud";
import { Button } from "@material-ui/core";
import moment from "moment";
import notice from "../../../utils/notice";
import ModalCut from "./ModalCut";

const PaymentCut = () => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(defaultFilter);
  const [filterCutA, setFilterCutA] = useState(defaultCutFilterA);
  const [filterCutB, setFilterCutB] = useState(defaultCutFilterB);
  const [cuotasFacturadas, setCuotasFacturadas] = useState();
  const [pdfCorteA, setPdfCorteA] = useState("");
  const [pdfCorteB, setPdfCorteB] = useState("");
  const [locations, setLocations] = useState([]);
  const [list, setList] = useState([]);
  const [openA, setOpenA] = useState(false);
  const [openB, setOpenB] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    getListCuts()
      .then((res) => {
        setList(res.cortes);
      })
      .catch((e) =>
        Array.isArray(e.errors)
          ? e.errors.map(({ title }) => title)
          : "Error con el servicio"
      )
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoader(true));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });
        setFilterCutA({ ...filterCutA, client_location: res[0].name });
        setFilterCutB({ ...filterCutB, client_location: res[0].name });

        setLocations(res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id })));
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };
  const handleChangeA = ({ target }) => {
    const { name, value } = target;
    setFilterCutA({ ...filterCutA, [name]: value });
  };
  const handleChangeB = ({ target }) => {
    const { name, value } = target;
    setFilterCutB({ ...filterCutB, [name]: value });
  };
  const handleFilter = () => {
    dispatch(setLoader(true));
    let start_date = moment(filter.start_date).format("YYYY-MM-DD");
    let end_date = moment(filter.start_date).format("YYYY-MM-DD");
    let locationId;
    let cliente = filter.type_client;
    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        locationId = loc.id;
      }
    });

    if (start_date && end_date && locationId && cliente) {
      filterPaymentCut(start_date, end_date, locationId, cliente)
        .then((res) => {
          if (res.cortes.length > 0) {
            setList(res.cortes);
          } else {
            setList([]);
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(dispatch(setLoader(false)));
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
      getListCuts()
        .then((res) => {
          setList(res.cortes);
        })
        .catch((e) =>
          Array.isArray(e.errors)
            ? e.errors.map(({ title }) => title)
            : "Error con el servicio"
        )
        .finally(() => dispatch(setLoader(false)));
    }
  };

  //download pdf corte
  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Corte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleOpenA = () => {
    setOpenA(true);
  };

  const handleOpenB = () => {
    setOpenB(true);
  };

  // CONSULTAR A
  const handleConsultA = () => {
    let total_cuenta =
      filterCutA.total_cuenta_externa === null ? "0.00" : filterCutA.total_cuenta_externa;
    let cobro = filterCutA.cobro_factura === null ? "0.00" : filterCutA.cobro_factura;
    let start = moment(filterCutA.start_date).format("YYYY-MM-DD");
    let end = moment(filterCutA.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filterCutA.client_location) {
        location = loc.id;
      }
    });
    if (location && start && end) {
      getPaymentCutTypeA(start, end, location, total_cuenta, cobro)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => {
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function() {
                let base64data = reader.result;
                setPdfCorteA(base64data);
                return;
              };
            });
          } else if (res.status === 500) {
            notice("Datos inválidos", "warning");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(dispatch(setLoader(false)));
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
    }
  };

  // CONSULTA B
  const handleConsultB = () => {
    let start = moment(filterCutB.start_date).format("YYYY-MM-DD");
    let end = moment(filterCutB.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filterCutB.client_location) {
        location = loc.id;
      }
    });
    if (location && start && end) {
      getPaymentCutTypeB(start, end, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => {
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function() {
                let base64data = reader.result;
                setPdfCorteB(base64data);
                return;
              };
            });
          } else if (res.status === 500) {
            notice("Datos inválidos", "warning");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(dispatch(setLoader(false)));
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
    }
  };

  // GENERAR A
  const generarA = () => {
    dispatch(setLoader(true));
    let start = moment(filterCutA.start_date).format("YYYY-MM-DD");
    let end = moment(filterCutA.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filterCutA.client_location) {
        location = loc.id;
      }
    });
    if (location && start && end) {
      generarCutTypeA(start, end, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Datos inválidos", "warning");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(dispatch(setLoader(false)));
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
    }
  };
  //GENERAR B
  const generarB = () => {
    dispatch(setLoader(true));
    let start = moment(filterCutB.start_date).format("YYYY-MM-DD");
    let end = moment(filterCutB.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filterCutB.client_location) {
        location = loc.id;
      }
    });
    if (location && start && end) {
      generarCutTypeB(start, end, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Datos inválidos", "warning");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(dispatch(setLoader(false)));
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
    }
  };
  return (
    <>
      <UtilInputs
        formValues={filter}
        onValuesChange={handleChange}
        inputValues={filtersForm(locations)}
        className="mb-3"
      />
      <div style={{ display: "flex", gap: 5, marginTop: 15, marginBottom: 15 }}>
        <Button color="primary" variant="contained" onClick={handleFilter}>
          Filtrar
        </Button>

        {/* {permissions.includes("cuts:cut_a") && ( */}
        <Button color="primary" variant="contained" onClick={handleOpenA}>
          Corte Tipo A
        </Button>
        {/* )} */}
        {permissions.includes("cuts:cut_b") && (
          <Button color="primary" variant="contained" onClick={handleOpenB}>
            Corte Tipo B
          </Button>
        )}
      </div>

      <FullTable title="Cortes" data={list || []} columns={CutColumns} />
      {/* TIPO A */}
      <ModalCut
        openA={openA}
        setOpenA={setOpenA}
        filterCutA={filterCutA}
        handleChangeA={handleChangeA}
        locations={locations}
        handleConsultA={handleConsultA}
        generarA={generarA}
        pdfCorteA={pdfCorteA}
        setPdfCorteA={setPdfCorteA}
        // CORTE TIPO B
        openB={openB}
        setOpenB={setOpenB}
        filterCutB={filterCutB}
        handleChangeB={handleChangeB}
        handleConsultB={handleConsultB}
        generarB={generarB}
        pdfCorteB={pdfCorteB}
        setPdfCorteB={setPdfCorteB}
      />
    </>
  );
};

export default PaymentCut;
