export const defaultMeet = {
  title: "",
  start: new Date(),
  end: new Date(),
  textColor: "white",
  color: "#4653b9",
  coup: 0,
  category_id: "",
  instructor_id: "",
  description: "",
  levels: [],
  students: [],
  group_schedules: [],
  // track
};

export const meetForm = (categoriesOpt = [], instOption = []) => [
  // {
  //   name: "title",
  //   label: "Clave",
  //   type: "text",
  // },
  {
    name: "coup",
    label: "Cupo  (obligatorio)",
    type: "number",
  },
  {
    name: "start",
    label: "Fecha de inicio (obligatorio)",
    type: "date",
    minDate: new Date(),
  },
  {
    name: "end",
    label: "Fecha de fin (obligatorio)",
    type: "date",
  },

  {
    name: "category_id",
    label: "Grupo de enseñanza (Obligatorio)",
    type: "dropdown",
    options: categoriesOpt,
  },
  {
    name: "instructor_id",
    label: "Instructor (obligatorio)",
    type: "dropdown",
    options: instOption,
  },
  {
    name: "description",
    label: "Descripcion",
    type: "text",
    width: "100%",
    disabled: true,
  },
];

export const groupForm = (i) => [
  {
    name: `group_schedules['${i}']['day']`,
    label: "Nombre (obligatorio)",
    type: "dropdown",
    options: [
      { value: 1, text: "Lunes" },
      { value: 2, text: "Martes" },
      { value: 3, text: "Miércoles" },
      { value: 4, text: "Jueves" },
      { value: 5, text: "Viernes" },
      { value: 6, text: "Sábado" },
      { value: 7, text: "Domingo" },
    ],
  },
  {
    name: `group_schedules['${i}']['hours_start']`,
    label: "Hora de inicio (obligatorio)",
    type: "time",
  },
  {
    name: `group_schedules['${i}']['hours_end']`,
    label: "Hora de fin (obligatorio)",
    type: "time",
  },
];
