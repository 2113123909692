import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import LevelDialog from "./LevelDialog";
import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import CustomButton from "../../../../../common/components/CustomButton";
import FullTable from "../../../../../common/components/FullTable";
import { columns } from "./utils";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import ConfirmDialog from "../../../../../utils/ConfirmDialog";
import notice from "../../../../../utils/notice";

function ConfiguracionLevels({ levels, setLevel, permit, removeLevel }) {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const [id, setID] = useState(false);

  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        configCRUD.getLevel(setLevel, id).then(() => setDialog(mode));
        break;
      case "creating":
        setLevel(configCRUD.defaultLevel);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setLevel(configCRUD.defaultLevel);
        }, 200);
        break;
      default:
        console.error("Invalid dialog mode");
    }
  }

  const deleteLvl = () => {
    dispatch(setLoader(true));
    configCRUD
      .deleteLevel(id)
      .then(() => {
        removeLevel(id);
        setID(false);
        notice("Nivel borrado correctamente", "success");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <>
      <div className="text-right mb-4">
        {permit.levelsCreate && (
          <CustomButton
            title="Crear nivel"
            onClick={() => openDialog("creating")}
          />
        )}
      </div>
      <FullTable
        title={"Niveles"}
        data={levels.data}
        columns={columns(setID, openDialog)}
      />
      <LevelDialog mode={dialog} setDialog={openDialog} permit={permit} />
      <ConfirmDialog
        openConfirm={Boolean(id)}
        setConfirm={() => setID(false)}
        functionConfirm={deleteLvl}
        message={
          <h4 className="text-center">
            ¿Estas seguro que quieres borrar este nivel?
          </h4>
        }
      />
    </>
  );
}

const mapStateToProps = (store) => ({
  levels: store.config.levels,
});

export default connect(mapStateToProps, config.actions)(ConfiguracionLevels);
