import React from "react";

export const ReportStatusColumn = (data) => {
  return (
    <span
      className={`badge badge-pill badge-${
        data ? "success" : "warning"
      } font-weight-bold`}
    >
      {data ? "Guardado" : "Pendiente"}
    </span>
  );
};
