import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as hlp from "../../../store/ducks/help.duck";

function AyudaDialog({ mode, setDialog, setHelpVideo, helpObject, title, openVideoDialog }) { /// FALTA AGREGAR HELP DEL DUCK


  const open = mode === 'open'



  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle> {/* IMPORTANTE: pedirle a ro que mande esto con el titulo general en el objeto */}
      <DialogContent>
      <Table>
        <TableBody>
          {helpObject.length > 0 && helpObject.map(obj => {
            return(
            <TableRow key={obj.title} style={{cursor:"pointer"}}
              onMouseEnter={(el) => el.currentTarget.style.backgroundColor = "#efefef"}
              onMouseLeave={(el) => el.currentTarget.style.backgroundColor = "white"}
              onClick={() => openVideoDialog('open', obj)}
            >
              <TableCell component="th" scope="row">
                {obj.title}
              </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
      </DialogContent>
    </Dialog>
  )


}

const mapStateToProps = store => ({
  help: store.hlp.help,
  helpObject: store.hlp.helpObject
});

export default connect(mapStateToProps, hlp.actions)(AyudaDialog)
