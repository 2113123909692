import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
  FormHelperText,
  Divider,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import notice from "../../../../../utils/notice";
import SimpleTime from "../../../../../common/components/SimpleTime";
import SimpleAutocomplete from "../../../../../common/components/SimpleAutocomplete";
import moment from "moment";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import SimpleText from "../../../../../common/components/SimpleText";
import SimpleCheckbox from "../../../../../common/components/SimpleCheckbox";

const ConfClassDialog = ({
  mode,
  setDialog,
  updateGroups,
  group,
  categories,
  levels,
  instructors,
}) => {
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [loading, setLoading] = useState(false);
  const open = mode === "editing" || mode === "creating";
  const title = mode === "editing" ? "Editar Clase" : "Crear Grupo";
  const [valueLevel, setValueLevel] = useState(null);
  const [valuesLevel, setValuesLevel] = useState([]);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function addLevel(data, setterFunction) {
    if (levelExists(data)) {
      notice("Ya el nivel se encuentra agregado");
    } else {
      setValuesLevel([...valuesLevel, { id: data.id, name: data.name }]);
    }
    setterFunction("levels", valuesLevel);
  }

  const levelExists = (level) => {
    let filtered = valuesLevel.filter((item) => item.id === level.id);
    return filtered.length === 1;
  };

  function deleteLevel(index, data, deleteLevel, setterFunction) {
    if (data[index].id !== undefined) {
      deleteLevel.push(data[index].id);
      setterFunction("deleteLevel", deleteLevel);
    }
    data.splice(index, 1);
  }

  const weekdays = [
    { value: "Lunes", text: "Lunes" },
    { value: "Martes", text: "Martes" },
    { value: "Miercoles", text: "Miércoles" },
    { value: "Jueves", text: "Jueves" },
    { value: "Viernes", text: "Viernes" },
    { value: "Sabado", text: "Sábado" },
    { value: "Domingo", text: "Domingo" },
  ];

  const create = (values) => {
    const checkGroup = {
      name: values.name,
      availability: values.availability,
      description: values.description,
      category_id: values.category_id,
      level_ids: values.level_ids,
      instructor: values.instructor, // users vendrian a ser los instructores
      schedules: values.schedules,
      active: values.active,
    };
    configCRUD
      .saveGroup(checkGroup, updateGroups)
      .then(() => {
        setDialog("closed");
        notice("Clase guardada", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  };

  const update = (values) => {
    let idInstructor;
    instructors.users.map((user) => {
      if (values.instructor === user.name) {
        idInstructor = user.id;
      }
    });
    let data = {
      id: group.id,
      name: values.name,
      coup: values.availability,
      // start: values.start_date,
      end: values.end_date,
      color: values.color,
      textColor: "black",
      description: values.description,
      category_id: values.category_id,
      instructor_id: idInstructor,
      levels: valuesLevel,
      group_schedules: values.group_schedules.map((group) => {
        let start_time = moment(group.start_time, "hh:mm a").format("HH:mm");
        let end_time = moment(group.end_time, "hh:mm a").format("HH:mm");

        return {
          id: group.id,
          track: group.track,
          start_time: start_time,
          end_time: end_time,
          day: group.day,
          delete: group.delete ? group.delete : false,
        };
      }),
    };
    configCRUD
      .updateGroup(data, updateGroups)
      .then((res) => {
        notice("Grupo actualizado", "success");
      })
      .catch((e) => {
        if (e.errors[0].status === 422) {
          notice("No se pudo eliminar aún hay alumnos inscritos en la clase", "error");
        } else {
          notice("Error con su solicitud", "error");
        }
      })
      .finally(() => {
        setDialog("closed");

        disableLoading();
      });
  };

  // let selectedDate = new Date(group.schedule)
  // selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset())

  // const instructors = users.filter(x => x.role.name === 'Instructor Principal')

  let date_start = group && moment(group.start_date).format("YYYY-MM-DD");
  let date_end = group && moment(group.end_date).format("YYYY-MM-DD");
  const groupDummyLesson = {
    day: "",
    start_time: new Date(2022, new Date().getMonth() + 1, new Date().getDate(), 8),
    end_time: new Date(2022, new Date().getMonth() + 1, new Date().getDate(), 10),
    students: [],
    track: "",
    delete: null,
  };
  function addDays(groupLessons, setterFunction) {
    groupLessons.push(groupDummyLesson);
    setterFunction("groups", groupLessons);
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            name: group && group.name,
            availability: group && group.availability,
            description: group && group.description,
            instructor: group && group.instructor,
            start_date: group && date_start,
            end_date: group && date_end,
            color: group && group.color,
            category_id: group && group.category_id,
            //   level_ids: group.level_ids,
            group_schedules: group && group.group_schedules,
            active: group && group.active,
            deleteLevel: [],
          }}
          validate={(values) => {
            // const errors = {}
            // if (!values.name) errors.name = 'Campo requerido'
            // if (!values.availability) errors.availability = 'Campo requerido'
            // if (!values.category_id) errors.category_id = 'Campo requerido'
            // if (!values.instructor) errors.instructor = 'Campo requerido'
            // if (!values.level_ids.some(x => x)) errors.level_ids = 'Campo requerido'
            // if (!values.schedules.some(x => x.state)) errors.schedules = 'Agregue por lo menos un horario'
            // return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            group.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      type="text"
                      margin="normal"
                      name="name"
                      label="Nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} style={{ marginTop: "17px" }}>
                    <TextField
                      label="Cupo"
                      type="number"
                      name="availability"
                      onChange={handleChange}
                      value={values.availability}
                      fullWidth
                      helperText={touched.availability && errors.availability}
                      error={Boolean(touched.availability && errors.availability)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ marginTop: "17px" }}>
                    <TextField
                      name="start_date"
                      type="date"
                      label="Fecha Inicio"
                      onChange={handleChange}
                      value={values.start_date}
                      fullWidth
                      onBlur={handleBlur}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={touched.start_date && errors.start_date}
                      error={Boolean(touched.start_date && errors.start_date)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ marginTop: "17px" }}>
                    <TextField
                      name="end_date"
                      type="date"
                      label="Fecha Fin"
                      onChange={handleChange}
                      value={values.end_date}
                      fullWidth
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ min: values.start_date }}
                      helperText={touched.end_date && errors.end_date}
                      error={Boolean(touched.end_date && errors.end_date)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      type="color"
                      label="Color"
                      margin="normal"
                      name="color"
                      inputProps={{
                        style: {
                          width: "110px",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color ? values.color : "#9C27B0"}
                      helperText={touched.color && errors.color}
                      error={Boolean(touched.color && errors.color)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      label="Descripción"
                      margin="normal"
                      className="kt-width-full"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      fullWidth
                      multiline
                      rowsMax="4"
                      helperText={touched.description && errors.description}
                      error={Boolean(touched.description && errors.description)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.category_id && errors.category_id)}
                    >
                      <InputLabel labelId="category_id">Categoria</InputLabel>
                      <Select
                        labelId="category_id"
                        name="category_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.category_id}
                      >
                        {categories.map((cat) => (
                          <MenuItem key={cat.id} value={cat.id}>
                            {cat.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.category_id && errors.category_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.instructor_id && errors.instructor_id)}
                    >
                      <InputLabel labelId="instructor">Instructor</InputLabel>
                      <Select
                        labelId="instructor"
                        name="instructor"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instructor}
                      >
                        {instructors.users.map((inst) => (
                          <MenuItem key={inst.id} value={inst.name}>
                            {inst.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.instructor_id && errors.instructor_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={4} md={4} style={{ padding: "36px 36px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="active"
                          size="small"
                          checked={values.active}
                          disabled
                          // onChange={(e, value) => {
                          //     handleChange({ target: values.schedules[i] = { ...values.schedules[i], state: value, name: 'state' } })
                          //     delete values.schedules[i].name
                          // }}
                        />
                      }
                      label="Activo"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <div style={{ width: 300 }}>
                      <SimpleAutocomplete
                        label="Nivel /es (Obligatorio)"
                        name="level"
                        value={valueLevel}
                        onChange={(event) => setValueLevel(event.target.value)}
                        options={levels.map((lv) => ({
                          ...lv,
                          value: lv.id,
                          text: lv.name,
                        }))}
                      />
                    </div>
                    <Button
                      disabled={valueLevel === null}
                      onClick={() => addLevel(valueLevel, setFieldValue)}
                    >
                      + Agregar nivel
                    </Button>
                    {valuesLevel
                      ? valuesLevel.map((level, i) => (
                          <Grid
                            container
                            xs={6}
                            style={{
                              marginBottom: "20px",
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={8}>
                              <Typography>{level.name}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                style={{
                                  backgroundColor: "#001979",
                                  color: "white",
                                }}
                                onClick={() =>
                                  deleteLevel(
                                    i,
                                    valuesLevel,
                                    values.deleteLevel,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      : ""}
                    {group && (
                      <Grid
                        container
                        xs={6}
                        style={{
                          marginBottom: "20px",
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={8}>
                          <Typography>{group.levels}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Grupos</h3>
                    <Divider />
                  </Grid>
                  {values.group_schedules?.map((group, i) => (
                    <Grid container spacing={2} key={group.id}>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Dia</InputLabel>
                          <Select
                            name={`group_schedules['${i}']['day']`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.group_schedules[i].day}
                          >
                            {weekdays.map((weekday) => (
                              <MenuItem key={weekday.value} value={weekday.value}>
                                {weekday.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} style={{ marginTop: "15px" }}>
                        <SimpleTime
                          name={`group_schedules['${i}']['start_time']`}
                          value={moment(values.group_schedules[i].start_time, "hh:mm a")}
                          label="Hora inicial"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} style={{ marginTop: "15px" }}>
                        <SimpleTime
                          name={`group_schedules['${i}']['end_time']`}
                          value={moment(values.group_schedules[i].end_time, "hh:mm a")}
                          label="Hora final"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={1} style={{ marginTop: "15px" }}>
                        <SimpleText
                          name={`group_schedules['${i}']['track']`}
                          value={values.group_schedules[i].track}
                          label="Carril"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={1} style={{ marginTop: "15px" }}>
                        <SimpleCheckbox
                          label="Borrar"
                          labelPos="end"
                          name={`group_schedules['${i}']['delete']`}
                          value={values.group_schedules[i].delete}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      // disabled={value === null}
                      onClick={() => addDays(values.group_schedules, setFieldValue)}
                    >
                      + Agregar dia
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {mode === "editing" ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  group: store.config.group,
  clients: store.clients.clients.data,
  categories: store.config.categories.data,
  levels: store.config.levels.data,
  programs: store.config.programs.data,
});

export default connect(mapStateToProps, config.actions)(ConfClassDialog);
