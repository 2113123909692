import React, { useEffect, useState } from "react";

import {
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { deleteGroupByClient } from "../../../../crud/clients.crud";
import notice from "../../../../utils/notice";
export const DialogAction = ({ openConfirm, setConfirm, functionConfirm }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={openConfirm}
      onClose={setConfirm}
      style={{ width: "50%", margin: "auto" }}
    >
      {/* <Backdrop open={loader} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop> */}
      <DialogTitle id="form-dialog-title">¿Esta seguro de eliminar la clase?</DialogTitle>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={setConfirm}>
          Cancelar
        </Button>
        <Button
          style={{ background: "#FD3939", color: "#FFFFFF" }}
          variant="contained"
          onClick={functionConfirm}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
