import React, { useState } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { percentageCalculation } from "../../../../../crud/clients.crud";
const PriceGroups = ({
  selectedGroups,
  setSelectedGroups,
  category,
  courtesyDesc,
  ageDesc,
}) => {
  const [price, setPrice] = useState("0");
  const calculateTotalPrice = (groups) => {
    const body = new FormData();
    groups.map((group, index) => {
      Object.keys(group).map((key) => {
        if (key === "group_price_id") {
          body.append(`data[${index}][group_price]`, group?.group_price_id);
        } else if (key === "day") {
          body.append(`data[${index}][day]`, group?.day);
        }
      });
    });
    body.append(`desc[desc_tercera_edad]`, ageDesc);
    body.append(`desc[desc_cortecia]`, courtesyDesc);

    percentageCalculation(body, setPrice);
    return price;
  };
  return (
    <Grid
      item
      xs={12}
      sm={3}
      md={3}
      style={{ display: "flex", flexDirection: "column", gap: 5, textAlign: "center" }}
    >
      <Typography variant="h6" gutterBottom>
        {" "}
        Precio:
      </Typography>
      <Typography variant="h6" gutterBottom>
        {selectedGroups.length !== 0
          ? `$${calculateTotalPrice(selectedGroups)} /mes`
          : "$0  / mes"}
      </Typography>
    </Grid>
  );
};
export default PriceGroups;
