import React from 'react';
import PropTypes from "prop-types"
import { Tab, Tabs } from '@material-ui/core';

const AntTabs = ({ value, onChange, options=[], color="primary", variant="standard", centered=false, scroll="off", className="" }) => {
  return (
    <Tabs
      value={value}
      textColor={color}
      indicatorColor={color}
      onChange={(e, value) => onChange(value)}
      scrollButtons={scroll}
      centered={centered}
      variant={variant}
      className={className}
    >
      {options.map((option, i) => option&&(
        <Tab key={option.value + i} label={option.label} disabled={option.disabled} value={option.value} />
      ))}
    </Tabs>
  )
};

AntTabs.propTypes = {
  allowScrollButtonsMobile: PropTypes.bool,
  centered: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func,
  scroll: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string
}

export default AntTabs;
