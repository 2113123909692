import notice from "../utils/notice";

import {
  CORTES_URL,
  EXPECTATION_URL,
  ROLES_URL,
  PERMISSIONS_URL,
  LOCATIONS_URL,
  SUGGESTIONS_STATUSES_URL,
  SUGGESTIONS_TYPES_URL,
  AREAS_URL,
  CATEGORIES_URL,
  PROGRAMS_URL,
  LEVELS_URL,
  SUGGESTIONS_SOURCES_URL,
  LEVEL_REQUIREMENTS_URL,
  POOLS_URL,
  POOL_TYPES_URL,
  GROUPS_URL,
  STORE_CATEGORY_URL,
  GROUP_PRICE_URL,
  INSTRUCTOR_URL,
  GROUP_SCHEDULE,
  STUDENTS_URL,
} from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";
import store from "../store/store";
import { useSelector } from "react-redux";
import { ContactSupportSharp } from "@material-ui/icons";

export const defaultRole = {
  id: null,
  name: "",
  description: "",
  location_id: null,
  permission_ids: [],
  color: "#000000",
};
export const defaultLocation = {
  id: null,
  name: "",
  description: "",
  color: "#000000",
};
export const defaulIntructor = { id: null, name: "" };
export const defaultSuggestionType = {
  id: null,
  name: "",
  description: "",
  color: "#000000",
};
export const defaultSuggestionStatus = {
  id: null,
  name: "",
  description: "",
  color: "#000000",
};
export const defaultArea = {
  id: null,
  name: "",
  description: "",
  color: "#000000",
};
export const defaultCategory = {
  id: null,
  name: "",
  description: "",
  location_id: null,
  color: "#000000",
};
export const defaultProgram = {
  id: null,
  name: "",
  description: "",
  location_id: null,
};
export const defaultLevel = {
  id: null,
  name: "",
  description: "",
  location_id: null,
  level_requirements: [],
  color: "#000000",
};
export const defaultSuggestionSource = {
  id: null,
  name: "",
  description: "",
  color: "#000000",
};
export const defaultPool = {
  id: null,
  name: "",
  description: "",
  dimensions: "",
  capacity: "",
  pool_type_id: null,
  location_id: null,
};
export const defaultPoolType = {
  id: null,
  name: "",
  description: "",
};
export const defaultGroup = {
  id: null,
  name: "",
  color: "#9C27B0",
  description: "",
  category_id: null,
  active: true,
  availability: "",
  end_date: "",
  start_date: "",
  textColor: "black",
  instructor_id: null,
  levels: [],
  students: [],
  group_schedules: [],
};
export const defaultGroupPrice = {
  id: null,
  // name: "",
  // description: "",
  category_id: "",
  category_name: "",
  account: "",
  price_per_class: 0,
  first_discount: 0,
  second_discount: 0,
  third_discount: 0,
  fourth_discount: 0,
  fifth_discount: 0,
  sixth_discount: 0,
  seventh_discount: 0,
  eighth_discount: 0,
  nineth_discount: 0,
};
export const defaultStoreCategory = {
  id: null,
  name: "",
  is_store: true,
};

//=================================ATTENDANCE=================

export function getAttendance(id, setterFunction) {
  return fetch(`${GROUPS_URL}/attendances?id=${id}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => (setterFunction ? setterFunction(json.attendances) : json))
    .catch(handleError);
}
// ==================================== ROLES ====================================
export function getRoles(setterFunction) {
  return fetch(ROLES_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => (setterFunction ? setterFunction(json) : json))
    .catch(handleError);
}

export function getRole(setterFunction, id) {
  return fetch(ROLES_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateRole(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ role: data });

  return fetch(ROLES_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Rol editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveRole(data, setterFunction) {
  const body = JSON.stringify({ role: data });

  return fetch(ROLES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Rol creado con éxito", "success");
    })
    .catch(handleError);
}
// ==================================== FILTRAR ====================================
export const getFilterByStatus = async (start, end, status, location) => {
  ///api/v1/cortes/descargar_pdf?date_start=2022-09-01&date_end=2022-09-02&tipo_baja=PARCIAL
  return fetch(
    `${CORTES_URL}/descargar_pdf?date_start=${start}&date_end=${end}&tipo_baja=${status}&location_id=${location}`,
    getParams("GET")
  );
};
// ==================================== PERMISOS ====================================

export const getPermissions = async (setterFunction) => {
  return fetch(PERMISSIONS_URL)
    .then((data) => data.json())
    .then((json) => setterFunction(json))
    .catch((e) => notice("Error de servidor, contacta al administrador"));
};
export const getPermissionsNew = async () => {
  return fetch(PERMISSIONS_URL, getParams("GET"))
    .then((data) => data.json())

    .catch((e) => notice("Error de servidor, contacta al administrador"));
};
// ==================================== LOCATIONS ====================================
export function getLocations(setterFunction) {
  return fetch(LOCATIONS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => (setterFunction ? setterFunction(json) : json))
    .catch(handleError);
}

export function getLocation(setterFunction, id) {
  return fetch(LOCATIONS_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateLocation(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ location: data });

  return fetch(LOCATIONS_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Ubicación editada con éxito", "success");
    })
    .catch(handleError);
}

export function saveLocation(data, setterFunction) {
  const body = JSON.stringify({ location: data });

  return fetch(LOCATIONS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Ubicación creada con éxito", "success");
    })
    .catch(handleError);
}

//==================================== EXPECTATION ====================================
export const getExpectationList = async (setterFunction) => {
  return fetch(EXPECTATION_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
};
export const newExpectation = async (data) => {
  let body = JSON.stringify(data);
  return fetch(EXPECTATION_URL, getParams("POST", body))
    .then(handleResponse)
    .catch(handleError);
};
export const updateExpectation = async (data, id) => {
  let body = JSON.stringify(data);
  return fetch(`${EXPECTATION_URL}/${id}`, getParams("PUT", body))
    .then(handleResponse)
    .catch(handleError);
};

export const deleteExpectation = async (id) => {
  return fetch(`${EXPECTATION_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
};
// ==================================== SUGGESTIONS TYPES ====================================
export function getSuggestionTypes(setterFunction) {
  return fetch(SUGGESTIONS_TYPES_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
}

export function getSuggestionType(setterFunction, id) {
  return fetch(SUGGESTIONS_TYPES_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateSuggestionTypes(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ suggestion_type: data });

  return fetch(SUGGESTIONS_TYPES_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Tipo de sugerencia editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveSuggestionTypes(data, setterFunction) {
  const body = JSON.stringify({ suggestion_type: data });

  return fetch(SUGGESTIONS_TYPES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Tipo de sugerencia creado con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== SUGGESTIONS STATUSES ====================================
export function getSuggestionStatuses(setterFunction) {
  return fetch(SUGGESTIONS_STATUSES_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getSuggestionStatus(setterFunction, id) {
  return fetch(SUGGESTIONS_STATUSES_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateSuggestionStatuses(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ suggestion_status: data });

  return fetch(SUGGESTIONS_STATUSES_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Estado de sugerencia editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveSuggestionStatuses(data, setterFunction) {
  const body = JSON.stringify({ suggestion_status: data });

  return fetch(SUGGESTIONS_STATUSES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Estado de sugerencia creado con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== AREAS ====================================
export function getAreas(setterFunction) {
  return fetch(AREAS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getArea(setterFunction, id) {
  return fetch(AREAS_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateArea(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ area: data });

  return fetch(AREAS_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Área editada con éxito", "success");
    })
    .catch(handleError);
}

export function saveArea(data, setterFunction) {
  const body = JSON.stringify({ area: data });

  return fetch(AREAS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Área creada con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== CATEGORIES ====================================
export function getCategoriesWithouPrice(setterFunction) {
  return fetch(`${CATEGORIES_URL}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
}
export function getCategories(setterFunction) {
  return fetch(`${CATEGORIES_URL}/with_group_price`, getParams("GET"))
    .then(handleResponse)
    .then((json) => (setterFunction ? setterFunction(json) : json))
    .catch(handleError);
}

export function getCategory(setterFunction, id) {
  return fetch(CATEGORIES_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateCategory(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ category: data });

  return fetch(CATEGORIES_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Categoría editada con éxito", "success");
    })
    .catch(handleError);
}

export function saveCategory(data, setterFunction) {
  const body = JSON.stringify({ category: data });

  return fetch(CATEGORIES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Categoría creada con éxito", "success");
    })
    .catch(handleError);
}

export function deleteCategory(id) {
  const body = JSON.stringify({ id });

  return fetch(`${CATEGORIES_URL}/delete_logic`, getParams("PUT", body))
    .then(handleResponse)
    .catch(handleError);
}

// ==================================== PROGRAMS ====================================
export function getPrograms(setterFunction) {
  return fetch(PROGRAMS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getProgram(setterFunction, id) {
  return fetch(PROGRAMS_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateProgram(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ program: data });

  return fetch(PROGRAMS_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Programa editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveProgram(data, setterFunction) {
  const body = JSON.stringify({ program: data });

  return fetch(PROGRAMS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Programa creado con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== LEVELS ====================================
export function getLevels(setterFunction) {
  return fetch(LEVELS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getLevel(setterFunction, id) {
  return fetch(LEVELS_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateLevel(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ level: data });

  return fetch(LEVELS_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Nivel editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveLevel(data, setterFunction) {
  const body = JSON.stringify({ level: data });

  return fetch(LEVELS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Nivel creado con éxito", "success");
    })
    .catch(handleError);
}

export function deleteLevel(id) {
  return fetch(`${LEVELS_URL}/delete_logic?id=${id}`, getParams("PUT"))
    .then(handleResponse)
    .catch(handleError);
}

// ==================================== INSTRUCTORS ====================================
export function getIntructors(setterFunction) {
  return fetch(INSTRUCTOR_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}
export function getAllIntructors(setterFunction) {
  return fetch(INSTRUCTOR_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
}

export function getIntructor(setterFunction, id) {
  return fetch(INSTRUCTOR_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateIntructor(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ level: data });

  return fetch(INSTRUCTOR_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Intructor editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveIntructor(data, setterFunction) {
  const body = JSON.stringify({ level: data });

  return fetch(INSTRUCTOR_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Intructor creado con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== SUGGESTIONS SOURCES ====================================
export function getSuggestionSources(setterFunction) {
  return fetch(SUGGESTIONS_SOURCES_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getSuggestionSource(setterFunction, id) {
  return fetch(SUGGESTIONS_SOURCES_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateSuggestionSource(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ suggestion_source: data });

  return fetch(SUGGESTIONS_SOURCES_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Fuente de sugerencia editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveSuggestionSource(data, setterFunction) {
  const body = JSON.stringify({ suggestion_source: data });

  return fetch(SUGGESTIONS_SOURCES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Fuente de sugerencia creado con éxito", "success");
    })
    .catch(handleError);
}

// ==================================== LEVEL REQUIREMENTS ====================================
export function getLevelRequirements(setterFunction) {
  return fetch(LEVEL_REQUIREMENTS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getLevelRequirement(setterFunction, id) {
  return fetch(LEVEL_REQUIREMENTS_URL + "/" + id, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateLeveRequirement(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ level_requirement: data });

  return fetch(LEVEL_REQUIREMENTS_URL + "/" + id, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      notice("Requisito editado con éxito", "success");
    })
    .catch(handleError);
}

export function deleteLeveRequirement(id, setterFunction) {
  return fetch(LEVEL_REQUIREMENTS_URL + "/" + id, getParams("DELETE"))
    .then(handleResponse)
    .then((json) => {
      notice("Requisito eliminado con éxito", "success");
    })
    .catch(handleError);
}

export function saveLevelRequirement(data, setterFunction) {
  const body = JSON.stringify({ level_requirement: data });

  return fetch(LEVEL_REQUIREMENTS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Requisito creado con éxito", "success");
    })
    .catch(handleError);
}

// ====================================== POOLS ===========================================
export function getPools(setterFunction) {
  return fetch(POOLS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getPool(setterFunction, id) {
  return fetch(`${POOLS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updatePool(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ pool: data });

  return fetch(`${POOLS_URL}/${id}`, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Alberca editada con exito", "success");
    })
    .catch(handleError);
}

export function savePool(data, setterFunction) {
  const body = JSON.stringify({ pool: data });
  return fetch(POOLS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Alberca creada con exito", "success");
    })
    .catch(handleError);
}

// ====================================== POOLS TYPES ===========================================
export function getPoolTypes(setterFunction) {
  return fetch(POOL_TYPES_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getPoolType(setterFunction, id) {
  return fetch(`${POOL_TYPES_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updatePoolType(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ pool_type: data });

  return fetch(`${POOL_TYPES_URL}/${id}`, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Tipo de alberca editada con exito", "success");
    })
    .catch(handleError);
}

export function savePoolType(data, setterFunction) {
  const body = JSON.stringify({ pool_type: data });

  return fetch(POOL_TYPES_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Tipo de alberca creada con exito", "success");
    })
    .catch(handleError);
}

// ====================================== GROUPS ===========================================
export function getGroups(setterFunction, page, item) {
  return fetch(`${GROUPS_URL}/?page=${page}&items=${item}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
}

export function getGroupsDate(setterFunction, location) {
  return fetch(`${GROUPS_URL}?&location_id=${location}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getGroupsByTypeCurse(setterFunction, curso, location, instructor) {
  let cursoId = curso ? `&tipo_curso=${curso}` : "";
  let locationId = location ? `&location_id=${location}` : "";
  let instuctorId = instructor ? `&instructor_id=${instructor}` : "";

  let url = `${cursoId + locationId + instuctorId}`;

  return fetch(`${GROUPS_URL}?${url}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}
export function getGroupsInstructor(setterFunction, data, start, end) {
  return fetch(
    `${GROUPS_URL}/instructor?instructor_id=${data}&calendar_start_date=${start}&calendar_end_date=${end}`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function getGroup(setterFunction, id) {
  return fetch(`${GROUPS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateGroup(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ data });
  return fetch(`${GROUPS_URL}/${id}`, getParams("PUT", body)).then(handleResponse);
}

export function deleteStudent(id, setterFunction) {
  return fetch(`${STUDENTS_URL}/${id}`, getParams("DELETE"))
    .then((response) => {
      if (response.ok) {
        notice("Reporte eliminado con exito", "success");
        setterFunction(id);
      }
    })
    .catch((e) => {
      notice(e.errors);
    });
}

export function filterGroups(day, start, end, status, setterFunction) {
  let dayQuery = day.length > 0 ? `&day=${day}` : "";
  let startQuery = start ? `&time_from=${start}` : "";
  let endQuery = end ? `&time_end=${end}` : "";
  let statusQuery = status ? `&estatus=${status}` : "";

  let url = `?${dayQuery + startQuery + endQuery + statusQuery}`;
  url = url.slice(0, 1) + url.slice(2);

  return fetch(`${GROUPS_URL}${url}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function filterGroupsPagy(
  day,
  start,
  end,
  status,
  page,
  rowsPerPage,
  setterFunction
) {
  let dayQuery = day.length > 0 ? `&day=${day}` : "";
  let startQuery = start ? `&time_from=${start}` : "";
  let endQuery = end ? `&time_end=${end}` : "";
  let statusQuery = status ? `&estatus=${status}` : "";

  let url = `?${dayQuery + startQuery + endQuery + statusQuery}`;
  url = url.slice(0, 1) + url.slice(2);

  return fetch(`${GROUPS_URL}${url}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function desactiveGroup(data) {
  const body = JSON.stringify({ data });
  return fetch(`${GROUPS_URL}/deactivate_class`, getParams("PUT", body))
    .then(handleResponse)
    .catch(handleError);
}

export function saveGroup(data, setterFunction = () => {}) {
  const body = JSON.stringify({ data });

  return fetch(GROUPS_URL, getParams("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

export function saveStudent(data, setterFunction) {
  const body = JSON.stringify({ data });
  console.log(body);
  return fetch(GROUP_SCHEDULE, getParams("POST", body));
}

// ====================================== STORE CATEGORIES ===========================================
export function getStoreCategories(setterFunction) {
  return fetch(STORE_CATEGORY_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      if (json) {
        setterFunction(json);
      } else {
        setterFunction([]);
      }
    })
    .catch(handleError);
}

export function getStoreCategory(setterFunction, id) {
  return fetch(`${STORE_CATEGORY_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateStoreCategory(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ store_category: data });

  return fetch(`${STORE_CATEGORY_URL}/${id}`, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Categoría editada con exito", "success");
    })
    .catch(handleError);
}

export function saveStoreCategory(data, setterFunction) {
  const body = JSON.stringify({ store_category: data });

  return fetch(STORE_CATEGORY_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      notice("Categoría creada con exito", "success");
    })
    .catch(handleError);
}

// ====================================== GROUP PRICES ===========================================

//categorias
export function getCategoryPrices() {
  return fetch(`${CATEGORIES_URL}/category_group_price`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
}
export function getGroupPrices(setterFunction) {
  return fetch(GROUP_PRICE_URL, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
    })
    .catch(handleError);
}

export function getGroupPrice(setterFunction, id) {
  return fetch(`${GROUP_PRICE_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json.group_price);
    })
    .catch(handleError);
}

export function updateGroupPrice(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify(data);

  return fetch(`${GROUP_PRICE_URL}/${id}`, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Precio por grupo editado con exito", "success");
    })
    .catch(handleError);
}

export function saveGroupPrice(data, setterFunction) {
  const body = JSON.stringify(data);
  return fetch(GROUP_PRICE_URL, getParams("POST", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Precio por grupo creado con exito", "success");
    })
    .catch(handleError);
}
