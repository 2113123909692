// eslint-disable-next-line

export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "clients", //regresar a dashboard cuando se este listo
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Material UI",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "Layout",
            bullet: "dot",
            submenu: [
              {
                title: "Box",
                bullet: "line",
                page: "google-material/layout/box",
              },
              {
                title: "Container",
                bullet: "line",
                page: "google-material/layout/container",
              },
              {
                title: "Grid",
                bullet: "line",
                page: "google-material/layout/grid",
              },
              {
                title: "Grid list",
                bullet: "line",
                page: "google-material/layout/grid-list",
              },
              {
                title: "Hidden",
                bullet: "line",
                page: "google-material/layout/hidden",
              },
            ],
          },
          {
            title: "Inputs",
            bullet: "dot",
            submenu: [
              {
                title: "Autocomplete",
                page: "google-material/inputs/autocomplete",
                bullet: "line",
                permission: "accessToECommerceModule",
              },
              {
                title: "Buttons",
                page: "google-material/inputs/buttons",
                bullet: "line",
              },
              {
                title: "Checkboxes",
                page: "google-material/inputs/checkboxes",
                bullet: "line",
              },
              {
                title: "Pickers",
                page: "google-material/inputs/pickers",
                bullet: "line",
              },
              {
                title: "Radio Buttons",
                page: "google-material/inputs/radio-buttons",
                bullet: "line",
              },
              {
                title: "Selects",
                page: "google-material/inputs/selects",
                bullet: "line",
              },
              {
                title: "Switches",
                page: "google-material/inputs/switches",
                bullet: "line",
              },
              {
                title: "Text Fields",
                page: "google-material/inputs/text-fields",
                bullet: "line",
              },
              {
                title: "Transfer List",
                page: "google-material/inputs/transfer-list",
                bullet: "line",
              },
            ],
          },
          {
            title: "Navigation",
            bullet: "dot",
            submenu: [
              {
                title: "Bottom Navigation",
                bullet: "line",
                page: "google-material/navigation/bottom-navigation",
              },
              {
                title: "Breadcrumbs",
                bullet: "line",
                page: "google-material/navigation/breadcrumbs",
              },
              {
                title: "Drawers",
                bullet: "line",
                page: "google-material/navigation/drawer",
              },
              {
                title: "Links",
                bullet: "line",
                page: "google-material/navigation/links",
              },
              {
                title: "Menus",
                bullet: "line",
                page: "google-material/navigation/menus",
              },
              {
                title: "Steppers",
                bullet: "line",
                page: "google-material/navigation/steppers",
              },
              {
                title: "Tabs",
                bullet: "line",
                page: "google-material/navigation/tabs",
              },
            ],
          },
          {
            title: "Surfaces",
            bullet: "dot",
            submenu: [
              {
                title: "App Bar",
                bullet: "line",
                page: "google-material/surfaces/app-bar",
              },
              {
                title: "Paper",
                bullet: "line",
                page: "google-material/surfaces/paper",
              },
              {
                title: "Cards",
                bullet: "line",
                page: "google-material/surfaces/cards",
              },
              {
                title: "Expansion Panels",
                bullet: "line",
                page: "google-material/surfaces/expansion-panels",
              },
            ],
          },
          {
            title: "Feedback",
            bullet: "dot",
            submenu: [
              {
                title: "Progress",
                bullet: "line",
                page: "google-material/feedback/progress",
              },
              {
                title: "Dialogs",
                bullet: "line",
                page: "google-material/feedback/dialogs",
              },
              {
                title: "Snackbars",
                bullet: "line",
                page: "google-material/feedback/snackbars",
              },
            ],
          },
          {
            title: "Data Display",
            bullet: "dot",
            submenu: [
              {
                title: "Avatars",
                bullet: "line",
                page: "google-material/data-displays/avatars",
              },
              {
                title: "Badges",
                bullet: "line",
                page: "google-material/data-displays/badges",
              },
              {
                title: "Chips",
                bullet: "line",
                page: "google-material/data-displays/chips",
              },
              {
                title: "Dividers",
                bullet: "line",
                page: "google-material/data-displays/dividers",
              },
              {
                title: "Icons",
                bullet: "line",
                page: "google-material/data-displays/icons",
              },
              {
                title: "Lists",
                bullet: "line",
                page: "google-material/data-displays/lists",
              },
              {
                title: "Tables",
                bullet: "line",
                page: "google-material/data-displays/tables",
              },
              {
                title: "Tooltips",
                bullet: "line",
                page: "google-material/data-displays/tooltips",
              },
              {
                title: "Typography",
                bullet: "line",
                page: "google-material/data-displays/typography",
              },
            ],
          },
          {
            title: "Utils",
            bullet: "dot",
            submenu: [
              {
                title: "Click Away Listener",
                bullet: "line",
                page: "google-material/utils/click-away-listener",
              },
              {
                title: "Modal",
                bullet: "line",
                page: "google-material/utils/modal",
              },
              {
                title: "No SSR",
                bullet: "line",
                page: "google-material/utils/no-ssr",
              },
              {
                title: "Popover",
                bullet: "line",
                page: "google-material/utils/popover",
              },
              {
                title: "Popper",
                bullet: "line",
                page: "google-material/utils/popper",
              },
              {
                title: "Portal",
                bullet: "line",
                page: "google-material/utils/portal",
              },
              {
                title: "Transitions",
                bullet: "line",
                page: "google-material/utils/transitions",
              },
              {
                title: "useMediaQuery",
                bullet: "line",
                page: "google-material/utils/use-media-query",
              },
            ],
          },
        ],
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Clientes",
        root: true,
        icon: "flaticon-users",
        page: "clients",
        bullet: "dot",
      },
      {
        title: "Mi perfil",
        root: true,
        icon: "flaticon2-user",
        page: "mi-perfil",
        bullet: "dot",
      },
      {
        title: "Punto de venta",
        root: true,
        icon: "flaticon-price-tag",
        page: "punto-de-venta",
        bullet: "dot",
      },
      {
        title: "Productos",
        root: true,
        icon: "flaticon-shopping-basket",
        page: "products",
        bullet: "dot",
      },
      {
        title: "Ingresos",
        root: true,
        icon: "flaticon-coins",
        page: "ingresos",
        bullet: "dot",
      },
      {
        title: "Cortes",
        root: true,
        icon: "flaticon-piggy-bank",
        page: "cortes",
        bullet: "dot",
      },
      {
        title: "Caja chica",
        root: true,
        icon: "flaticon-open-box",
        page: "minor-box",
        bullet: "dot",
      },
      {
        title: "Pagos Mercado Pago",
        root: true,
        icon: "flaticon2-shopping-cart-1",
        page: "pagos-mercado-pago",
        bullet: "dot",
      },
      {
        title: "Calendario",
        root: true,
        icon: "flaticon-calendar",
        page: "meetings",
        bullet: "dot",
      },
      {
        title: "Alumnos",
        root: true,
        icon: "flaticon-users-1",
        page: "students",
        bullet: "dot",
        submenu: [
          { title: "Lista de alumnos", page: "students/list" },
          { title: "Deudores", page: "students/debtors" },
          { title: "Alumnos nuevos", page: "new-students/list" },
        ],
      },
      {
        title: "Bajas",
        root: true,
        icon: "flaticon2-list-2",
        page: "discharges",
        bullet: "dot",
      },
      //flaticon2-list-2

      {
        title: "Asistencia",
        root: true,
        icon: "flaticon-list-3",
        page: "asistencia",
        bullet: "dot",
      },
      {
        title: "Instructores",
        root: true,
        icon: "flaticon-profile",
        page: "instructors",
        bullet: "dot",
        submenu: [
          { title: "Instructores", page: "instructors" },
          { title: "Reporte de pagos", page: "report/list" },
          // { title: "Deudores", page: "students/debtors" },
        ],
      },
      {
        title: "Usuarios",
        root: true,
        icon: "flaticon-user",
        page: "users",
        bullet: "dot",
      },
      {
        title: "Sugerencias",
        root: true,
        icon: "flaticon-like",
        page: "sugerencias",
        bullet: "dot",
      },
      // {
      //   title: "Mantenimiento",
      //   root: true,
      //   icon: "flaticon-list-1",
      //   page: "mantenimiento",
      //   bullet: "dot",
      // },
      {
        title: "Configuración",
        root: true,
        icon: "flaticon2-settings",
        page: "configuracion",
        bullet: "dot",
      },
      {
        title: "Ayuda",
        root: true,
        icon: "flaticon2-information",
        page: "ayuda",
        bullet: "dot",
      },
    ],
  },
};
