import { FINDOUTS_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const findoutList = () => {
  return fetch(FINDOUTS_URL, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const singleFindout = (id) => {
  return fetch(`${FINDOUTS_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const createFindout = (data) => {
  return fetch(FINDOUTS_URL, getParams("POST", JSON.stringify(data)))
    .then(handleResponse)
    .catch(handleError);
};

export const updateFindout = (data) => {
  return fetch(
    `${FINDOUTS_URL}/${data.id}`,
    getParams("PUT", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch(handleError);
};

export const deleteFindout = (data) => {
  return fetch(
    `${FINDOUTS_URL}/${data.id}`,
    getParams("DELETE", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch(handleError);
};

export const removeFindout = (id) => {
  return fetch(
    `${FINDOUTS_URL}/delete_logic`,
    getParams("PUT", JSON.stringify({ id }))
  )
    .then(handleResponse)
    .catch(handleError);
};
