import PropTypes from "prop-types";
import React from "react";
import MUIDataTable from "mui-datatables";
import "../styles/_fullTable.scss";
import { CircularProgress, Grid, Input, InputAdornment } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import IconButton from "./IconButton";
import CustomButton from "./CustomButton";

const customOptions = {
  filter: false,
  filterType: "dropdown",
  responsive: "scrollMaxHeight",
  sort: false,
  print: false,
  viewColumns: false,
  download: false,
  selectableRows: "none",
  rowsPerPage: 15,
  rowsPerPageOptions: [10, 20, 50],
  confirmFilters: true,
  textLabels: {
    body: {
      noMatch: "No se encontraron resultados",
    },
    pagination: {
      next: "Siguiente",
      previous: "Anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imnprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todos",
      title: "Filtrar",
      reset: "Limpiar",
    },
  },
};

export const customFilterConfirm = (
  currentFilterList = [],
  handleFilterSubmit = console.log
) => {
  return (
    <CustomButton
      className="mt-4"
      title="Filtrar"
      onClick={() => handleFilterSubmit(currentFilterList)}
    />
  );
};

export const customSearchInput = (
  searchQuery = "",
  setSearchText,
  onConfirmSearch = console.log
) => {
  const handleClearSearch = () => {
    setSearchText("");
    onConfirmSearch("");
  };
  return (
    <Grid container spacing={1} className="w-100">
      <Grid item xs={10}>
        <Input
          fullWidth
          className="text-white"
          defaultValue={searchQuery}
          onChange={({ target }) => setSearchText(target.value)}
          endAdornment={
            searchQuery && (
              <InputAdornment position="end">
                <Close onClick={handleClearSearch} style={{ cursor: "pointer" }} />
              </InputAdornment>
            )
          }
        />
      </Grid>
      <Grid item>
        <IconButton
          Icon={Search}
          tooltip="Buscar"
          onClick={() => onConfirmSearch(searchQuery)}
          color="primary"
        />
      </Grid>
    </Grid>
  );
};
export const customSearchInputAuto = (searchTextFunction, loaderInput) => {
  // const handleClearSearch = () => {
  //   setSearchText("");
  //   onConfirmSearch("");
  // };
  return (
    <Grid container spacing={1} className="w-100">
      <Grid item xs={10}>
        <Input
          fullWidth
          className="text-white"
          onChange={({ target }) => searchTextFunction(target.value)}
          endAdornment={
            loaderInput && (
              <InputAdornment position="end">
                <CircularProgress size={15} color="inherit" />
              </InputAdornment>
            )
          }
        />
      </Grid>
    </Grid>
  );
};

const FullTable = ({ title = "", columns = [], data = [], options = {} }) => {
  return (
    <div className="wrapper">
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={{ ...customOptions, ...options }}
      />
    </div>
  );
};

FullTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  title: PropTypes.string,
};

export default FullTable;
