export const defaultPDF = {
  start_date: null,
  end_date: null,
  status: "",
  location_name: "",
};

export const pdfForm = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "status",
    label: "Estatus",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "ACTIVO", text: "Activo" },
      { value: "PARCIAL", text: "Baja parcial" },
      { value: "TOTAL", text: "Baja total" },
    ],
  },
  {
    name: "location_id",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
];
