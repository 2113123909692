import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { Paper } from '@material-ui/core';
import FullTable from '../../../common/components/FullTable';
import { fetchAttendance } from '../../../crud/attendance.crud';
import { filterParams } from '../../../crud/helpers/validate';
import Loading from '../../../utils/Loading';
import notice from '../../../utils/notice';
import { attendance_group_columns } from './utils';

const AttendeceByGroupPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [page] = useState(1)
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchAttendance(page, "", filterParams({group_schedule_id: id}))
    .then(res => setResults(res.clients_attendances))
    .catch(e => notice(e.join('\n') || "Ha ocurrido un error con su solicitud"))
    .finally(() => setLoading(false))
  }, [dispatch, id, page])

  if (loading) return <Loading />

  return (
    <>
      <Paper>
        <FullTable
          title={`Grupo ${results[0]?.group || ""} - Horario ${results[0]?.group_schedule || ""}`}
          data={results}
          columns={attendance_group_columns}
          // options={{...table_options(console.log), page}}
        />
      </Paper>
    </>
  );
};

export default AttendeceByGroupPage;
