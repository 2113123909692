import notice from "../utils/notice";
import { INSTRUCTORS_URL, INSTRUCTORS_REPORT_URL, CORTES_URL } from "./helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleResponse,
} from "./helpers/validate";
//REPORTE DE PAGOS
export const getReportPDFById = (id) => {
  return fetch(
    `${CORTES_URL}/pagos_instructores_PDF_guardados.pdf?id_reporte_pago=${id}`,
    getParams("GET")
  );
};
export const getReportInstructorsList = async (page = 1, active = true) => {
  return fetch(`${INSTRUCTORS_REPORT_URL}/report_pagos_instructors`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getInstructorsListByReport = async (idReport) => {
  return fetch(
    `${INSTRUCTORS_REPORT_URL}/report_instructors_id_reporte_pago?id_reporte_pago=${idReport}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getInstructorsReportFilter = async (id) => {
  let locationQuery = id ? `?location_id=${id}` : "";
  return fetch(
    `${INSTRUCTORS_REPORT_URL}/inf_reporte_instructores${locationQuery}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getInstructorReportData = async (id) => {
  return fetch(
    `${INSTRUCTORS_REPORT_URL}/inf_reporte_instructores_id?instructor_id=${id}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};
export const createReportData = async (body) => {
  return fetch(`${INSTRUCTORS_REPORT_URL}`, getParams("POST", JSON.stringify(body)))
    .then(handleResponse)
    .catch(handleError);
};

export const updateReportData = async (body, id) => {
  return fetch(`${INSTRUCTORS_REPORT_URL}/${id}`, getParams("PUT", JSON.stringify(body)))
    .then(handleResponse)
    .catch(handleError);
};
export const getReportPDF = (locationId, start, end) => {
  return fetch(
    `${CORTES_URL}/reporte_pagos_instructores.pdf?location_id=${locationId}&start_date=${start}&end_date=${end}`,
    getParams("GET")
  );
};
export const getReportFilter = async (locationId, start, end, idReport, reportDate) => {
  let fechaQuery = start && end ? `&start_date=${start}&end_date=${end}` : "";
  let locationIdQuery = locationId ? `&location_id=${locationId}` : "";
  let idReportQuery = idReport ? `&id_reporte_pago=${idReport}` : "";
  let reportQuery = reportDate ? `&date_reporte=${reportDate}` : "";
  let url = `report_pagos_instructors?${fechaQuery +
    locationIdQuery +
    idReportQuery +
    reportQuery}`;
  url = url.slice(0, 25) + url.slice(26);
  return fetch(`${INSTRUCTORS_REPORT_URL}/${url}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
//-------------------------------------
export const getInstructorsList = (page = 1, active = true) => {
  return fetch(`${INSTRUCTORS_URL}?page=${page}&active=${active}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const searchInstructors = async (setterFunction, query = "") => {
  return fetch(`${INSTRUCTORS_URL}?query=${query}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      setterFunction(res.users);
    })
    .catch((e) => notice(e.errors));
};

export const searchInstructorsByLocation = async (
  setterFunction,
  query = "",
  idLocation
) => {
  return fetch(
    `${INSTRUCTORS_URL}?location_id=${idLocation}&query=${query}`,
    getParams("GET")
  )
    .then(handleResponse)
    .then((res) => {
      setterFunction(res.users);
    })
    .catch((e) => notice(e.errors));
};
export const searchLocation = async (setterFunction, idLocation) => {
  return fetch(`${INSTRUCTORS_URL}?location_id=${idLocation}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      setterFunction(res.users);
    })
    .catch((e) => notice(e.errors));
};
