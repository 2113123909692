import React from 'react';
import { KeyboardDateTimePicker } from "@material-ui/pickers";

const SimpleDateTime = ({ label, name, onChange, value, variant="standard", disabled, style={}, className="", color="primary", margin='none', error, helperText="", onBlur=console.log }) => {
  return (
    <KeyboardDateTimePicker
      autoOk
      fullWidth
      name={name}
      color={color}
      disabled={disabled}
      label={label}
      value={value}
      style={style}
      size='small'
      error={error}
      onBlur={onBlur}
      helperText={helperText}
      margin={margin}
      variant="inline"
      className={className}
      inputVariant={variant}
      format="dd/MM/yyyy hh:mm a"
      placeholder="dd/MM/yyyy HH:mm"
      onChange={date => onChange({ target: { value: date, name } })}
    />
  )
};

export default SimpleDateTime;
