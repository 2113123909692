import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import AntTabs from "../../../common/components/AntTabs";
import PackSaleTab from "./components/PackSaleTab";
import StoreSaleTab from "./components/StoreSaleTab";
import PaymentMethodTab from "./components/PaymentMethodTab";
import SummarySale from "./components/SummarySale";
import { defaultPackageSale, defaultSummary, tabsOptions } from "./utils";
import { getClientsForPointOfSale, getResponsible } from "../../../crud/clients.crud";
import SimpleAutocomplete from "../../../common/components/SimpleAutocomplete";
import notice from "../../../utils/notice";
import { useParams } from "react-router-dom";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const PointOfSalePage = () => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [loader, setLoader] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [packageSale, setPackageSale] = useState(defaultPackageSale);
  const [summary, setSummary] = useState(defaultSummary);
  const [packages, setPackages] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [responsible, setResponsible] = useState(null);
  const [totalPayment, setTotalPayment] = useState(0.0);
  const { id } = useParams();
  useEffect(() => {
    setLoader(true);
    getClientsForPointOfSale()
      .then(({ clients }) => {
        setLoader(false);
        setClients(
          clients.map((client) => ({
            ...client,
            value: client.id,
            text: `#${client.membership_number || ""} ${client.name} ${client.last_name}`,
          }))
        );
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (summary.client && summary.client?.responsible_id)
      getResponsible(null, summary.client?.responsible_id)
        .then((res) => {
          setResponsible(res.responsible);
          if (res.responsible.payment_methods.length === 0) {
            // setTabValue(2);
            notice(
              "Este cliente no tiene metodos de pago, favor de agregar uno antes de realizar cualquier acción",
              "info"
            );
          }
        })
        .catch((e) =>
          notice("Ha ocurrido un error al traer al responsable de este cliente")
        );
  }, [summary.client]);

  useEffect(() => {
    if (id !== undefined && clients.length !== 0) {
      clients.map((client) => {
        if (client.id === Number(id)) {
          let target = { target: { name: "client", value: client } };
          handleClient(target);
        }
      });
    }
  }, [clients]);

  const handleClient = ({ target }) => {
    if (target.value) {
      let notaClient = target?.value?.nota ? target?.value?.nota : "";
      setSummary({
        ...defaultSummary,
        client: target.value,
        nota: notaClient,
        products: summary.products,
      });
      if (target?.value?.group_prices.length > 0) {
        setPackages(
          target.value.group_prices.map((gp) => ({ ...gp, value: gp.id, text: gp.name }))
        );
      } else {
        setPackages([]);
      }
      setPaymentTypes([
        ...target.value.other_fees.map((fee) => ({
          ...fee,
          value: fee.id,
          text:
            fee.name === "annuity"
              ? "Anualidad"
              : fee.name === "inscription"
              ? "Inscripción"
              : fee.name,
          text2: fee.status ? "Vigente" : "Adeudo",
          text2_className: `badge badge-pill badge-${
            fee.status ? "success" : "danger"
          } text-light font-weight-bold`,
          disabled: fee.status,
        })),
        {
          value: "monthly",
          text: "Mensualidad",
          disabled: Boolean(target.value.other_fees.find((fee) => !fee.status)),
        },
      ]);
    }
  };
  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <div className="px-4 pt-2">
              <SimpleAutocomplete
                name="client"
                label="Nombre del alumno"
                variant="outlined"
                options={clients}
                value={summary.client}
                onChange={handleClient}
              />
            </div>
            <AntTabs
              options={tabsOptions(role)}
              value={tabValue}
              onChange={(value) => setTabValue(value)}
            />
            <div className="p-4">
              {tabValue === 0 && (
                <PackSaleTab
                  packageSale={packageSale}
                  setPackageSale={setPackageSale}
                  packages={packages}
                  paymentTypes={paymentTypes}
                  summary={summary}
                  setSummary={setSummary}
                />
              )}
              {tabValue === 1 && (
                <StoreSaleTab summary={summary} setSummary={setSummary} />
              )}
              {tabValue === 2 && (
                <PaymentMethodTab
                  totalPayment={totalPayment}
                  setTotalPayment={setTotalPayment}
                  responsible={responsible}
                  setResponsible={setResponsible}
                  summary={summary}
                  onSelectCard={(data) => {
                    setSummary({ ...summary, ...data });
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SummarySale
              setTotalPayment={setTotalPayment}
              totalPayment={totalPayment}
              summary={summary}
              setSummary={setSummary}
              currentTab={tabValue}
              setTabValue={setTabValue}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PointOfSalePage;
