import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Cards from "react-credit-cards";
import clsx from "clsx";
import {
  FaCcAmex,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from "react-icons/fa";
import * as clientsCRUD from "../../../../crud/clients.crud";
import UtilInputs from "../../../../common/components/UtilInputs";

const Payment = ({ responsible, setResponsibles, deletePaymentMethod }) => {
  const [open, setOpen] = useState(false);
  const [card, setCard] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleChange = ({ target }) => {
    setCard({ ...card, [target.name]: target.value });
  };

  const handleFocus = ({ target }) => {
    setCard({ ...card, focus: target.name });
  };

  const resetCardValues = (open) => {
    setOpen(open);
    setCard({
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
    });
  };
  function create() {
    let data = {
      card_number: card.number,
      card_expiration: card.expiry,
      card_cvc: card.cvc,
    };
    clientsCRUD
      .createPaymentMethod(responsible.responsible.id, data, setResponsibles)
      .then(() => {
        disableLoading();
        resetCardValues(false);
      });
  }

  function delete_card(id) {
    clientsCRUD.deletePaymentMethod(
      { id: id, responsible_id: responsible.responsible.id },
      deletePaymentMethod
    );
  }

  function getCardBrandIcon(brand) {
    let icon = <FaCreditCard size={42} color={"#0027c5"} />;
    switch (brand) {
      case "mastercard":
        icon = <FaCcMastercard size={42} color={"#0027c5"} />;
        break;
      case "amex":
        icon = <FaCcAmex size={42} color={"#0027c5"} />;
        break;
      case "visa":
        icon = <FaCcVisa size={42} color={"#0027c5"} />;
        break;
      default:
        icon = <FaCreditCard size={42} color={"#0027c5"} />;
    }
    return icon;
  }

  return (
    <Paper>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{ color: "#001979", padding: "15px" }}
          >
            Métodos de pago
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid>
        <Typography variant="h6" style={{ padding: "15px 0px 15px 27px" }}>
          Mis tarjetas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {responsible.responsible.payment_methods ? (
            <>
              {responsible.responsible.payment_methods.map((pm) => (
                <Grid
                  item
                  style={{
                    border: "1px dashed darkgray",
                    margin: "15px",
                    borderRadius: "10px",
                    padding: 10,
                    width: 300,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ padding: "15px 0px 15px 27px" }}
                      >
                        {responsible.responsible.name}{" "}
                        {responsible.responsible.last_name} {pm.store_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <center>{getCardBrandIcon(pm.card.brand)}</center>
                        </Grid>
                        <Grid item xs={10}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              variant="body"
                              style={{ textTransform: "capitalize" }}
                            >
                              {pm.card.brand} ****{pm.card.last4}
                            </Typography>
                            <Typography variant="body">
                              Expira el {pm.card.exp_month} de{" "}
                              {pm.card.exp_year}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <center>
                        <Button
                          variant="contained"
                          onClick={() => delete_card(pm.id)}
                        >
                          Borrar
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            ""
          )}
          <Grid
            item
            style={{
              border: "1px dashed #98d1fb",
              margin: "15px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "50px 0px",
              borderRadius: "10px",
              background: "#f1faff",
              width: 300,
              height: 120,
            }}
          >
            <Button
              style={{ marginRight: 25 }}
              color="primary"
              variant="contained"
              onClick={() => resetCardValues(true)}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => resetCardValues(false)}
      >
        <DialogTitle>Nueva tarjeta</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Cards
                cvc={card.cvc}
                expiry={card.expiry}
                name={card.name}
                number={card.number}
                focused={card.focus}
              />
            </Grid>
            <Grid item xs={12} sm={6} component="form" onFocus={handleFocus}>
              <UtilInputs
                width="100%"
                inputValues={[
                  {
                    name: "number",
                    label: "Número de tarjeta",
                    type: "text",
                    variant: "outlined",
                    mask: "9999 9999 9999 9999",
                  },
                  {
                    name: "name",
                    label: "Nombre del titular",
                    type: "text",
                    variant: "outlined",
                  },
                  {
                    name: "expiry",
                    label: "Fecha de expiración",
                    type: "text",
                    variant: "outlined",
                    width: "53%",
                    mask: "99/99",
                  },
                  {
                    name: "cvc",
                    label: "CVC",
                    type: "text",
                    variant: "outlined",
                    width: "36%",
                    mask: "999",
                  },
                ]}
                formValues={card}
                onValuesChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => resetCardValues(false)}>
            Cancelar
          </Button>
          <button
            id="kt_login_signin_submit"
            className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
              {
                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
              }
            )}`}
            style={loadingButtonStyle}
            onClick={() => {
              enableLoading();
              setTimeout(() => {
                create();
              }, 1000);
            }}
          >
            Guardar
          </button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Payment;
