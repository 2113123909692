import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Grid, Paper, Typography, TextField } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import CustomButton from "../../../../common/components/CustomButton";

import { TableCell } from "@material-ui/core";
import SummarySale from "../../PointOfSale/components/SummarySale";
import PointOfSalePage from "../../PointOfSale/PointOfSalePage";
import AccountStatus from "./AccountStatus";
import AccountStatusTable from "./AccountStatusTable";
import { getClientPayments } from "../../../../crud/clients.crud";
import Loading from "../../../../utils/Loading";
import notice from "../../../../utils/notice";

const ClientQuota = ({ clientPayment, responsible }) => {
  const { id } = useParams();

  const [studentPayments, setStudentPayments] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  let data = responsible.responsible;
  let data2 = clientPayment;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //summary section
  useEffect(() => {
    setLoading(true);
    getClientPayments(id)
      .then((res) => {
        if (res) setStudentPayments(res);
        else throw new Error({ errors: ["Error con su solicitud"] });
      })
      .catch((e) => notice("Error con su solicitud"))
      .finally(() => setLoading(false));
  }, [id]);
  if (loading) return <Loading />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid item xs={6} sm={5} md={6} xl={8}>
              <Grid
                style={{
                  paddingLeft: "10px",
                }}
              >
                <div style={{ display: "flex", paddingBottom: 3 }}>
                  <PersonOutlineIcon style={{ color: "#6685DE" }} />
                  <Typography variant="p" style={{ paddingLeft: 5 }}>
                    Alumno:
                    <b>
                      {data.name} {data.last_name}
                    </b>
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingBottom: 3 }}>
                  <ReceiptIcon style={{ color: "#6685DE" }} />
                  <Typography variant="p" style={{ paddingLeft: 5 }}>
                    Folio de cuota: <b> - </b>
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingBottom: 3 }}>
                  <CalendarTodayIcon style={{ color: "#6685DE" }} />
                  <Typography variant="p" style={{ paddingLeft: 5 }}>
                    Fecha: <b>{data2.created_at}</b>
                  </Typography>
                </div>{" "}
                <div style={{ display: "flex", paddingBottom: 3 }}>
                  <SettingsIcon style={{ color: "#6685DE" }} />
                  <Typography variant="p" style={{ paddingLeft: 5 }}>
                    Tipo de pago: <b> - </b>
                  </Typography>
                </div>
                <div style={{ display: "flex", paddingBottom: 3 }}>
                  <ReceiptIcon style={{ color: "#6685DE" }} />
                  <Typography variant="p" style={{ paddingLeft: 5 }}>
                    Producto: <b> - </b>
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={5} md={6} xl={8}>
              <Grid
                style={{
                  paddingLeft: "10px",
                  textAlign: "right",
                }}
              >
                <Typography variant="p">
                  Cantidad a pagar | <b>-</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* PAGOS */}
        <Grid item xs={12} sm={12} md={12}>
          <Paper
            style={{
              padding: 15,
              // outline: "2px solid red",
              display: "flex",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <div
              style={{
                // outline: "2px solid green",
                width: "75%",
                display: "flex",
                flexWrap: "wrap",
                gap: 15,
              }}
            >
              <Grid item>
                <TextField
                  label="Monto de la cuota"
                  defaultValue="$1,890.00"
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Cuenta de depósito"
                  variant="outlined"
                  type="dropdown"
                  defaultValue="Aquara Kids"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Estatus"
                  variant="outlined"
                  defaultValue="Parcialmente abonada"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Monto del recargo"
                  variant="outlined"
                  defaultValue="$0.00"
                />
              </Grid>
            </div>
            <div>
              <CustomButton
                title="Editar información"
                // className="mt-4"
                // onClick={handleEdit}
                disabled={false}
              />
            </div>
          </Paper>
        </Grid>
        {/* table */}

        <Grid item xs={12} sm={12} md={12}>
          {/* <Paper
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 10,
            }}
          > */}
          {studentPayments.map((account) => (
            <div className="mb-3" key={account.id}>
              <AccountStatusTable account={account.payments} />
            </div>
          ))}

          {/* <AccountStatus /> */}
          {/* <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Monto</TableCell>
                    <TableCell>Método de pago</TableCell>
                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow>
                      <TableCell>{row.monto}</TableCell>
                      <TableCell>{row.pago}</TableCell>
                      <TableCell style={{ color: "red" }}>{row.accion}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          <div>
            <CustomButton
              title="Agregar"
              // className="mt-4"
              onClick={handleOpen}
              disabled={false}
            />
          </div>
          {/* </Paper> */}
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{ outline: "2p solid red", width: "90%", margin: "auto", marginTop: 70 }}
        >
          <PointOfSalePage />
        </div>
      </Modal>
    </>
  );
};
export default ClientQuota;
