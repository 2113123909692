import React from 'react'
import { Grid, FormControl, Select, MenuItem, Typography, Slider, InputLabel } from '@material-ui/core'

export const MantTransparency = ({ users, values, handleChange }) => {
  const marks = [
    {
      value: 0,
      label: 'Pésima',
    },
    {
      value: 33,
      label: 'Turbia',
    },
    {
      value: 66,
      label: 'Aceptable',
    },
    {
      value: 100,
      label: 'Excelente',
    },
  ];

  return (
    <div style={{marginTop: '20px'}}> 
      <Grid container spacing={2} justify="space-around" >
        <Grid item xs={2}>
          <FormControl fullWidth >
          <InputLabel id='responsable_label'>Responsable</InputLabel>
            <Select
              labelId="responsable_label"
              name='user_id'
              defaultValue={values.water_transparencies[0].user_id ? values.water_transparencies[0].user_id : users.data[0].id}
              onChange={(e) => handleChange({target: values.water_transparencies[0] = {...values.water_transparencies[0], [e.target.name]: e.target.value}}) }
              >
              {users.data.map(user => (
                <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>
        <Grid item xs={8}>
          <Typography>Mañana</Typography>
            <Slider
              name="transparency"
              step={null}
              marks={marks}
              defaultValue={values.water_transparencies[0].transparency}
              onChange={(e, newValue) => handleChange({target: values.water_transparencies[0] = {...values.water_transparencies[0], transparency: newValue}})}
            />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="space-around" >
        <Grid item xs={2}>
          <FormControl fullWidth >
            <InputLabel id='responsable_night_label'>Responsable</InputLabel>
            <Select
              labelId="responsable_night_label"
              name='user_id'
              defaultValue={values.water_transparencies[1].user_id ? values.water_transparencies[1].user_id : users.data[0].id}
              onChange={(e) => handleChange({target: values.water_transparencies[1] = {...values.water_transparencies[1], [e.target.name]: e.target.value}})}
              >
              {users.data.map(user => (
                <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>  
        </Grid>
        <Grid item xs={8}>
          <Typography>Noche</Typography>
            <Slider
              name="transparency"
              step={null}
              marks={marks}
              defaultValue={values.water_transparencies[1].transparency}
              onChange={(e, newValue) => handleChange({target: values.water_transparencies[1] = {...values.water_transparencies[1], transparency: newValue}})}
            />
        </Grid>
      </Grid>
    </div>
  )
}
