import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullTable from "../../../common/components/FullTable";
import { Button } from "@material-ui/core";
import { setLoader } from "../../../store/ducks/loader.duck";
import {
  getAddNewMinior,
  getFilterMinior,
  getMinorAll,
  getPDFMinior,
} from "../../../crud/miniorBox.crud";
import {
  MinorColumns,
  defaultFilter,
  filtersForm,
  newFiltersForm,
  newDefaultFilter,
} from "./utils";
import UtilInputs from "../../../common/components/UtilInputs";

import { getLocations } from "../../../crud/config.crud";
import notice from "../../../utils/notice";
import moment from "moment";
import { Modal } from "@material-ui/core";

const MinorBox = () => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);
  const currentDate = moment();
  const havePermissionDate = permissions.includes("minorbox:adminsitrate");
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [newFilter, setNewFilter] = useState(newDefaultFilter);
  const [add, setAdd] = useState();
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (!permissions.includes("minorbox:adminsitrate")) {
      setFilter({ ...filter, start_date: currentDate, end_date: currentDate });
      setNewFilter({ ...newFilter, operation_date: currentDate });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLoader(true));
    getMinorAll()
      .then((res) => {
        setList(res.minor_boxes);
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch, add]);
  //location
  useEffect(() => {
    dispatch(setLoader(true));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });
        setNewFilter({ ...newFilter, location_name: res[0].name });

        if (!permissions.includes("minorbox:adminsitrate")) {
          setFilter({
            ...filter,
            start_date: currentDate,
            end_date: currentDate,
            client_location: res[0].name,
          });
          setNewFilter({
            ...newFilter,
            operation_date: currentDate,
            location_name: res[0].name,
          });
        }
        setLocations(res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id })));
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };
  const handleChangeNew = ({ target }) => {
    const { name, value } = target;
    setNewFilter({ ...newFilter, [name]: value });
  };

  const handleFilter = () => {
    let start = moment(filter.start_date).format("YYYY-MM-DD");
    let end = moment(filter.end_date).format("YYYY-MM-DD");
    let location;
    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });
    if (location && start !== "Fecha inválida" && end !== "Fecha inválida") {
      getFilterMinior(start, end, location)
        .then((res) => {
          setList(res.minor_boxes);
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(() => dispatch(setLoader(false)));
    } else if (start === "Fecha inválida" && end === "Fecha inválida" && location) {
      let start = moment().format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      getFilterMinior(start, end, location)
        .then((res) => {
          setList(res.minor_boxes);
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(() => dispatch(setLoader(false)));
    } else if (location === undefined) {
      getMinorAll()
        .then((res) => {
          setList(res.minor_boxes);
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(() => dispatch(setLoader(false)));
    } else {
      dispatch(setLoader(false));
      notice("Datos incompletos", "warning");
    }
  };

  //obtener pdf
  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handlePDF = () => {
    let start = moment(filter.start_date).format("YYYY-MM-DD");
    let end = moment(filter.end_date).format("YYYY-MM-DD");
    let location;
    dispatch(setLoader(true));

    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });

    if (location && start && end) {
      getPDFMinior(start, end, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Datos incompletos", "warning");
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      dispatch(setLoader(false));
      notice("Datos incompletos", "warning");
    }
  };

  //Añadir
  const handleAdd = () => {
    dispatch(setLoader(true));
    const { operation_date, operation_type, concepto, amount, location_name } = newFilter;

    let location;

    locations.map((loc) => {
      if (loc.text === location_name) {
        location = loc.id;
      }
    });
    let date = moment(operation_date).format("YYYY-MM-DD");
    if (date && operation_type && concepto && amount && location) {
      getAddNewMinior(date, operation_type, concepto, amount, location)
        .then((res) => {
          setAdd(res);
          notice("Nuevo movimiento añadido", "success");
          // window.location.reload();
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })
        .finally(() => {
          handleClose();
          setNewFilter(defaultFilter);
          dispatch(setLoader(false));
        });
    } else {
      notice("Datos incompletos", "warning");
      dispatch(setLoader(false));
    }
  };

  //modal
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewFilter(defaultFilter);
  };
  return (
    <>
      <UtilInputs
        formValues={filter}
        onValuesChange={handleChange}
        inputValues={filtersForm(locations, havePermissionDate)}
        className="mb-3"
      />
      <div style={{ display: "flex", gap: 5, marginTop: 15, marginBottom: 15 }}>
        <Button color="primary" variant="contained" onClick={handleFilter}>
          Filtrar
        </Button>
        {permissions.includes("minbox:create") && (
          <Button color="primary" variant="contained" onClick={handleOpen}>
            Nuevo movimiento
          </Button>
        )}
        {permissions.includes("minbox:report") && (
          <Button color="primary" variant="contained" onClick={handlePDF}>
            Generar reporte
          </Button>
        )}
      </div>

      <FullTable title="Caja chica" data={list} columns={MinorColumns} />
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            outline: "2p solid red",
            width: "90%",
            margin: "auto",
            marginTop: 120,
            padding: 30,
            borderRadius: 10,
            background: "white",
          }}
        >
          <p>Nuevo movimiento</p>
          <UtilInputs
            formValues={newFilter}
            onValuesChange={handleChangeNew}
            inputValues={newFiltersForm(locations, havePermissionDate)}
            className="mb-3"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            style={{ marginRight: 5 }}
          >
            Cerrar
          </Button>
          <Button color="primary" variant="contained" onClick={handleAdd}>
            Guardar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MinorBox;
