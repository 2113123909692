export const tabsOptions = (role) => [
  { value: 0, label: "Clases" },
  role.name !== "Cliente" && { value: 1, label: "Tienda" },
  // {value: 2, label: "Cafeteria"},
];

// PACKAGE SALE DATA
export const defaultPackageSale = {
  payment_type: "monthly",
  other_fee: null,
  period: [],
  package: "",
  group_price: null,
  inscription: false,
  annuality: false,
  amount: 0,
  note: "",
};

export const packageSaleForm = (
  packages,
  paymentTypes = [],
  paymentType = false,
  periods = []
) => [
  {
    name: "payment_type",
    label: "Tipo de pago",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: paymentTypes,
  },
  {
    name: "package",
    label: "Clase",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: packages,
    disabled: paymentType !== "monthly",
  },
  {
    name: "period",
    label: "Fechas",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: periods,
    disabled: paymentType !== "monthly",
    multiple: true,
  },
  {
    name: "amount",
    label: "Monto",
    type: "number",
    width: "50%",
    variant: "outlined",
    isPrice: true,
    decimals: 2,
    disabled: true,
  },
  {
    name: "note",
    label: "Notas adicionales",
    type: "text",
    variant: "outlined",
    multiline: true,
    rows: 4,
    width: "100%",
  },
];

// SUMMARY DATA

export const defaultSummary = {
  nota: null,
  client: null,
  payment_groups: [], // packages
  products: [],
  other_fees: [], // packages
  note_discount: "",
  additional_discount_type: "",
  additional_discount: "0.00",
  no_reference: "",
  reference_file: null,
  direct_card: false,
  // stripe keys
  customer: "",
  payment_method: "",
  last4: "",
  store_name: "aquara",
};

export const summaryForm = (discount_type, note) => [
  {
    name: "nota",
    label: "Notas",
    type: "text",
    variant: "outlined",
    multiline: true,
    rows: 3,
    width: "100%",
  },
  {
    name: "note_discount",
    label: "Nota descuento",
    type: "text",
    variant: "outlined",
    multiline: true,
    rows: 3,
    width: "100%",
  },
  {
    name: "additional_discount_type",
    label: "Tipo de descuento",
    type: "dropdown",
    variant: "outlined",
    options: [
      { value: "percentage", text: "Porcentaje" },
      { value: "amount", text: "Fijo" },
    ],
    disabled: !note,
  },
  {
    name: "additional_discount",
    label: "Descuento",
    type: "number",
    variant: "outlined",
    isPercentage: discount_type === "percentage",
    isPrice: discount_type === "amount",
    decimals: 2,
    disabled: !discount_type,
  },
];

// PAYMENT METHOD TAB
export const defaultCard = {
  name: "",
  number: "",
  cvc: "",
  expiry: "",
  focus: "",
};

export const cardForm = [
  {
    name: "number",
    label: "Número de tarjeta",
    type: "text",
    variant: "outlined",
    mask: "9999 9999 9999 9999",
  },
  {
    name: "name",
    label: "Nombre del titular",
    type: "text",
    variant: "outlined",
  },
  {
    name: "expiry",
    label: "Fecha de expiración",
    type: "text",
    variant: "outlined",
    width: "70%",
    mask: "99/99",
  },
  {
    name: "cvc",
    label: "CVC",
    type: "text",
    variant: "outlined",
    width: "30%",
    mask: "999",
  },
  // {
  //   name: "save_card",
  //   label: "Guardar tarjeta para futuras compras",
  //   type: "switch",
  // },
];
