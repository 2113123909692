import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Grid, InputLabel, Select, FormControl, MenuItem, TextField, Button, Tabs, Tab } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from "formik";
import clsx from 'clsx';

import * as maint from "../../../../store/ducks/maint.duck";
import * as maintCRUD from "../../../../crud/maint.crud";

import { MantSolutions } from './MantSolutions';
import { MantPoolState } from './MantPoolState';
import ConfirmDialog from "../../../../utils/ConfirmDialog"
import notice from '../../../../utils/notice';

const MantBiweeklyDialog = ({mode, setDialog, biweekly_report, updateBiweeklyReports, pools, users, deleteBiweeklyReports}) => {
  const open = mode === 'editing' || mode === 'creating'
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({paddingRight: "2.5rem"});
  const [ tabValue, setTabValue ] = useState(0);
  const title = biweekly_report.id ? 'Editar reporte quincenal' : 'Nuevo reporte quincenal'

  function renderComponent(values, handleChange){
    let component
    switch (tabValue) {
      case 0:
        component = <MantSolutions values={values} handleChange={handleChange}/>
      break;
      case 1:
        component = <MantPoolState values={values} handleChange={handleChange}/>
      break;
      default: component = <MantSolutions />
    }
    return component
  }

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function create(values) {
    const report = {
      start_date: values.start_date,
      end_date: values.end_date,
      pool_id: values.pool,
      user_id: values.user,
      comments: values.comments,
      free_cl: values.free_cl,
      chloramines: values.chloramines,
      acid: values.acid,
      alkalinity: values.alkalinity,
      hardness: values.hardness,
      phosphate: values.phosphate,
      sdt: values.sdt,
      bomb_measure: values.bomb_measure,
      bomb_state: values.bomb_state,
      water_tank_thermo_measure: values.water_tank_thermo_measure,
      water_tank_thermo_state: values.water_tank_thermo_state,
      water_softener_measure: values.water_softener_measure,
      water_softener_state: values.water_softener_state,
      hydropneumatic_pressure_measure: values.hydropneumatic_pressure_measure,
      hydropneumatic_pressure_state: values.hydropneumatic_pressure_state,
      concentrated_chlorine_supply: values.concentrated_chlorine_supply,
      concentrated_acid_supply: values.concentrated_acid_supply,
      pool_structure_state: values.pool_structure_state,
      dispenser_state: values.dispenser_state,
    }
    maintCRUD.saveBiweeklyReport(report, updateBiweeklyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento guardado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  function update(values) {
    const report = {
      id: biweekly_report.id,
      //start_date: values.start_date,
      //end_date: values.end_date,
      pool_id: values.pool.id,
      user_id: values.user,
      comments: values.comments,
      free_cl: values.free_cl,
      chloramines: values.chloramines,
      acid: values.acid,
      alkalinity: values.alkalinity,
      hardness: values.hardness,
      phosphate: values.phosphate,
      sdt: values.sdt,
      bomb_measure: values.bomb_measure,
      bomb_state: values.bomb_state,
      water_tank_thermo_measure: values.water_tank_thermo_measure,
      water_tank_thermo_state: values.water_tank_thermo_state,
      water_softener_measure: values.water_softener_measure,
      water_softener_state: values.water_softener_state,
      hydropneumatic_pressure_measure: values.hydropneumatic_pressure_measure,
      hydropneumatic_pressure_state: values.hydropneumatic_pressure_state,
      concentrated_chlorine_supply: values.concentrated_chlorine_supply,
      concentrated_acid_supply: values.concentrated_acid_supply,
      pool_structure_state: values.pool_structure_state,
      dispenser_state: values.dispenser_state,
    }
    maintCRUD.updateBiweeklyReport(report, updateBiweeklyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento actualizado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  let firstDate = new Date(biweekly_report.start_date)
  firstDate.setMinutes(firstDate.getMinutes() + firstDate.getTimezoneOffset())
  let lastDate = new Date(biweekly_report.end_date)
  lastDate.setMinutes(lastDate.getMinutes() + lastDate.getTimezoneOffset())

  const confirmDeleteReport = () => {
    setOpenConfirm(false)
    maintCRUD.deleteBiweeklyReport(biweekly_report.id, deleteBiweeklyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento eliminado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  return (
    <Dialog
      open={open}
      fullWidth maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title"
    >
    <DialogTitle>
        <div className='modal-title'>
          {title}
          {mode !== 'creating' ? 
          <Button variant="outlined" onClick={() => setOpenConfirm(true)}>
            <DeleteOutlineIcon/>
          </Button>: null
          }
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <ConfirmDialog
          openConfirm={openConfirm} 
          setConfirm={() => setOpenConfirm(false)} 
          functionConfirm={() => confirmDeleteReport()}
        />
        <Formik
          initialValues={{
            start_date: (mode !== 'creating' ? firstDate : biweekly_report.report_date),
            end_date: (mode !== 'creating' ? lastDate : biweekly_report.report_date),
            pool: biweekly_report.pool,
            user: biweekly_report.user,
            comments: biweekly_report.comments,
            free_cl: biweekly_report.free_cl,
            chloramines: biweekly_report.chloramines,
            acid: biweekly_report.acid,
            alkalinity: biweekly_report.alkalinity,
            hardness: biweekly_report.hardness,
            phosphate: biweekly_report.phosphate,
            sdt: biweekly_report.sdt,
            bomb_measure: biweekly_report.bomb_measure,
            bomb_state: biweekly_report.bomb_state,
            water_tank_thermo_measure: biweekly_report.water_tank_thermo_measure,
            water_tank_thermo_state: biweekly_report.water_tank_thermo_state,
            water_softener_measure: biweekly_report.water_softener_measure,
            water_softener_state: biweekly_report.water_softener_state,
            hydropneumatic_pressure_measure: biweekly_report.hydropneumatic_pressure_measure,
            hydropneumatic_pressure_state: biweekly_report.hydropneumatic_pressure_state,
            concentrated_chlorine_supply: biweekly_report.concentrated_chlorine_supply,
            concentrated_acid_supply: biweekly_report.concentrated_acid_supply,
            pool_structure_state: biweekly_report.pool_structure_state,
            dispenser_state: biweekly_report.dispenser_state,
          }}
          validate={values => {
            const errors = {}
            if(!values.pool) errors.pool = 'Campo requerido'
            if(!values.user) errors.user = 'Campo requerido'

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading()
            biweekly_report.id ? update(values) : create(values)
          }}
        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <form
            noValidate={true}
            autoComplete='off'
            onSubmit={handleSubmit}
            className='kt-form'
          >
            <div className="form-group">
              <Grid container justify="flex-end" spacing={2}>
                <Grid item sm={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      disabled={mode !== 'creating'}
                      margin="normal"
                      id="start_date"
                      label="Fecha de inicio"
                      name='start_date'
                      format='yyyy/MM/dd'
                      value={values.start_date}
                      onChange={(date) => handleChange({target:{ value: date, name:'start_date'}})}
                      maxDate={values.end_date}
                      maxDateMessage="La fecha de inicio no debe superar la de fin"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      disabled={mode !== 'creating'}
                      margin="normal"
                      id="end_date"
                      label="Fecha de fin"
                      name='end_date'
                      format='yyyy/MM/dd'
                      value={values.end_date}
                      onChange={(date) => handleChange({target:{ value: date, name:'end_date'}})}
                      minDate={values.start_date}
                      minDateMessage="La fecha de fin no debe ser inferior a la de inicio"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <FormControl fullWidth margin='normal' error={Boolean(errors.pool)} disabled={mode !== 'creating'}>
                    <InputLabel htmlFor="pool-label">Alberca</InputLabel>
                      <Select
                        labelId="pool-label"
                        name='pool'
                        defaultValue={values.pool !== 0 ? values.pool.id : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                      {pools.data.map(pool => (
                        <MenuItem key={pool.id} value={pool.id}>{pool.name}</MenuItem>
                      ))}
                      </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl fullWidth margin='normal' disabled>
                    <InputLabel htmlFor="pool-dimension">Dimensiones</InputLabel>
                      <Select
                        labelId="pool-dimension"
                        value={typeof values.pool === 'object' ? values.pool.id : values.pool}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                      {pools.data.map(pool => (
                        <MenuItem key={pool.id} value={pool.id}>{pool.dimensions}</MenuItem>
                      ))}
                      </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl fullWidth margin='normal' disabled>
                    <InputLabel htmlFor="pool-capacity">Capacidad</InputLabel>
                    <Select
                      labelId="pool-capacity"
                      value={typeof values.pool === 'object' ? values.pool.id : values.pool}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                    {pools.data.map(pool => (
                      <MenuItem key={pool.id} value={pool.id}>{pool.capacity}</MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm ={3}>
                  <FormControl margin='normal' error={Boolean(errors.user)} >
                    <InputLabel htmlFor="responsable-label">Responsable</InputLabel>
                    <Select
                      labelId="responsable-label"
                      name='user'
                      defaultValue={values.user !== 0 ? values.user.id : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      >
                      {users.data.map(user => (
                        <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tabs
                      value={tabValue}
                      onChange={(e,value) => setTabValue(value)}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab label='Soluciones'/>
                      <Tab label='Estado de la alberca'/>
                    </Tabs>
                    {renderComponent(values, handleChange)}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="comments"
                      fullWidth
                      variant='outlined'
                      multiline
                      rowsMax="4"
                      label='Comentarios'
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className='btn-dialog'>
              <Button onClick={() => setDialog('closed')} color="primary">
                Cancelar
              </Button>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={loading}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                }
                )}`}
                style={loadingButtonStyle}
              >
                {biweekly_report.id ? 'Editar' : 'Crear'}
              </button>
            </div>
          </form>
        )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  biweekly_report: store.maint.biweekly_report,
  pool_types: store.config.pool_types,
  users: store.user.users
})

export default connect(mapStateToProps, maint.actions)(MantBiweeklyDialog)