import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import FullTable, { customSearchInputAuto } from "../../../common/components/FullTable";
import CustomButton from "../../../common/components/CustomButton";
import ClientDialog from "./ClientDialog";
import notice from "../../../utils/notice";
import Loading from "../../../utils/Loading";
import { columns, options } from "./utils/table_data";
import * as clients from "../../../store/ducks/clients.duck";
import * as clientsCRUD from "../../../crud/clients.crud";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../store/ducks/config.duck";
import {
  getFilterByStatus,
  getLocations,
  getPermissions,
  getPermissionsNew,
} from "../../../crud/config.crud";
import UtilInputs from "../../../common/components/UtilInputs";
import { defaultPDF, pdfForm } from "./utils/bajas_filter_data";
import { Backdrop, CircularProgress, Modal } from "@material-ui/core";

import moment from "moment";
import { setLoader } from "../../../store/ducks/loader.duck";

const Clients = ({
  clients,
  setClient,
  locations,
  levels,
  categories,
  groups,
  permit,
}) => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);
  const [responsibles, setResponsibles] = useState([]);
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating,
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loaderInput, setLoaderInput] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [permisos, setPermisos] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState(defaultPDF);
  const [locationsDrop, setLocationsDrop] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    getPermissionsNew(setPermisos).then((json) => {
      dispatch(actions.setPermissions(json));
      setPermisos(json);
    });
    changePage(page, rowPerPage);

    getLocations()
      .then((res) => {
        setLocationsDrop(
          res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id }))
        );
      })
      .catch(console.log);
  }, []);

  const searchResponsible = (query) => {
    setSearchTerm(query);
  };

  useEffect(() => {
    setLoaderInput(true);
    const delayDebounceFn = setTimeout(() => {
      clientsCRUD
        .getResponsiblesLite(setResponsibles, page, rowPerPage, searchTerm, setLoading)
        .then(() => setLoaderInput(false));
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDownloadPDF(pdfForm);
  };

  const handleChangePDF = ({ target }) => {
    const { name, value } = target;
    setDownloadPDF({ ...downloadPDF, [name]: value });
  };
  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte bajas";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setOpen(false);
  }

  const handleDownloadPdf = () => {
    let start = moment(downloadPDF.start_date).format("YYYY-MM-DD");
    let end = moment(downloadPDF.end_date).format("YYYY-MM-DD");
    let status = downloadPDF.status;
    let location;
    locationsDrop.map((loc) => {
      if (loc.text === downloadPDF.location_id) {
        location = loc.id;
      }
    });
    dispatch(setLoader(true));
    if (end && start && status && location) {
      getFilterByStatus(start, end, status, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Datos incompletos", "warning");
          }
        })
        .catch((err) => {
          notice("Ocurrio un error", "error");
        })

        .finally(() => {
          setDownloadPDF(pdfForm);
          dispatch(setLoader(false));
        });
    } else {
      dispatch(setLoader(false));
      notice("Datos incompletos", "warning");
    }
  };

  function openDialog(mode, id = null) {
    switch (mode) {
      case "creating":
        setClient(clientsCRUD.defaultClient);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setClient(clientsCRUD.defaultClient);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  }

  const headerNames = [
    { name: "full_name", download: true },
    { name: "number_of_clients", download: true },
    { name: "clients_string", download: true },
    { name: "location", download: true },
  ];

  const clientID = (id = null) => history.push(`/client/${id}`);
  const changePage = (page1, rowsPerPage1, action) => {
    setLoader(true);
    setPage(page1);
    clientsCRUD
      .getResponsiblesLite(setResponsibles, page1, rowsPerPage1, "")
      .then(console.log)
      .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
      .finally(() => setLoader(false));
  };

  const options = {
    responsive: "scrollMaxHeight",
    filter: true,
    serverSide: true,
    pagination: true,
    count: responsibles?.pagy?.count,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: [10, 20, 50],
    onRowClick: (data) => clientID(data[0]),

    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page + 1, tableState.rowsPerPage, action);
          break;
        case "changeRowsPerPage":
          changePage(tableState.page + 1, tableState.rowsPerPage, action);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customSearchRender: () => customSearchInputAuto(searchResponsible, loaderInput),
    downloadOptions: {
      filename: "Clientes.csv",
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const table = buildHead(headerNames) + buildBody(data);
      return table;
    },
  };

  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 999, height: "100%" }}>
        <CircularProgress />
      </Backdrop>
      {permit.responsiblesCreate && (
        <>
          <div className="text-right mb-4">
            {permissions.includes("discharges:report") && (
              <CustomButton
                title="Reporte de bajas"
                onClick={handleOpen}
                style={{ marginRight: 3 }}
              />
            )}
            <CustomButton title="Crear Cliente" onClick={() => openDialog("creating")} />
          </div>
        </>
      )}
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            width: "90%",
            margin: "auto",
            marginTop: 120,
            padding: 30,
            borderRadius: 10,
            background: "white",
          }}
        >
          <p>Reporte de bajas</p>
          <UtilInputs
            formValues={downloadPDF}
            onValuesChange={handleChangePDF}
            inputValues={pdfForm(locationsDrop)}
            className="mb-3"
          />
          <CustomButton title="Cerrar" onClick={handleClose} style={{ marginRight: 3 }} />
          <CustomButton
            title="Reporte"
            onClick={handleDownloadPdf}
            style={{ marginRight: 3 }}
          />
        </div>
      </Modal>
      <FullTable
        title="Clientes"
        data={responsibles?.responsibles}
        columns={columns}
        options={options}
      />
      <ClientDialog
        mode={dialog}
        setDialog={openDialog}
        locations={locations}
        clients={clients}
        levels={levels}
        categories={categories}
        groups={groups}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  loggedUser: store.auth.user.user,
  clients: store.clients.clients,
  client: store.clients.client,
  locations: store.config.locations,
  levels: store.config.levels,
  categories: store.config.categories,
  groups: store.config.group_prices,
});

export default connect(mapStateToProps, clients.actions)(Clients);
