import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";

import * as auth from "../../store/ducks/auth.duck";
import { forgotPassword } from "../../crud/auth.crud";

class ForgotPassword extends Component {
  state = { 
    isRequested: false,
    loading: false,
    loadingButtonStyle: {paddingRight: "2.5rem"}
  };

  enableLoading = () => {
    this.setState({
      loading: true,
      loadingButtonStyle: {paddingRight: "3.5rem"}
    })
  };

  disableLoading = () => {
    this.setState({
      isRequested: true,
      loading: false,
      loadingButtonStyle: {paddingRight: "2.5rem"},
    })
  };

  render() {
    const { intl } = this.props;
    const { isRequested, loading, loadingButtonStyle } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      // <div className={"kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper " + styles.outerDiv}>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h3>
            </div>

            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                this.enableLoading()
                forgotPassword({email: values.email})
                  .then(() => this.disableLoading())
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="email"
                      label="Correo"
                      margin="normal"
                      fullWidth={true}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link
                      to="/auth"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      style={{paddingTop:"13.5px", paddingBottom:"13px"}}
                    >
                      Atrás
                    </Link>

                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                      disabled={isSubmitting}
                    >
                      Recuperar
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      // </div>
    );
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ForgotPassword)
);
