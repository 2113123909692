import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  DialogContent,
  Chip,
} from "@material-ui/core";
import { Formik } from "formik";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import notice from "../../../utils/notice";
import UtilInputs from "../../../common/components/UtilInputs";
import SimpleText from "../../../common/components/SimpleText";

import * as products from "../../../store/ducks/products.duck";
import * as productsCRUD from "../../../crud/products.crud";

import { BASIC_URL } from "../../../crud/helpers/routes";
import { getStoreCategories } from "../../../crud/config.crud";
import { formProducts } from "./utils";
import { setLoader } from "../../../store/ducks/loader.duck";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const ProductDialog = ({ mode, setDialog, product, updateProduct }) => {
  const dispatch = useDispatch();
  const open = mode === "editing" || mode === "creating";
  const title = product.id ? "Editar producto" : "Nuevo producto";
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [categories, setCategories] = useState([]);
  const [modelValue, setModelValue] = useState("");
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState("");
  const [files, setFiles] = useState([]);
  const [filesCrop, setFilesCrop] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    minSize: 0,
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    getStoreCategories(setCategories);
  }, []);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const create = (values) => {
    const productCheck = {
      clave_sat: values.clave_sat,
      descripcion_sat: values.descripcion_sat,
      unidad: values.unidad,
      descrip_unidad: values.descrip_unidad,
      name: values.name,
      image: filesCrop,
      price: values.price,
      description: values.description,
      brand: values.brand,
      code: values.code,
      store_category_id: values.store_category_id,
    };
    productsCRUD
      .saveProduct(productCheck)
      .then((res) => {
        if (values.variants.length === 0) {
          updateProduct(res);
          notice("Producto guardado", "success");
          setDialog("closed");
          setCropData("");
          setFiles([]);
        } else {
          let variantsPromise = [];
          values.variants.forEach((v) =>
            variantsPromise.push(
              productsCRUD.createVariant({
                product_id: res.product.id,
                name: v.name,
              })
            )
          );
          Promise.all(variantsPromise)
            .then((responses) => {
              updateProduct(res);
              notice("Producto guardado", "success");
              setDialog("closed");
              setCropData("");
              setFiles([]);
            })
            .catch((e) => {
              notice(
                e.errors && Array.isArray(e.errors)
                  ? e.errors
                  : "Ha ocurrido un error con su solicitud"
              );
            });
        }
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurrido un error con su solicitud"
        )
      )
      .finally(disableLoading);
  };

  const update = (values) => {
    const productCheck = {
      clave_sat: values.clave_sat,
      descripcion_sat: values.descripcion_sat,
      unidad: values.unidad,
      descrip_unidad: values.descrip_unidad,
      id: product.id,
      name: values.name,
      image: filesCrop,
      price: values.price,
      description: values.description,
      brand: values.brand,
      code: values.code,
      store_category_id: values.store_category_id,
      variants: values.variants,
    };

    productsCRUD
      .updateProduct(productCheck, updateProduct)
      .then((res) => {
        notice("Producto actualizado", "success");
        setDialog("closed");
        setCropData("");
        setFiles([]);
        updateProduct(res);
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurrido un error con su solicitud"
        )
      )
      .finally(disableLoading);
  };

  const thumbs = files.map((file) => (
    <Cropper
      style={{ height: 250, width: 250 }}
      zoomTo={0}
      initialAspectRatio={1 / 1}
      preview=".img-preview"
      src={file.preview}
      viewMode={1}
      minCropBoxHeight={5}
      minCropBoxWidth={5}
      background={true}
      cropBoxResizable={false}
      responsive={false}
      dragMode="none"
      autoCropArea={1}
      checkOrientation={false}
      onInitialized={(instance) => {
        setCropper(instance);
      }}
      guides={true}
    />
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      urltoFile(cropper.getCroppedCanvas().toDataURL(), "image", "image/png").then(
        function(file) {
          setFilesCrop(file);
        }
      );
    }
  };

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function(res) {
        return res.arrayBuffer();
      })
      .then(function(buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const deleteImg = () => {
    setCropData("");
    setFiles([]);
  };

  const closedDialog = () => {
    setDialog("closed");
    setCropData("");
    setFiles([]);
  };

  const handleCreateVariant = (id) => {
    dispatch(setLoader(true));
    return productsCRUD
      .createVariant({ product_id: id, name: modelValue })
      .then((res) => {
        notice("Variante agregada al producto", "success");
        setModelValue("");
        return res;
      })
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurrido un error guardando la variante"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleAddVariant = async (setterFunction, variants) => {
    if (product.id) {
      const { variant } = await handleCreateVariant(product.id);
      setterFunction("variants", [...variants, variant]);
      setModelValue("");
    } else {
      if (!variants.find((v) => v.name === modelValue)) {
        setterFunction("variants", [...variants, { name: modelValue }]);
        setModelValue("");
      }
    }
  };

  const handleDeleteVariant = (id) => {
    dispatch(setLoader(true));
    return productsCRUD
      .deleteVariant(id)
      .then((res) => notice("Variante borrada del producto", "success"))
      .catch((e) =>
        notice(
          e.errors && Array.isArray(e.errors)
            ? e.errors
            : "Ha ocurrido un error borrando la variante"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleRemoveVariants = async (setterFunction, variants, variant) => {
    if (product.id) {
      await handleDeleteVariant(variant.id);
      let newVariants = variants.filter((v) => v.name !== variant.name);
      setterFunction("variants", newVariants);
    } else {
      let newVariants = variants.filter((v) => v.name !== variant.name);
      setterFunction("variants", newVariants);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            clave_sat: product.clave_sat,
            descripcion_sat: product.descripcion_sat,
            unidad: product.unidad,
            descrip_unidad: product.descrip_unidad,
            name: product.name,
            description: product.description,
            price: product.price,
            image: product.image,
            brand: product.brand,
            code: product.code,
            store_category_id: product?.id
              ? product.store_category?.id
              : product.store_category_id,
            variants: product.variants,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.clave_sat) errors.clave_sat = "Campo Requerido";
            // if (!values.descripcion_sat) errors.descripcion_sat = "Campo Requerido";
            // if (!values.unidad) errors.unidad = "Campo Requerido";
            // if (!values.descrip_unidad) errors.descrip_unidad = "Campo Requerido";
            if (!values.name) errors.name = "Campo Requerido";
            if (!values.price) errors.price = "Campo Requerido";
            if (!values.store_category_id) errors.store_category_id = "Campo Requerido";
            if (!values.brand) errors.brand = "Campo Requerido";
            if (!values.code) errors.code = "Campo Requerido";

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (filesCrop instanceof File) {
              values.image = filesCrop;
            }
            enableLoading();
            product.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      alignContent="center"
                    >
                      <Grid item>
                        {product.image && files.length === 0 ? (
                          <section className="container">
                            <div
                              style={{ cursor: "pointer" }}
                              {...getRootProps({ className: "dropzone" })}
                            >
                              <input {...getInputProps()} />
                              <img
                                style={{ width: "250px", height: "250px" }}
                                src={BASIC_URL + product.image}
                                alt={product.name}
                              />
                            </div>
                          </section>
                        ) : files.length > 0 && cropData ? (
                          <div>
                            <img
                              style={{ width: "250px", height: "250px" }}
                              src={cropData}
                              alt="cropped"
                            />
                            <div>
                              <Button
                                style={{ marginTop: "13px" }}
                                color="primary"
                                onClick={deleteImg}
                              >
                                Quitar Imagen
                              </Button>
                            </div>
                          </div>
                        ) : cropData ? (
                          <div>
                            <aside style={thumbsContainer}>{thumbs}</aside>
                            <Button
                              style={{ marginTop: "13px" }}
                              color="primary"
                              onClick={getCropData}
                            >
                              Cortar Imagen
                            </Button>
                          </div>
                        ) : files.length === 0 ? (
                          <section
                            style={{
                              border: "1px dashed grey",
                              padding: "50px",
                            }}
                            className="container"
                          >
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <p style={{ padding: "0 33%", cursor: "pointer" }}>
                                <PhotoCameraOutlinedIcon
                                  fontSize="large"
                                  color="primary"
                                />
                              </p>
                              <p style={{ color: "#3f51b5", cursor: "pointer" }}>
                                Agregar Foto
                              </p>
                            </div>
                          </section>
                        ) : files.length > 0 && cropData ? (
                          <div>
                            <img
                              style={{ width: "250px", height: "250px" }}
                              src={cropData}
                              alt="cropped"
                            />
                            <div>
                              <Button
                                style={{ marginTop: "13px" }}
                                color="primary"
                                onClick={deleteImg}
                              >
                                Quitar Imagen
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <aside style={thumbsContainer}>{thumbs}</aside>
                            <Button
                              style={{ marginTop: "13px" }}
                              color="primary"
                              onClick={getCropData}
                            >
                              Cortar Imagen
                            </Button>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <UtilInputs
                  width={"33%"}
                  formValues={values}
                  onValuesChange={handleChange}
                  inputValues={formProducts(categories)}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
                <Grid container spacing={2} className="mt-4">
                  <Grid item xs={6}>
                    <SimpleText
                      label="Modelo"
                      name="model"
                      value={modelValue}
                      onBlur={handleBlur}
                      onChange={(e) => setModelValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ placeSelf: "end" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleAddVariant(setFieldValue, values.variants)}
                      disabled={!modelValue}
                    >
                      + Agregar modelo
                    </Button>
                  </Grid>
                  {values.variants.length > 0 &&
                    values.variants.map((variant) => (
                      <Grid item xs={12} sm={6} md={2}>
                        <Chip
                          name="variants"
                          key={variant.name}
                          label={variant.name}
                          onDelete={() =>
                            handleRemoveVariants(setFieldValue, values.variants, variant)
                          }
                          color="primary"
                          style={{ marginRight: "15px", padding: "0 10px" }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => closedDialog()} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {mode === "editing" ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  product: store.products.product,
});

export default connect(mapStateToProps, products.actions)(ProductDialog);
