import React, { useState } from "react";
import { connect } from "react-redux";
import {Paper, Tabs, Tab} from '@material-ui/core'

import {SStatusesTable} from "./SStatusesTable";
import {STypesTable} from "./STypesTable";
import { SSourcesTable } from "./SSourcesTable";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";

function ConfiguracionSuggestions({suggestion_types, suggestion_statuses, suggestion_sources, setSuggestionSource, setSuggestionType, setSuggestionStatus, user, permit}) {

  const [ tabValue, setTabValue ] = useState(0);

  function renderComponent(){
    let component
    switch (tabValue) {
      case 0:
        component = <SStatusesTable suggestion_statuses={suggestion_statuses} configCRUD={configCRUD} setSuggestionStatus={setSuggestionStatus} permit={permit} />
      break;
      case 1: 
        component = <STypesTable configCRUD={configCRUD} suggestion_types={suggestion_types} setSuggestionType={setSuggestionType} permit={permit} />
      break;
      case 2:
        component = <SSourcesTable suggestion_sources={suggestion_sources} configCRUD={configCRUD} setSuggestionSource={setSuggestionSource} permit={permit}/>
      break;
      default:
        component = <SStatusesTable suggestion_statuses={suggestion_statuses} configCRUD={configCRUD} setSuggestionStatus={setSuggestionStatus} permit={permit} />
    }
    return component
  }

  return (
    <>
      <Paper style={{flexGrow: 1, marginBottom: 20}}>
        <Tabs
          value={tabValue}
          onChange={(e,value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered>

          {permit.suggestionsStatuses && <Tab label="Estados"/>}
          {permit.suggestionsTypes && <Tab label="Tipos"/>}
          {permit.suggestionsSources && <Tab label="Fuentes"/>}
        </Tabs>
      </Paper>
      {renderComponent()}
      
  </>
  )

}

const mapStateToProps = store => ({
  suggestion_types: store.config.suggestion_types,
  suggestion_statuses: store.config.suggestion_statuses,
  suggestion_sources: store.config.suggestion_sources,
  user: store.auth.user.user,
});

export default connect(mapStateToProps, config.actions)(ConfiguracionSuggestions)
