import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, useMediaQuery } from "@material-ui/core";
import FullTable from "../../../common/components/FullTable";
import UtilInputs from "../../../common/components/UtilInputs";
import DownloadInvoiceModal from "./components/DownloadInvoiceModal";
import CustomButton from "../../../common/components/CustomButton";
import InvoiceModal from "./components/InvoiceModal";
import notice from "../../../utils/notice";
import { customOptions, defaultFilter, IncomeColumns, incomeFilters } from "./utils";
import { getLocations } from "../../../crud/config.crud";
import {
  getIncomes,
  getInvoicePDF,
  getQuickbooksAuth,
  getIncomesURLString,
  getQuickbooksURL,
  getIncomesURLWithLocation,
  getIncomesByTypeClient,
  getIncomesByUbication,
  getIncomeReportPdf,
  getIncomesFilter,
} from "../../../crud/incomes.crud";
import { downLoaderWithURL } from "../../../utils/data_formatters";
import { setLoader } from "../../../store/ducks/loader.duck";
import { objectDateFormat } from "../../../utils/dates";
import moment from "moment";
import { useSelector } from "react-redux";

const Incomes = () => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  /* A hook that returns true if the screen is smaller than sm. */
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  /* A hook that sets the state of the filter object to the value of the input field. */
  const [filter, setFilter] = useState(defaultFilter);
  const [locations, setLocations] = useState([]);
  const [list, setList] = useState([]);
  const [downloadInvoice, setDownloadInvoice] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [successdBtn, setSuccessBtn] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);

  let urlString = location.search;
  let code = urlString.substring(urlString.indexOf("=") + 1, urlString.indexOf("&")); //importante
  let remain1 = urlString.replace(code, "");
  let deleteStr1 = remain1.replace("?code=&state", "");
  //state
  let stateStr = deleteStr1.substring(
    deleteStr1.indexOf("=") + 1,
    deleteStr1.indexOf("&")
  );
  let remain2 = deleteStr1.replace(stateStr, "");
  let deleteStr2 = remain2.replace("=&realmId=", "");
  //realmid
  let realmid = deleteStr2;

  //BOTON
  useEffect(() => {
    if (location.search.includes("code=")) {
      setSuccessBtn(true);
    }
  }, [location.search]);

  /**
   * It gets the URL of the invoice, opens it in a new tab, and redirects the user to the incomes page
   */
  const downloadPdf = (res) => {
    const dataBase64 = res?.file;
    const linkSource = `${dataBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = "Factura.pdf";
    downloadLink.href = linkSource;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;
    downloadLink.click();
  };
  let url = location.pathname;
  const handleInvoice = () => {
    dispatch(setLoader(true));

    //click al btn factura de mostrador
    if (url !== "" && urlString !== "") {
      let idLocation;

      locations.map((local) => {
        if (local.text === filter.client_location) {
          idLocation = local.id;
        }
      });
      if (idLocation) {
        getIncomesURLWithLocation(code, stateStr, realmid, idLocation)
          .then((res) => {
            downloadPdf(res);
            //download pdf with location
          })
          .catch((e) =>
            notice(
              Array.isArray(e.Error)
                ? e.Error.map(({ title }) => title)
                : "Error con el servicio"
            )
          )
          .finally(() => {
            dispatch(setLoader(false));
            history.push("/ingresos");
            setSuccessBtn(false);
          });
      } else {
        getIncomesURLString(code, stateStr, realmid)
          .then((res) => {
            //download pdf
            downloadPdf(res);
          })
          .catch((e) =>
            notice(
              Array.isArray(e.Error)
                ? e.Error.map(({ title }) => title)
                : "Error con el servicio"
            )
          )
          .finally(() => {
            dispatch(setLoader(false));
            history.push("/ingresos");
            setSuccessBtn(false);
          });
      }
    } else {
      getQuickbooksURL()
        .then((res) => {
          window.open(res.url, "_blank");
          setSuccessBtn(true);
        })
        .catch((e) =>
          notice(
            Array.isArray(e.Error)
              ? e.Error.map(({ title }) => title)
              : "Error con el servicio"
          )
        )
        .finally((e) => {
          setSuccessBtn(false);
        });
    }
  };
  /**
   * It fetches the incomes from the server and then filters them.
   */
  const handleFilterData = () => {
    dispatch(setLoader(true));
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD"); //fecha innicio
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD"); //fecha fin
    let client = filter.client_name; //cliente
    let typeClient = filter.type_client;
    let location = filter.client_location; //ubicacion
    let locationId;
    locations.map((local) => {
      if (local.text === filter.client_location) {
        locationId = local.id;
      }
    });
    let factura = filter.type_factura;
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    if (
      client ||
      location ||
      typeClient ||
      (end && start) ||
      (end === "" && start === "") ||
      factura ||
      locationId
    ) {
      getIncomesFilter(client, location, typeClient, end, start, factura, locationId)
        .then(({ incomes }) => {
          setList(incomes);
        })
        .catch(console.log)
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleClearFilterData = () => {
    dispatch(setLoader(true));

    setFilter({
      ...filter,
      start_date: null,
      end_date: null,
      type_client: null,
      client_location: null,
      type_factura: null,
      client_name: "",
    });
    getIncomes()
      .then(({ incomes }) => {
        setList(incomes);
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };
  const fetchIncomes = () => {
    getIncomes()
      .then(({ incomes }) => {
        handleFilter(incomes);
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    fetchIncomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /*  A hook that is called when the component is mounted.
   *   for loading locations for filter from server.
   */
  useEffect(() => {
    dispatch(setLoader(true));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });

        setLocations(res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id })));
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  /**
   * `handleChange` is a function that takes an event object as an argument, and then sets the state of
   * the filter object to the value of the input field
   */
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  /**
   * It filters the list based on the filter object
   * @param oList - The original list of items
   */
  const handleFilter = (oList) => {
    let filteredList = oList;

    if (filter.month) {
      filteredList = filteredList.filter(
        (item) =>
          new Date(objectDateFormat(item.date))?.getMonth() === filter.month?.getMonth()
      );
    }
    if (filter.year) {
      filteredList = filteredList.filter(
        (item) =>
          new Date(objectDateFormat(item.date))?.getFullYear() ===
          filter.year?.getFullYear()
      );
    }
    if (filter.type_client) {
      filteredList = filteredList.filter(
        (item) => item.type_client === filter.type_client
      );
    }
    if (filter.client_location) {
      filteredList = filteredList.filter(
        (item) => item.client_location === filter.client_location
      );
    }
    setList(filteredList);
  };

  const handleOpenModalInvoice = (invoiceData) => {
    setInvoiceModal(true);
    setInvoiceData(invoiceData);
  };
  const loadInvoice = (id_payment) => {
    dispatch(setLoader(true));
    getQuickbooksAuth(`${location.search}&payment_id=${id_payment}`)
      .then((res) => {
        history.push("/ingresos");
        notice("Se ha enviado la factura al correo proporcionado", "success");
      })
      .catch((e) =>
        notice(
          e.error && Array.isArray(e.error)
            ? e.error.map((msg) => msg.title)
            : "Error en el sistema, por favor vuelva a solicitar la factura"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleInvoicePDF = (id_responsible, quickbooks_id) => {
    dispatch(setLoader(true));
    getInvoicePDF(id_responsible, quickbooks_id, location.search)
      .then((res) => {
        notice("Se ha descargado la factura", "success");
        downLoaderWithURL(res.file, `Cliente-${id_responsible}.pdf`);
      })
      .catch((e) =>
        notice(
          e.error && Array.isArray(e.error)
            ? e.error.map((msg) => msg.title)
            : "Error en el sistema, por favor vuelva a solicitar la factura"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  //BLOB
  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.open(url);

    window.URL.revokeObjectURL(url);
  }
  //REPORTE DE INGRESOS
  const getIncomeReport = () => {
    dispatch(setLoader(true));
    let start = moment(filter.start_date).format("YYYY-MM-DD");
    let end = moment(filter.end_date).format("YYYY-MM-DD");
    let location;
    let typeClient = filter.type_client;
    locations.map((local) => {
      if (local.text === filter.client_location) {
        location = local.id;
      }
    });
    if (
      location &&
      start !== "Fecha inválida" &&
      end !== "Fecha inválida" &&
      typeClient
    ) {
      getIncomeReportPdf(start, end, location, typeClient)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            // notice("Datos incompletos", "warning");
            notice("Ocurrio un error", "error");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(() => dispatch(setLoader(false)));
    } else {
      dispatch(setLoader(false));
      notice("Datos incompletos", "warning");
    }
  };
  return (
    <>
      <div
        className={`pb-4 px-2 d-flex flex-${
          mobileView ? "column" : "column"
        } justify-content-${mobileView ? "center" : "between"} align-items-${
          mobileView ? "center" : "end"
        }`}
        style={{ gap: 20 }}
      >
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={incomeFilters(locations)}
        />
        <div style={{ display: "flex" }}>
          <CustomButton title="Filtrar" onClick={handleFilterData} />
          <CustomButton
            title="Limpiar Filtros"
            className="mx-3"
            onClick={handleClearFilterData}
          />
          {permissions.includes("incomes:preview_report") && (
            <CustomButton
              title="Reporte de ingresos"
              className="mx-3"
              onClick={getIncomeReport}
            />
          )}
          {permissions.includes("incomes:global_invoice") && (
            <CustomButton
              title="Factura de mostrador"
              onClick={handleInvoice}
              disabled={disabledButton}
              className="mx-3"
              style={{
                width: 175,
                background: `${successdBtn ? "#28a745" : "#5867dd"} `,
                color: "white",
              }}
            />
          )}
        </div>
      </div>
      <FullTable
        title="Ingresos"
        data={list}
        columns={IncomeColumns(
          setDownloadInvoice,
          handleOpenModalInvoice,
          loadInvoice,
          handleInvoicePDF,
          location.search,
          permissions
        )}
        options={customOptions}
      />
      <DownloadInvoiceModal open={downloadInvoice} setOpen={setDownloadInvoice} />
      <InvoiceModal
        open={invoiceModal}
        setOpen={setInvoiceModal}
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
      />
    </>
  );
};

export default Incomes;
