import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import { newExpectation, updateExpectation } from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../../../store/ducks/loader.duck";

const ExpectationDialog = ({ mode, setDialog, idEdit, setUpdateList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const open = mode === "editing" || mode === "creating";
  const title = idEdit.value ? "Editar expectativa" : "Nueva expectativa";
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  const update = (values) => {
    dispatch(setLoader(false));
    let id = idEdit.value;
    updateExpectation(values, id)
      .then((res) => {
        notice("La expectativa se actualizo correctamente", "success");
        setUpdateList("new");
      })
      .catch(() => notice("Ocurrio un error", "error"))
      .finally(() => {
        dispatch(setLoader(false));
        setDialog("closed");
        disableLoading();
      });
  };
  const create = (values) => {
    dispatch(setLoader(false));
    newExpectation(values)
      .then((res) => {
        notice("La expectativa se creo correctamente", "success");
        setUpdateList("update");
      })
      .catch(() => notice("Ocurrio un error", "error"))
      .finally(() => {
        dispatch(setLoader(false));
        setDialog("closed");
        disableLoading();
      });
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: idEdit.text,
            description: idEdit.description,
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            idEdit.value ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container>
                  <TextField
                    label="Nombre"
                    margin="normal"
                    className="kt-width-full"
                    //   disabled={disableTexts}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name || ""}
                  />
                  <TextField
                    label="Descripción"
                    margin="normal"
                    className="kt-width-full"
                    //   disabled={disableTexts}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description || ""}
                  />
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {idEdit.value ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ExpectationDialog;
