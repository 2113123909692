export const baja_columns = [
  {
    name: "discharge_date",
    label: "Fecha de baja",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "discharge_motive",
    label: "Motivo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "discharge_amount",
    label: "Adeudo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "discharge_details",
    label: "Detalles de adeudos",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "discharge_kind",
    label: "Tipo de baja",
    options: {
      filter: true,
      sort: true,
    },
  },
];
export const defaultParcial = {
  description: "",
};

export const parcial = [
  {
    name: "description",
    label: "Motivo de baja",
    type: "text",
    variant: "filled",
  },
];

export const defaultTotal = {
  description: "",
};

export const total = [
  {
    name: "description",
    label: "Motivo de baja",
    type: "text",
    variant: "filled",
  },
];
