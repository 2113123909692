import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, TextField, Dialog, DialogContent, DialogTitle, Grid,  Select,MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { Formik } from "formik";
import clsx from "clsx";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";
import notice from "../../../../utils/notice";

function CategoryDialog({ mode, category, setDialog, updateStoreCategories }) {
    
    const open = mode === 'edit' || mode === 'create'
    const title = category.id ? "Editar categoría" : "Nueva categoría"
    const [loading, setLoading] = useState(false)
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({ paddingRight: "2.5rem" })

    const store = [
        { value: false, label: 'Cafetería' },
        { value: true, label: 'Tienda' },
    ]

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    function create(values) {     
        configCRUD.saveStoreCategory(values,updateStoreCategories)
        .then(() => {
          setDialog('closed')
          notice("Categoria guardada", "success")
        })
        .catch(e => notice(e.join('\n') || "Error con su solicitud"))
        .finally(disableLoading)
    }

    function update(values) {
        configCRUD.updateStoreCategory(values, updateStoreCategories)
        .then(() => {
          setDialog('closed')
          notice("Categoria actualizada", "success")
        })
        .catch(e => notice(e.join('\n') || "Error con su solicitud"))
        .finally(disableLoading)
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={() => setDialog("closed")}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        id: category.id,
                        name: category.name,
                        is_store: category.is_store
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = "Campo requerido"
                        }
                                                
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {                    
                        enableLoading();
                        category.id ? update(values) : create(values);
                    }}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit}
                            className="kt-form"
                        >                            
                            <div className="form-group">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Nombre"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="name"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal' >
                                            <InputLabel htmlFor="simple-select-label">Punto de venta</InputLabel>
                                            <Select                                                
                                                name='is_store'
                                                value={values.is_store}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {store.map(a => (
                                                    <MenuItem key={a.value} value={a.value}>
                                                        {a.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="btn-dialog">
                                <Button onClick={() => setDialog("closed")} color="primary">
                                    Cancelar
                                </Button>
                                <button
                                    id="kt_login_signin_submit"
                                    type='submit'
                                    disabled={loading}
                                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                        {
                                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                                        }
                                    )}`}
                                    style={loadingButtonStyle}
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = store => ({
    category: store.config.store_category
});

export default connect(mapStateToProps, config.actions)(CategoryDialog)