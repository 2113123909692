import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import CustomButton from "../../../../common/components/CustomButton";
import IconButton from "../../../../common/components/IconButton";
import UtilInputs from "../../../../common/components/UtilInputs";
import notice from "../../../../utils/notice";
import { defaultStudentGroup, studentGroupForm } from "../utils/InfoSchedule_data";
import * as configCRUD from "../../../../crud/config.crud";
import { deleteGroupByClient } from "../../../../crud/clients.crud";

const InfoMeetSchedule = ({ group, students, close = () => {} }) => {
  const [addSt, setAddSt] = useState(false);
  const [selectedGroupIdx, setSelectedGroupIdx] = useState("");
  const [studentGroup, setStudentGroup] = useState(defaultStudentGroup);
  const [groupSelect, setGroupSelect] = useState([]);

  const handleChange = ({ target }) =>
    setStudentGroup({ ...studentGroup, [target.name]: target.value });

  const handleSelectGroup = (index, group_schedule) => {
    setGroupSelect(group_schedule);
    selectedGroupIdx === index ? setSelectedGroupIdx("") : setSelectedGroupIdx(index);
  };

  const handleRemoveStudent = (group, student, i) => {
    let scheduleId = group.id;
    let studentId = student.id;
    let priceId = student.group_price_id;
    deleteGroupByClient(priceId, scheduleId, studentId)
      .then((res) => {
        if (res.status === 200) {
          notice("Estudiante eliminado correctamente", "success");
        } else {
          notice("No se pudo eliminar el estudiante", "error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setStudentGroup(defaultStudentGroup);
        close();
      });
  };
  const update = () => {
    let data = {
      schedule: studentGroup.days.map((id) => ({ id })),
      student_id: studentGroup.student?.id,
    };
    configCRUD
      .saveStudent(data, console.log)
      .then((res) => {
        if (res.status === 422) {
          notice("Este grupo ya no tiene cupo disponible", "error");
        } else if (res.status === 200) {
          notice("Alumno agregado con exito", "success");
        }
        setStudentGroup(defaultStudentGroup);
        close();
      })
      .catch((e) =>
        notice(Array.isArray(e) ? e?.join("\n") : "Ha ocurrido un error con su solicitud")
      );
  };
  return (
    <>
      <CustomButton
        title={addSt ? "Regresar" : "+ Agregar alumno"}
        onClick={() => setAddSt(!addSt)}
        className="mb-3"
      />
      {addSt ? (
        <>
          <UtilInputs
            width={400}
            inputValues={studentGroupForm(
              students.map((st) => ({
                ...st,
                value: st.id,
                text: `${st.name} ${st.last_name} - ${
                  st.level === "SN" ? "Sin Nivel" : st.level
                } - ${st.age}`,
              })),
              group.group_schedules.map((day) => ({
                ...day,
                value: day.id,
                text: day.day,
                disabled: day.free_places === 0 ? true : false,
              })),
              update
            )}
            formValues={studentGroup}
            onValuesChange={handleChange}
          />
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {group?.group_schedules?.map((group_schedule, i) => (
              <div
                key={group_schedule.id}
                onClick={() => handleSelectGroup(i, group_schedule)}
                className={`w-100 text-${
                  selectedGroupIdx === i ? "white" : "dark"
                } d-flex justify-content-between align-items-center rounded p-3 mb-2`}
                style={{
                  border: "1px dashed #bebebe",
                  cursor: "pointer",
                  backgroundColor: selectedGroupIdx === i ? "#001979" : "#FFFFFF",
                  maxHeight: 67,
                }}
              >
                <div className="d-flex align-items-center">
                  <div
                    className={`rounded-circle mr-3`}
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: selectedGroupIdx === i ? "#0cc740" : "#737683",
                    }}
                  />
                  <div>
                    {group_schedule.day}
                    <br />
                    {group_schedule.start_time} - {group_schedule.end_time}
                  </div>
                </div>
                <div>
                  Cupo: {group_schedule.students.number}/{group.availability}
                </div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedGroupIdx.toString() ? (
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">No Socio.</TableCell>
                      <TableCell align="left">Nombre</TableCell>
                      <TableCell align="left">Nivel</TableCell>
                      <TableCell align="left">Edad</TableCell>
                      <TableCell align="left">Accion</TableCell>
                    </TableRow>
                  </TableHead>
                  {group.group_schedules[selectedGroupIdx]?.students?.data ? (
                    <TableBody>
                      {group.group_schedules[selectedGroupIdx]?.students?.data?.map(
                        (student, i) => (
                          <TableRow>
                            <TableCell align="left">
                              {student.membership_number}
                            </TableCell>

                            <TableCell align="left">{student.name}</TableCell>
                            <TableCell align="left">
                              {student.level ? student.level : "Sin nivel"}
                            </TableCell>
                            <TableCell align="left">
                              {student.age === 0 ? "" : student.age}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() =>
                                  handleRemoveStudent(
                                    group.group_schedules[selectedGroupIdx],
                                    student,
                                    i
                                  )
                                }
                                Icon={Delete}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    "No hay Alumnos en este Horario de clases"
                  )}
                </Table>
              </Paper>
            ) : (
              "Por favor seleccione un grupo"
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InfoMeetSchedule;
