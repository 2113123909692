import notice from "../utils/notice";
import {
  BASE_URL,
  CLIENTS_URL,
  CLIENTS_WPAG_URL,
  GROUPS_URL,
  PAYMENTS_URL,
  RESPONSIBLE_URL,
  RESPONSIBLE_LITE_URL,
  DISCHARGES_URL,
  CORTES_URL,
} from "./helpers/routes";
import {
  getMultipartParams,
  getParams,
  handleError,
  handleErrorClient,
  handleResponse,
} from "./helpers/validate";

export const defaultClient = {
  responsible: {
    name: "",
    last_name: "",
    last_name_2: "",
    phone: "",
    landline: "",
    email: "",
    membership_number: "",
    isStudent: false,
    accept: false,
    country: "México",
    state: "",
    postal_code: "",
    street_name: "",
    suburb: "",
    number: "",
    district: "",
  },
  clients: [],
  main_contact: {
    email: "",
    code: "",
    cellphone: "",
    landline: "",
    postal_code: "",
  },
  invoice_information: {
    invoice_person_type: "",
    name: "",
    street: "",
    interior_number: "",
    outdoor_number: "",
    suburb: "",
    municipality: "",
    country: "",
    state: "",
    postal_code: "",
    email: "",
    rfc: "",
    locality: "",
    regimen_fiscal: "",
    cfdi_use: "",
  },
  share_contact: true,
};
//alumnos nuevos
export const getNewStudentList = async (ubication, end, start) => {
  let fechaQueryStart = start ? `&date_start=${start}` : "";
  let fechaQueryEnd = end ? `&date_end=${end}` : "";
  let locationIdQuery = ubication ? `&location_id=${ubication}` : "";
  let url = `inscritos_new?${fechaQueryStart + fechaQueryEnd + locationIdQuery}`;
  url = url.slice(0, 14) + url.slice(15);
  return fetch(
    //aquara-api-staging.herokuapp.com/api/v1/discharges/inscritos_new?location_id=22&date_start=2022-09-12&date_end=2022-09-15
    `${DISCHARGES_URL}/${url}`,
    getParams("GET")
  )
    .then((response) => handleResponse(response))
    .catch(handleErrorClient);
};

//rporte alumnos nuevos
export const getNewStudentPDF = async (ubication, end, start) => {
  let fechaQueryStart = start ? `&date_start=${start}` : "";
  let fechaQueryEnd = end ? `&date_end=${end}` : "";
  let locationIdQuery = ubication ? `&location_id=${ubication}` : "";
  let url = `reporte_alumnos.pdf?${fechaQueryStart + fechaQueryEnd + locationIdQuery}`;
  url = url.slice(0, 20) + url.slice(21);
  return fetch(`${CORTES_URL}/${url}`, getParams("GET")).catch(handleError);
};

//bajas
export const getBajaParcial = async (id, motivo) => {
  return fetch(
    `${DISCHARGES_URL}/baja_parcial?client_id=${id}&motivo=${motivo}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
export const getBajaTotal = async (id, motivo) => {
  return fetch(
    `${DISCHARGES_URL}/baja_total?client_id=${id}&motivo=${motivo}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
export const getReactivar = async (id) => {
  return fetch(`${DISCHARGES_URL}/reactivar_parcial?client_id=${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleErrorClient);
};
//validar emial
export const getValidateEmail = async (email) => {
  return fetch(`${BASE_URL}responsibles/validate_email?email=${email}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleErrorClient);
};
//obtener horas
export const getScheduleByDay = async (day, id) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?&day=${day}&category_id=${id}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
  // return fetch(
  //     `${BASE_URL}groups/filter_groups_day?&day=${day}&category_id=${id}`,
  //     getParams('GET')
  // )
  //     .then(handleResponse)
  //     .catch(handleErrorClient);
};

//obtener instructores por dia, hora y categoria
export const getAllInstructors = async (day, id, time) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?day=${day}&start_time=${time}&category_id=${id}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

export const getAllInstructorsByLevel = async (day, id, time, level) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?day=${day}&start_time=${time}&category_id=${id}&level_id=${level}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
//obtener los grupos en base a la hora, dia y categoria seleccionado
export const getAllGroupsByHour = async (day, id, time) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?day=${day}&start_time=${time}&category_id=${id}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
export const getAllGroupsByInstructor = async (categoryId, days, time, instructorId) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?&category_id=${categoryId}&instructor_id=${instructorId}&start_time=${time}&day=${days}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
export const getAllLevels = async (days, categoryId, time) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?&category_id=${categoryId}&start_time=${time}&day=${days}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

export const getAllLevelsByInstructor = async (days, categoryId, time) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?&category_id=${categoryId}&start_time=${time}&day=${days}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
export const getAllGroupsByInstructorAndLevel = async (
  categoryId,
  days,
  time,
  instructorId,
  levels
) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?&category_id=${categoryId}&instructor_id=${instructorId}&start_time=${time}&day=${days}&level_id=${levels}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

export const getScheduleByLesson = async (group, schedule) => {
  return fetch(
    `${BASE_URL}groups/filter_groups?group_id=${group}&group_shedule_id=${schedule}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

//OBTENER HORAS POR DIA Y CATEGORIA
export const getHourByDay = async (day, id) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?category_id=${id}&day=${day}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

export const getGroupByDayHour = async (time, day, id) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?start_time=${time}&category_id=${id}&day=${day}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};

//horarios por dia y por categoria
export const getScheduleByGroup = async (groupId, categoryId) => {
  return fetch(
    `${BASE_URL}groups/filter_groups_day?group_id=${groupId}&category_id=${categoryId}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleErrorClient);
};
//catalogos
export const getCatalogExpectative = async () => {
  return fetch(`${BASE_URL}catalogo_expectativas`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getCatalogCFDI = async () => {
  return fetch(`${BASE_URL}catalago_cdfis`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getCatalogFiscal = async () => {
  return fetch(`${BASE_URL}catalago_fiscals`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export function getClientsForPointOfSale(setterFunction) {
  return fetch(CLIENTS_WPAG_URL, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}
//clients?page=1&items=10,

export function getClients(setterFunction) {
  return fetch(CLIENTS_URL, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

//lite?page=1&items=50&search=
export const getResponsiblesLite = async (setterFunction, page, items, query = "") => {
  return fetch(
    `${RESPONSIBLE_LITE_URL}?page=${page}&items=${items}&search=${query}`,
    getParams("GET")
  )
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch(handleError);
};

export function getResponsibles(setterFunction, page = 1, query = "") {
  return fetch(`${RESPONSIBLE_URL}?page=${page}&search=${query}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction && setterFunction(json);
      return json;
    })
    .catch(handleError);
}

export function getStudents(setterFunction, page, item, status, searchText) {
  const estatus = status ? status : "ACTIVO";
  const query = searchText ? `&search=${searchText}` : "";
  return fetch(
    `${CLIENTS_URL}/students?page=${page}&items=${item}&estatus=${estatus}${query}`,
    getParams("GET")
  )
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

export function getListStudents(setterFunction) {
  
  return fetch(
    `${CLIENTS_URL}/students_list`,
    getParams("GET")
  )
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

export function getResponsible(setterFunction, id) {
  return fetch(`${RESPONSIBLE_URL}/${id}`, getParams("GET"))
    .then(handleResponse)
    .then((res) => {
      if (setterFunction) return setterFunction(res);
      else return res;
    })
    .catch(handleError);
}

export const getResponsibleSaldoPendiente = async (id) => {
  return fetch(`${RESPONSIBLE_URL}/get_quota?id=${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export function getInvoiceInformation(setterFunction, id) {
  return fetch(`${RESPONSIBLE_URL}/invoice_information?id=${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then(
      (json) => json?.invoice_information && setterFunction(json?.invoice_information)
    )
    .catch((e) => notice(e.errors));
}

export function updateResponsibles(data, setterFunction) {
  const body = new FormData();
  const id = data.id;

  body.append("responsible[name]", data.name);
  body.append("responsible[last_name]", data.last_name);
  body.append("responsible[last_name_2]", data.last_name_2);
  body.append("responsible[email]", data.email);
  body.append("responsible[phone]", data.phone);
  body.append("responsible[landline]", data.landline);
  body.append("responsible[membership_number]", data.membership_number);
  body.append("responsible[country]", data.country);
  body.append("responsible[district]", data.district);
  body.append("responsible[number]", data.number);
  body.append("responsible[state]", data.state);
  body.append("responsible[street_name]", data.street_name);
  body.append("responsible[suburb]", data.suburb);

  if (data.profile_image instanceof File) {
    body.append("responsible[profile_image]", data.profile_image);
  }

  return fetch(`${RESPONSIBLE_URL}/${id}`, getMultipartParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Responsable editado con exito", "success");
    })
    .catch((e) => notice(e.errors));
}

export const getClientPayments = (id) => {
  return fetch(`${PAYMENTS_URL}/client_payments?responsible_id=${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getClientOthersData = async (id) => {
  return fetch(`${PAYMENTS_URL}/client_payments?responsable_id=${id}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export function updateInvoiceInformation(data, setterFunction, id) {
  const body = JSON.stringify({ invoice_information: data });

  return fetch(
    `${RESPONSIBLE_URL}/update_invoice_information?id=${id}`,
    getParams("PUT", body)
  )
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json.invoice_information);
      notice("Datos editados con exito", "success");
    })
    .catch((e) => notice(e.errors));
}

export function updateLevel(data, setterFunction, id) {
  const body = JSON.stringify({ data });
  return fetch(`${CLIENTS_URL}/student_level?id=${id}`, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("nivel editado con exito", "success");
    })
    .catch((e) => notice(e.errors));
}

export function getResponsiblesWithClients(setterFunction, id) {
  return fetch(`${RESPONSIBLE_URL}/responsible_with_clients?id=${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function getResponsibleContact(setterFunction, id) {
  return fetch(`${RESPONSIBLE_URL}/contact?id=${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function updateResponsibleContact(data, setterFunction, id) {
  let body = {};
  if (data.share_contact) {
    delete data.clients;
    body = JSON.stringify({
      id: id,
      contact: { ...data },
      share_contact: 1,
    });
  } else {
    let c_contacts = [];
    data.clients.forEach((client) => {
      c_contacts.push({
        id: client.id,
        contact: client.contact,
      });
    });
    body = JSON.stringify({
      id: id,
      clients: c_contacts,
      share_contact: 2,
    });
  }

  return fetch(`${RESPONSIBLE_URL}/update_contact`, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Usuario editado con exito", "success");
    })
    .catch((e) => notice(e.errors));
}

export function getClient(setterFunction, id) {
  return fetch(`${CLIENTS_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function updateClient(id, data, setterFunction) {
  const body = new FormData();
  Object.keys(data.responsible).map((key) => {
    return body.append(`responsible[${key}]`, data.responsible[key]);
  });
  data.clients.forEach((c, index) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(c).map((key) => {
      if (key === "contact") {
        let nkey = "location_id";
        if (!data.share_contact) {
          body.append(`clients[${index}][${nkey}]`, c[key]["location_id"]);
          return Object.keys(c[key]).map((k) => {
            return body.append(`clients[${index}][${key}][${k}]`, c[key][k]);
          });
        } else {
          return body.append(
            `clients[${index}][${nkey}]`,
            data.main_contact["location_id"]
          );
        }
      } else if (
        key === "terms_and_conditions" ||
        key === "privacy_policy" ||
        key === "privacy_policy_responsible"
      ) {
        if (c[key] instanceof File)
          return body.append(`clients[${index}][${key}]`, c[key]);
      } else if (key === "ids_expectativas") {
        return body.append(`clients[${index}][expectative_id][0][id]`, c[key]);
      } else {
        return body.append(`clients[${index}][${key}]`, c[key]);
      }
    });
  });
  if (data.invoice_information) {
    Object.keys(data.invoice_information).map((key) => {
      return body.append(`invoice_information[${key}]`, data.invoice_information[key]);
    });
  }

  if (data.deletedClients) {
    if (data.deletedClients.length > 0) {
      body.append("deletedClients", data.deletedClients.join());
    }
  }

  body.append("share_contact", data.share_contact);

  if (data.share_contact) {
    Object.keys(data.main_contact).map((key) => {
      return body.append(`main_contact[${key}]`, data.main_contact[key]);
    });
  }
  // for (let pair of body.entries()) {
  //     console.log(pair[0] + ', ' + pair[1]);
  // }
  return fetch(`${RESPONSIBLE_URL}/${id}`, getMultipartParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      json.responsible.clients = json.clients;
      delete json.clients;
      setterFunction(json);
      notice("Clientes editado con exito", "success");
    })
    .catch((e) => notice(e.errors));
}

export function saveClient(data, setterFunction, setNewResponsible) {
  const body = new FormData();
  Object.keys(data.responsible).map((key) => {
    return body.append(`responsible[${key}]`, data.responsible[key]);
  });
  data.clients.forEach((client, index) => {
    // eslint-disable-next-line array-callback-return
    let descuentoCortesia = client["desc_cortesia"];
    let descuentoTerceraEdad = client["desc_tercera_edad"];
    Object.keys(client).map((key) => {
      if (key === "contact") {
        let nkey = "location_id";
        if (!data.share_contact) {
          body.append(`clients[${index}][${nkey}]`, client["location_id"]);
          Object.keys(client[key]).map((k) => {
            return body.append(`clients[${index}][${key}][${k}]`, client[key][k]);
          });
        }
      } else if (key === "groups") {
        let group_schedule = [];

        client[key].forEach((group, gi) => {
          // eslint-disable-next-line array-callback-return
          if (descuentoCortesia) {
            group["desc_cortesia"] = descuentoCortesia;
          } else if (descuentoTerceraEdad) {
            group["desc_tercera_edad"] = descuentoTerceraEdad;
          }
          Object.keys(group).map((gkey) => {
            if (gkey === "group_id" || gkey === "additional_classes") {
              return body.append(
                `clients[${index}][${key}][${gi}][${gkey}]`,
                group[gkey]
              );
            } else if (gkey === "group_schedule_id") {
              group_schedule.push(group[gkey]);
            } else {
              body.append(`clients[${index}][${key}][${gi}][${gkey}]`, group[gkey]);
            }
          });
        });
        body.append(`clients[${index}][group_shedules][${index}][id]`, group_schedule);
      } else if (key === "expectative_id") {
        body.append(`clients[${index}][${key}][0][id]`, client[key]);
      } else {
        body.append(`clients[${index}][${key}]`, client[key]);
      }
    });
  });
  Object.keys(data.invoice_information).map((key) => {
    return body.append(`invoice_information[${key}]`, data.invoice_information[key]);
  });

  body.append("share_contact", data.share_contact);
  if (data.share_contact) {
    Object.keys(data.main_contact).map((key) => {
      return body.append(`main_contact[${key}]`, data.main_contact[key]);
    });
  }
  body.append("share_contact", data.share_contact);
  body.append("billing_information", data.billing_information);
  body.append("find_out_id", data.find_out_id);
  // for (let pair of body.entries()) {
  //     console.log(pair[0] + ', ' + pair[1]);
  // }
  return fetch(RESPONSIBLE_URL, getMultipartParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      let names = json.clients
        .map(function(el) {
          return el.name;
        })
        .join(",");
      setNewResponsible({
        idClient: json.clients[0].id,
      });
      setterFunction({
        id: json.responsible.id,
        number_of_clients: json.clients.length,
        full_name: `${json.responsible.name} ${json.responsible.last_name}`,
        clients_string: names,
        location: json.clients[0].location,
      });
      notice("Clientes creado con exito", "success");
    })
    .catch(handleError);
}

//nuevo grupo al cliente
export const getAddNewLesson = async (body) => {
  return fetch(`${CLIENTS_URL}/incriotion_two_group`, getMultipartParams("POST", body));
};

export const getListGroupByClient = async (id, setterFunction) => {
  return fetch(`${GROUPS_URL}/group_lits_for_client?id=${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
    })
    .catch((e) => {
      if (e.Error[0].status !== 422) {
        notice(e.Error[0].details, "error");
        setterFunction([]);
      }
      setterFunction([]);
    });
};

//ELIMINIAR
export const deleteGroupByClient = async (priceId, schedulId, idStudent) => {
  return fetch(
    `${CLIENTS_URL}/delete_two_group?group_price_id=${priceId}&group_schedule_id=${schedulId}&client_id=${idStudent}`,
    getParams("DELETE")
  );
};
//CALCULO PORCENTUAL
export const percentageCalculation = async (body, setterFunction) => {
  return fetch(`${GROUPS_URL}/calculo_porcentual`, getMultipartParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json.Success[0].details);
    })
    .catch((e) => {
      setterFunction([]);
      notice(e.errors);
    });
};

export const percentageCalculationClient = async (body) => {
  return fetch(`${GROUPS_URL}/calculo_porcentual`, getMultipartParams("POST", body))
    .then(handleResponse)
    .catch((e) => {
      notice(e.errors);
    });
};

export function getInscriptionInformation(id, setterFunction) {
  return fetch(`${RESPONSIBLE_URL}/inscription?id=${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
    })
    .catch((e) => notice(e.errors));
}

export function createPaymentMethod(id, data, setterFunction) {
  const body = new FormData();
  Object.keys(data).map((key) => {
    return body.append(`payment_method[${key}]`, data[key]);
  });

  return fetch(
    `${RESPONSIBLE_URL}/create_payment_method?id=${id}`,
    getMultipartParams("POST", body)
  )
    .then(handleResponse)
    .then((json) => {
      notice("Metodo de pago creado con éxito", "success");
      if (setterFunction) return setterFunction(json);
      return json;
    })
    .catch((e) => notice(e.errors));
}

export function deletePaymentMethod(data, setterFunction) {
  const body = new FormData();
  Object.keys(data).map((key) => {
    return body.append(key, data[key]);
  });

  return fetch(
    `${RESPONSIBLE_URL}/delete_payment_method`,
    getMultipartParams("DELETE", body)
  )
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(data.id);
      notice("Metodo de pago eliminado con éxito", "success");
    })
    .catch((e) => notice(e.errors));
}
