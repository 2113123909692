import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function Loader() {
  return (
    <Box sx={{ display: "flex", gap: 10, alignItems: "center" }}>
      Cargando <CircularProgress size={15} />
    </Box>
  );
}
