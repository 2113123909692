import React, { useEffect, useState } from "react";
/* eslint-disable */
import {
  Grid,
  Paper,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import * as clientsCRUD from "../../../../crud/clients.crud";
import LessonTable from "./LessonTable";
import AntTabs from "../../../../common/components/AntTabs";
import { Button } from "@material-ui/core";
import LessonClientTable from "./LessonClientTable";

const Lessons = ({ clients, levels, updateLevel, responsible }) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [tabValue, setTabValue] = useState(0);
  const [studentClients, setStudentClients] = useState([]);
  const [studentClientsClass, setStudentClientsClass] = useState([]);
  const [inscription, setInscription] = useState([]);
  const [level_i, setLevel_i] = useState("");
  const [id_l, setId_l] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });
  //RESPONBILE ID OBTENER LOS CLIENTES
  const fillInformation = (json) => {
    let info = [];
    json.forEach((element) => {
      info.push({
        name: element.full_name,
        group: element.groups_prices.group_names,
        class: element.groups_prices.number_of_classes,
        mounth: `$${element.groups_prices.total}`,
        id: element.id,
      });
    });
    setInscription(info);
  };
  useEffect(() => {
    clientsCRUD.getInscriptionInformation(responsible.responsible.id, fillInformation);
  }, [responsible.responsible.id]);

  //STUDENT CLIENTS
  const tabOptions = studentClients.map((studentClient) => ({
    label: studentClient.name,
    value: studentClient.id,
  }));
  const studentIndex = studentClients?.findIndex((st) => st.id === tabValue);
  const student = studentClients[studentIndex];
  //INFORMACIÓN PARA EDITAR CLASES POR ESTUDIANTE
  let studentClass = [];
  const studentIndexClass = inscription.findIndex((st) => st.id === tabValue);
  studentClass.push(inscription[studentIndexClass]);
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  useEffect(() => {
    let students = [];

    clients?.forEach((client) => {
      students.push({
        id: client.id,
        name: `${client.name} ${client.last_name}`,
        category: client.category,
        nivel: client.level,
        group: client.groups,
        attendances: client.attendances,
      });
    });
    setStudentClients(students);
    setTabValue(students[0]?.id);
  }, [clients]);
  const saveLevel = () => {
    const data = {
      level_id: level_i,
    };
    clientsCRUD.updateLevel(data, updateLevel, id_l).then(() => {
      disableLoading();
      window.scrollTo(0, 0);
    });
  };

  const handleChangeSelect = (v, i, setterFunction, id) => {
    setterFunction(`clients["${i}"]['level_id']`, v);
    setId_l(id);
    setLevel_i(v);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper style={{ display: "flex" }}>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
                Clases
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ paddingTop: "8px", display: "flex", justifyContent: "flex-end" }}
            >
              <AntTabs value={tabValue} options={tabOptions} onChange={setTabValue} />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Formik
            initialValues={{
              clients: clients,
            }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                saveLevel(values);
                setSubmitting(false);
                disableLoading();
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                {student && (
                  <div className="form-group">
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <Grid item xs={6}>
                            <Typography
                              variant="h6"
                              style={{ color: "#001979", padding: "15px" }}
                            >
                              Clases
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        <>
                          <Grid>
                            <Typography
                              variant="h6"
                              style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
                            >
                              Configuracion de acuerdo a los grupos inscritos
                            </Typography>
                          </Grid>

                          <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid
                              item
                              style={{
                                padding: "7px 15px",
                                border: "1px dashed darkgrey",
                                margin: "10px 27px",
                              }}
                            >
                              <Typography variant="body1" style={{}}>
                                {student.category === null
                                  ? "Sin Categoría"
                                  : student.category}
                              </Typography>
                              <Typography variant="body2" style={{ color: "#868686" }}>
                                Categoria
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              style={{
                                padding: "7px 15px",
                                border: "1px dashed darkgrey",
                                margin: "10px 27px",
                              }}
                            >
                              <Typography variant="body1" style={{}}>
                                {student.group === null ? "Sin Clases" : student.group}
                              </Typography>
                              <Typography variant="body2" style={{ color: "#868686" }}>
                                Grupos
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              {role?.name !== "Cliente" && (
                                <FormControl fullWidth margin="normal">
                                  <InputLabel id="level">Nivel</InputLabel>
                                  <Select
                                    labelId="level"
                                    name={`clients["${studentIndex}"]['level_id']`}
                                    onChange={(e) =>
                                      handleChangeSelect(
                                        e.target.value,
                                        studentIndex,
                                        setFieldValue,
                                        values?.clients[studentIndex]?.id
                                      )
                                    }
                                    value={
                                      values?.clients[studentIndex]?.level_id === null
                                        ? "SN"
                                        : values?.clients[studentIndex]?.level_id
                                    }
                                  >
                                    {levels.map((lev) => (
                                      <MenuItem key={lev.id} value={lev.id}>
                                        {lev.name}
                                      </MenuItem>
                                    ))}
                                    <MenuItem key="SN" value="SN">
                                      Sin nivel
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid item sm={3} style={{ margin: "20px 30px" }}>
                              {role?.name !== "Cliente" && (
                                <button
                                  id="kt_login_signin_submit"
                                  type="submit"
                                  disabled={isSubmitting}
                                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                    {
                                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                                    }
                                  )}`}
                                  style={loadingButtonStyle}
                                >
                                  Guardar Cambios
                                </button>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                      <Paper>
                        <LessonTable clases={student.attendances || []} />
                      </Paper>
                    </Grid>
                  </div>
                )}
              </form>
            )}
          </Formik>
        </Grid>
        {/* AÑADIR CLASES */}
        <Grid item xs={12} sm={12} md={12}>
          <LessonClientTable
            studentClass={studentClass}
            student={student}
            levels={levels}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Lessons;
