import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "../../../tablas/Tablas.module.css";

import PoolTypesDialog from "./PoolTypesDialog";
import CustomButton from "../../../../../common/components/CustomButton";

export const PoolTypesTable = ({ configCRUD, pool_types, setPoolType, permit }) => {
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Tipo",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Descripción",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "editing":
        configCRUD.getPoolType(setPoolType, id).then(() => setDialog(mode));
        break;
      case "creating":
        setPoolType(configCRUD.defaultPoolType);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setPoolType(configCRUD.defaultPoolType);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  };

  const options = {
    responsive: "scrollMaxHeight",
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    onRowClick: (data) => openDialog("editing", data[0]),
    download: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
      },
    },
  };

  return (
    <>
      <div className="text-right mb-4">
        {permit.poolsTypes && (
          <CustomButton
            title="Crear tipo de alberca"
            onClick={() => openDialog("creating")}
          />
        )}
      </div>
      <Paper>
        <div className={styles.wrapper}>
          <MUIDataTable
            title={"Tipos de alberca"}
            data={pool_types.data}
            columns={columns}
            options={options}
          />
        </div>
      </Paper>
      <PoolTypesDialog mode={dialog} setDialog={openDialog} permit={permit} />
    </>
  );
};
