import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Paper } from '@material-ui/core';

import styles from '../../tablas/Tablas.module.css'
import SStatusesDialog from './SStatusesDialog';
import CustomButton from '../../../../common/components/CustomButton';

export const SStatusesTable = (props) => {
    const { suggestion_statuses, configCRUD, setSuggestionStatus, permit } = props
    const [dialog, setDialog] = useState('closed')

    const columns =[
        {
        name: 'id',
        label: 'id',
        options:{
            filter:false,
            sort: false,
            display: false
        }
        },
        {
        name: "name",
        label: "Estados",
        options: {
        filter: false,
        sort: false,
        }
        },
        {
        name: "description",
        label: "Descripción",
        options: {
            filter: false,
            sort: false,
        }
        },
        {
            name: "color",
            label: "Color",
            options: {
                filter: false,
                sort: false,
                customBodyRender:(data, dataIndex, rowIndex) => {
                    return <div
                    style={{
                    width: "30px",
                    height: "10px",
                    borderRadius: "10px",
                    backgroundColor: data
                    }}
                />
                }
            }
        },
    ]

    const options = {
        responsive: 'scrollMaxHeight',
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: 'none',
        onRowClick: data => openDialog('editing', data[0]),
        download: false,
        textLabels:{
        body:{
            noMatch: "No se encontraron resultados"
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
        },toolbar: {
            search: "Buscar",
        }
        }
      };

    const openDialog = (mode, id = null) => {
        switch (mode) {
        case 'editing':
            configCRUD.getSuggestionStatus(setSuggestionStatus, id).then(() => setDialog(mode))
            break;
        case 'creating':
            setSuggestionStatus(configCRUD.defaultSuggestionStatus)
            setDialog(mode)
            break;
        case 'closed':
            setDialog(mode)
            setTimeout(() => {
            setSuggestionStatus(configCRUD.defaultSuggestionStatus)
            }, 200);
            break;
        default:
            console.error('Invalid dialog mode');
        }
    }

    return (
        <>
            <div className="text-right mb-4">
              {permit.suggestionsStatuses && <CustomButton title="Crear sugerencia" onClick={() => openDialog('creating')} />}
            </div>
            <Paper>
                <div className={styles.wrapper}>
                    <MUIDataTable
                    title={"Estados de sugerencia"}
                    data={suggestion_statuses.data}
                    columns={columns}
                    options={options}
                    />
                </div>
            </Paper>
            <SStatusesDialog mode={dialog} setDialog={openDialog} permit={permit}/>
        </>
    )
}
