import notice from "../utils/notice";
import { LOGIN_URL, ME_URL, RESET_PASSWORD_URL } from "./helpers/routes";
import { getParams, handleResponse } from "./helpers/validate";

export function login(data, setterFunction) {
  const body = JSON.stringify({ user: data });
  let token;

  return fetch(LOGIN_URL, getParams("POST", body))
    .then((response) => {
      if (!response.ok) throw response.status;
      for (let entry of response.headers.entries()) {
        if (entry[0] === "authorization") {
          token = entry[1];
        }
      }

      return response.json();
    })
    .then((json) => {
      // console.log(json)
      setterFunction(json, token);
      return;
    });
  // El catch ocurre en Login.js
}

export function getUserByToken(setterFunction) {
  return fetch(ME_URL, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function forgotPassword(data) {
  const body = JSON.stringify({ user: data });

  return fetch(RESET_PASSWORD_URL, getParams("POST", body))
    .then((response) => handleResponse(response))
    .then((json) =>
      notice(
        "Encontrarás las instrucciones para recuperar tu contraseña en tu correo electrónico",
        "success",
        5000
      )
    )
    .catch((e) => notice(e.errors));
}

export function resetPassword(data) {
  const body = JSON.stringify({ user: data });

  return fetch(RESET_PASSWORD_URL, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => notice("Tu contraseña ha sido actualizada correctamente", "success"));
  // El catch ocurre en ResetPassword.js
}
