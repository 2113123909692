import React, { useState } from "react";
import FullTable from "../../../../common/components/FullTable";
import CustomButton from "../../../../common/components/CustomButton";
import { attendance_columns, options } from "../utils/InfoSchedule_data";
import { useEffect } from "react";
import { updateAttendance } from "../../../../crud/attendance.crud";
import notice from "../../../../utils/notice";
import * as configCRUD from "../../../../crud/config.crud";

const InfoMeetTable = ({ group }) => {
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    configCRUD.getAttendance(group.id, setAttendance).catch((e) => console.log(e));
  }, []);

  const updateStates = () => {
    setLoading(true);
    updateAttendance({ attendance })
      .then(() => notice("Estados actualizados", "success"))
      .catch((e) => notice(Array.isArray(e) ? e.join("\n") : "Error del sistema"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FullTable
        title="Lista de asistencia"
        columns={attendance_columns(attendance, setAttendance)}
        data={attendance}
        options={options(attendance)}
      />
      <div className="d-flex justify-content-end mt-4">
        <CustomButton
          title="Actualizar asistencias"
          loading={loading}
          disabled={loading}
          onClick={updateStates}
        />
      </div>
    </>
  );
};

export default InfoMeetTable;
