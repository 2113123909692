import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Formik } from 'formik';
import clsx from 'clsx';
import UtilInputs from '../../../../../common/components/UtilInputs';

import * as config from '../../../../../store/ducks/config.duck';
import * as configCRUD from '../../../../../crud/config.crud';
import notice from '../../../../../utils/notice';
import { formInputs } from './utils';

function RoleDialog({
    mode,
    role,
    setRole,
    setDialog,
    permissions,
    setRoles,
    updateRoles,
    permit,
    user,
    locations,
}) {
    const open = mode === 'editing' || mode === 'creating';
    const title = role.id ? 'Editar Rol' : 'Nuevo Rol';

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: '2.5rem',
    });

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: '3.5rem' });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: '2.5rem' });
    };

    function create(values) {
        const roleCheck = {
            location_id: user.role.location_id
                ? user.role.location_id
                : values.location_id,
            name: values.name,
            description: values.description,
            permission_ids: values.permission_ids,
            color: values.color,
        };
        configCRUD
            .saveRole(roleCheck, updateRoles)
            .then(() => {
                setDialog('closed');
                notice('Rol guardado', 'success');
            })
            .catch((e) =>
                notice(e.join('\n') || 'Error con su solicitud')
            )
            .finally(disableLoading);
    }

    function update(values) {
        const roleCheck = {
            id: role.id,
            location_id: user.role.location_id
                ? user.role.location_id
                : values.location_id,
            name: values.name,
            description: values.description,
            permission_ids: values.permission_ids,
            color: values.color,
        };
        configCRUD
            .updateRole(roleCheck, updateRoles)
            .then(() => {
                setDialog('closed');
                notice('Rol actualizado', 'success');
            })
            .catch((e) =>
                notice(e.join('\n') || 'Error con su solicitud')
            )
            .finally(disableLoading);
    }
    console.log(role);
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            onClose={() => setDialog('closed')}
            aria-labelledby='form-dialog-title'
        >
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
                </DialogContentText>
                <Formik
                    initialValues={{
                        location_id: role.location_id,
                        name: role.name,
                        description: role.description,
                        color: role.color,
                        permission_ids: role.permission_ids,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.name) {
                            errors.name = 'Campo requerido';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        enableLoading();
                        role.id ? update(values) : create(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete='off'
                            onSubmit={handleSubmit}
                            className='kt-form'
                        >
                            <div className='form-group'>
                                <UtilInputs
                                    formValues={values}
                                    onValuesChange={handleChange}
                                    inputValues={formInputs(
                                        permissions,
                                        user.role.location_id,
                                        locations.data
                                    )}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </div>
                            <div className='btn-dialog'>
                                <Button
                                    onClick={() =>
                                        setDialog('closed')
                                    }
                                    color='primary'
                                >
                                    Cancelar
                                </Button>
                                <button
                                    id='kt_login_signin_submit'
                                    type='submit'
                                    disabled={loading}
                                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                        {
                                            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                                        }
                                    )}`}
                                    style={loadingButtonStyle}
                                >
                                    {role.id ? 'Editar' : 'Crear'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (store) => ({
    role: store.config.role,
    permissions: store.config.permissions.data,
    user: store.auth.user.user,
    locations: store.config.locations,
});

export default connect(mapStateToProps, config.actions)(RoleDialog);
