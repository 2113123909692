import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as config from "../../../store/ducks/config.duck";
import * as configCRUD from "../../../crud/config.crud";

import { getUsers } from "../../../crud/users.crud";
import * as user from "../../../store/ducks/users.duck";

import Sugerencias from "./Sugerencias.js";

class SugerenciasWrapper extends Component {
  componentDidMount() {
    const { configActions, userActions } = this.props;

    configCRUD.getSuggestionStatuses(configActions.setSuggestionStatuses);
    configCRUD.getSuggestionTypes(configActions.setSuggestionTypes);
    configCRUD.getSuggestionSources(configActions.setSuggestionSources);

    configCRUD.getAreas(configActions.setAreas);
    configCRUD.getLocations(configActions.setLocations);

    getUsers(userActions.setUsers);
  }
  render() {
    return (
      <>
        <Sugerencias />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    configActions: bindActionCreators(config.actions, dispatch),
    userActions: bindActionCreators(user.actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SugerenciasWrapper);
