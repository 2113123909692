import { TableCell, TableRow } from "@material-ui/core";
import React from "react";

export const LessonsRowFormatter = (data, onClientClick) => {
  const [
    id,
    responsible_id,
    name,
    last_name,
    membership_number,
    classes_enrolled,
    available_classes,
    groups,
    category,
    level,
    age,
  ] = data;
  return (
    <TableRow
      onClick={() => onClientClick(responsible_id)}
      hover
      key={id}
      style={{ background: classes_enrolled === 0 ? "#FFCBCB" : "white" }}
    >
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {name}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {last_name}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {membership_number}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {classes_enrolled}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {available_classes}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {groups}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {category}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {level}
      </TableCell>
      <TableCell
        className={`text-${
          classes_enrolled === 0 ? "danger font-weight-bold" : "dark"
        }`}
      >
        {age}
      </TableCell>
    </TableRow>
  );
};
