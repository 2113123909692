import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'

const actionType = {
  GetAttendances: '[ATTENDANCE] GET_ATTENDANCES',
  GetAttendance: '[ATTENDANCE] GET_ATTENDANCE',
}

const initialConfigState = {
  results: { meta: null, data: [] },
  selectedAttendance: null
}

export const reducer = persistReducer(
    { storage, key: 'attendance', whitelist: []},
    (state = initialConfigState, action) => {
        switch (action.type) {
            case actionType.GetAttendances: {
                return { ...state, results: { meta: action.payload.meta, data: action.payload.clients_attendances } }
            }

            case actionType.GetAttendance: {
                return { ...state, selectedAttendance: action.payload.product }
            }

            default:
                return state
        }
    }
)

export const attendanceActions = {
    setAttendances: data => ({ type: actionType.GetAttendances, payload: data }),
    setAttendance: data => ({ type: actionType.GetAttendance, payload: data }),
};