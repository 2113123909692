import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: "20px 10px",
    display: "flex",
    width: "50%",
    height: "auto",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 8px",
    borderRadius: 8,
    justifyContent: "space-around",
  },
  cardContainerClient: {
    padding: "20px 10px",
    display: "flex",
    width: "60%",
    height: "auto",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 8px",
    borderRadius: 8,
    justifyContent: "space-around",
  },
  cardText: {
    display: "flex",
    flexDirection: "column",
  },
  cardLesson: {
    display: "flex",
    justifyContent: "start",

    alignItems: "center",
  },
  dot: {
    height: 5,
    width: 5,
    borderRadius: "50%",
    backgroundColor: "#00176E",
    marginRight: 5,
  },
}));
