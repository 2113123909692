import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { defaultProduct } from '../../crud/products.crud'

export const actionType = {
    GetProducts: '[Config] GET_PRODUCTS',
    GetProduct: '[Config] GET_PRODUCT',
    UpdateProduct: '[Config] UPDATE_PRODUCT'
}

const initialConfigState = {
    products: {meta: [], data: [], isFetched: false},
    product: defaultProduct
}

export const reducer = persistReducer(
    { storage, key: 'products', whitelist: []},
    (state = initialConfigState, action) => {
        switch (action.type) {
            case actionType.GetProducts: {
                return { ...state, products: {meta: action.payload.meta, data: action.payload.products, isFetched: true} }
            }

            case actionType.GetProduct: {
                return { ...state, product: action.payload.product }
            }

            case actionType.UpdateProduct: {
                let newData
                const index = state.products.data.findIndex( x => x.id === action.payload.product.id)
                if (index === -1) {
                    newData = [action.payload.product, ...state.products.data]
                } else {
                    newData = state.products.data
                    newData[index] = action.payload.product
                }

                return {...state, products: {data: newData, isFetched: true}}
            }

            default:
                return state
        }
    }
)

export const actions = {
    setProducts: products => ({ type: actionType.GetProducts, payload: products}),
    setProduct: product => ({ type: actionType.GetProduct, payload: product }),
    updateProduct: products => ({ type: actionType.UpdateProduct, payload: products})
};