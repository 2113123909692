import { Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import { createURL } from "../../utils/data_formatters";
import BaseModal from "./BaseModal";

const FileButton = ({
  index,
  handleChange,
  label = "Cargar archivos",
  accept = "image/*",
  multiple = false,
  disabled,
  className = "",
  color = "primary",
  loadPreview = false,
}) => {
  const [previewFile, setPreviewFile] = useState(null);
  const [preview, setPreview] = useState("");

  const handleCapture = ({ target }) => {
    if (target?.files && target?.files[0]) {
      setPreviewFile(target.files[0]);
      !multiple ? handleChange(target.files[0], index) : handleChange([...target.files]);
    }
  };

  const openPreview = () => {
    const previewUrl = createURL(previewFile);
    if (previewFile.type.includes("image")) setPreview(previewUrl);
    else window.open(previewUrl, "_blank");
  };

  return (
    <>
      <label
        className={`btn btn-${color}${disabled ? " disabled" : ""} ${className}`}
        style={{ cursor: disabled && "not-allowed" }}
      >
        <input
          hidden
          type="file"
          accept={accept}
          onChange={handleCapture}
          disabled={disabled}
          multiple={multiple}
        />
        <span>{label}</span>
        {loadPreview && previewFile && (
          <button
            onClick={openPreview}
            disabled={!previewFile}
            className="btn btn-icon btn-success btn-sm ml-2"
          >
            <Visibility fontSize="small" />
          </button>
        )}
      </label>
      <BaseModal
        open={Boolean(preview)}
        onClose={() => setPreview("")}
        hideTitle
        maxWidth="xs"
      >
        <div className="text-center">
          <img src={preview} alt="preview" width="100%" />
        </div>
      </BaseModal>
    </>
  );
};

export default FileButton;
