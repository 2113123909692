import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
  Input,
  Checkbox,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import { BASE_URL } from "../../../../crud/helpers/routes";
import * as clientsCRUD from "../../../../crud/clients.crud";

const Notices = ({ clients, responsiblesWithClients, locations, updateClients }) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const getLocationId = (name) => {
    let index = locations.data
      .map(function(e) {
        return e.name;
      })
      .indexOf(name);
    return locations.data[index].id;
  };

  const update = (values) => {
    let n_responsible = {
      responsible: { ...responsiblesWithClients.responsible },
    };
    delete n_responsible.responsible.clients;
    n_responsible.clients = values.clients;
    n_responsible.clients.forEach((element) => {
      if (element.location_id === undefined) {
        element.location_id = getLocationId(element.location);
      }
    });
    clientsCRUD.updateClient(n_responsible.responsible.id, n_responsible, updateClients);
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    background: "#1b76d1",
    color: "white",
    margin: "10px",
  });
  return (
    <>
      <Paper>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
              Avisos
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {role?.name !== "Cliente" && !edit && (
              <Button
                style={{ marginRight: 25 }}
                color="primary"
                variant="contained"
                onClick={() => setEdit(true)}
              >
                Editar archivos
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider />
        {edit ? (
          <Formik
            initialValues={{
              clients: clients,
            }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                update(values);
                setSubmitting(false);
                setEdit(false);
                disableLoading();
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="form-group">
                  <Grid item xs={12} style={{ marginTop: 20, paddingLeft: "27px" }}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Checkbox
                        color="primary"
                        checked={responsiblesWithClients.responsible.confirmed_t_c}
                        // onChange={handleChange}
                        name="share_contact"
                        disabled
                      />
                      <Typography variant="h6">
                        Ha leído y aceptado el reglamento y avisos de privacidad
                      </Typography>
                    </Grid>
                  </Grid>
                  {values.clients.map((client, i) => (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            padding: "15px 0px 15px 27px",
                            fontWeight: 600,
                          }}
                        >
                          Alumno {i + 1} - {client.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "15px 0px 15px 27px",
                          }}
                        >
                          Aviso de privacidad
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Input
                          label="Aviso de privacidad"
                          margin="normal"
                          type="file"
                          className="kt-width-full"
                          name={`clients["${i}"]['privacy_policy']`}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(
                              `clients["${i}"]['privacy_policy']`,
                              e.target.files[0]
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "15px 0px 15px 27px",
                          }}
                        >
                          Términos y condiciones
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Input
                          label="Términos y condiciones"
                          margin="normal"
                          type="file"
                          className="kt-width-full"
                          name={`clients["${i}"]['terms_and_conditions']`}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(
                              `clients["${i}"]['terms_and_conditions']`,
                              e.target.files[0]
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#868686",
                            padding: "15px 0px 15px 27px",
                          }}
                        >
                          Aviso de privacidad encargado
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Input
                          label="Aviso de privacidad encargado"
                          margin="normal"
                          type="file"
                          className="kt-width-full"
                          name={`clients["${i}"]['privacy_policy_responsible']`}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(
                              `clients["${i}"]['privacy_policy_responsible']`,
                              e.target.files[0]
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      setEdit(false);
                    }}
                    variant="contained"
                    style={{
                      background: "#001979",
                      color: "white",
                      margin: "10px",
                      padding: "9.5px 20px",
                    }}
                  >
                    Cancelar
                  </Button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                    })}`}
                    style={loadingButtonStyle}
                  >
                    Guardar Cambios
                  </button>
                </Grid>
              </form>
            )}
          </Formik>
        ) : (
          <>
            <Grid item xs={12} style={{ marginTop: 20, paddingLeft: "27px" }}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Checkbox
                  color="primary"
                  checked={responsiblesWithClients.responsible.confirmed_t_c}
                  // onChange={handleChange}
                  name="share_contact"
                  disabled
                />
                <Typography variant="h6">
                  Ha leído y aceptado el reglamento y avisos de privacidad
                </Typography>
              </Grid>
            </Grid>
            {clients.map((client, i) => (
              <>
                <Grid>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "15px 0px 15px 27px",
                      fontWeight: 600,
                    }}
                  >
                    Alumno {i + 1} - {client.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Aviso de privacidad
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Términos y condiciones
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#868686",
                        padding: "15px 0px 15px 27px",
                      }}
                    >
                      Aviso de privacidad encargado
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.privacy_policy ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${BASE_URL}${client.privacy_policy}`}
                        >
                          {client.privacy_policy.split("/").pop()}
                        </a>
                      ) : (
                        "Sin información"
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.terms_and_conditions ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${BASE_URL}${client.terms_and_conditions}`}
                        >
                          {client.terms_and_conditions.split("/").pop()}
                        </a>
                      ) : (
                        "Sin información"
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        padding: "15px 0px 15px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {client.privacy_policy_responsible ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`${BASE_URL}${client.privacy_policy_responsible}`}
                        >
                          {client.privacy_policy_responsible.split("/").pop()}
                        </a>
                      ) : (
                        "Sin información"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </>
        )}
      </Paper>
    </>
  );
};
export default Notices;
