import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLevels, getCategories, getIntructors} from '../../../crud/config.crud';
import { getListStudents} from '../../../crud/clients.crud';
import * as config from "../../../store/ducks/config.duck";
import * as clients from '../../../store/ducks/clients.duck'
import Meeting from './Meeting'

class MeetingWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { isFetched: false };
    }

    componentDidMount() {
        getLevels(this.props.configActions.setLevels)
        getCategories(this.props.configActions.setCategories)
        // getGroups(this.props.configActions.setGroups)
        getIntructors(this.props.configActions.setIntructors)
        getListStudents(this.props.clientActions.setStudents)

        setTimeout(() => {
            this.setState({ isFetched: true })
        }, 3000);
    }

    render() {
        return (
            <>
                <Meeting />
            </>
        );
    }
    
}

const mapStateToProps = store => ({
    levels: store.config.levels,
    categories: store.config.categories,
    groups: store.config.groups,
    intructors: store.config.intructors,
})


function mapDispatchToProps(dispatch) {
    return {
        configActions: bindActionCreators(config.actions, dispatch),
        clientActions: bindActionCreators(clients.actions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingWrapper)
