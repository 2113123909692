import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  InputLabel,
  Link,
  Select,
  FormControl,
  MenuItem,
  Typography,
  TextField,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import Cards from "react-credit-cards";
import { FaCcAmex, FaCcMastercard, FaCcVisa, FaCreditCard } from "react-icons/fa";
import CustomButton from "../../../../common/components/CustomButton";
import UtilInputs from "../../../../common/components/UtilInputs";
import SimpleCheckbox from "../../../../common/components/SimpleCheckbox";
import SimpleText from "../../../../common/components/SimpleText";
import FileButton from "../../../../common/components/FileButton";
import { createPaymentMethod } from "../../../../crud/clients.crud";
import Cash from "../../../../../assets/images/cash.png";
import { cardForm, defaultCard } from "../utils";
import notice from "../../../../utils/notice";
import BaseModal from "../../../../common/components/BaseModal";

const PaymentMethodTab = ({
  responsible,
  setResponsible,
  summary,
  onSelectCard,
  setTotalPayment,
  totalPayment,
}) => {
  const { role } = useSelector((store) => store.auth.user.user);
  const [card, setCard] = useState(defaultCard);
  const [showCard, setShowCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPaymentA, setTotalPaymentA] = useState("");
  const [totalPaymentB, setTotalPaymentB] = useState("");

  const [directCardModal, setDirectCardModal] = useState(false);

  const handleChange = ({ target }) => {
    setCard({ ...card, [target.name]: target.value });
  };

  const handleFocus = ({ target }) => {
    setCard({ ...card, focus: target.name });
  };

  const create = () => {
    setLoading(true);
    let data = {
      card_number: card.number,
      card_expiration: card.expiry,
      card_cvc: card.cvc,
    };
    createPaymentMethod(responsible.id, data)
      .then((res) => {
        setResponsible(res.responsible);
        setShowCard(false);
        setCard(defaultCard);
      })
      .catch((e) => notice("Ha ocurrido un error con su solicitud"))
      .finally(() => setLoading(false));
  };

  function getCardBrandIcon(brand) {
    let icon = <FaCreditCard size={42} color={"#0027c5"} />;
    switch (brand) {
      case "mastercard":
        icon = <FaCcMastercard size={42} color={"#0027c5"} />;
        break;
      case "amex":
        icon = <FaCcAmex size={42} color={"#0027c5"} />;
        break;
      case "visa":
        icon = <FaCcVisa size={42} color={"#0027c5"} />;
        break;
      default:
        icon = <FaCreditCard size={42} color={"#0027c5"} />;
    }
    return icon;
  }

  const handleDirectModal = (state) => {
    onSelectCard({ direct_card: state });
    setDirectCardModal(state);
  };
  const handleInputA = (e) => {
    setTotalPaymentA(e.target.value);
    setTotalPayment(Number(e.target.value) + Number(totalPaymentB));
  };
  const handleInputB = (e) => {
    setTotalPaymentB(e.target.value);
    setTotalPayment(Number(e.target.value) + Number(totalPaymentA));
  };
  return (
    <>
      <Grid container spacing={2}>
        {role.name !== "Cliente" && (
          <Grid item xs={12} sm={6}>
            <button
              onClick={() =>
                onSelectCard({
                  customer: "",
                  payment_method: "cash",
                  last4: "",
                  reference: "",
                  reference_file: null,
                  direct_card: false,
                })
              }
              className="btn btn-light w-100 h-100 text-left d-flex flex-row justify-content-start"
              style={{
                border: "1px dashed darkgray",
                borderRadius: "10px",
                padding: 10,
              }}
            >
              <img src={Cash} alt="cash-icon" width={60} />
              <h4 style={{ marginLeft: 10 }}>Efectivo</h4>
            </button>
          </Grid>
        )}
        {role.name !== "Cliente" && (
          <Grid item xs={12} sm={6}>
            <div
              className="w-100 h-100 d-flex flex-column justify-content-start"
              style={{
                border: "1px dashed darkgray",
                borderRadius: "10px",
                padding: 10,
              }}
            >
              <div className="d-flex">
                <SimpleCheckbox
                  name="payment_method"
                  label="Pago por trasferencia"
                  value={summary.payment_method === "transfer"}
                  onChange={({ target }) =>
                    onSelectCard({
                      customer: "",
                      payment_method:
                        summary.payment_method === "transfer" ? "" : "transfer",
                      reference_file: null,
                      direct_card: false,
                    })
                  }
                />
                <div>
                  <FileButton
                    label={<AttachFile />}
                    accept="application/pdf,image/*"
                    handleChange={(reference_file) => onSelectCard({ reference_file })}
                    disabled={summary.payment_method !== "transfer"}
                  />
                </div>
              </div>
              {summary?.reference_file?.name && (
                <Link
                  component="button"
                  style={{ overflow: "hidden" }}
                  onClick={() => onSelectCard({ reference_file: null })}
                >
                  {summary?.reference_file?.name} X
                </Link>
              )}
              <SimpleText
                disabled={summary.payment_method !== "transfer"}
                name="no_reference"
                label="Referencia"
                value={summary.no_reference}
                onChange={({ target }) => onSelectCard({ no_reference: target.value })}
              />
            </div>
          </Grid>
        )}
        {role.name !== "Cliente" && (
          <Grid item xs={12} sm={6}>
            <div
              className="w-100 h-100 d-flex flex-column justify-content-start"
              style={{
                border: "1px dashed darkgray",
                borderRadius: "10px",
                padding: 10,
              }}
            >
              {/* /*PAGFO POR TERMINAL */}
              <Typography>Pago por terminal</Typography>
              <div className="d-flex">
                <FormControl fullWidth variant="standard" label=">Pago por terminal">
                  <InputLabel id="invoice_person_type">Pago por terminal</InputLabel>
                  <Select>
                    <MenuItem value="">Seleccione una opción</MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "terminal",
                          payment_terminal: "tarjeta_debito_amount",
                        })
                      }
                      value="tarjeta_debito_amount"
                    >
                      Tarjeta de débito
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "terminal",
                          payment_terminal: "tarjeta_credito_amount",
                        })
                      }
                      value="tarjeta_credito_amount"
                    >
                      Tarjeta de crédito
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        )}
        {/* ---------------------------- */}

        {role.name !== "Cliente" && (
          <Grid item xs={12} sm={6}>
            <div
              className="w-100 h-100 d-flex flex-column justify-content-start"
              style={{
                border: "1px dashed darkgray",
                borderRadius: "10px",
                padding: 10,
              }}
            >
              <Typography>Pago mixto</Typography>
              <div className="d-flex">
                <FormControl fullWidth variant="standard">
                  <InputLabel id="invoice_person_type">Pago mixto A</InputLabel>
                  <Select>
                    <MenuItem value="">Seleccione una opción</MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_A: "tarjeta_debito_amount",
                        })
                      }
                      value="tarjeta_debito_amount"
                    >
                      Tarjeta de débito
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_A: "tarjeta_credito_amount",
                        })
                      }
                      value="tarjeta_credito_amount"
                    >
                      Tarjeta de crédito
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_A: "efectivo_amount",
                        })
                      }
                      value="cash"
                    >
                      Efectivo
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_A: "transferencia_amount",
                        })
                      }
                      value="transfer"
                    >
                      Transferencia
                    </MenuItem>
                  </Select>
                  {/* <TextField
                    onChange={(e) =>
                      onSelectCard({
                        payment_mixto_A_amount: e.target.value,
                      })
                    }
                    name="pago_mixto_A"
                  /> */}
                  <Input
                    onChange={(e) => {
                      onSelectCard({
                        payment_mixto_A_amount: e.target.value,
                      });
                      handleInputA(e);
                    }}
                    name="pago_mixto_A"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>
              </div>
              {/* segundo input */}
              <div className="d-flex" style={{ marginTop: 10 }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="invoice_person_type">Pago mixto B</InputLabel>
                  <Select>
                    <MenuItem value="">Seleccione una opción</MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_B: "tarjeta_debito_amount",
                        })
                      }
                      value="tarjeta_debito_amount"
                    >
                      Tarjeta de débito
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_B: "tarjeta_credito_amount",
                        })
                      }
                      value="tarjeta_credito_amount"
                    >
                      Tarjeta de crédito
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_B: "efectivo_amount",
                        })
                      }
                      value="cash"
                    >
                      Efectivo
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onSelectCard({
                          customer: "",
                          payment_method: "mixto",
                          payment_mixto_B: "transferencia_amount",
                        })
                      }
                      value="transfer"
                    >
                      Transferencia
                    </MenuItem>
                  </Select>

                  {/* <TextField
                    name="pago_mixto_B"
                    onChange={(e) =>
                      onSelectCard({
                        payment_mixto_B_amount: e.target.value,
                      })
                    }
                  /> */}
                  <Input
                    name="pago_mixto_B"
                    onChange={(e) => {
                      onSelectCard({
                        payment_mixto_B_amount: e.target.value,
                      });
                      handleInputB(e);
                    }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>
              </div>
              <div style={{ marginTop: 10 }}>
                <Typography>Total: ${totalPayment}</Typography>
              </div>
            </div>
          </Grid>
        )}

        {responsible?.payment_methods?.map((pm) => (
          <Grid item xs={12} sm={6} key={pm.id}>
            <button
              onClick={() =>
                onSelectCard({
                  customer: pm.customer,
                  payment_method: pm.payment_method,
                  last4: pm.card.last4,
                  reference: "",
                  reference_file: null,
                })
              }
              className="btn btn-light w-100 text-left"
              style={{
                border: "1px dashed darkgray",
                borderRadius: "10px",
                padding: 10,
              }}
            >
              <p>
                {responsible?.name} {responsible?.last_name} {responsible?.last_name2}
              </p>
              <p>{pm.store_name}</p>
              <div className="d-flex">
                {getCardBrandIcon(pm.card.brand)}
                <div className="ml-3">
                  {pm.card.brand} ****{pm.card.last4}
                  <br />
                  <span className="text-muted">
                    Exp {pm.card.exp_month}/{pm.card.exp_year?.toString()?.slice(-2)}
                  </span>
                </div>
              </div>
            </button>
          </Grid>
        ))}
      </Grid>
      <div className="d-flex justify-content-between">
        <SimpleCheckbox
          name="direct_card"
          label="Domiciliación de tarjeta"
          // disabled={["transfer", "cash", ""].includes(summary.payment_method)}

          disabled={summary.last4 !== "" ? false : true}
          value={summary.direct_card}
          onChange={({ target }) => handleDirectModal(target.value)}
        />
        <CustomButton
          title="Agregar tarjeta"
          className="my-3"
          onClick={() => setShowCard(true)}
        />
      </div>
      {showCard && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Cards
                cvc={card.cvc}
                expiry={card.expiry}
                name={card.name}
                number={card.number}
                focused={card.focus}
              />
            </Grid>
            <Grid item xs={12} sm={6} component="form" onFocus={handleFocus}>
              <UtilInputs
                width="100%"
                inputValues={cardForm}
                formValues={card}
                onValuesChange={handleChange}
              />
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end">
            <CustomButton
              title="Cancelar"
              color="light"
              onClick={() => {
                setShowCard(false);
                setCard(defaultCard);
              }}
            />
            <CustomButton
              title="Aceptar"
              onClick={create}
              disabled={
                loading || !card.cvc || !card.expiry || !card.name || !card.number
              }
              loading={loading}
            />
          </div>
        </>
      )}
      <BaseModal
        hideTitle
        cancelLabel="No"
        confirmLabel="Si"
        open={directCardModal}
        onClose={() => handleDirectModal(false)}
        onCancel={() => handleDirectModal(false)}
        onConfirm={() => setDirectCardModal(false)}
      >
        <h5>¿Estás seguro que deseas domiciliar tu tarjeta para futuros pagos?</h5>
      </BaseModal>
    </>
  );
};

export default PaymentMethodTab;
