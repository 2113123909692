import React, { useState } from "react";
import { connect } from "react-redux";
import { Paper } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import styles from '../../../tablas/Tablas.module.css'

import RoleDialog from './RoleDialog';
import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import CustomButton from "../../../../../common/components/CustomButton";

function ConfiguracionRoles({roles, setRole, user, permit}) {
  const [dialog, setDialog] = useState('closed') // closed, editing, creating

  const columns =[
    {
      name: 'id',
      label: 'id',
      options:{
        filter:false,
        sort: false,
        display: false
      }
    },
    {
      name: "name",
      label: "Rol",
      options: {
       filter: false,
       sort: false,
      }
     },
    {
      name: "description",
      label: "Descripción",
      options: {
       filter: false,
       sort: false,
      }
     },
    {
      name: "location",
      label: "Ubicación",
      options: {
        filter: false,
        sort: false,
        display: !user.role.location_id,
        customBodyRender:(data, dataIndex, rowIndex) => {
          return data ? data.name : 'Todos'
        }
      }
    },
    {
      name: "permissions.length",
      label: "Permisos",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "color",
      label: "Color",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(data, dataIndex, rowIndex) => {
          return <div
            style={{
            width: "30px",
            height: "10px",
            borderRadius: "10px",
            backgroundColor: data
          }}
        />
        }
      }
    },
  ]

  const options = {
    responsive: 'scrollMaxHeight',
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    onRowClick: data => openDialog('editing', data[0]),
    download: false,
    textLabels:{
      body:{
        noMatch: "No se encontraron resultados"
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },toolbar: {
        search: "Buscar",
      }
    }
  };

  function openDialog(mode, id = null){
    switch (mode) {
      case 'editing':
        configCRUD.getRole(setRole, id).then(() => setDialog(mode))
        break;
      case 'creating':
        setRole(configCRUD.defaultRole)
        setDialog(mode)
        break;
      case 'closed':
        setDialog(mode)
        setTimeout(() => {
          setRole(configCRUD.defaultRole)
        }, 200);
        break;
      default:
        console.error('Invalid dialog mode');
    }
  }

  return (

    <>
      <div className="text-right mb-4">
        {permit.rolesCreate && <CustomButton title="Crear rol" onClick={() => openDialog('creating')} />}
      </div>
      <Paper>
      <div className={styles.wrapper}>
        <MUIDataTable
          title={"Roles"}
          data={roles.data}
          columns={columns}
          options={options}
        />
      </div>
      </Paper>
      <RoleDialog mode={dialog} setDialog={openDialog} permit={permit}/>
  </>
  )

}

const mapStateToProps = store => ({
  roles: store.config.roles,
  user: store.auth.user.user,
});

export default connect(mapStateToProps, config.actions)(ConfiguracionRoles)
