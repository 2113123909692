import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Select,
  Button,
  DialogContent,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import { Formik } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import * as user from "../../../store/ducks/users.duck";
import * as UsersCRUD from "../../../crud/users.crud";
import DateFnsUtils from "@date-io/date-fns";
import clsx from "clsx";
import GSearch from "../../../utils/GSearch";
import notice from "../../../utils/notice";

const UserDialog = ({
  mode,
  setDialog,
  defUser,
  updateUser,
  getRoles,
  permit,
  locations,
}) => {
  const open = mode === "editing" || mode === "creating";
  const title = defUser.id ? "Editar usuario" : "Nueva usuario";
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const create = (values) => {
    const userCheck = {
      id: defUser.id,
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      role_id: values.role_id,
      active: values.active,
      date_of_birth: values.date_of_birth,
      blood_type: values.blood_type,
      address: values.address,
      address_number: values.address_number,
      location_id: values.location_id,
      // documents: []
    };
    UsersCRUD.saveUser(userCheck, updateUser)
      .then(() => {
        setDialog("closed");
        notice("Usuario guardado", "success");
      })
      .catch((e) =>
        notice(
          Array.isArray(e.errors)
            ? e.errors.join("\n")
            : "Error con su solicitud"
        )
      )
      .finally(disableLoading);
  };

  const update = (values) => {
    const userCheck = {
      id: defUser.id,
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      role_id: values.role_id,
      active: values.active,
      date_of_birth: values.date_of_birth,
      blood_type: values.blood_type,
      address: values.address,
      address_number: values.address_number,
      location_id: values.location_id,
      // documents: []
    };
    UsersCRUD.updateUser(userCheck, updateUser)
      .then(() => {
        setDialog("closed");
        notice("Usuario guardado", "success");
      })
      .catch((e) =>
        notice(
          Array.isArray(e.errors)
            ? e.errors.join("\n")
            : "Error con su solicitud"
        )
      )
      .finally(disableLoading);
  };

  let disableTexts = true;
  if (
    (defUser.id && permit.usersUpdate) ||
    (!defUser.id && permit.usersCreate)
  ) {
    disableTexts = false;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: defUser.name,
            last_name: defUser.last_name,
            email: defUser.email,
            password: defUser.password,
            role_id: defUser.role_id,
            active: defUser.active,
            date_of_birth: defUser.date_of_birth,
            blood_type: defUser.blood_type,
            address: defUser.address,
            address_number: defUser.address_number,
            location_id: defUser.location_id,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) errors.name = "Campo Requerido";
            if (!values.last_name) errors.last_name = "Campo Requerido";
            if (!values.email) errors.email = "Campo Requerido";
            // if(!values.password) errors.password = 'Campo Requerido'
            if (!values.role_id) errors.role_id = "Campo Requerido";

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            defUser.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      margin="normal"
                      label="Nombre"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      disabled={disableTexts}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      margin="normal"
                      label="Apellidos"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.last_name && errors.last_name}
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      disabled={disableTexts}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="email"
                      margin="normal"
                      label="Correo Electronico"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      disabled={defUser.id !== null || disableTexts}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="password"
                      margin="normal"
                      label="Contraseña"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // opcional. ya que no permite editar usuario hasta que muestre la contraseña
                      // helperText={touched.password && errors.password}
                      // error={Boolean(touched.password && errors.password)}
                      fullWidth
                      disabled={defUser.id !== null || disableTexts}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.role_id && errors.role_id)}
                    >
                      <InputLabel htmlFor="simple-select-label">
                        Role
                      </InputLabel>
                      <Select
                        value={values.role_id}
                        onChange={handleChange}
                        labelId="simple-select-label"
                        name="role_id"
                        onBlur={handleBlur}
                        disabled={disableTexts}
                      >
                        {getRoles.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="mui-pickers-date"
                        label="Fecha de nacimiento"
                        name="date_of_birth"
                        value={values.date_of_birth}
                        onChange={(date) =>
                          handleChange({
                            target: { value: date, name: "date_of_birth" },
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={disableTexts}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="simple-select-label">
                        Ubicaciones
                      </InputLabel>
                      <Select
                        labelId="simple-select-label"
                        name="location_id"
                        value={values.location_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableTexts}
                      >
                        {locations.map((loc) => (
                          <MenuItem key={loc.id} value={loc.id}>
                            {loc.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      margin="normal"
                      label="Tipo de Sangre"
                      name="blood_type"
                      value={values.blood_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={disableTexts}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <GSearch
                      addressValue={values.address}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel>Estatus del cliente</FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="active"
                            checked={values.active}
                            value={values.active}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disableTexts}
                          />
                        }
                        label={values.active ? "Activo" : "Inactivo"}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  {defUser.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  defUser: store.user.user,
  getRoles: store.config.roles.data,
});

export default connect(mapStateToProps, user.actions)(UserDialog);
