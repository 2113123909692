import { Edit } from "@material-ui/icons";
import React from "react";
import IconButton from "../../../../common/components/IconButton";

const ReportesColumn = (handleSelectId, data, dataRow) => {
  return (
    <div className="d-flex justify-content-around">
      <IconButton
        tooltip="Editar instructor"
        color="primary"
        Icon={Edit}
        onClick={() => handleSelectId(data)}
      />
    </div>
  );
};

export default ReportesColumn;
