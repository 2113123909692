import React from 'react';
import { connect } from "react-redux";
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, makeStyles } from '@material-ui/core';

import * as hlp from "../../../store/ducks/help.duck";
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenVideo({mode, setVideoDialog, helpVideo}) {
  const classes = useStyles();
  const open = mode === 'open'
  const handleClose = () => {
    setVideoDialog('closed');
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar} style={{backgroundColor:'#001359'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {helpVideo.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{width:"100%", height:"calc(100% - 64px)", padding:'10px', backgroundColor:'#212121'}}>
        <iframe title="video" style={{width:"100%", height:"100%"}} src={helpVideo.link} frameBorder="0"></iframe>
      </div>
    </Dialog>
  );
}

const mapStateToProps = store => ({
  helpVideo: store.hlp.helpVideo
});

export default connect(mapStateToProps, hlp.actions)(FullScreenVideo)
