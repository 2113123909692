import React from "react";
import ReactDOM from "react-dom";
import "./notice.css";

let timeoutHandle = null;

export default function notice(message, status = "error", timeout = 4000) {
  clearTimeout(timeoutHandle);
  ReactDOM.unmountComponentAtNode(document.getElementById("notice"));
  ReactDOM.render(
    <Notice message={message} status={status} timeout={timeout} />,
    document.getElementById("notice")
  );
  timeoutHandle = setTimeout(() => {
    ReactDOM.unmountComponentAtNode(document.getElementById("notice"));
  }, timeout);
}

function Notice(props) {
  let style = {};
  switch (props.status) {
    case "error":
      style = {
        backgroundColor: "#d32f2f",
        position: "fixed",
      };
      break;
    case "info":
      style = { backgroundColor: "#859de0" };
      break;
    case "success":
      style = { backgroundColor: "#319631", position: "fixed" };
      break;
    case "warning":
      style = { backgroundColor: "#ffa500", position: "fixed" };
      break;
    default:
      style = { backgroundColor: "#d32f2f", zIndex: 9999 };
  }

  const message = () => {
    if (Array.isArray(props.message)) {
      return props.message.map((msg, i) => <li key={i}>{msg}</li>);
    } else {
      return <li>{props.message}</li>;
    }
  };

  return (
    <div className={"notice"} style={style}>
      <ul>{message()}</ul>
    </div>
  );
}
