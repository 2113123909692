import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import CustomButton from "../../../common/components/CustomButton";
import SimpleSwitch from "../../../common/components/SimpleSwitch";
import FullTable from "../../../common/components/FullTable";
import InstructorsDialog from "./InstructorsDialog";
import DeleteModal from "./components/DeleteModal";
import notice from "../../../utils/notice";

import { instructorPermissions } from "../../../utils/permissions";
import { setLoader } from "../../../store/ducks/loader.duck";
import * as user from "../../../store/ducks/users.duck";
import { defaultUser, getUser, updateUser } from "../../../crud/users.crud";
import {
  getInstructorsList,
  searchInstructors,
  searchInstructorsByLocation,
  searchLocation,
} from "../../../crud/instructors.crud";
import { getLocations } from "../../../crud/config.crud";
import { columns, defaultFilter, filtersForm, options } from "./utils";
import Loading from "../../../utils/Loading";
import UtilInputs from "../../../common/components/UtilInputs";

const Instructors = ({ setUser }) => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState("closed");
  const [id, setID] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [newInstructors, setNewInstructors] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [clearText, setClearText] = useState(false);

  const [locationsInput, setLocationsInput] = useState([]);
  const [locations, setLocations] = useState([]);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [showActives, setShowActives] = useState(true);
  const fetchInstructors = () => {
    dispatch(setLoader(true));
    return getInstructorsList(page, showActives)
      .then((res) => {
        setInstructors(res.users);
        setCount(res.meta?.pagination?.total_objects);
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    fetchInstructors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, showActives]);

  const fetchLocations = () => {
    dispatch(setLoader(true));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });

        setLocations(res);
        setLocationsInput(
          res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id }))
        );
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };
  useEffect(() => {
    fetchInstructors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInstructors]);

  useEffect(() => {
    fetchLocations();
    fetchInstructors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permit = instructorPermissions();

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "editing":
        dispatch(setLoader(true));
        getUser(setUser, id)
          .then(() => setDialog(mode))
          .finally(() => dispatch(setLoader(false)));
        break;
      case "creating":
        setUser(defaultUser);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setUser(defaultUser);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  };

  const updateStatus = async (index, status) => {
    try {
      dispatch(setLoader(true));
      let inst = instructors[index];
      inst = { ...inst, active: status };
      await updateUser(inst);
    } catch (error) {
      notice(
        Array.isArray(error?.errors)
          ? error?.errors?.join("\n")
          : "Error con su solicitud"
      );
    } finally {
      dispatch(setLoader(false));
    }
  };

  const deleteInstrutor = async () => {
    try {
      dispatch(setLoader(true));
      let inst = instructors.find((x) => x.id === id);
      inst = { ...inst, instructor_is_active: false };
      await updateUser(inst);
      await fetchInstructors();
      setID(false);
      notice("Usuario archivado", "success");
    } catch (error) {
      notice(
        Array.isArray(error?.errors)
          ? error?.errors?.join("\n")
          : "Error con su solicitud"
      );
    } finally {
      dispatch(setLoader(false));
    }
  };
  const fetchInstructorsQuery = (query, location) => {
    let idLocation;
    if (location) {
      locationsInput.map((loc) => {
        if (loc.text === location) {
          idLocation = loc.id;
        }
      });
    } else {
      locationsInput.map((loc) => {
        if (loc.text === filter.client_location) {
          idLocation = loc.id;
        }
      });
    }

    setLoading(true);
    if (idLocation && query) {
      searchInstructorsByLocation(setInstructors, query, idLocation)
        .then(console.log)
        .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
        .finally(() => setLoading(false));
    } else if (query) {
      searchInstructors(setInstructors, query)
        .then(console.log)
        .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
        .finally(() => setLoading(false));
    } else if (idLocation && query === "") {
      searchLocation(setInstructors, idLocation)
        .then(console.log)
        .catch((e) => notice(e.join("\n") || "Error en su solicitud"))
        .finally(() => setLoading(false));
    } else {
      fetchInstructors();
      setLoading(false);
    }
  };
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
    if (value === "") {
      fetchInstructors();
    } else {
      fetchInstructorsQuery(searchText, value);
    }
  };

  if (loading) return <Loading />;
  return (
    <>
      <div style={{ width: 300 }}>
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={filtersForm(locationsInput)}
          className="mb-3"
        />
      </div>
      <div className="text-right mb-4">
        <SimpleSwitch
          label="Mostrar activos"
          value={showActives}
          onChange={({ target }) => setShowActives(target.value)}
        />

        {permit.instructorsCreate && (
          <CustomButton
            title="Crear Instructor"
            onClick={() => openDialog("creating")}
            className="ml-4"
          />
        )}
      </div>

      <FullTable
        title={"Instructores"}
        data={instructors}
        columns={columns(locations, updateStatus, setID, openDialog)}
        options={options(
          page,
          setPage,
          count,
          searchText,
          setSearchText,
          fetchInstructorsQuery
        )}
      />
      <InstructorsDialog
        mode={dialog}
        setDialog={openDialog}
        permit={permit}
        locations={locations}
        setNewInstructors={setNewInstructors}
      />
      <DeleteModal id={id} setID={setID} deleteInstrutor={deleteInstrutor} />
    </>
  );
};

export default connect(null, user.actions)(Instructors);
