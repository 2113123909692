import React, { useEffect, useState } from "react";

import FullTable from "../../../../common/components/FullTable";

import { getDebtors } from "../../../../crud/debtors.crud";
import { debtorsColumns } from "./utils";

const Debtors = () => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    getDebtors()
      .then(setResults)
      .catch(console.log);
  }, []);
  return (
    <>
      <FullTable title={"Alumnos con adeudos"} data={results} columns={debtorsColumns} />
    </>
  );
};

export default Debtors;
