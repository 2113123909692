import React from 'react'
import { FormControl, Select, MenuItem, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'

export const MantDissolvedSolids = ({values, handleChange, users}) => {

  return (
    <Table style={{marginTop: 20}}>
      <TableHead>
        <TableRow>
          <TableCell align="right" colSpan={2}>Sólidos disueltos</TableCell>
          <TableCell align='right' >Responsable</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Mañana (06:00 - 07:00)</TableCell>
          <TableCell align="center">
            <TextField 
              name='dissolved_solids'
              type='number'
              fullWidth
              defaultValue={values.total_dissolved_solid.dissolved_solids}
              onChange={(e) => handleChange({target: values.total_dissolved_solid = {...values.total_dissolved_solid, [e.target.name]: e.target.value}})}
            />
          </TableCell>
          <TableCell align='right'>
            <FormControl margin='normal' fullWidth >
              <Select
                name='user_id'
                defaultValue={values.total_dissolved_solid.user_id}
                onChange={(e) => handleChange({target: values.total_dissolved_solid = {...values.total_dissolved_solid, [e.target.name]: e.target.value}}) }
                >
                {users.data.map(user => (
                  <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                ))}
              </Select>
            </FormControl> 
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
