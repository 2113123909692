import React, { useState } from 'react'
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Typography, FormLabel, FormGroup, FormControlLabel, Switch, Checkbox, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { Formik } from 'formik';
import clsx from 'clsx';

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from '../../../../../utils/notice';

const ConfGroupsDialog = ({ mode, setDialog, updateGroups, group, categories, levels, users }) => {
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({paddingRight: "2.5rem"});
  const [loading, setLoading] = useState(false);

  const open = mode === 'editing' || mode === 'creating'
  const title = mode === 'editing' ? 'Editar Grupo' : 'Crear Grupo'

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
  setLoading(false);
  setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const create = values => {
    const checkGroup = {
      name: values.name,
      availability: values.availability,
      description: values.description,
      category_id: values.category_id,
      level_ids: values.level_ids,
      user_id: values.user_id,           // users vendrian a ser los instructores
      schedules: values.schedules,
      active: values.active,
    }
    configCRUD.saveGroup(checkGroup, updateGroups)
    .then(() => {
      setDialog('closed')
      notice("Grupo guardado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  const update = values => {
    const checkGroup = {
      id: group.id,
      name: values.name,
      availability: values.availability,
      description: values.description,
      category_id: values.category_id,
      level_ids: values.level_ids,
      user_id: values.user_id,           // users vendrian a ser los instructores
      schedules: values.schedules,
      active: values.active,
    }
    configCRUD.updateGroup(checkGroup, updateGroups)
    .then(() => {
      setDialog('closed')
      notice("Grupo actualizado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  // let selectedDate = new Date(group.schedule)
  // selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset())

  const instructors = users.filter(x => x.role.name === 'Instructor Principal')

  return (   
    <Dialog
      open={open}
      fullWidth={true} maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              name: group.name,
              availability: group.availability,
              description: group.description,
              category_id: group.category_id,
              level_ids: group.level_ids,
              user_id: group.user === null ? null : group.user.id,           // users vendrian a ser los instructores
              schedules: group.schedules,
              active: group.active,
            }}

            validate={values => {
              const errors={}
              if (!values.name) errors.name = 'Campo requerido'
              if (!values.availability) errors.availability = 'Campo requerido'
              if (!values.category_id) errors.category_id = 'Campo requerido'
              if (!values.user_id) errors.user_id = 'Campo requerido'
              if (!values.level_ids.some(x => x)) errors.level_ids = 'Campo requerido'
              if (!values.schedules.some(x => x.state)) errors.schedules = 'Agregue por lo menos un horario'

              return errors
            }}
            onSubmit={(values, {setSubmitting}) => {
              enableLoading()
              group.id ? update(values) : create(values)
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <form
                noValidate={true}
                autoComplete='off'
                onSubmit={handleSubmit}
                className='kt-form'
              >
                <div className='form-group'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin='normal'
                        name='name'
                        label='Nombre'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        type='number'
                        margin='normal'
                        name='availability'
                        label='Cupo'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.availability === null ? '' : values.availability}
                        helperText={touched.availability && errors.availability}
                        error={Boolean(touched.availability && errors.availability)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin='normal'
                        name='description'
                        label='Descripción'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth margin='normal' error={Boolean(touched.category_id && errors.category_id)}>
                        <InputLabel>Categoría</InputLabel>
                        <Select
                          name='category_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.category_id === null ? '' : values.category_id}
                        >
                        {categories.map(cat => (
                          <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                        ))}
                        </Select>
                        <FormHelperText>{touched.category_id && errors.category_id}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth margin='normal' error={Boolean(touched.user_id && errors.user_id)}>
                        <InputLabel>Instructor</InputLabel>
                        <Select
                          name='user_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.user_id === null ? '' : values.user_id}
                        >
                        {instructors.map(inst => (
                          <MenuItem key={inst.id} value={inst.id}>{inst.name}</MenuItem>
                        ))}
                        </Select>
                        <FormHelperText>{touched.user_id && errors.user_id}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth margin='normal' error={Boolean(touched.level_ids && errors.level_ids)}>
                        <InputLabel>Nivel</InputLabel>
                        <Select
                          multiple
                          name='level_ids'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.level_ids}
                        >
                        {levels.map(lev => (
                          <MenuItem key={lev.id} value={lev.id}>{lev.name}</MenuItem>
                        ))}
                        </Select>
                        <FormHelperText>{touched.level_ids && errors.level_ids}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth margin='normal'>
                        <FormLabel htmlFor="simple-select-label">Estado</FormLabel>
                        <FormControlLabel
                          label={values.active? 'Activo' : 'Inactivo'}
                          control={
                            <Checkbox
                              name='active' 
                              checked={values.active}
                              value={values.active}
                              onChange={handleChange}
                              onBlur={handleBlur}
                          />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin='normal' error={Boolean(touched.schedules && errors.schedules)}>
                        <FormLabel>Horario</FormLabel>
                        <FormHelperText>{touched.schedules && errors.schedules}</FormHelperText>
                          <FormGroup>
                          {values.schedules.sort((a, b) => a.id > b.id ? 1 : 0).map((week, i) => (
                            <div key={week.day_of_week} className='schedule-box'>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={3}>
                                  <Typography>{week.day_of_week}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <FormControlLabel control={
                                    <Switch
                                      id='state'
                                      checked={week.state}
                                      onChange={(e, value) => {
                                        handleChange({ target: values.schedules[i] = { ...values.schedules[i], state: value, name: 'state' } })
                                        delete values.schedules[i].name
                                      }}
                                    />
                                  }/>
                                </Grid>
                                {week.state ?
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} key={i}>
                                    <Grid item xs={6} sm={3}>
                                      <TimePicker autoOk
                                        maxDate={values.schedules[i].end_time}
                                        value={values.schedules[i].start_time}
                                        onChange={(date) => handleChange({target: values.schedules[i] = { ...values.schedules[i], start_time: date, name:'start_time' } })}
                                      />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                      <TimePicker autoOk 
                                        minDate={values.schedules[i].start_time}
                                        value={values.schedules[i].end_time}
                                        onChange={(date) => handleChange({target: values.schedules[i] = { ...values.schedules[i], end_time: date, name:'end_time' } })}
                                      />
                                    </Grid>
                                  </MuiPickersUtilsProvider>
                                : null }
                              </Grid>
                              <hr />
                            </div>
                          ))}
                          </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className='btn-dialog'>
                  <Button onClick={() => setDialog('closed')} color="primary">
                    Cancelar
                  </Button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                  }
                  )}`}
                  style={loadingButtonStyle}
                  >
                    {mode === 'editing' ? 'Editar' : 'Crear'}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  group: store.config.group,
  clients: store.clients.clients.data,
  categories: store.config.categories.data,
  levels: store.config.levels.data,
  programs: store.config.programs.data,
})

export default connect(mapStateToProps, config.actions)(ConfGroupsDialog)
