import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import styles from "./Requirement.module.css";
import Close from "@material-ui/icons/Close";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import ConfirmDialog from "../../../../../utils/ConfirmDialog";
import notice from "../../../../../utils/notice";

function LevelDialog({
  mode,
  level,
  setDialog,
  updateLevels,
  permit,
  user,
  locations,
  updateLevelRequirements,
}) {
  const open = mode === "editing" || mode === "creating";
  const title = level.id ? "Editar nivel" : "Nueva nivel";
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const [loadingReq, setLoadingReq] = useState(false);
  const [reqButtonStyle, setReqButtonStyle] = useState({
    paddingRight: "2.5rem",
    marginBottom: "15px",
  });
  const [openConfirm, setOpenConfirm] = useState(false);

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const disableLoadingReq = () => {
    setLoadingReq(false);
    setReqButtonStyle({ paddingRight: "2.5rem", marginBottom: "15px" });
  };

  const onSubmitReq = (obj, value) => {
    setLoadingReq(true);
    setReqButtonStyle({ paddingRight: "3.5rem", marginBottom: "15px" });
    setTimeout(() => {
      disableLoadingReq();
      configCRUD.saveLevelRequirement(obj, value);
    }, 1000);
  };

  let disableTexts = true;
  if ((level.id && permit.levelsUpdate) || (!level.id && permit.levelsCreate)) {
    disableTexts = false;
  }

  function create(values) {
    const lvl = {
      name: values.name,
      description: values.description,
      location_id: values.location_id,
      color: values.color,
      requirements: values.requirements,
    };
    configCRUD
      .saveLevel(lvl, updateLevels)
      .then(() => {
        setDialog("closed");
        notice("Niverl guardado", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }

  function update(values) {
    const lvl = {
      id: level.id,
      name: values.name,
      description: values.description,
      location_id: values.location_id,
      color: values.color,
      requirements: values.requirements,
    };
    configCRUD
      .updateLevel(lvl, updateLevels)
      .then(() => {
        setDialog("closed");
        notice("Nivel actualizado", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }

  // const ColorButton = withStyles(theme => ({
  //   root: {
  //     color: theme.palette.getContrastText(green[500]),
  //     backgroundColor: green[500],
  //     '&:hover': {
  //       backgroundColor: green[700],
  //     },
  //   },
  // }))(Button);
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
        </DialogContentText>
        <Formik
          initialValues={{
            name: level.name,
            description: level.description,
            location_id: level.location_id ? level.location_id : locations.data[0].id,
            color: level.color,
            complete_requirements: level.level_requirements,
            requirements: level.level_requirements.map((e) => e.name),
            new_requirement: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Campo requerido";
            }

            if (!values.description) {
              errors.description = "Campo requerido";
            }
            if (values.new_requirement && values.new_requirement.length < 3) {
              errors.new_requirement =
                "El requerimiento debe tener un mínimo de 3 caracteres";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            level.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nombre"
                      margin="normal"
                      className="kt-width-full"
                      disabled={disableTexts}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="color"
                      label="Color"
                      margin="normal"
                      name="color"
                      inputProps={{
                        style: {
                          width: "200px",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color}
                    />
                  </Grid>
                  <Grid item xs={12} sm={user.role.location_id == null ? 6 : 12}>
                    <TextField
                      label="Descripción"
                      margin="normal"
                      className="kt-width-full"
                      name="description"
                      disabled={disableTexts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      helperText={touched.description && errors.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      variant="outlined"
                      multiline
                      rowsMax="4"
                    />
                  </Grid>
                  {/* {user.role.location_id == null ?  */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="simple-select-label">Ubicaciones</InputLabel>
                      <Select
                        value={
                          values.location_id
                          //  == null
                          //   ? locations.data[0].id
                          //   : values.location_id
                        }
                        onChange={handleChange}
                        labelId="simple-select-label"
                        name="location_id"
                        onBlur={handleBlur}
                        disabled={disableTexts}
                      >
                        {locations.data.length > 0 ? (
                          locations.data.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                              {location.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No hay resultados</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ marginBottom: "35px" }}>
                      {values.requirements.length > 0 ? (
                        <p className={styles.subTitle1}>Requisitos</p>
                      ) : null}
                      {values.complete_requirements.map((requirement, index) => {
                        return (
                          <Grid
                            container
                            spacing={2}
                            className={styles.gridContainer}
                            key={requirement.id}
                          >
                            <Grid item xs={disableTexts ? 12 : 11}>
                              <TextField
                                id={"" + index}
                                type="text"
                                margin="normal"
                                className="kt-width-full"
                                disabled={disableTexts}
                                onChange={(e) => {
                                  // cambiar el array puede ser con e.target.id
                                  let temp = values.requirements;
                                  temp[e.target.id] = e.target.value;
                                  handleChange({
                                    target: { name: "requirements", value: temp },
                                  });
                                }}
                                value={requirement.name}
                              />
                            </Grid>
                            {disableTexts || (
                              <Grid item xs={1} className={styles.gridIcon}>
                                <Close
                                  id={"" + index}
                                  onClick={() => setOpenConfirm(true)}
                                  className={styles.buttonStyling}
                                />
                              </Grid>
                            )}
                            <ConfirmDialog
                              openConfirm={openConfirm}
                              setConfirm={closeConfirm}
                              functionConfirm={() => {
                                // cambiar el array puede ser con e.target.id
                                let temp = values.complete_requirements;
                                temp.splice(index, 1);
                                handleChange({
                                  target: { name: "complete_requirements", value: temp },
                                });

                                if (mode === "editing")
                                  configCRUD.deleteLeveRequirement(
                                    requirement.id,
                                    updateLevels
                                  );

                                setOpenConfirm(false);
                              }}
                            />
                          </Grid>
                        );
                      })}

                      {mode === "editing" && (
                        <>
                          {" "}
                          <p className={styles.subTitle2}>Agregar nuevo requisito</p>
                          <Grid
                            container
                            spacing={2}
                            className={styles.gridContainer}
                            style={{ marginBottom: "20px" }}
                          >
                            <Grid
                              item
                              xs={disableTexts ? 11 : 10}
                              style={{ paddingTop: "0px" }}
                            >
                              <TextField
                                style={{ marginTop: "5px !important" }}
                                type="text"
                                margin="normal"
                                className="kt-width-full"
                                name="new_requirement"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(
                                  touched.new_requirement && errors.new_requirement
                                )}
                                helperText={
                                  touched.new_requirement && errors.new_requirement
                                }
                                value={values.new_requirement}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              style={{ paddingTop: "0px" }}
                              className={styles.gridIcon}
                            >
                              <button
                                type="reset"
                                disabled={values.new_requirement.length < 3}
                                style={reqButtonStyle}
                                onClick={(e) => {
                                  // cambiar el array puede ser con e.target.id
                                  //let temp = values.complete_requirements
                                  //temp.push({name: values.new_requirement, level_id: level.id})
                                  //handleChange({target:{name:'requirements',value:temp}})
                                  handleChange({
                                    target: { name: "new_requirement", value: "" },
                                  });
                                  //TODO - Agregar aqui el create de l requirement cuando sea edición
                                  if (mode === "editing")
                                    onSubmitReq(
                                      {
                                        name: values.new_requirement,
                                        level_id: level.id,
                                      },
                                      updateLevelRequirements
                                    );
                                  //configCRUD.saveLevelRequirement({name: values.new_requirement, level_id: level.id}, updateLevels)
                                }}
                                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                  {
                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loadingReq,
                                  }
                                )}`}
                              >
                                Crear
                              </button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {level.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store) => ({
  level: store.config.level,
  user: store.auth.user.user,
  locations: store.config.locations,
});

export default connect(mapStateToProps, config.actions)(LevelDialog);
