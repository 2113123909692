import React, { useEffect, useState } from "react";
import { Delete, Edit } from "@material-ui/icons";
import IconButton from "../../../../common/components/IconButton";
import { DialogAction } from "./DialogAction";

const ActionsColumn = (
  data,
  setID,
  setOpenDialogDelete
  // openDialogDelete,
  // dataIndex,
  // list,
  // setChangeList
) => {
  return (
    <>
      <div className="d-flex justify-content-around">
        <IconButton
          tooltip="Borrar nivel"
          color="danger"
          Icon={Delete}
          onClick={() => {
            setID(data);
            setOpenDialogDelete(true);
          }}
        />
      </div>
      {/* <DialogAction
        setOpenDialogDelete={setOpenDialogDelete}
        openDialogDelete={openDialogDelete}
        // dataIndex={dataIndex}
        list={list}
        setChangeList={setChangeList}
        data={data}
      /> */}
    </>
  );
};

export default ActionsColumn;
