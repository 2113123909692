import React from "react";
import { useHistory } from "react-router-dom";
import { Paper, useMediaQuery, useTheme } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import CustomButton from "../../../common/components/CustomButton";
import successfull_payment from "../../../../assets/images/successful_payment.png";

const PaymentSuccessful = () => {
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const handleReturn = () => history.goBack();

  return (
    <Paper
      style={{ padding: `20px ${desktopView ? 20 : 10}vw` }}
      className={`h-100 d-flex flex-column align-items-center text-center`}
    >
      <h2>¡Pago exitoso!</h2>
      <CheckCircle style={{ fontSize: "xxx-large" }} className="text-success my-3" />
      <h4 className="text-muted">
        Se ha realizado el pago de forma exitosa.
        <br />
        Se te enviará el comprobante de pago por correo.
      </h4>
      <CustomButton title="Regresar al inicio" onClick={handleReturn} className="my-4" />
      <img
        src={successfull_payment}
        alt="successful_payment"
        width={250}
        className="mt-5"
      />
    </Paper>
  );
};

export default PaymentSuccessful;
