import React, { useState } from "react";
import { connect } from "react-redux";

import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";
import { FormHelperText } from "@material-ui/core";

const PoolsDialog = ({
  mode,
  setDialog,
  pool,
  updatePools,
  user,
  pool_types,
  locations,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const open = mode === "editing" || mode === "creating";
  const title = pool.id ? "Editar Alberca" : "Nueva Alberca";

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  function create(values) {
    const poolData = {
      name: values.name,
      description: values.description,
      dimensions: values.dimensions,
      capacity: values.capacity,
      pool_type_id: values.pool_type_id,
      location_id: values.location_id,
    };
    configCRUD
      .savePool(poolData, updatePools)
      .then(() => {
        setDialog("closed");
        notice("Alberca guardada", "success");
      })
      .catch((e) => notice("Error con su solicitud"))
      .finally(disableLoading);
  }

  function update(values) {
    const poolData = {
      id: pool.id,
      name: values.name,
      description: values.description,
      dimensions: values.dimensions,
      capacity: values.capacity,
      pool_type_id: values.pool_type_id,
      location_id: values.location_id,
    };
    configCRUD
      .updatePool(poolData, updatePools)
      .then(() => {
        setDialog("closed");
        notice("Alberca actualizada", "success");
      })
      .catch((e) => notice(e.join("\n") || "Error con su solicitud"))
      .finally(disableLoading);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: pool.name,
            description: pool.description,
            dimensions: pool.dimensions,
            capacity: pool.capacity,
            pool_type_id: pool.pool_type_id,
            location_id: pool.id ? pool.location_id : locations.data[0].id,
            // location_id: pool.location_id,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Campo requerido";
            }

            if (!values.description) {
              errors.description = "Campo requerido";
            }

            if (!values.dimensions) {
              errors.dimensions = "Campo requerido";
            }

            if (!values.capacity) {
              errors.capacity = "Campo requerido";
            }

            if (!values.pool_type_id) {
              errors.pool_type_id = "Campo requerido";
            }
            if (!values.location_id) {
              errors.location_id = "Campo requerido";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            pool.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      label="Nombre"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      label="Dimensiones"
                      name="dimensions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dimensions}
                      helperText={touched.dimensions && errors.dimensions}
                      error={Boolean(touched.dimensions && errors.dimensions)}
                    />
                  </Grid>
                  <Grid item xs={user.role.location_id == null ? 4 : 6}>
                    <TextField
                      margin="normal"
                      label="Capacidad"
                      name="capacity"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.capacity}
                      helperText={touched.capacity && errors.capacity}
                      error={Boolean(touched.capacity && errors.capacity)}
                    />
                  </Grid>
                  <Grid item xs={user.role.location_id == null ? 4 : 6}>
                    <FormControl
                      fullwidth="true"
                      margin="normal"
                      style={{ width: "100%" }}
                    >
                      <InputLabel htmlFor="pool-type-select-label">
                        Tipo de alberca
                      </InputLabel>
                      <Select
                        value={values.pool_type_id}
                        onChange={handleChange}
                        labelId="pool-type-select-label"
                        name="pool_type_id"
                        onBlur={handleBlur}
                      >
                        {pool_types.data.map((poolType) => (
                          <MenuItem key={poolType.id} value={poolType.id}>
                            {poolType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      fullwidth="true"
                      margin="normal"
                      style={{ width: "100%" }}
                      error={Boolean(touched?.location_id && errors?.location_id)}
                    >
                      <InputLabel htmlFor="location-select-label">Ubicaciones</InputLabel>
                      <Select
                        value={values.location_id}
                        onChange={handleChange}
                        labelId="location-select-label"
                        name="location_id"
                        onBlur={handleBlur}
                      >
                        {locations.data.map((location) => (
                          <MenuItem key={location.id} value={location.id}>
                            {location.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched?.location_id && errors?.location_id}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      label="Descripción"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      helperText={touched.description && errors.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      variant="outlined"
                      multiline
                      rowsMax="4"
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  {pool.id ? "Editar" : "Crear"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  pool: store.config.pool,
  user: store.auth.user.user,
  locations: store.config.locations,
  pool_types: store.config.pool_types,
});

export default connect(mapStateToProps, config.actions)(PoolsDialog);
