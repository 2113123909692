import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

export const NumberInput = ({ inputRef, onChange, decimals, ...other }) => {
  return (
    <NumberFormat
      {...other}
      thousandSeparator
      fixedDecimalScale
      getInputRef={inputRef}
      decimalScale={decimals}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

const SimpleNumber = ({
  label,
  placeholder,
  name,
  onChange = () => {},
  value,
  variant = "standard",
  disabled = false,
  style = {},
  color = "primary",
  className = "",
  error,
  helperText = "",
  isPrice = false,
  isPercentage = false,
  decimals = 0,
  margin = "none",
  onBlur = console.log,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      style={style}
      size="small"
      margin={margin}
      onBlur={onBlur}
      color={color}
      className={className}
      variant={variant}
      placeholder={placeholder ? placeholder : label}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      helperText={helperText}
      error={error}
      InputProps={{
        startAdornment: isPrice && <InputAdornment position="start">$</InputAdornment>,
        endAdornment: isPercentage && <InputAdornment position="end">%</InputAdornment>,
        inputComponent: NumberInput,
        inputProps: {
          decimals,
        },
      }}
    />
  );
};

export default SimpleNumber;
