
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import { Formik } from "formik";
import clsx from "clsx";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";

function AreaDialog({mode, area, setArea, setDialog, permissions, setAreas, updateAreas, permit, user, locations}) {

  const open = mode === 'editing' || mode === 'creating'
  const title = area.id ? 'Editar Area' : 'Nuevo Area'

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  let disableTexts = true
  if((area.id && permit.areasUpdate) || (!area.id && permit.areasCreate)){
    disableTexts = false
  }

  function create(values) {
    const areaCheck = {
        id: area.id,
        name: values.name,
        description: values.description,
        color: values.color
      }
    configCRUD.saveArea(areaCheck, updateAreas)
    .then(() => {
      setDialog('closed')
      notice("Área guardada", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  function update(values) {
    const areaCheck = {
        id: area.id,
        name: values.name,
        description: values.description,
        color: values.color
      }
    configCRUD.updateArea(areaCheck, updateAreas)
    .then(() => {
      setDialog('closed')
      notice("Área actualizada", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }


  return (
    <Dialog open={open} fullWidth={true} maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
        </DialogContentText>
        <Formik
          initialValues={{name: area.name, description: area.description, color: area.color}}
          validate={values => {
            const errors = {}
            if(!values.name){
              errors.name = "Campo requerido"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading()
            area.id ? update(values) : create(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={handleSubmit}
              className='kt-form'>
                <div className='form-group'>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>  
                      <TextField
                        disabled={disableTexts}
                        margin="normal"
                        label="Nombre"
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="color"
                        label="Color"
                        margin="normal"
                        name="color"
                        inputProps={{style: {
                          width: "200px"
                        }}}
                        disabled={disableTexts}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.color}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={disableTexts}
                        margin="normal"
                        label="Descripción de la Area"
                        name='description'
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        variant='outlined'
                        multiline
                        rowsMax="4"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className='btn-dialog'>
                  <Button onClick={() => setDialog('closed')} color="primary">
                    Cancelar
                  </Button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    {area.id ? 'Editar' : 'Crear'}
                  </button>
                </div>

              </form>
          )}

        </Formik>
      </DialogContent>
    </Dialog>
  )


}

const mapStateToProps = store => ({
  area: store.config.area,
  permissions: store.config.permissions.data,
  user: store.auth.user.user,
  locations: store.config.locations
});

export default connect(mapStateToProps, config.actions)(AreaDialog)
