import React from "react";
import { Modal } from "@material-ui/core";
import UtilInputs from "../../../common/components/UtilInputs";
import { consultarFormA, consultarFormB } from "./utils";
import { Button } from "@material-ui/core";

const ModalCut = ({
  locations,
  openA,
  setOpenA,
  filterCutA,
  handleChangeA,
  handleConsultA,
  generarA,
  pdfCorteA,
  setPdfCorteA,

  openB,
  setOpenB,
  filterCutB,
  handleChangeB,
  handleConsultB,
  generarB,
  pdfCorteB,
  setPdfCorteB,
}) => {
  return (
    <Modal
      open={openA ? openA : openB}
      onClose={
        openA
          ? () => {
              setOpenA(false);
              setPdfCorteA("");
            }
          : () => {
              setOpenB(false);
              setPdfCorteB("");
            }
      }
      style={{ overflowY: "auto" }}
    >
      <div
        style={{
          width: "90%",
          margin: "auto",
          marginTop: 50,
          padding: 30,
          borderRadius: 10,
          background: "white",
          height: "auto",
        }}
      >
        <p>{openA ? "Corte tipo A" : "Corte tipo B"}</p>
        <UtilInputs
          formValues={openA ? filterCutA : filterCutB}
          onValuesChange={openA ? handleChangeA : handleChangeB}
          inputValues={openA ? consultarFormA(locations) : consultarFormB(locations)}
          className="mb-3"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={openA ? handleConsultA : handleConsultB}
          style={{ marginRight: 5 }}
        >
          Consultar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={openA ? generarA : generarB}
          style={{ marginRight: 5 }}
        >
          Generar
        </Button>
        <Button
          style={{ background: "rgb(235, 0, 20)", color: "rgb(255, 255, 255)" }}
          variant="contained"
          onClick={
            openA
              ? () => {
                  setOpenA(false);
                  setPdfCorteA("");
                }
              : () => {
                  setOpenB(false);
                  setPdfCorteB("");
                }
          }
        >
          Cerrar
        </Button>
        {pdfCorteA && (
          <div style={{ height: "60vh", marginTop: 10 }}>
            <iframe src={pdfCorteA} height="100%" width="100%"></iframe>
          </div>
        )}
        {pdfCorteB && (
          <div style={{ height: "60vh", marginTop: 10 }}>
            <iframe src={pdfCorteB} height="100%" width="100%"></iframe>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalCut;
