import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Paper } from "@material-ui/core";

import styles from "../../../tablas/Tablas.module.css";
import PoolsDialog from "./PoolsDialog";
import CustomButton from "../../../../../common/components/CustomButton";

export const PoolsTable = ({
  configCRUD,
  pools,
  pool_types,
  setPool,
  permit,
  locations,
  user,
}) => {
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nombre",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: "Descripción",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dimensions",
      label: "Dimensiones",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "capacity",
      label: "Capacidad",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pool_type_id",
      label: "Tipo",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          const pool_type = pool_types.data.find((x) => x.id === data);
          return <div>{pool_type ? pool_type.name : ""}</div>;
        },
      },
    },
    {
      name: "location_id",
      label: "Ubicación",
      options: {
        display: !user.role.location_id,
        sort: false,
        filter: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          const location = locations.data.find((x) => x.id === data);
          return <div>{location ? location.name : ""}</div>;
        },
      },
    },
  ];

  const options = {
    responsive: "scrollMaxHeight",
    download: false,
    sort: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    onRowClick: (data) => openDialog("editing", data[0]),
    textLabels: {
      body: {
        noMatch: "No se encontraron resultados",
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
      },
    },
  };

  const openDialog = (mode, id = null) => {
    switch (mode) {
      case "editing":
        configCRUD.getPool(setPool, id).then(() => setDialog(mode));
        break;
      case "creating":
        setPool(configCRUD.defaultPool);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setPool(configCRUD.defaultPool);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  };

  return (
    <>
      <div className="text-right mb-4">
        {permit.poolsCreate && (
          <CustomButton title="Crear alberca" onClick={() => openDialog("creating")} />
        )}
      </div>
      <Paper>
        <div className={styles.wrapper}>
          <MUIDataTable
            title={"Albercas"}
            data={pools?.data ? pools.data : []}
            columns={columns}
            options={options}
          />
        </div>
      </Paper>
      <PoolsDialog mode={dialog} setDialog={openDialog} permit={permit} />
    </>
  );
};
