import React, { useState } from 'react'
import { Paper, Tabs, Tab } from '@material-ui/core'
import ConfiguracionRoles from './roles/ConfiguracionRoles'
import ConfiguracionPool from "./pools/ConfiguracionPool";
import ConfiguracionLocations from './locations/ConfiguracionLocations';
import ConfiguracionAreas from './areas/ConfiguracionAreas';
import FindOut from './FindOut'

const ConfiguracionGeneral = ({ permit, permissions }) => {
  const [tabValue, setTabValue] = useState(0)

  const renderComponent = () => {
    let component
    switch (tabValue) {
      case 0:
        component = <ConfiguracionRoles permit={permit} />
        break;
      case 1:
        component = <ConfiguracionLocations permit={permit} />
        break
      case 2:
        component = <ConfiguracionAreas permit={permit} />
        break
      case 3:
        component = <ConfiguracionPool permit={permit} />
        break
      case 4:
        component = <FindOut />
        break
      default:
        component = <ConfiguracionRoles />
    }
    return component
  }

  return (
    <>
      <Paper style={{ marginBottom: 20 }}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          centered
        >
          {permit.rolesRead && <Tab label='Roles' />}
          {permit.locationsRead && <Tab label='Ubicaciones' />}
          {permit.areasRead && <Tab label='Áreas' />}
          <Tab label='Albercas' />
          <Tab label='Como te enteraste' />
        </Tabs>
      </Paper>
      {renderComponent()}
    </>
  )
}

export default ConfiguracionGeneral
