import React, { useState } from "react";
import { connect } from "react-redux";
import CustomButton from "../../../common/components/CustomButton";
import FullTable from "../../../common/components/FullTable";
import UserDialog from "./UserDialog";
import { userPermissions } from "../../../utils/permissions";
import * as user from "../../../store/ducks/users.duck";
import * as usersCRUD from "../../../crud/users.crud";
import { columns, options } from "./utils";

const Users = ({ users, setUser, loggedUser, locations }) => {
  const [dialog, setDialog] = useState("closed"); // closed, editing, creating
  const permit = userPermissions();

  function openDialog(mode, id = null) {
    switch (mode) {
      case "editing":
        usersCRUD.getUser(setUser, id).then(() => setDialog(mode));
        break;
      case "creating":
        setUser(usersCRUD.defaultUser);
        setDialog(mode);
        break;
      case "closed":
        setDialog(mode);
        setTimeout(() => {
          setUser(usersCRUD.defaultUser);
        }, 200);
        break;
      default:
        console.error("Error, dialog mode invalid");
    }
  }

  return (
    <>
      <div className="text-right mb-4">
        {permit.usersCreate && (
          <CustomButton
            title="Crear Usuario"
            onClick={() => openDialog("creating")}
          />
        )}
      </div>
      <FullTable
        title={"Usuarios"}
        data={users}
        columns={columns(locations, loggedUser)}
        options={options(openDialog)}
      />
      <UserDialog
        mode={dialog}
        setDialog={openDialog}
        permit={permit}
        locations={locations}
        loggedUser={loggedUser}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  loggedUser: store.auth.user.user,
  users: store.user.users.data,
  permissions: store.config.permissions,
  locations: store.config.locations.data,
});

export default connect(mapStateToProps, user.actions)(Users);
