import React, { Component } from "react";
import { connect } from "react-redux";

import * as hlp from "../../../store/ducks/help.duck";
import * as helpCRUD from "../../../crud/help.crud";
import Loading from "../../../utils/Loading";
import Ayuda from "./Ayuda.js";


class AyudaWrapper extends Component {

  state = {
    tabValue: 0
  }

  componentDidMount() {
    helpCRUD.getHelp(this.props.setHelp)
    //configCRUD.getPermissions(this.props.setPermissions) //TODO: Mover este al login/me
  }

  render () {
    const { isFetched, data } = this.props.help; //Define wich variable contains the necessary data to load the view
    
    /*return (
      <>
        <Ayuda/>
      </>
    )*/

    if ( isFetched || data.length ) { //If we choose to persist data locally, we can load the view without having fetched
      return (
        <>
          <Ayuda/>
        </>
      )
    } else {
      return <Loading/>
    }

  }

}

const mapStateToProps = store => ({
  help: store.hlp.help
});

export default connect(mapStateToProps, hlp.actions)(AyudaWrapper)