import React, { useState } from 'react'
import { useEffect } from 'react'
import FullTable from '../../../common/components/FullTable'
import { cosultConsiliationsMP } from '../../../crud/mpPayments.crud'
import PaymentModal from './PaymentModal'
import { columns } from './utils'

const MPPayments = () => {
  const [modal, setModal] = useState(false)
  const [list, setList] = useState([])

  const openPayment = id => {
    setModal(true)
  }

  useEffect(() => {
    cosultConsiliationsMP()
    .then(({ mercado_pago_details }) => setList(mercado_pago_details))
    .catch(console.log)
  }, [])
  

  return (
    <>
      <FullTable
        title={"Pagos Mercado Pago"}
        data={list}
        columns={columns(openPayment)}
      />
      <PaymentModal open={modal} setOpen={setModal} />
    </>
  )
}

export default MPPayments