import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
import * as clientsCRUD from "../../../../crud/clients.crud";
import moment from "moment";
import "moment/locale/es";
const Inscription = ({ responsible }) => {
  const [inscription, setInscription] = useState([]);
  const [clientPayment, setClientPayment] = useState();

  useEffect(() => {
    clientsCRUD
      .getClientPayments(responsible?.responsible.id)
      .then((res) => {
        setClientPayment(res[0]?.payments[0]?.created_at);
      })
      .catch((e) => console.log("Error con su solicitud"))
      .finally(() => console.log("final"));
  }, [responsible.responsible.id]);

  const fillInformation = (json) => {
    let info = [];
    json.forEach((element) => {
      info.push({
        name: element.full_name,
        // inscription: "12 de pctubre",
        anualiti: "Vigente",
        group: element.groups_prices.group_names,
        class: element.groups_prices.number_of_classes,
        mounth: `$${element.groups_prices.total}`,
      });
    });
    setInscription(info);
  };

  useEffect(() => {
    clientsCRUD.getInscriptionInformation(responsible.responsible.id, fillInformation);
  }, [responsible.responsible.id]);
  let createdDate = moment(clientPayment, "DD-MM-YYYY").format("D [de] MMMM [de] YYYY");
  return (
    <Paper>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Grid item xs={6}>
          <Typography variant="h6" style={{ color: "#001979", padding: "15px" }}>
            Inscripción
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {inscription.map((studentClient) => (
        <>
          <Grid>
            <Typography
              variant="h6"
              style={{ padding: "15px 0px 15px 27px", fontWeight: 600 }}
            >
              {studentClient.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Typography
                variant="body1"
                style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
              >
                Inscripcion
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
              >
                Anualidad
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
              >
                Grupo
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
              >
                Clases a la semana
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#868686", padding: "15px 0px 15px 27px" }}
              >
                Cobro mensual
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body1"
                style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
              >
                {createdDate}
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
              >
                {studentClient.anualiti}
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
              >
                {studentClient.group}
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
              >
                {studentClient.class}
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: "15px 0px 15px 0px", fontWeight: 600 }}
              >
                {studentClient.mounth}
              </Typography>
            </Grid>
          </Grid>
        </>
      ))}
    </Paper>
  );
};

export default Inscription;
