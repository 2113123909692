import { currencyFormat } from "../../../utils/data_formatters";

export const defaultFilter = {
  start_date: null,
  end_date: null,
  client_location: "",
  type_client: "",
};
export const defaultCutFilterA = {
  start_date: null,
  end_date: null,
  location: "",
  total_cuenta_externa: null,
  cobro_factura: null,
};
export const defaultCutFilterB = {
  start_date: null,
  end_date: null,
  location: "",
};
export const consultarFormA = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "client_location",
    label: "Ubicación",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
  // {
  //   name: "total_cuenta_externa",
  //   label: "Sitio en linea (Conekta)",
  //   type: "number",
  //   variant: "filled",
  //   isPrice: true,
  //   decimals: 2,
  // },
  {
    name: "cobro_factura",
    label: "Por cambio de factura",
    type: "number",
    variant: "filled",
    isPrice: true,
    decimals: 2,
  },
];
export const consultarFormB = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "client_location",
    label: "Ubicación",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
];
export const filtersForm = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "client_location",
    label: "Ubicación",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
  {
    name: "type_client",
    label: "Tipo de cliente",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "A", text: "A" },
      { value: "B", text: "B" },
    ],
  },
  // {
  //   label: "Filtrar",
  //   type: "button",
  //   width: 80,
  //   onClick: () => window.alert("Función aún no disponible"),
  // },
  // {
  //   label: "Generar Corte",
  //   type: "button",
  //   onClick: () => window.alert("Función aún no disponible"),
  // },
];

export const CutColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "date_corte",
    label: "Fecha de corte",
    // options: {
    //   filter: false,
    //   sort: false,
    //   display: false,
    // },
  },
  {
    name: "tipo_corte",
    label: "Tipo de corte",
    // options: {
    //   filter: false,
    //   sort: false,
    //   display: false,
    // },
  },
  // {
  //   name: "responsible_fullname",
  //   label: "Responsable",
  //   options: {
  //     filter: false,
  //     sort: true,
  //     sortDirection: "asc",
  //   },
  // },
  // {
  //   name: "type",
  //   label: "Tipo de pago",
  //   options: {
  //     filter: false,
  //     sort: true,
  //   },
  // },
  {
    name: "location.name",
    label: "Sucursal",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "amount",
    label: "Importe",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => data && currencyFormat.format(data),
    },
  },
  // {
  //   name: "payment_type",
  //   label: "Método de pago",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRender: (data) =>
  //       data
  //         ? data === "card"
  //           ? "Tarjeta"
  //           : data === "cash"
  //           ? "Efectivo"
  //           : "Transferencia"
  //         : "",
  //   },
  // },
  // {
  //   name: "invoice_information_id",
  //   label: "Facturada",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRender: (data) => (data !== null ? "Facturado" : "Sin Factura"),
  //   },
  // },
  // {
  //   name: "invoice_type",
  //   label: "Tipo de Factura",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
];
