import React, { useState } from 'react'
import { Paper } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import moment from 'moment';

import styles from '../../tablas/Tablas.module.css'
import MantDailyDialog from './MantDailyDialog';

import * as maintCRUD from "../../../../crud/maint.crud";
import Loading from '../../../../utils/Loading';
import { maintPermissions } from '../../../../utils/permissions';
import CustomButton from '../../../../common/components/CustomButton';

const MantReporteDiario = ({daily_reports, maintActions, pool_types}) => {
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState('closed')
  const permit = maintPermissions()

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "pool.name",
      label: "Alberca",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "pool.pool_type_id",
      label: "Tipo de alberca",
      options: {
        filter: true,
        sort: true,
        customBodyRender:(data, dataIndex, rowIndex) => {
          const pool_type = pool_types.data.find(x=>(x.id === data))
          return(
            <div>{pool_type ? pool_type.name : ""}</div>
          )
        }
      }
    },
    {
      name: "pool.dimensions",
      label: "Dimensiones",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "pool.capacity",
      label: "Capacidad",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "report_date",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "updated_at",
      label: "Última Edición",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return `${moment(data).format('L')} ${moment(data).format('LT')}`
        }
      }
    },
  ];

  const options = {
    responsive: 'scrollMaxHeight',
    sort: false, //el sort y el search no funcionan todavia
    search: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    serverSide: true,
    rowsPerPage: 15,
    count: daily_reports.count,
    searchText: searchText,
    rowsPerPageOptions: [],
    onRowClick: data => openDialog('editing', data[0]),
    downloadOptions: {
      filename: 'Reporte_Diario.csv',
      filterOptions: {useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true}
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const table = buildHead(headerNames) + buildBody(data)
      return table;
    },
    textLabels:{
      body:{
        noMatch: "No se encontraron resultados",
        toolTip: 'Sort',
        columnHeaderTooltip: column => ``
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
      }
    },
    onSearchChange: (text) => {
      setSearchText(text)
      maintCRUD.getDailyReports(maintActions.setDailyReports, 0, text)
    },
  };
  
  const headerNames = [
    {name:'Alberca', download:true },
    {name:'Tipo de alberca', download:true },
    {name:'Dimensiones', download:true },
    {name:'Capacidad', download:true },
    {name:'Fecha', download:true },
    {name:'Ultima edición', download:true },
  ]

  function openDialog(mode, id = null){
    switch(mode) {
      case 'editing':
        maintCRUD.getDailyReport(maintActions.setDailyReport, id).then(() => setDialog(mode))
        break;
      case 'creating':
        maintActions.setDailyReport(maintCRUD.defaultDailyReport)
        setDialog(mode)
        break;
      case 'closed':
        setDialog(mode)
        setTimeout(() => {
          maintActions.setDailyReport(maintCRUD.defaultDailyReport)
        }, 200)
        break;
      default:
        console.error('Error, dialog mode invalid')
    }
  }

  const { isFetched, data } = daily_reports
  if (isFetched || data.length) {
    return (
      <>
        <div className="text-right mb-4">
          {permit.reportsCreate && <CustomButton title="Crear Reporte" onClick={() => openDialog('creating')} />}
        </div>
        <Paper>
          <div className={styles.wrapper}>
          <MUIDataTable
            title={"Reportes Diarios"}
            data={daily_reports.data}
            columns={columns}
            options={options}
          />
          </div>
        </Paper>
        <MantDailyDialog mode={dialog} setDialog={openDialog} permit={permit} pool_types={pool_types}/>
      </>
    )
  } else {
    return <Loading />
  }
}

export default MantReporteDiario