import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Paper } from '@material-ui/core';

import STypesDialog from './STypesDialog';
import styles from '../../tablas/Tablas.module.css'
import CustomButton from '../../../../common/components/CustomButton';

export const STypesTable = (props) => {
    const { suggestion_types, configCRUD, setSuggestionType, permit } = props
    const [dialog, setDialog] = useState('closed') // closed, editing, creating

    const columns =[
        {
        name: 'id',
        label: 'id',
        options:{
            filter:false,
            sort: false,
            display: false
        }
        },
        {
        name: "name",
        label: "Tipos",
        options: {
        filter: false,
        sort: false,
        }
        },
        {
        name: "description",
        label: "Descripción",
        options: {
            filter: false,
            sort: false,
        }
        },
        {
            name: "color",
            label: "Color",
            options: {
                filter: false,
                sort: false,
                customBodyRender:(data, dataIndex, rowIndex) => {
                    return <div
                    style={{
                    width: "30px",
                    height: "10px",
                    borderRadius: "10px",
                    backgroundColor: data
                    }}
                />
                }
            }
        },
    ]
    const options = {
        responsive: 'scrollMaxHeight',
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: 'none',
        onRowClick: data => openDialog('editing', data[0]),
        download: false,
        textLabels:{
        body:{
            noMatch: "No se encontraron resultados"
        },
        pagination: {
            next: "Siguiente",
            previous: "Anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
        },toolbar: {
            search: "Buscar",
        }
        }
    };

    const openDialog = (mode, id = null) => {
        switch (mode) {
            case 'editing':
            configCRUD.getSuggestionType(setSuggestionType, id).then(() => setDialog(mode))
            break;
            case 'creating':
            setSuggestionType(configCRUD.defaultSuggestionType)
            setDialog(mode)
            break;
            case 'closed':
            setDialog(mode)
            setTimeout(() => {
                setSuggestionType(configCRUD.defaultSuggestionType)
            }, 200);
            break;
            default:
            console.error('Invalid dialog mode');
        }
    }

    return (
        <>
            <div className="text-right mb-4">
              {permit.suggestionsTypes && <CustomButton title="Crear sugerencia" onClick={() => openDialog('creating')} />}
            </div>
            <Paper>
                <div className={styles.wrapper}>
                    <MUIDataTable
                    title={"Tipos de sugerencia"}
                    data={suggestion_types.data}
                    columns={columns}
                    options={options}
                    />
                </div>
            </Paper>
            <STypesDialog mode={dialog} setDialog={openDialog} permit={permit}/>
        </>
    )
}
