/* eslint-disable no-throw-literal */
import store from "../../store/store";
import notice from "../../utils/notice";

export function getToken() {
  return store?.getState()?.auth?.authToken;
}

export const axiosConfig = () => {
  return {
    headers: {
      Authorization: store?.getState()?.auth?.authToken,
    },
  };
};

export function getParams(method, body = null) {
  return {
    method: method,
    body: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
  };
}

export function getMultipartParams(method, body = null) {
  return {
    method: method,
    body: body,
    headers: {
      Authorization: getToken(),
    },
  };
}

export const handleFormData = (data) => {
  const formData = new FormData();

  for (const name in data) {
    if (Array.isArray(data[name]))
      data[name].forEach((item) =>
        Object.keys(item).forEach((key) =>
          formData.append(`${name}[][${key}]`, item[key])
        )
      );
    else formData.append(name, data[name]);
  }

  return formData;
};

export const filterParams = (obj) =>
  Object.entries(obj)
    .map((x) => x.join("="))
    .join("&");

export async function handleResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      store.dispatch({ type: "[Logout] Action" });
      notice("Session expired. Please log in again");
      throw { errors: "Session expired" };
      // } else if (response.status === 422 || response.status === 400){
    } else if (response.status >= 400 && response.status < 500) {
      throw await response.json();
    }
    throw { errors: response.statusText };
  } else {
    return response.statusText === "No Content" ? {} : response.json();
  }
}

export const handleError = (e) => {
  throw e;
};

export const handleErrorClient = (e) => {
  return e;
};
