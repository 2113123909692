import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { getClients } from '../../../crud/clients.crud';
import { getLocations, getLevels, getCategories, getGroupPrices } from '../../../crud/config.crud';

import * as clients from '../../../store/ducks/clients.duck'
import * as config from "../../../store/ducks/config.duck";
import { responsiblePermissions } from "../../../utils/permissions";

import Clients from './Clients'
import Loading from '../../../utils/Loading';

class ClientWrapper extends Component {

  componentDidMount() {
    getClients(this.props.clientActions.setClients)
    
    getLocations(this.props.configActions.setLocations)
    getLevels(this.props.configActions.setLevels)
    getCategories(this.props.configActions.setCategories)
    getGroupPrices(this.props.configActions.setGroupPrices)
  }

  render() {
    const { isFetched, data } = this.props.clients
    if (isFetched || data.length) {
      return (
        <>
          <Clients permit={responsiblePermissions()}/>
        </>
      )
    } else return <Loading />
  }
}

const mapStateToProps = store => ({
  clients: store.clients.clients
})

function mapDispatchToProps(dispatch) {
  return {
    clientActions: bindActionCreators(clients.actions, dispatch),
    configActions: bindActionCreators(config.actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWrapper)