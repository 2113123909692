import React, { useState } from "react";
import { connect } from "react-redux";

import { PoolsTable } from "./PoolsTable";
import { PoolTypesTable } from "./PoolTypesTable";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import AntTabs from "../../../../../common/components/AntTabs";
import { Paper } from "@material-ui/core";

const ConfiguracionPool = ({
  pools,
  pool_types,
  setPool,
  setPoolType,
  permit,
  locations,
  user,
}) => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <div className="position-absolute">
        <Paper>
          <AntTabs
            value={tabValue}
            onChange={setTabValue}
            options={[
              { value: 0, label: "Tipos" },
              { value: 1, label: "Albercas" },
            ]}
          />
        </Paper>
      </div>
      {tabValue === 0 && (
        <PoolTypesTable
          configCRUD={configCRUD}
          pool_types={pool_types}
          setPoolType={setPoolType}
          permit={permit}
        />
      )}
      {tabValue === 1 && (
        <PoolsTable
          configCRUD={configCRUD}
          pools={pools}
          pool_types={pool_types}
          setPool={setPool}
          permit={permit}
          locations={locations}
          user={user}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  pools: store.config.pools,
  pool_types: store.config.pool_types,
  locations: store.config.locations,
  user: store.auth.user.user,
});

export default connect(mapStateToProps, config.actions)(ConfiguracionPool);
