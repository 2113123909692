import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { ArrowLeft, ArrowRight, Delete, MoveToInboxTwoTone } from "@material-ui/icons";
import {
  Divider,
  Input,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PaymentDeclainedModal from "./PaymentDeclainedModal";
import UtilInputs from "../../../../common/components/UtilInputs";
import IconButton from "../../../../common/components/IconButton";
import CustomButton from "../../../../common/components/CustomButton";
import default_product from "../../../../../assets/images/default_product.png";
import { createPayment } from "../../../../crud/PointOfSale.crud";
import { BASE_URL } from "../../../../crud/helpers/routes";
import { currencyFormat } from "../../../../utils/data_formatters";
import { summaryForm } from "../utils";
import notice from "../../../../utils/notice";
import Loading from "../../../../utils/Loading";
import PaymentDeclainedProduct from "./PaymentDeclainedProduct";

const SummarySale = ({
  summary,
  setSummary,
  currentTab,
  setTabValue,
  setTotalPayment,
  totalPayment,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const permiso = useSelector((store) => store.auth.user.user.role.name);
  const desktopView = useMediaQuery(theme.breakpoints.up("sm"));
  const [declinedPayment, setDeclinedPayment] = useState(false);
  const [declinedPaymentProduct, setDeclinedPaymentProduct] = useState(false);

  const [loading, setLoading] = useState(false);
  const { role } = useSelector(({ auth }) => auth.user.user);

  const handleProdPriceChange = (e) => {
    const { name, value } = e.target;
    let newProducts = summary.products.map((prod) => {
      if (!isNaN(value) && prod.id == name) {
        return { ...prod, price: value };
      }
      return prod;
    });
    setSummary({ ...summary, products: newProducts });
  };
  const handleFeePriceChange = (e) => {
    const { name, value } = e.target;
    let newFees = summary.other_fees.map((fee) => {
      if (!isNaN(value) && fee.id == name) {
        return { ...fee, price: value };
      }
      return fee;
    });
    setSummary({ ...summary, other_fees: newFees });
  };
  const handleGroupPriceChange = (e) => {
    const { name, value } = e.target;

    let newGroups = summary.payment_groups.map((group) => {
      if (!isNaN(value) && group.id == name) {
        return { ...group, quantity: value };
      }
      return group;
    });
    setSummary({ ...summary, payment_groups: newGroups });
  };
  //OPERACIONES
  const IVA = 0.16;
  let sumPriceProd = summary.products
    .map(({ price, quantity, id }) => price * quantity)
    .reduce((a, b) => Number(a) + Number(b), 0);
  let sumPriceGroup = summary.payment_groups
    .map((pg) => pg?.quantity || 0)
    .reduce((a, b) => Number(a) + Number(b), 0);
  let sumOtherFees = summary.other_fees
    .map((fee) => fee?.price || 0)
    .reduce((a, b) => Number(a) + Number(b), 0);
  let additional_discount_amount =
    summary.additional_discount_type === "percentage"
      ? (Number(sumPriceProd) + Number(sumPriceGroup) + Number(sumOtherFees)) *
        (summary.additional_discount / 100)
      : summary.additional_discount;

  let total =
    Number(sumPriceProd) +
    Number(sumPriceGroup) +
    Number(sumOtherFees) -
    additional_discount_amount;
  let subTotal = total / (IVA + 1);
  let ivaCost = total - subTotal;
  let amount = subTotal;
  let amount_with_discount = total;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setSummary({ ...summary, [name]: value });
  };

  const handleProdQuantity = (index, quantity) => {
    let updatedState = summary.products;
    updatedState[index].quantity += quantity;

    setSummary({ ...summary, products: updatedState });
  };
  const handleRemoveItem = (key, index) => {
    let updatedState = summary[key];
    updatedState.splice(index, 1);
    setSummary({ ...summary, [key]: updatedState });
  };
  const handlePayment = () => {
    if (summary.client == null) {
      notice("No se ha seleccionado un cliente");
    } else {
      setLoading(true);
      createPayment({
        ...summary,
        amount,
        additional_discount_amount,
        amount_with_discount,
      })
        .then((res) => {
          if (res.status === "succeeded" || res.status === "Pago completado")
            return history.push("/pago-exitoso");
          else return setDeclinedPayment(true);
        })
        .catch((e) => {
          if (e.errors[0].status === 422) {
            setDeclinedPaymentProduct(true);
          }
          notice("Ha ocurrido un error con su solicitud");
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`p-4 border-left border-${desktopView ? "left" : "top"}`}>
          <h6>Resumen de compra</h6>
          <div style={{ height: 200, overflow: "auto" }}>
            {!(summary.payment_groups?.length > 0 || summary?.products?.length > 0) && (
              <h6 className="text-muted">Sin productos</h6>
            )}
            {summary.payment_groups?.map((pack, i) => {
              return (
                pack && (
                  <div
                    key={pack.id + i}
                    className="w-100 d-flex justify-content-between p-1"
                  >
                    <div>
                      <p className="font-weight-bold">{pack.name}</p>
                      <p>{pack.period?.text}</p>
                    </div>
                    <div>
                      {/* <TextField value={currencyFormat.format(pack.quantity)} /> */}
                      {permiso === "Admin" ? (
                        <Input
                          // TODO Falta lo de poner concurrencyFormat
                          // type="number"
                          value={Number(pack.quantity).toFixed(2)}
                          name={pack.id}
                          onChange={(e) => handleGroupPriceChange(e)}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      ) : (
                        currencyFormat.format(pack.quantity)
                      )}
                      <IconButton
                        Icon={Delete}
                        tooltip="Eliminar"
                        onClick={() => handleRemoveItem("payment_groups", i)}
                      />
                    </div>
                  </div>
                )
              );
            })}
            {summary.other_fees?.map((fee, i) => {
              return (
                fee && (
                  <div
                    key={fee.id + i}
                    className="w-100 d-flex justify-content-between p-1"
                  >
                    <div>
                      <p className="font-weight-bold">{fee.text}</p>
                    </div>
                    <div>
                      {permiso === "Admin" ? (
                        <Input
                          // TODO Falta lo de poner concurrencyFormat
                          // type="number"
                          value={Number(fee.price).toFixed(2)}
                          // value={fee.price.slice(0, fee.price.lastIndexOf(".") + 3)}
                          name={fee.id}
                          onChange={(e) => handleFeePriceChange(e)}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      ) : (
                        currencyFormat.format(fee.price)
                      )}
                      <IconButton
                        Icon={Delete}
                        tooltip="Eliminar"
                        onClick={() => handleRemoveItem("other_fees", i)}
                      />
                    </div>
                  </div>
                )
              );
            })}
            {summary.products?.map((prod, index) => {
              return (
                <div key={prod.id} className="w-100 d-flex justify-content-between p-1">
                  <div className="d-flex">
                    <img
                      src={prod.image ? `${BASE_URL}${prod.image}` : default_product}
                      alt={prod.name}
                      width={80}
                    />
                    <div className="text-center">
                      <p className="font-weight-normal">{prod.name}</p>
                      <p className="text-muted">{prod.store_category?.name}</p>
                      <div className="d-flex align-items-center">
                        <IconButton
                          onClick={() => handleProdQuantity(index, -1)}
                          style={{ height: 20 }}
                          color="light"
                          Icon={ArrowLeft}
                          disabled={prod.count === 1}
                        />

                        {prod.quantity}
                        <IconButton
                          onClick={() => handleProdQuantity(index, 1)}
                          style={{ height: 20 }}
                          color="light"
                          Icon={ArrowRight}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {permiso === "Admin" ? (
                      <Input
                        // type="number"
                        value={Number(prod.price).toFixed(2)}
                        // value={prod.price.slice(0, prod.price.lastIndexOf(".") + 3)}
                        name={prod.id}
                        onChange={(e) => handleProdPriceChange(e)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    ) : (
                      currencyFormat.format(prod.price)
                    )}
                    <IconButton
                      Icon={Delete}
                      tooltip="Eliminar"
                      onClick={() => handleRemoveItem("products", index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Divider />
          {/* {role.name === "Admin" && ( */}
          <UtilInputs
            width="50%"
            formValues={summary}
            inputValues={summaryForm(
              summary.additional_discount_type,
              summary.note_discount
            )}
            onValuesChange={handleChange}
          />
          {/* )} */}

          <div className="d-flex justify-content-between">
            <p>Subtotal</p>
            <p>{currencyFormat.format(subTotal)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Descuento</p>
            <p>{currencyFormat.format(additional_discount_amount)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>IVA</p>
            <p>{currencyFormat.format(ivaCost)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Total</p>
            <p>{currencyFormat.format(amount_with_discount)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Método de pago</p>
            {!isNaN(Number(summary.last4)) && summary.last4 && <p>****{summary.last4}</p>}
            {summary.payment_method === "cash" && "Efectivo"}
            {summary.payment_method === "transfer" && "Tranferencia"}
            {summary.payment_method === "terminal" && "Terminal"}
            {summary.payment_method === "mixto" && "Mixto"}
          </div>
          {currentTab !== 2 && (
            <CustomButton
              title="Pagar"
              className="w-100 mt-4"
              onClick={() => setTabValue(2)}
              disabled={
                summary.payment_groups?.length === 0 &&
                summary.products.length === 0 &&
                summary.other_fees.length === 0
              }
            />
          )}
          {currentTab === 2 && (
            <CustomButton
              outlined
              title="Regresar"
              className="w-100 my-4"
              onClick={() => setTabValue(0)}
            />
          )}
          {currentTab === 2 &&
            (summary.payment_method !== "mixto" ? (
              <CustomButton
                title="Pagar"
                className="w-100"
                onClick={handlePayment}
                disabled={!summary.payment_method}
              />
            ) : (
              <CustomButton
                title="Pagar"
                className="w-100"
                onClick={handlePayment}
                disabled={totalPayment.toFixed(2) !== total.toFixed(2)}
              />
            ))}
        </div>
      )}
      <PaymentDeclainedModal open={declinedPayment} setOpen={setDeclinedPayment} />
      <PaymentDeclainedProduct
        open={declinedPaymentProduct}
        setOpen={setDeclinedPaymentProduct}
      />
    </>
  );
};

export default SummarySale;
