import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    paddingTop: ({ variant }) =>
      variant === "standard" ? 3 : variant === "filled" ? 18 : 10.5,
    paddingBottom: ({ variant }) => (variant === "standard" ? 7 : 10.5),
  },
}));

const SimpleDropdown = ({
  label,
  name,
  required = "",
  onChange,
  value,
  options = [],
  variant = "standard",
  disabled,
  color = "primary",
  className = "",
  containerClass = "",
  style = {},
  multiple = false,
  margin = "none",
  error,
  helperText = "",
  onBlur = console.log,
}) => {
  const classes = useStyles({ variant });
  return (
    <FormControl
      error={error}
      margin={margin}
      variant={variant}
      fullWidth
      size="small"
      style={style}
      className={containerClass}
    >
      <InputLabel shrink required={required}>
        {label}
      </InputLabel>
      <Select
        displayEmpty
        fullWidth
        onBlur={onBlur}
        margin={margin}
        size="small"
        name={name}
        label={label}
        color={color}
        value={value}
        variant={variant}
        onChange={onChange}
        disabled={disabled}
        className={className}
        classes={classes}
        multiple={multiple}
        renderValue={(selected) => {
          if (selected) {
            if (multiple) {
              let renderMSelected = selected.map(
                (sel) => options.find((op) => sel === op.value)?.text
              );
              return renderMSelected.length > 1
                ? renderMSelected.join(", ")
                : renderMSelected;
            } else return options.find((op) => op.value === selected)?.text;
          } else return "Seleccione una opción";
        }}
      >
        <MenuItem value="">Seleccione una opción</MenuItem>
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem
              key={option?.value?.id ? option.value.id : option.value}
              value={option?.value?.id ? option.value.id : option.value}
              disabled={option.disabled}
            >
              <ListItemText
                primary={option.text}
                secondary={option.text2}
                className="d-flex align-items-end justify-content-between m-0"
                primaryTypographyProps={{ style: { lineHeight: "inherit" } }}
                secondaryTypographyProps={{
                  className: option.text2_className,
                  style: option.text2_style,
                }}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem>No hay resultados</MenuItem>
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleDropdown;
