import React from 'react'
import SVG from "react-inlinesvg";
import { Tooltip } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../_metronic';

const IconButton = ({ onClick=()=>{}, color="light", label="", disabled, style={}, tooltip='', src, Icon, className="" }) => {
  return (
    <>
      {!tooltip ?
        <button
          className={`btn btn-icon btn-${color} btn-sm ${className}`}
          onClick={onClick}
          disabled={disabled}
          style={{ cursor: disabled && 'not-allowed', ...style }}
        >
          {src&& <SVG src={toAbsoluteUrl(src)} />}
          {Icon&&<Icon/>}
          <p style={{ marginBottom: -5}}>{label}</p>
        </button>
      : <Tooltip title={tooltip} arrow>
        <button
          className={`btn btn-icon btn-${color} btn-sm ${className}`}
          onClick={onClick}
          disabled={disabled}
          style={{ cursor: disabled && 'not-allowed', ...style }}
        >
          {src&&<span className={`svg-icon`} >
            <SVG src={toAbsoluteUrl(src)} height="auto" />
          </span>}
          {Icon&&<Icon/>}
        </button>
      </Tooltip>
      } 
    </>
  )
}

export default IconButton