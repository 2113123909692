/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useState } from 'react';
import {
    Redirect,
    Route,
    Switch,
    withRouter,
} from 'react-router-dom';
import { shallowEqual, useSelector, connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import HomePage from '../pages/home/HomePage';
import AuthPage from '../pages/auth/AuthPage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import { LayoutContextProvider } from '../../_metronic';
import * as routerHelpers from '../router/RouterHelpers';

import * as auth from '../store/ducks/auth.duck';
import { getUserByToken } from '../crud/auth.crud';
import store from '../store/store';
const Routes = withRouter(({ Layout, history, requestUser }) => {
    const [fetched, setFetched] = useState(false);

    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    //TODO: Change menuConfig according to permissions after the selector is called
    const {
        isAuthorized,
        menuConfig,
        userLastLocation,
    } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation(),
        }),
        shallowEqual
    );
    if (!fetched) {
        const token = store.getState().auth.authToken;
        if (token) {
            console.log('authenticating...');
            getUserByToken(requestUser).then(
                console.log('authenticated!')
            );
            setFetched(true);
        }
    }

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider
            history={history}
            menuConfig={menuConfig}
        >
            <Switch>
                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <Route path='/auth' component={AuthPage} />
                ) : (
                    /* Otherwise redirect to root page (`/`) */
                    <Redirect from='/auth' to={userLastLocation} />
                )}

                <Route path='/error' component={ErrorsPage} />
                <Route path='/logout' component={LogoutPage} />

                {!isAuthorized ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to='/auth/login' />
                ) : (
                    <Layout>
                        <HomePage
                            userLastLocation={userLastLocation}
                        />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
});

export default connect(null, auth.actions)(Routes);
