import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { Formik } from "formik";
import clsx from "clsx";
import UtilInputs from "../../../../common/components/UtilInputs";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";
import notice from "../../../../utils/notice";
import { paymentForm } from "./utils";

function PaymentDialog({ mode, group_price, setDialog, updateGroupPrices }) {
  const open = mode === "edit" || mode === "create";
  const title = group_price?.id ? "Editar precio por grupo" : "Nuevo precio por grupo";
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  useEffect(() => {
    configCRUD.getCategoryPrices().then((res) => {
      if (res.Success) {
        setCategories(res.Success[0].data);
      } else {
        setCategories([]);
      }
    });
  }, []);
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function create(values) {
    let categoryName;
    categories.map((category) => {
      if (values.category_id === category.value) {
        categoryName = category.text;
      }
    });

    let categoryValues = { ...values, category_name: categoryName };
    configCRUD
      .saveGroupPrice(categoryValues, updateGroupPrices)
      .then(() => {
        setDialog("closed");
        notice("Pago guardado", "success");
      })
      .catch((e) => notice(e.errors.map((x) => x.title) || "Error con su solicitud"))
      .finally(disableLoading);
  }
  function update(values) {
    if (values.category_id === group_price.category_id) {
      delete values.category_id;
    }
    configCRUD
      .updateGroupPrice(values, updateGroupPrices)
      .then(() => {
        setDialog("closed");
        notice("Pago actualizado", "success");
      })
      .catch((e) => notice(e.errors.map((x) => x.title) || "Error con su solicitud"))
      .finally(disableLoading);
  }

  const percentage = (price, percent) => {
    if (price) {
      let total = price * (1 - percent / 100);
      return total.toFixed(2);
    }
  };

  const total = (price, percent, classes) => {
    let valuePercent;
    if (percent === undefined) {
      valuePercent = 0;
    } else {
      valuePercent = percent;
    }
    if (price) {
      let total = price * (1 - valuePercent / 100);
      let suma = total * classes;
      return suma.toFixed(2);
    }
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            id: group_price.id,
            category_id: group_price.category_id,
            category_name: group_price.category_name,
            // name: group_price.name,
            classes_per_week: "1",
            // description: group_price.description,
            account: group_price.account,
            is_verano: group_price.is_verano,
            price_per_class: group_price.price_per_class,
            first_discount: group_price.first_discount,
            second_discount: group_price.second_discount,
            third_discount: group_price.third_discount,
            fourth_discount: group_price.fourth_discount,
            fifth_discount: group_price.fifth_discount,
            sixth_discount: group_price.sixth_discount,
            seventh_discount: group_price.seventh_discount,
            eighth_discount: group_price.eighth_discount,
            nineth_discount: group_price.nineth_discount,
            total_discount: group_price.total_discount,
            total_second_discount: group_price.total_second_discount,
            total_third_discount: group_price.total_third_discount,
            total_fourth_discount: group_price.total_fourth_discount,
            total_fifth_discount: group_price.total_fifth_discount,
            total_sixth_discount: group_price.total_sixth_discount,
            total_seventh_discount: group_price.total_seventh_discount,
            total_eighth_discount: group_price.total_eighth_discount,
            total_nineth_discount: group_price.total_nineth_discount,
          }}
          validate={(values) => {
            const errors = {};
            // if (!values.name) {
            //   errors.name = "Campo requerido";
            // }
            // if (!values.description) {
            //   errors.description = "Campo requerido";
            // }
            if (!values.account) {
              errors.account = "Campo requerido";
            }
            // if (!values.category_id) {
            //   errors.category_id = "Campo requerido";
            // }
            if (!values.price_per_class) {
              errors.price_per_class = "Campo requerido";
            }
            if (values.price_per_class <= 0) {
              errors.price_per_class = "El precio debe ser mayor a 0";
            }
            if (values.first_discount === "") errors.first_discount = "Campo requerido";
            if (values.second_discount === "") errors.second_discount = "Campo requerido";
            if (values.third_discount === "") errors.third_discount = "Campo requerido";
            if (values.fourth_discount === "") errors.fourth_discount = "Campo requerido";
            if (values.fifth_discount === "") errors.fifth_discount = "Campo requerido";
            if (values.sixth_discount === "") errors.sixth_discount = "Campo requerido";
            if (values.seventh_discount === "")
              errors.seventh_discount = "Campo requerido";
            if (values.eighth_discount === "") errors.eighth_discount = "Campo requerido";
            if (values.nineth_discount === "") errors.nineth_discount = "Campo requerido";

            if (!(values.first_discount >= 0 && values.first_discount <= 100))
              errors.first_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.second_discount >= 0 && values.second_discount <= 100))
              errors.second_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.third_discount >= 0 && values.third_discount <= 100))
              errors.third_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.fourth_discount >= 0 && values.fourth_discount <= 100))
              errors.fourth_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.fifth_discount >= 0 && values.fifth_discount <= 100))
              errors.fifth_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.sixth_discount >= 0 && values.sixth_discount <= 100))
              errors.sixth_discount = "El porcentaje debe ser mayor a 0 y menor a 100";

            if (!(values.seventh_discount >= 0 && values.seventh_discount <= 100))
              errors.seventh_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.eighth_discount >= 0 && values.eighth_discount <= 100))
              errors.eighth_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            if (!(values.nineth_discount >= 0 && values.nineth_discount <= 100))
              errors.nineth_discount = "El porcentaje debe ser mayor a 0 y menor a 100";
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            group_price.id ? update(values) : create(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="kt-form">
              <div className="form-group">
                <UtilInputs
                  inputValues={paymentForm(categories, group_price.id)}
                  formValues={values}
                  onValuesChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 2"
                      margin="normal"
                      className="kt-width-full"
                      name="first_discount"
                      onBlur={handleBlur}
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      value={values.first_discount}
                      helperText={touched.first_discount && errors.first_discount}
                      error={Boolean(touched.first_discount && errors.first_discount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.first_discount
                              ? percentage(values.price_per_class, values.first_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.first_discount, 2) || 0} al
                      mes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 3"
                      margin="normal"
                      className="kt-width-full"
                      name="second_discount"
                      onBlur={handleBlur}
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      value={values.second_discount}
                      helperText={touched.second_discount && errors.second_discount}
                      error={Boolean(touched.second_discount && errors.second_discount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.second_discount
                              ? percentage(values.price_per_class, values.second_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.second_discount, 3) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 4"
                      margin="normal"
                      className="kt-width-full"
                      name="third_discount"
                      onBlur={handleBlur}
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      value={values.third_discount}
                      helperText={touched.third_discount && errors.third_discount}
                      error={Boolean(touched.third_discount && errors.third_discount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.third_discount
                              ? percentage(values.price_per_class, values.third_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.third_discount, 4) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 5"
                      margin="normal"
                      className="kt-width-full"
                      name="fourth_discount"
                      onBlur={handleBlur}
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      value={values.fourth_discount}
                      helperText={touched.fourth_discount && errors.fourth_discount}
                      error={Boolean(touched.fourth_discount && errors.fourth_discount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.fourth_discount
                              ? percentage(values.price_per_class, values.fourth_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.fourth_discount, 5) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 6"
                      margin="normal"
                      className="kt-width-full"
                      name="fifth_discount"
                      onBlur={handleBlur}
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      value={values.fifth_discount}
                      helperText={touched.fifth_discount && errors.fifth_discount}
                      error={Boolean(touched.fifth_discount && errors.fifth_discount)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.fifth_discount
                              ? percentage(values.price_per_class, values.fifth_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.fifth_discount, 6) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 7"
                      margin="normal"
                      className="kt-width-full"
                      name="sixth_discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.sixth_discount}
                      helperText={touched.sixth_discount && errors.sixth_discount}
                      error={Boolean(touched.sixth_discount && errors.sixth_discount)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.sixth_discount
                              ? percentage(values.price_per_class, values.sixth_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.sixth_discount, 7) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 8"
                      margin="normal"
                      className="kt-width-full"
                      name="seventh_discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.seventh_discount}
                      helperText={touched.seventh_discount && errors.seventh_discount}
                      error={Boolean(touched.seventh_discount && errors.seventh_discount)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.seventh_discount
                              ? percentage(
                                  values.price_per_class,
                                  values.seventh_discount
                                )
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.seventh_discount, 8) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 9"
                      margin="normal"
                      className="kt-width-full"
                      name="eighth_discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.eighth_discount}
                      helperText={touched.eighth_discount && errors.eighth_discount}
                      error={Boolean(touched.eighth_discount && errors.eighth_discount)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.eighth_discount
                              ? percentage(values.price_per_class, values.eighth_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.eighth_discount, 9) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Descuento por clase adicional 10"
                      margin="normal"
                      className="kt-width-full"
                      name="nineth_discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.nineth_discount}
                      helperText={touched.nineth_discount && errors.nineth_discount}
                      error={Boolean(touched.nineth_discount && errors.nineth_discount)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            $
                            {values.nineth_discount
                              ? percentage(values.price_per_class, values.nineth_discount)
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography align="right">
                      $ {total(values.price_per_class, values.nineth_discount, 10) || "0"}{" "}
                      al mes
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className="btn-dialog">
                <Button onClick={() => setDialog("closed")} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  Guardar
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store) => ({
  group_price: store.config.group_price,
});

export default connect(mapStateToProps, config.actions)(PaymentDialog);
