import { customSearchInputAuto } from "../../../../common/components/FullTable";

export const columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "membership_number",
    label: "No. socio",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "clients_string",
    label: "Alumnos",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "number_of_clients",
    label: "Alumnos bajo su cargo",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "full_name",
    label: "Nombre del responsable",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "location",
    label: "Ubicación",
    options: {
      sort: false,
      filter: false,
    },
  },
];
