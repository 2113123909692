import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, MenuItem } from "@material-ui/core";
import FullTable from "../../../common/components/FullTable";
import UtilInputs from "../../../common/components/UtilInputs";
import { getLocations } from "../../../crud/config.crud";
import { BajasColumns, defaultFilter, filtersForm } from "./utils";
import { setLoader } from "../../../store/ducks/loader.duck";
import {
  getDischargesFilter,
  getDischarges,
  getDischargesCSV,
  getDischargesPDF,
} from "../../../crud/discharges";
import moment from "moment";
import notice from "../../../utils/notice";

const Discharges = () => {
  const { permissions } = useSelector(({ auth }) => auth.user.user);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(defaultFilter);
  const [locations, setLocations] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(setLoader(true));
    getLocations()
      .then((res) => {
        setFilter({ ...filter, client_location: res[0].name });

        setLocations(res.map((loc) => ({ value: loc.name, text: loc.name, id: loc.id })));
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));

    getDischarges()
      .then((res) => {
        if (res) {
          if (res.Success[0].status === 200) {
            setList(res.Success[0].details);
          }
        }
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };
  const handleFilter = () => {
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD");
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let status = filter.status;
    let location;
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    dispatch(setLoader(true));

    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });

    if (location || status || (end && start) || (end === "" && start === "")) {
      getDischargesFilter(status, start, end, location)
        .then((res) => {
          setList(res.Success[0].details);
        })
        .catch((e) => console.log(e))
        .finally(() => dispatch(setLoader(false)));
    }
  };
  const handleClearFilterData = () => {
    dispatch(setLoader(true));

    setFilter({
      ...filter,
      start_date: null,
      end_date: null,
      status: null,
      client_location: "",
    });
    getDischarges()
      .then((res) => {
        if (res) {
          if (res.Success[0].status === 200) {
            setList(res.Success[0].details);
          }
        }
      })
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenOption = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function downloadCSV(data) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  function downloadPDF(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  const handleDownloadCSV = () => {
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD");
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let status = filter.status;
    let location;
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    dispatch(setLoader(true));

    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });

    if (location || status || (end && start) || (end === "" && start === "")) {
      getDischargesCSV(status, start, end, location)
        .then(function(res) {
          return res.text();
        })
        .then(function(data) {
          let newData = data
            .split("\n")
            .map((item) => item.trim())
            .filter((item) => item);
          downloadCSV(newData.join("\n"));
        })
        .catch((e) => console.log(e))
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const handleDownloadPDF = () => {
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD");
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    let status = filter.status;
    let location;
    let start = dateStart === "Fecha inválida" ? "" : dateStart;
    let end = dateEnd === "Fecha inválida" ? "" : dateEnd;
    dispatch(setLoader(true));

    locations.map((loc) => {
      if (loc.text === filter.client_location) {
        location = loc.id;
      }
    });

    if (location || status || (end && start) || (end === "" && start === "")) {
      getDischargesPDF(status, start, end, location)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => downloadPDF(blob));
          } else if (res.status === 500) {
            notice("Datos incompletos", "warning");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => dispatch(setLoader(false)));
    }
  };
  return (
    <>
      <div style={{ display: "flex", gap: 5 }}>
        <UtilInputs
          formValues={filter}
          onValuesChange={handleChange}
          inputValues={filtersForm(locations)}
          className="mb-3"
        />
      </div>
      <div style={{ display: "flex", gap: 5, marginTop: 15, marginBottom: 15 }}>
        <Button color="primary" variant="contained" onClick={handleFilter}>
          Filtrar
        </Button>
        <Button color="primary" variant="contained" onClick={handleClearFilterData}>
          Limpiar filtros
        </Button>
        {permissions.includes("discharges:report") && (
          <Button color="primary" variant="contained" onClick={handleOpenOption}>
            Reporte
          </Button>
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownloadPDF}>PDF</MenuItem>
        <MenuItem onClick={handleDownloadCSV}>Excel</MenuItem>
      </Menu>
      <FullTable
        title="Bajas"
        data={list.length > 0 ? list : []}
        columns={BajasColumns}
      />
    </>
  );
};

export default Discharges;
