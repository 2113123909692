import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Dialog, Grid, TextField, FormControl, Select, Button, DialogContent, InputLabel, FormControlLabel, Checkbox, FormLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import clsx from 'clsx';
import GSearch from '../../../utils/GSearch';

import * as user from '../../../store/ducks/users.duck'
// import * as UsersCRUD from '../../../crud/users.crud'
import { Formik } from 'formik';

const StudentsDialog = ({ mode, setDialog, student, permit, }) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({paddingRight: "2.5rem"});
  
  const open = mode === 'editing' || mode === 'creating'
//   const title = student.id ? 'Editar alumno' : 'Nuevo alumno'

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };
  const disableLoading = () => {
  setLoading(false);
  setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };


//   const create = (values) => {
//     const userCheck = {
//         name: values.name,
//         last_name: values.last_name,
//         email: values.email,
//         password: values.password,
//         role_id: values.role_id,
//         active: values.active,
//         date_of_birth: values.date_of_birth,
//         blood_type: values.blood_type,
//         address: values.address,
//         location_id: values.location_id,
//         category_ids: values.category_ids
//         // documents: []
//     }
    
//     UsersCRUD.saveUser(userCheck, updateUser).then(() => setDialog('closed'))
//   }

//   const update = (values) => {
//     const userCheck = {
//         id: instructor.id,
//         name: values.name,
//         last_name: values.last_name,
//         email: values.email,
//         password: values.password,
//         role_id: values.role_id,
//         active: values.active,
//         date_of_birth: values.date_of_birth,
//         blood_type: values.blood_type,
//         address: values.address,
//         location_id: values.location_id,
//         category_ids: values.category_ids
//         // documents: []
//     }
//     UsersCRUD.updateUser(userCheck, updateUser).then(() => setDialog('closed'))
//   }


  return (
    <Dialog
      open={open}
      fullWidth={true} maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title"
      >
      {/* <DialogTitle id="form-dialog-title">{title}</DialogTitle>s */}
      <DialogContent>
        <Formik
        initialValues={{
            // name: instructor.name,
            // last_name: instructor.last_name,
            // email: instructor.email,
            // password: instructor.password,
            // role_id: instructor.role_id,
            // active: instructor.active,
            // date_of_birth: instructor.id? selectedDate : instructor.date_of_birth,
            // blood_type: instructor.blood_type,
            // address: instructor.address,
            // location_id: instructor.location_id,
            // category_ids: instructor.category_ids
        }}
        validate={values => {
            const errors = {}
            if(!values.name) errors.name = 'Campo Requerido'
            if(!values.last_name) errors.last_name = 'Campo Requerido'
            if(!values.email) errors.email = 'Campo Requerido'
            // if(!values.password) errors.password = 'Campo Requerido'

            return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
            enableLoading()
            setTimeout(() => {
                // instructor.id ? update(values) : create(values)
                setSubmitting(false)
                disableLoading()
            }, 1000)
        }}
        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <form
          noValidate={true}
          autoComplete='off'
          onSubmit={handleSubmit}
          className='kt-form'>
            <div className="form-group">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Nombre"
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  fullWidth
                    margin="normal"
                    label="Apellidos"
                    name='last_name'
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.last_name && errors.last_name}
                    error={Boolean(touched.last_name && errors.last_name)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      autoOk
                      margin="normal"
                      id="mui-pickers-date"
                      label="Fecha de nacimiento"
                      name='date_of_birth'
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      openTo='year'
                      value={values.date_of_birth}
                      onChange={(date) => handleChange({target:{ value: date, name:'date_of_birth'}})}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    type='email'
                    margin="normal"
                    label="Correo Electronico"
                    name='email'
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    type='password'
                    margin="normal"
                    label="Contraseña"
                    name='password'
                    fullWidth
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <GSearch 
                    addressValue={values.address}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel htmlFor="simple-select-label">Tipo de sangre</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      name='blood_type'
                      value={values.blood_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      >
                      {/* {bloodType.map(b => (
                        <MenuItem key={b.type} value={b.type}>{b.label}</MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin='normal' >
                    <InputLabel htmlFor="simple-select-label">Tipo de Instructor</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      name='role_id'
                      value={values.role_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    //   disabled={disableTexts}
                      >
                      {/* {instructorRoles.map(rol => (
                        <MenuItem key={rol.id} value={rol.id}>
                          {rol.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin='normal' >
                    <InputLabel htmlFor="simple-select-label">Ubicaciones</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      name='location_id'
                      value={values.location_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    //   disabled={disableTexts}
                      >
                      {/* {locations.map(loc => (
                        <MenuItem key={loc.id} value={loc.id}>
                          {loc.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin='normal' >
                    <InputLabel htmlFor="multiple-select-label">Categorias</InputLabel>
                    <Select
                      labelId="multiple-select-label"
                      name='category_ids'
                      value={values.category_ids}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiple
                      >
                      {/* {categories.map(cat => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth margin='normal'>
                    <FormLabel htmlFor="simple-select-label">Estado</FormLabel>
                    <FormControlLabel control={
                        <Checkbox 
                        name='active' 
                        checked={values.active}
                        value={values.active}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabled={disableTexts}
                      />}
                      label={values.active? 'Activo' : 'Inactivo'}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className='btn-dialog'>
              <Button onClick={() => setDialog('closed')} color="primary">
                Cancelar
              </Button>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={isSubmitting}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
              }
              )}`}
              style={loadingButtonStyle}
              >
                {/* {instructor.id ? 'Editar' : 'Crear'} */}
              </button>
            </div>
          </form>)}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  instructor: store.user.user,
  roles: store.config.roles.data,
})

export default connect(mapStateToProps, user.actions)(StudentsDialog)