import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BaseModal from "../../../../common/components/BaseModal";
import UtilInputs from "../../../../common/components/UtilInputs";
import {
  createInvoice,
  getQuickbooksURL,
  getCatalogCFDI,
  getCatalogFiscal,
} from "../../../../crud/incomes.crud";
import { setLoader } from "../../../../store/ducks/loader.duck";
import notice from "../../../../utils/notice";
import { defaultInvoice, invoiceInputs } from "../utils";

const InvoiceModal = ({ open, setOpen, invoiceData, setInvoiceData }) => {
  const dispatch = useDispatch();
  /* Setting the initial state of the `invoice` object to the value of the `defaultInvoice` object. */
  const [invoice, setInvoice] = useState(defaultInvoice);
  const [disableBtn, setDisableBtn] = useState(true);
  const [fiscal, setFiscal] = useState([]);
  const [cdfis, setCdfis] = useState([]);

  useEffect(() => {
    if (invoiceData) setInvoice(invoiceData);
  }, [invoiceData]);
  useEffect(() => {
    //catalogo

    getCatalogCFDI()
      .then((res) => {
        setCdfis(res);
      })
      .catch(console.log);

    //regimen
    getCatalogFiscal()
      .then((res) => {
        setFiscal(res);
      })
      .catch(console.log);
  }, []);
  /**
   * `handleChange` is a function that takes an event object as an argument, and then sets the state of
   * the `invoice` object to the value of the input field that was changed
   */
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setInvoice({ ...invoice, [name]: value });
  };
  useEffect(() => {
    verifyFactInputs(invoice);
  }, [invoice]);

  const verifyFactInputs = (invoiceInformation) => {
    let isFilled = Object.entries(invoiceInformation).map((factValue) => {
      const [key, value] = factValue;
      if (
        key === "interior_number" ||
        key === "amount" ||
        key === "client" ||
        key === "client_location" ||
        key === "type" ||
        key === "type_client" ||
        key === "product" ||
        key === "id_quickbooks" ||
        key === "payment_type"
      )
        return true;

      if (value) {
        return true;
      } else {
        return false;
      }
    });
    if (isFilled.length !== 0 && isFilled.includes(false)) {
      setDisableBtn(true);
      return true;
    } else {
      setDisableBtn(false);

      return false;
    }
  };
  const handleCreateInvoice = () => {
    dispatch(setLoader(true));
    createInvoice({
      id: invoice.id_responsible,
      invoice_information: {
        invoice_person_type: invoice.invoice_person_type,
        name: invoice.name,
        email: invoice.email,
        rfc: invoice.rfc,
        country: invoice.country,
        state: invoice.state,
        suburb: invoice.suburb,
        municipality: invoice.municipality,
        street: invoice.street,
        outdoor_number: invoice.outdoor_number,
        interior_number: invoice.interior_number,
        postal_code: invoice.postal_code,
        locality: invoice.locality,
        regimen_fiscal: invoice.regimen_fiscal,
        cfdi_use: invoice.cfdi_use,
      },
    })
      .then(() =>
        getQuickbooksURL().then((res) => {
          window.open(res.url);
          window.close();
          return handleClose();
        })
      )
      .catch(console.log)
      .finally(() => dispatch(setLoader(false)));
  };

  const handleClose = () => {
    setOpen(null);
    setInvoice(defaultInvoice);
  };

  return (
    <BaseModal
      open={open}
      confirmLabel="Facturar"
      title="Facturar"
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={handleCreateInvoice}
      disableOnConfirm={disableBtn}
    >
      <UtilInputs
        width={"33%"}
        formValues={invoice}
        onValuesChange={handleChange}
        inputValues={invoiceInputs(fiscal, cdfis)}
      />
    </BaseModal>
  );
};

export default InvoiceModal;
