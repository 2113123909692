import React from 'react'
import FullTable from '../../../../common/components/FullTable';
import { lessons_columns } from '../utils/lesson_data';

const LessonTable = ({ clases }) => {

    return (
        <>
            <FullTable
                title={"Lista de Asistencia"}
                data={clases}
                columns={lessons_columns}
            />
        </>
    )
}

export default LessonTable