import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: theme.spacing(3),
  },
  buttonLarge: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(6)}px`,
  },
}));

const BaseModal = ({
  title,
  open,
  onClose,
  onConfirm,
  confirmLabel = "Aceptar",
  onCancel,
  cancelLabel = "Cancelar",
  maxWidth = "sm",
  children,
  confirmColor = "primary",
  cancelColor = "primary",
  confirmVariant = "contained",
  cancelVariant = "text",
  disableOnConfirm = false,
  loading=false,
  hidden=false,
  hideCloseButton=false,
  hideTitle=false,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      hidden={hidden}
      open={open}
      fullWidth={true}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{
        elevation: 1,
      }}
      TransitionProps={{
        style: {
          backgroundColor: "rgb(0 0 0 / 50%)",
        },
      }}
    >
      {!hideTitle&&<DialogTitle className={classes.header} disableTypography={true}>
        <Typography variant="h5">{title}</Typography>
        {!hideCloseButton&&<IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>}
      </DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {onCancel || onConfirm ? (
        <DialogActions className={classes.actionsContainer}>
          {onCancel ? (
            <Button
              className={classes.buttonLarge}
              disableElevation
              color={cancelColor}
              variant={cancelVariant}
              onClick={onCancel}
            >
              {cancelLabel}
            </Button>
          ) : null}
          {onConfirm ? (
            <Button
              disabled={disableOnConfirm}
              color={confirmColor}
              disableElevation
              variant={confirmVariant}
              className={classes.buttonLarge}
              onClick={onConfirm}
              endIcon={loading && <CircularProgress size={18} />}
            >
              {confirmLabel}
            </Button>
          ) : null}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default BaseModal;