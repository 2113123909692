import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import FullTable from "../../../common/components/FullTable"
import { table_options, attendance_columns } from './utils';
import { fetchAttendance } from '../../../crud/attendance.crud';
import { attendanceActions } from '../../../store/ducks/attendance.duck';
import notice from '../../../utils/notice';
import Loading from '../../../utils/Loading';

const AttendancePage = () => {
  const { results } = useSelector(store => store.attendance)
  const dispatch = useDispatch()
  const history = useHistory()
  const [page] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchAttendance(page)
    .then(res => dispatch(attendanceActions.setAttendances(res)))
    .catch(e => notice(Array.isArray(e) ? e.join('\n') : "Ha ocurrido un error con su solicitud"))
    .finally(() => setLoading(false))
  }, [dispatch, page])

  const showByGroup = id => history.push(`/asistencia-por-grupo/${id}`)

  if (loading) return <Loading />

  return (
    <>
      <Paper>
        <FullTable
          title={"Asistencia"}
          data={results.data}
          columns={attendance_columns}
          options={{...table_options(showByGroup, page)}}
        />
      </Paper>
    </>
  )
};

export default AttendancePage;
