import React from 'react'

const OweColumn = (data, tableMeta, updateFunction, attendances, setAttendances) => {
  return (
    <span className={`badge badge-pill badge-${data ? "danger" : "success"} font-weight-bold`}>
      {data ? 'Debe' : 'No debe'}
    </span>
  );
}

export default OweColumn