import { BASE_URL, PAYMENTS_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const filterPaymentCut = async (start, end, ubication, tipo) => {
  return fetch(
    `${PAYMENTS_URL}/lista_cortes?dia_star=${start}&dia_end=${end}&location_id=${ubication}&tipo_corte=${tipo}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getListCuts = async () => {
  return fetch(`${PAYMENTS_URL}/lista_cortes`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getPaymentCuts = () => {
  return fetch(`${PAYMENTS_URL}/cuotas_facturadas`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getPaymentCutsForDate = (date) => {
  return fetch(`${PAYMENTS_URL}/cuotas_facturadas?search=${date}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getPaymentCutsWithFilter = async (start, end, ubication) => {
  return fetch(
    `${PAYMENTS_URL}/cuotas_pdf.pdf?dia_star=${start}&dia_end=${end}&ubicacion=${ubication}`,
    getParams("GET")
  );
};
export const getPaymentCutTypeA = async (start, end, ubication, total_cuenta, cobro) => {
  return fetch(
    `${BASE_URL}cortes/cortes_tipoA.pdf?ubicacion=${ubication}&dia_start=${start}&dia_end=${end}&total_cuenta_externa=${total_cuenta}&cobro_factura=${cobro}`,
    getParams("GET")
  );
};
export const getPaymentCutTypeB = async (start, end, ubication) => {
  return fetch(
    `${BASE_URL}cortes/cortes_tipoB.pdf?ubicacion=${ubication}&dia_start=${start}&dia_end=${end}`,
    getParams("GET")
  );
};
export const generarCutTypeA = async (start, end, ubication) => {
  return fetch(
    `${BASE_URL}cortes/cortes_tipoA.pdf?ubicacion=${ubication}&dia_start=${start}&dia_end=${end}&accion=crear`,
    getParams("GET")
  );
};
export const generarCutTypeB = async (start, end, ubication) => {
  return fetch(
    `${BASE_URL}cortes/cortes_tipoB.pdf?ubicacion=${ubication}&dia_start=${start}&dia_end=${end}&accion=crear`,
    getParams("GET")
  );
};
