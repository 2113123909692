import React from "react";
import { Typography, Divider, makeStyles, useMediaQuery } from "@material-ui/core";
import SimpleText from "./SimpleText";
import SimplePassword from "./SimplePassword";
import SimpleDropdown from "./SimpleDropdown";
import SimpleDropdownChip from "./SimpleDropdownChip";
import SimpleDate from "./SimpleDate";
import SimpleDateTime from "./SimpleDateTime";
import SimpleAutocomplete from "./SimpleAutocomplete";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleSwitch from "./SimpleSwitch";
import SimpleRadioButton from "./SimpleRadioButton";
import CustomButton from "./CustomButton";
import IconButton from "./IconButton";
import SimpleMultipleAutocomplete from "./SimpleMultipleAutocomplete";
import SimpleNumber from "./SimpleNumber";
import SimpleTime from "./SimpleTime";
import SimpleAddressInput from "./SimpleAddressInput";
import SimpleColor from "./SimpleColor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "&>*": {
      paddingRight: 15,
      marginTop: 15,
    },
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const UtilInputs = ({
  formValues,
  onValuesChange,
  inputValues = [],
  width = 210,
  style = {},
  className = "",
  onBlur,
  touched,
  errors,
}) => {
  const classes = useStyles();
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={`${classes.root} ${className}`} style={style}>
      {inputValues.map((filter, i) => {
        if (filter)
          switch (filter.type) {
            case "text":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleText
                    name={filter.name}
                    required={filter.required}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    label={filter.label}
                    variant={filter.variant}
                    placeholder={filter.placeholder}
                    disabled={filter.disabled}
                    multiline={filter.multiline}
                    rows={filter.rows}
                    isPassword={filter.isPassword}
                    style={filter.style}
                    className={filter.className}
                    color={filter.color}
                    startAdornment={filter.startAdornment}
                    endAdornment={filter.endAdornment}
                    mask={filter.mask}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "color":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleColor
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    label={filter.label}
                    variant={filter.variant}
                    disabled={filter.disabled}
                    style={filter.style}
                    className={filter.className}
                    color={filter.color}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "number":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleNumber
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    label={filter.label}
                    variant={filter.variant}
                    placeholder={filter.placeholder}
                    disabled={filter.disabled}
                    style={filter.style}
                    className={filter.className}
                    color={filter.color}
                    isPrice={filter.isPrice}
                    isPercentage={filter.isPercentage}
                    decimals={filter.decimals}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "password":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimplePassword
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    disabled={filter.disabled}
                    width={filter.width || (width && width)}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "address":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleAddressInput
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    disabled={filter.disabled}
                    width={filter.width || (width && width)}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "dropdown":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleDropdown
                    required={filter.required}
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    options={filter.options}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    style={filter.style}
                    containerClass={filter.containerClass}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    multiple={filter.multiple}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "dropdown-chip":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleDropdownChip
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    options={filter.options}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    style={filter.style}
                    containerClass={filter.containerClass}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "date":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleDate
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    format={filter.format}
                    placeholder={filter.placeholder}
                    mask={filter.mask}
                    views={filter.views}
                    initialView={filter.initialView}
                    minDate={filter.minDate}
                    maxDate={filter.maxDate}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "date-time":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleDateTime
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    minDate={filter.minDate}
                    maxDate={filter.maxDate}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "time":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleTime
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    minDate={filter.minDate}
                    maxDate={filter.maxDate}
                    margin={filter.margin}
                    minTime={filter.minTime}
                    maxTime={filter.maxTime}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "search":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleAutocomplete
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    searchFunction={filter.searchFunction}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    margin={filter.margin}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "search-multiple":
              return (
                <div
                  key={filter.name + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <SimpleMultipleAutocomplete
                    label={filter.label}
                    name={filter.name}
                    onChange={onValuesChange}
                    value={formValues[filter.name]}
                    width={filter.width || (width && width)}
                    disabled={filter.disabled}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    searchFunction={filter.searchFunction}
                    style={filter.style}
                    className={filter.className}
                    variant={filter.variant}
                    color={filter.color}
                    limitTags={filter.limitTags}
                    showAvatars={filter.showAvatars}
                    margin={filter.margin}
                    disableCloseOnSelect={filter.disableCloseOnSelect}
                    filterSelectedOptions={filter.filterSelectedOptions}
                    checkboxOption={filter.checkboxOption}
                    onBlur={onBlur}
                    helperText={
                      touched && errors && touched[filter.name] && errors[filter.name]
                    }
                    error={Boolean(
                      touched && errors && touched[filter.name] && errors[filter.name]
                    )}
                  />
                </div>
              );
            case "checkbox":
              return (
                <div
                  key={filter.name + i}
                  style={{
                    width: mobileView ? "100%" : filter.width || width,
                    marginLeft: 10,
                  }}
                >
                  <SimpleCheckbox
                    name={filter.name}
                    disabled={filter.disabled}
                    label={filter.label}
                    value={formValues[filter.name]}
                    onChange={onValuesChange}
                    labelPos={filter.labelPos}
                    color={filter.color}
                  />
                </div>
              );

            case "switch":
              return (
                <div
                  key={filter.name + i}
                  style={{
                    width: mobileView ? "100%" : filter.width || width,
                    marginLeft: 10,
                  }}
                >
                  <SimpleSwitch
                    name={filter.name}
                    disabled={filter.disabled}
                    label={filter.label}
                    value={formValues[filter.name]}
                    onChange={onValuesChange}
                    labelPos={filter.labelPos}
                    color={filter.color}
                    className={filter.className}
                  />
                </div>
              );

            case "radio":
              return (
                <div
                  key={filter.name + i}
                  style={{
                    width: mobileView ? "100%" : filter.width || width,
                    marginLeft: 10,
                  }}
                >
                  <SimpleRadioButton
                    name={filter.name}
                    disabled={filter.disabled}
                    label={filter.label}
                    value={formValues[filter.name]}
                    onChange={onValuesChange}
                    labelPos={filter.labelPos}
                    options={filter.options}
                    className={filter.className}
                    color={filter.color}
                  />
                </div>
              );
            case "button":
              return (
                <div
                  key={filter.label + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <CustomButton
                    title={filter.label}
                    disabled={filter.disabled}
                    onClick={filter.onClick}
                    color={filter.color}
                    outlined={filter.outlined}
                    className={filter.className}
                    startIcon={filter.startIcon}
                    endIcon={filter.endIcon}
                    style={filter.style}
                  />
                </div>
              );
            case "icon-button":
              return (
                <div
                  key={filter.label + i}
                  style={{ width: mobileView ? "100%" : filter.width || width }}
                >
                  <IconButton
                    label={filter.label}
                    tooltip={filter.tooltip}
                    disabled={filter.disabled}
                    onClick={filter.onClick}
                    color={filter.color}
                    className={filter.className}
                    tooltipPos={filter.tooltipPos}
                    style={{ ...filter.style, marginTop: 10 }}
                    src={filter.src}
                  />
                </div>
              );
            case "line-break":
              return (
                <div key={filter.type + i} style={{ flexBasis: "100%" }}>
                  {filter.title && (
                    <>
                      <Divider fullWitdh />
                      <Typography variant="h6">{filter.title}</Typography>
                    </>
                  )}
                  {filter.separator && <Divider full />}
                </div>
              );
            default:
              return <></>;
          }
        return false;
      })}
    </div>
  );
};

export default UtilInputs;
