import { MINOR_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const getMinorAll = async () => {
  return fetch(`${MINOR_URL}minor_box`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getPDFMinior = async (start, end, ubication) => {
  return fetch(
    `${MINOR_URL}minor_box_pdf.pdf?location_id=${ubication}&date_from=${start}&date_to=${end}`,
    getParams("GET")
  );
};
export const getFilterMinior = async (start, end, ubication) => {
  return fetch(
    `${MINOR_URL}minor_box?location_id=${ubication}&date_from=${start}&date_to=${end}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};
export const getAddNewMinior = async (date, type, concepto, amount, location) => {
  const body = {
    operation_date: date,
    operation_type: type,
    concepto: concepto,
    amount: amount,
    location_id: location,
  };

  const body2 = JSON.stringify({ minor_box: body });

  return fetch(`${MINOR_URL}minor_box`, getParams("POST", body2));
};
