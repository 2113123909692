import { PAYMENTS_URL } from "./helpers/routes";
import {
  getMultipartParams,
  handleError,
  handleFormData,
  handleResponse,
} from "./helpers/validate";

export const createPayment = async (data) => {
  let info_payment_method =
    data.payment_method === "mixto"
      ? {
          payment_mixto_A: data.payment_mixto_A,
          payment_mixto_B: data.payment_mixto_B,
          [data.payment_mixto_A]: data.payment_mixto_A_amount,
          [data.payment_mixto_B]: data.payment_mixto_B_amount,
        }
      : data.payment_method === "terminal"
      ? {
          [data.payment_terminal]: data.amount,
          payment_terminal: data.payment_terminal,
        }
      : {};

  let payment = {
    amount: data.amount,
    store_name: data.store_name,
    client_id: data.client.id,
    location_id: data.client.location_id,
    payment_method: data.payment_method,
    customer: data.customer,
    no_reference: data.no_reference,
    note_discount: data.note_discount,
    direct_card: data.direct_card ? data.direct_card : false,
    nota: data.nota,
    additional_discount_type: data.additional_discount_type,
    additional_discount: Number(data.additional_discount),
    additional_discount_amount: Number(data.additional_discount_amount),
    amount_with_discount: data.amount_with_discount,
    payment_groups: data.payment_groups.map(({ id, quantity, period }) => ({
      id,
      quantity,
      period: period.value,
    })),
    other_fees: data.other_fees.map(({ id, price, name }) => ({ id, price, name })),
    products: data.products.map(({ id, quantity, price }) => ({
      id,
      quantity,
      amount: price,
    })),
    ...info_payment_method,
  };
  if (data.reference_file)
    payment = {
      ...payment,
      reference_file: data.reference_file,
    };
  return fetch(
    `${PAYMENTS_URL}/store_payments`,
    getMultipartParams("POST", handleFormData(payment))
  )
    .then(handleResponse)
    .catch(handleError);
};
