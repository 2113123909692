import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import moment from "moment";

const DateIncription = ({ setDateInscription, dateInscription }) => {
  // const [date, setDate] = useState("");

  const handleChange = (e) => {
    setDateInscription(e.target.value);
    // setDate(e.target.value);
  };
  useEffect(() => {
    let dateNow = moment().format("YYYY-MM-DD");
    setDateInscription(dateNow);
  }, []);

  return (
    <Grid container xs={12}>
      <Grid item xs={12} sm={3} style={{ marginLeft: "-5px", marginRight: "13px" }}>
        <TextField
          name="date"
          type="date"
          label="Fecha de Inscripción *"
          onChange={handleChange}
          // value={materialDateInput}
          value={dateInscription}
          focused
          style={{ width: 200 }}
        />
      </Grid>
    </Grid>
  );
};

export default DateIncription;
