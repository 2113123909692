import notice from "../utils/notice";
import { CLIENTS_URL, USERS_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const defaultUser = {
  user: {
    id: null,
    name: "",
    last_name: "",
    email: "",
    password: "",
    role_id: "",
    location_id: "",
    category_ids: [],
    active: true,
    date_of_birth: new Date(),
    blood_type: "",
    address: "",
    address_number: "",
    documents: [],
    hour_price: "",
    half_hour_price: "",
    tree_quarters_hour_price: "",
  },
};

export function getFilterByStatus(status, setterFunction) {
  return fetch(`${CLIENTS_URL}/students?estatus=${status}`, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      return json;
    })
    .catch(handleError);
}
export function getUsers(setterFunction) {
  return fetch(USERS_URL, getParams("GET"))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      return json;
    })
    .catch(handleError);
}

export function getUser(setterFunction, id) {
  return fetch(`${USERS_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function updateUser(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ user: data });
  return fetch(`${USERS_URL}/${id}`, getParams("PUT", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction && setterFunction(json);
      notice("Usuario editado con exito", "success");
      return json;
    })
    .catch(handleError);
}

export function saveUser(data, setterFunction) {
  const body = JSON.stringify({ user: data });

  return fetch(USERS_URL, getParams("POST", body))
    .then(handleResponse)
    .then((json) => {
      setterFunction(json);
      return json;
    })
    .catch(handleError);
}

export function deleteUser(id) {
  return fetch(`${USERS_URL}/${id}`, getParams("DELETE"))
    .then(handleResponse)
    .catch(handleError);
}
