import FindOutsColumnsActions from "./Components/FindOutsColumnsActions";

export const FindoutColumns = (openDialog, handleRemove) => [
  {
    name: "name",
    label: "Descripción",
    options: {
      filter: false,
      sort: true,
      sortDirection: "asc",
    },
  },
  {
    name: "id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center w-25" }),
      customBodyRender: (data, dataRow) =>
        FindOutsColumnsActions(data, dataRow, openDialog, handleRemove),
    },
  },
];

export const customOptions = {
  filter: false,
  sort: false,
  search: false,
};

export const defaultFindout = {
  name: "",
};
