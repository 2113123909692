import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import { Formik } from "formik";
import clsx from "clsx";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";
import notice from "../../../../../utils/notice";

function ProgramDialog({mode, program, setProgram, setDialog, setPrograms, updatePrograms, permit, user, locations}) {

  const open = mode === 'editing' || mode === 'creating'
  const title = program.id ? 'Editar programa' : 'Nuevo programa'
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  let disableTexts = true
  if((program.id && permit.programsUpdate) || (!program.id && permit.programsCreate)){
    disableTexts = false
  }

  function create(values) {
    const pro = {
      name: values.name,
      description: values.description,
      location_id: user.role.location_id ? user.role.location_id : values.location_id ? values.location_id : locations.data[0].id,
    }
    configCRUD.saveProgram(pro, updatePrograms)
    .then(() => {
      setDialog('closed')
      notice("Pragrama guardado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  function update(values) {
    const pro = {
      id: program.id,
      name: values.name,
      description: values.description,
      location_id: user.role.location_id ? user.role.location_id : values.location_id ? values.location_id : locations.data[0].id,
    }
    configCRUD.updateProgram(pro, updatePrograms)
    .then(() => {
      setDialog('closed')
      notice("Pragrama actualizado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* SE AGREGARA TEXTO DESCRIPTIVO MAS ADELANTE */}
        </DialogContentText>
        <Formik
          initialValues={{ name: program.name, description: program.description, location_id: program.location_id }}
          validate={values => {
            const errors = {}
            if(!values.name){
              errors.name = 'Campo requerido'
            }

            if(!values.description){
              errors.description = 'Campo requerido'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            program.id ? update(values) : create(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={handleSubmit}
              className="kt-form"
            >
              <div className="form-group">
                <TextField
                  label="Nombre"
                  margin="normal"
                  className="kt-width-full"
                  disabled={disableTexts}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                />
                <TextField
                  label="Descripción"
                  margin="normal"
                  className="kt-width-full"
                  name="description"
                  disabled={disableTexts}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  helperText={touched.description && errors.description}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  variant='outlined'
                  multiline
                  rowsMax="4"
                />
                {user.role.location_id == null ? <FormControl fullWidth margin='normal'>
                    <InputLabel htmlFor="simple-select-label">Ubicaciones</InputLabel>
                    <Select
                      value={values.location_id == null ? locations.data[0].id : values.location_id}
                      onChange={handleChange}
                      labelId="simple-select-label"
                      name='location_id'
                      onBlur={handleBlur}
                      disabled={disableTexts}
                    >
                      {/* <MenuItem key={0} value={0}>Todos</MenuItem> */}
                      {locations.data.map(loc => (
                        <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl> : null}
                <div className='btn-dialog'>
                  <Button onClick={() => setDialog('closed')} color="primary">
                    Cancelar
                  </Button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    {program.id ? 'Editar' : 'Crear'}
                  </button>
                </div>
              </div>
            </form>
          )
          }
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  program: store.config.program,
  user: store.auth.user.user,
  locations: store.config.locations
});

export default connect(mapStateToProps, config.actions)(ProgramDialog)