import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import { getUsers } from '../../../crud/users.crud';
import { getRoles, getLocations } from '../../../crud/config.crud';

import * as user from '../../../store/ducks/users.duck'
import * as config from "../../../store/ducks/config.duck";

import Users from './Users';
import Loading from '../../../utils/Loading';

class UserWrapper extends Component {


    componentDidMount() {
        getUsers(this.props.userActions.setUsers)
        getRoles(this.props.configActions.setRoles)
        getLocations(this.props.configActions.setLocations)
    }


    render() {
        const {isFetched, data} = this.props.users
        if( isFetched || data.length ){
        return (
            <>
                <Users />
            </>
        );
        }else return <Loading />
    }
}

const mapStateToProps = store => ({
    users: store.user.users
})

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(user.actions, dispatch),
      configActions: bindActionCreators(config.actions, dispatch),
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(UserWrapper);
