import React from "react";

const StatusColumnFormatter = (data) => {
  return data ? (
    <div className="active">
      <p>Active</p>
    </div>
  ) : (
    <div className="inactive">
      <p>Inactivo</p>
    </div>
  );
};

export default StatusColumnFormatter;
