import moment from "moment";
import { currencyFormat } from "../../../utils/data_formatters";

export const defaultFilter = {
  start_date: null,
  end_date: null,
  client_location: "",
};
export const newDefaultFilter = {
  operation_date: null,
  operation_type: "",
  concepto: "",
  amount: "",
  location_name: "",
};

export const filtersForm = (locations = [], havePermissionDate) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
    disabled: havePermissionDate ? false : true,
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
    disabled: havePermissionDate ? false : true,
  },
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
];

export const newFiltersForm = (locations = [], havePermissionDate) => [
  {
    name: "operation_date",
    label: "Fecha",
    type: "date",
    variant: "filled",
    disabled: havePermissionDate ? false : true,
  },
  {
    name: "operation_type",
    label: "Operación",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "ENTRADA", text: "ENTRADA" },
      { value: "SALIDA", text: "SALIDA" },
    ],
  },
  {
    name: "concepto",
    label: "Concepto",
    type: "text",
    variant: "filled",
  },
  {
    name: "amount",
    label: "Importe",
    type: "number",
    variant: "filled",
    isPrice: true,
    decimals: 2,
  },
  {
    name: "location_name",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
];
export const MinorColumns = [
  {
    name: "operation_date",
    label: "Fecha",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (data) => `${moment(data).format("YYYY-MM-DD")}`,
    },
  },
  {
    name: "operation_type",
    label: "Operación",
    options: {
      filter: false,
      sort: true,
      sortDirection: "asc",
    },
  },
  {
    name: "concepto",
    label: "Concepto",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "amount",
    label: "Importe",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => currencyFormat.format(data),
    },
  },
  {
    name: "location_name",
    label: "Sucursal",
    options: {
      filter: false,
      sort: false,
    },
  },
];
