import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CustomButton from "../../../../common/components/CustomButton";
import FullTable from "../../../../common/components/FullTable";
import PaymentDilog from "./PaymentDilog";

import * as config from "../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../crud/config.crud";
import { columns } from "./utils";

function ConfiguracionPayments({ group_prices, setGroupPrice, setGroupPrices, permit }) {
  const [dialog, setDialog] = useState("closed"); //edit,create,closed

  useEffect(() => {
    if (group_prices.data.length === 0) {
      configCRUD.getGroupPrices(setGroupPrices);
    }
  }, [group_prices.data.length, setGroupPrices]);

  function openDialog(mode, id = null) {
    switch (mode) {
      case "edit":
        configCRUD.getGroupPrice(setGroupPrice, id).then(() => {
          setDialog(mode);
        });
        break;
      case "create":
        setGroupPrice(configCRUD.defaultGroupPrice);
        setDialog(mode);
        break;
      case "closed":
        setDialog("closed");
        setTimeout(() => {
          setGroupPrice(configCRUD.defaultGroupPrice);
        }, 200);
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="text-right mb-4">
        {permit.paymentMethodsCreate && (
          <CustomButton
            title="Crear precio por grupo"
            onClick={() => openDialog("create")}
          />
        )}
      </div>
      <FullTable
        title="Precios por grupo"
        data={group_prices.data}
        columns={columns}
        options={{ onRowClick: (data) => openDialog("edit", data[0]) }}
      />
      <PaymentDilog mode={dialog} setDialog={openDialog} />
    </>
  );
}
const mapStateToProps = (store) => ({
  group_prices: store.config.group_prices,
});
export default connect(mapStateToProps, config.actions)(ConfiguracionPayments);
