import React from "react";

const CategoriesColorColumn = (data) => {
  return (
    <div
      style={{
        width: "30px",
        height: "10px",
        borderRadius: "10px",
        backgroundColor: data,
      }}
    />
  );
};

export default CategoriesColorColumn;
