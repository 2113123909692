import React, { useState } from 'react'
import { connect } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Grid } from "@material-ui/core";

import * as config from "../../../../../store/ducks/config.duck";
import * as configCRUD from "../../../../../crud/config.crud";

const PoolTypesDialog = ({ mode, setDialog, permit, pool_type, updatePoolTypes }) => {
  const open = mode === 'editing' || mode === 'creating'
  const title = pool_type.id ? 'Editar tipo' : 'Nuevo tipo'
  
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function create(values){
    const poolType = {
      name: values.name,
      description: values.description,
    }
    configCRUD.savePoolType(poolType, updatePoolTypes).then(() => setDialog('closed'))
  }
  
  function update(values){
    const poolType = {
      id: pool_type.id,
      name: values.name,
      description: values.description,
    }
    configCRUD.updatePoolType(poolType, updatePoolTypes).then(() => setDialog('closed'))
  }

  return (
    <Dialog open={open} fullWidth maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ name: pool_type.name, description: pool_type.description }}
          validate={values => {
            const errors = {}
            if(!values.name){
              errors.name = 'Campo requerido'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
              pool_type.id ? update(values) : create(values)
              setSubmitting(false)
              disableLoading();
            }, 1000)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={handleSubmit}
              className="kt-form"
            >
            <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Tipo de Alberca"
                      margin="normal"
                      className="kt-width-full"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      helperText={touched.name && errors.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Descripción"
                      margin="normal"
                      className="kt-width-full"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      fullWidth
                      variant='outlined'
                      multiline
                      rowsMax="4"
                    />
                  </Grid>
                </Grid>
              </div>

              <div className='btn-dialog'>
                <Button onClick={() => setDialog('closed')} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  {pool_type.id ? 'Editar' : 'Crear'}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  pool_type: store.config.pool_type
})

export default connect(mapStateToProps, config.actions)(PoolTypesDialog)