import notice from "../utils/notice";
import { getParams, handleError, handleResponse } from "./helpers/validate";
import { DAILY_REPORTS_URL, BIWEEKLY_REPORTS_URL } from "./helpers/routes";

export const defaultGeneralData = {
  chlorine: 0,
  chlorine_dpd: 0,
  ph: 0,
  pool_temperature_1: 0,
  pool_temperature_2: 0,
  thermostat_temperature: 0,
  added_chlorine: 0,
  added_acid: 0,
  others: "",
  time_period_id: 1,
  user_id: 1,
};
export const defaultGeneralData2 = {
  chlorine: 0,
  chlorine_dpd: 0,
  ph: 0,
  pool_temperature_1: 0,
  pool_temperature_2: 0,
  thermostat_temperature: 0,
  added_chlorine: 0,
  added_acid: 0,
  others: "",
  time_period_id: 2,
  user_id: 1,
};
export const defaultGeneralData3 = {
  chlorine: 0,
  chlorine_dpd: 0,
  ph: 0,
  pool_temperature_1: 0,
  pool_temperature_2: 0,
  thermostat_temperature: 0,
  added_chlorine: 0,
  added_acid: 0,
  others: "",
  time_period_id: 3,
  user_id: 1,
};
export const defaultGeneralData4 = {
  chlorine: 0,
  chlorine_dpd: 0,
  ph: 0,
  pool_temperature_1: 0,
  pool_temperature_2: 0,
  thermostat_temperature: 0,
  added_chlorine: 0,
  added_acid: 0,
  others: "",
  time_period_id: 4,
  user_id: 1,
};
export const defaultGeneralData5 = {
  chlorine: 0,
  chlorine_dpd: 0,
  ph: 0,
  pool_temperature_1: 0,
  pool_temperature_2: 0,
  thermostat_temperature: 0,
  added_chlorine: 0,
  added_acid: 0,
  others: "",
  time_period_id: 5,
  user_id: 1,
};

export const defaultWaterTransparency = {
  transparency: 0,
  time_period_id: 2,
  user_id: 1,
};
export const defaultWaterTransparency2 = {
  transparency: 0,
  time_period_id: 5,
  user_id: 1,
};

export const defaultTotalDissolvedSolids = { dissolved_solids: 0, user_id: 1 };

export const defaultWaterSupply = {
  chlorine: 0,
  ph: 0,
  chloramines: 0,
  alkalinity: 0,
  hardness: 0,
  phosphate: 0,
  sdt: 0,
  added_lts: 0,
  user_id: 1,
};

export const defaultDailyReport = {
  daily_report: {
    id: null,
    pool: 1,
    report_date: new Date(),
    comments: "",
    water_supplies: [defaultWaterSupply],
    total_dissolved_solid: defaultTotalDissolvedSolids,
    water_transparencies: [defaultWaterTransparency, defaultWaterTransparency2],
    general_data: [
      defaultGeneralData,
      defaultGeneralData2,
      defaultGeneralData3,
      defaultGeneralData4,
      defaultGeneralData5,
    ],
  },
};

export const defaultBiweeklyReport = {
  biweekly_report: {
    id: null,
    start_date: new Date(),
    end_date: new Date(),
    pool: 0,
    user: 0,
    comments: "",
    free_cl: 0,
    chloramines: 0,
    acid: 0,
    alkalinity: 0,
    hardness: 0,
    phosphate: 0,
    sdt: 0,
    bomb_measure: 0,
    bomb_state: false,
    water_tank_thermo_measure: 0,
    water_tank_thermo_state: false,
    water_softener_measure: 0,
    water_softener_state: false,
    hydropneumatic_pressure_measure: 0,
    hydropneumatic_pressure_state: false,
    concentrated_chlorine_supply: 0,
    concentrated_acid_supply: 0,
    pool_structure_state: false,
    dispenser_state: false,
  },
};

// ==================================== DAILY REPORTS ====================================
export function getDailyReports(setterFunction, page = 1, search = "") {
  let url = "";
  if (search) url = `${DAILY_REPORTS_URL}?search=${search}&page=${page}`;
  else url = `${DAILY_REPORTS_URL}?page=${page}`;

  return fetch(url, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function getDailyReport(setterFunction, id) {
  return fetch(`${DAILY_REPORTS_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function updateDailyReport(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ daily_report: data });
  return fetch(`${DAILY_REPORTS_URL}/${id}`, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Reporte editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveDailyReport(data, setterFunction) {
  const body = JSON.stringify({ daily_report: data });
  return fetch(DAILY_REPORTS_URL, getParams("POST", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Reporte diario creado con exito", "success");
    })
    .catch(handleError);
}

export function deleteDailyReport(id, setterFunction) {
  return fetch(`${DAILY_REPORTS_URL}/${id}`, getParams("DELETE"))
    .then((response) => {
      if (response.ok) {
        notice("Reporte eliminado con exito", "success");
        setterFunction(id);
      }
    })
    .catch((e) => {
      notice(e.errors);
    });
}

// ==================================== BIWEEKLY REPORTS ====================================
export function getBiweeklyReports(setterFunction, page = 1, search = "") {
  let url = "";
  if (search) url = `${BIWEEKLY_REPORTS_URL}?search=${search}&page=${page}`;
  else url = `${BIWEEKLY_REPORTS_URL}?page=${page}`;

  return fetch(url, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function getBiweeklyReport(setterFunction, id) {
  return fetch(`${BIWEEKLY_REPORTS_URL}/${id}`, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch((e) => notice(e.errors));
}

export function updateBiweeklyReport(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ biweekly_report: data });

  return fetch(`${BIWEEKLY_REPORTS_URL}/${id}`, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Reporte editado con éxito", "success");
    })
    .catch(handleError);
}

export function saveBiweeklyReport(data, setterFunction) {
  const body = JSON.stringify({ biweekly_report: data });

  return fetch(BIWEEKLY_REPORTS_URL, getParams("POST", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Reporte diario creado con exito", "success");
    })
    .catch(handleError);
}

export function deleteBiweeklyReport(id, setterFunction) {
  return fetch(`${BIWEEKLY_REPORTS_URL}/${id}`, getParams("DELETE"))
    .then((response) => {
      if (response.ok) {
        notice("Reporte eliminado con exito", "success");
        setterFunction(id);
      }
    })
    .catch(handleError);
}
