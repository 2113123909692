import notice from "../utils/notice";
import { SUGGESTIONS_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

export const defaultSuggestion = {
  id: null,
  title: "",
  date: new Date(),
  area_id: "",
  user_id: "",
  client_phone: "",
  client_email: "",
  description: "",
  location_id: "",
  suggestion_status_id: "",
  suggestion_type_id: "",
  suggestion_source_id: "",
}; // falta client_id

// ==================================== SUGGESTIONS ====================================
export function getSuggestions(page = 0, search = "") {
  return fetch(
    `${SUGGESTIONS_URL}?page=${page}&search=${search}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getSuggestion(setterFunction, id) {
  return fetch(SUGGESTIONS_URL + "/" + id, getParams("GET"))
    .then((response) => handleResponse(response))
    .then((json) => setterFunction(json))
    .catch(handleError);
}

export function updateSuggestion(data, setterFunction) {
  const id = data.id;
  const body = JSON.stringify({ suggestion: data });

  return fetch(SUGGESTIONS_URL + "/" + id, getParams("PUT", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Sugerencia editada con éxito", "success");
    })
    .catch(handleError);
}

export function saveSuggestion(data, setterFunction) {
  const body = JSON.stringify({ suggestion: data });

  return fetch(SUGGESTIONS_URL, getParams("POST", body))
    .then((response) => handleResponse(response))
    .then((json) => {
      setterFunction(json);
      notice("Sugerencia creada con éxito", "success");
    })
    .catch(handleError);
}
