import { Edit } from "@material-ui/icons";
import React from "react";
import IconButton from "../../../../common/components/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
export const AllReportColumn = (data, openDialog, getReportPDF) => {
  // let id = dataRow.rowData[0];
  return (
    <div className="d-flex justify-content-around">
      <IconButton
        tooltip="Editar instructor"
        color="primary"
        Icon={Edit}
        onClick={() => openDialog("editing", data)}
      />
      <IconButton
        tooltip="Editar instructor"
        color="success"
        Icon={DescriptionIcon}
        onClick={() => getReportPDF(data)}
      />
    </div>
  );
};
