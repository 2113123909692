import moment from "moment";

export const defaultFilter = {
  start_date: null,
  end_date: null,
  status: null,
  client_location: null,
};

export const filtersForm = (locations = []) => [
  {
    name: "start_date",
    label: "Fecha de inicio",
    type: "date",
    variant: "filled",
  },
  {
    name: "end_date",
    label: "Fecha de fin",
    type: "date",
    variant: "filled",
  },
  {
    name: "client_location",
    label: "Sucursal",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
  {
    name: "status",
    label: "Estatus",
    type: "dropdown",
    variant: "filled",
    options: [
      // { value: "ACTIVO", text: "Activo" },
      { value: "PARCIAL", text: "Baja parcial" },
      { value: "TOTAL", text: "Baja total" },
    ],
  },
];

export const BajasColumns = [
  //   {
  //     name: "operation_date",
  //     label: "Fecha",
  //     options: {
  //       filter: true,
  //       sort: true,
  //       customBodyRender: (data) => `${moment(data).format("YYYY-MM-DD")}`,
  //     },
  //   },
  {
    name: "fecha_baja",
    label: "Fecha baja",
    options: {
      filter: false,
      sort: true,
      sortDirection: "asc",
      customBodyRender: (data) => `${moment(data).format("YYYY-MM-DD")}`,
    },
  },
  {
    name: "nombre_responsable",
    label: "Responsable",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "name_client",
    label: "Cliente",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "location",
    label: "Sucursal",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "tipo_baja",
    label: "Tipo de baja",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "motivo_baja",
    label: "Motivo",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "detalle_adeudo",
    label: "Detalle adeudo",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "adeudo",
    label: "Adeudo",
    options: {
      filter: false,
      sort: false,
    },
  },
];
