import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Stepper,
  Divider,
  Step,
  StepLabel,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormHelperText,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FilledInput,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Person, Delete, TextFields } from "@material-ui/icons";
import clsx from "clsx";
import { Formik } from "formik";
import "react-credit-cards/es/styles-compiled.css";
import SimpleCheckbox from "../../../common/components/SimpleCheckbox";
import SimpleDropdown from "../../../common/components/SimpleDropdown";
import * as clients from "../../../store/ducks/clients.duck";
import * as clientsCRUD from "../../../crud/clients.crud";
import notice from "../../../utils/notice";
import { findoutList } from "../../../crud/findout.crud";
import { errorMessages } from "./utils/dialog_data";
import {
  getCatalogCFDI,
  getCatalogFiscal,
  getFilterGroup,
  getCatalogExpectative,
} from "../../../crud/clients.crud";
import SimpleNumber from "../../../common/components/SimpleNumber";
import { useHistory } from "react-router-dom";
import { useStyles } from "./clientProfile/LessonDialog/styles";
import Loader from "./clientProfile/LessonDialog/Loader";

const ClientDialog = ({
  mode,
  setDialog,
  loggedUser,
  locations,
  client,
  clients,
  updateResponsibles,
  levels,
  groups,
  setClients,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const open = mode === "editing" || mode === "creating";
  const [tabValue, setTabValue] = useState(0);
  const [newResponsible, setNewResponsible] = useState({});
  const [loading, setLoading] = useState(false);
  const [findoutOptions, setFindoutOptions] = useState([]);
  const [fiscal, setFiscal] = useState([]);
  const [cdfis, setCdfis] = useState([]);
  const [expectations, setExpectations] = useState([]);

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const history = useHistory();
  const title = mode === "editing" ? "Editar Clientes" : "Crear Clientes";
  const button_txt = mode === "editing" ? "Editar" : "Crear";
  useEffect(() => {
    findoutList()
      .then(({ find_outs }) =>
        setFindoutOptions(
          find_outs.map(({ id, name }) => ({
            value: id,
            text: name,
          }))
        )
      )
      .catch(console.log);
    //catalogos
    getCatalogCFDI()
      .then((res) => {
        setCdfis(res);
      })
      .catch(console.log);

    //regimen
    getCatalogFiscal()
      .then((res) => {
        setFiscal(res);
      })
      .catch(console.log);

    //expectativas
    getCatalogExpectative()
      .then((res) => {
        setExpectations(res);
      })
      .catch(console.log);
  }, []);

  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
  const date =
    dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
    cancel();
  };

  const createClient = (values) => {
    enableLoading();
    clientsCRUD
      .saveClient(values, updateResponsibles, setNewResponsible)
      .then((res) => {
        if (res) {
          setTabValue(0);
          setDialog("closed");
        }
      })
      .catch((e) =>
        notice(
          Array.isArray(e.errors) ? e.map((msg) => msg.title) : "Error con su solicitud"
        )
      )
      .finally(disableLoading);
  };
  const [price, setPrice] = useState(0);
  useEffect(() => {
    if (newResponsible.idClient !== undefined) {
      history.push(`/punto-de-venta/${newResponsible.idClient}`);
    }
  }, [newResponsible]);

  const calculateTotalPriceByDescount = (
    category,
    descountName,
    valor,
    setterFunction,
    i,
    new_lesson
  ) => {
    if (new_lesson.length !== 0) {
      const body = new FormData();
      let courtesyDesc = descountName === "desc_cortesia" ? valor : 0;
      let ageDesc = descountName === "desc_tercera_edad" ? valor : 0;

      new_lesson.map((group, index) => {
        Object.keys(group).map((key) => {
          body.append(`data[${index}][group_price]`, category?.id);

          if (key === "day") {
            body.append(`data[${index}][day]`, group?.day);
          }
        });
      });

      body.append(`desc[desc_tercera_edad]`, ageDesc);
      body.append(`desc[desc_cortecia]`, courtesyDesc);

      clientsCRUD.percentageCalculationClient(body).then((json) => {
        setterFunction(`clients[${i}][priceTotal]`, json.Success[0].details);
        setterFunction(`clients["${i}"]["priceTotal"]`, json.Success[0].details);
      });
    } else {
      setterFunction(`clients[${i}][priceTotal]`, 0);
    }

    return price;
  };

  const calculateTotalPrice = (category, desc, setterFunction, i, new_lesson) => {
    if (new_lesson.length !== 0) {
      const body = new FormData();
      let courtesyDesc = desc?.desc_cortesia ? desc?.desc_cortesia : 0;
      let ageDesc = desc?.desc_tercera_edad ? desc?.desc_tercera_edad : 0;

      new_lesson.map((group, index) => {
        Object.keys(group).map((key) => {
          body.append(`data[${index}][group_price]`, category?.id);

          if (key === "day") {
            body.append(`data[${index}][day]`, group?.day);
          }
        });
      });

      body.append(`desc[desc_tercera_edad]`, ageDesc);
      body.append(`desc[desc_cortecia]`, courtesyDesc);
      clientsCRUD.percentageCalculationClient(body).then((json) => {
        setterFunction(`clients["${i}"]["priceTotal"]`, json.Success[0].details);
      });
    } else {
      setterFunction(`clients["${i}"]["priceTotal"]`, 0);
    }

    return price;
  };

  const steps = ["Información General", "Avisos y términos", "Clases"];

  const studentDummy = {
    name: "",
    last_name: "",
    last_name_2: "",
    phone: "",
    landline: "",
    email: "",
    membership_number: "",
    birthdate: "",
    gender: "",
    blood_type: "",
    expectative_id: [],
    client_type: "",
    postal_code: "",
    allergies_observations: "",
    inscription: true,
    inscriptionDate: "",
    annuity: true,
    accept: false,
    annuityDate: "",
    days_groups: [],
    hour_groups: [],
    level_selected: [],
    instrutor_selected: [],
    list_schedule_groups: "",
    category_groups: [],
    selected_groups: [],
    list_hour: [],
    list_instructors: [],
    list_levels: [],
    list_schedule_lesson: [],
    // group_schedule: [],
    priceTotal: 0,
    groups: [],
    street_name: "",
    number: "",
    suburb: "",
    district: "",
    state: "",
    country: "México",
    location_id: locations.data[0].id,
    code: "",
    invoice_person_type: "",
    street: "",
    interior_number: "",
    outdoor_number: "",
    regimen_fiscal: "",
    cfdi_use: "",
    bubication: false,
    bdiff_expectatives: false,
    cortesia: false,
    cortesia_edad: false,
    desc_tercera_edad: "",
    desc_cortesia: "",
    contact: {
      email: "",
      phone: "",
      landline: "",
    },
  };

  const States = [
    { value: "Aguascalientes", label: "Aguascalientes" },
    { value: "Baja California", label: "Baja California" },
    {
      value: "Baja California Sur",
      label: "Baja California Sur",
    },
    { value: "Campeche", label: "Campeche" },
    { value: "Chiapas", label: "Chiapas" },
    { value: "Coahuila", label: "Coahuila" },
    { value: "Colima", label: "Colima" },
    { value: "Ciudad de México", label: "Ciudad de México" },
    { value: "Durango", label: "Durango" },
    { value: "Guanajuato", label: "Guanajuato" },
    { value: "Guerrero", label: "Guerrero" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Jalisco", label: "Jalisco" },
    { value: "Estado de Mexico", label: "Estado de Mexico" },
    { value: "Michoacan", label: "Michoacan" },
    { value: "Morelos", label: "Morelos" },
    { value: "Nayarit", label: "Nayarit" },
    { value: "Nuevo Leon", label: "Nuevo Leon" },
    { value: "Oaxaca", label: "Oaxaca" },
    { value: "Puebla", label: "Puebla" },
    { value: "Queretaro", label: "Queretaro" },
    { value: "Quintana Roo", label: "Quintana Roo" },
    { value: "San Luis Potosi", label: "San Luis Potosi" },
    { value: "Sinaloa", label: "Sinaloa" },
    { value: "Sonora", label: "Sonora" },
    { value: "Tabasco", label: "Tabasco" },
    { value: "Tamaulipas", label: "Tamaulipas" },
    { value: "Tlaxcala", label: "Tlaxcala" },
    { value: "Veracruz", label: "Veracruz" },
    { value: "Yucatan", label: "Yucatan" },
    { value: "Zacatecas", label: "Zacatecas" },
  ];

  function addStudent(array, setterFunction) {
    array.push(studentDummy);
    setterFunction("clients", array);
  }

  function asStudent(responsible, clients, setterFunction, values) {
    if (responsible.isStudent) {
      clients.splice(0, 1);
      setterFunction("clients", clients);
    } else {
      let newuser = {
        ...responsible,
        location_id: locations.data[0].id,

        birthdate: "",
        gender: "",
        blood_type: "",
        expectative_id: [],
        postal_code: "",
        allergies_observations: "",
        inscription: true,
        inscriptionDate: materialDateInput,
        annuity: true,
        accept: false,
        annuityDate: "",
        groups: [],
        days_groups: [],
        hour_groups: [],
        level_selected: [],
        instrutor_selected: [],
        list_schedule_groups: "",
        category_groups: [],
        priceTotal: 0,
        list_hour: [],
        list_instructors: [],
        list_levels: [],
        list_schedule_lesson: [],
        selected_groups: [],
        contact: {
          email: "",
          phone: "",
          landline: "",
        },
      };
      clients.unshift(newuser);
      setterFunction("clients", clients);
    }
    setterFunction("responsible['isStudent']", !responsible.isStudent);
    setterFunction("main_contact['email']", responsible.email);
    setterFunction("main_contact['cellphone']", responsible.phone);
    setterFunction("main_contact['landline']", responsible.landline);
  }

  const calculateDate = (date) => {
    let birthDate = new Date(date);
    let today = new Date();
    let tt = today - birthDate;
    let age = Math.floor(tt / 31557600000);

    return age;
  };

  const [validateEmail, setValidateEmail] = useState(false);
  const handleValidateEmail = (e) => {
    const { value, name } = e.target;
    //values.responsible.email
    if (value) {
      clientsCRUD.getValidateEmail(value).then((res) => {
        if (res) {
          if (res?.Error) {
            setValidateEmail(true);
          } else if (res?.Success) {
            setValidateEmail(false);
          }
        }
      });
    }
  };
  const [namePDF, setNamePDF] = useState({
    name: "Aviso de privacidad alumno.pdf",
    url: "https://aquaraassets.s3.amazonaws.com/Aviso+de+privacidad+Aquara+Kids.pdf",
  });

  const [tabPDF, setTabPDF] = useState(0);
  const handleNextPDF = () => {
    if (tabPDF === 0) {
      setTabPDF(1);
      setNamePDF({
        ...namePDF,
        name: "Términos y condiciones alumno",
        url:
          "https://aquaraassets.s3.amazonaws.com/Terminos+y+condiciones+Aquara+Kids.pdf",
      });
    } else if (tabPDF === 1) {
      setTabPDF(2);
      setNamePDF({
        ...namePDF,
        name: "Avisos de privacidad encargado",
        url: "https://aquaraassets.s3.amazonaws.com/Aviso+de+privacidad+Aquara.pdf",
      });
    }
  };

  const handleReturnPDF = () => {
    if (tabPDF === 2) {
      setNamePDF({
        ...namePDF,
        name: "Términos y condiciones alumno",
        url:
          "https://aquaraassets.s3.amazonaws.com/Terminos+y+condiciones+Aquara+Kids.pdf",
      });
      setTabPDF(1);
    } else if (tabPDF === 1) {
      setTabPDF(0);
      setNamePDF({
        ...namePDF,
        name: "Aviso de privacidad alumno.pdf",
        url: "https://aquaraassets.s3.amazonaws.com/Aviso+de+privacidad+Aquara+Kids.pdf",
      });
    }
  };
  function renderInputs(
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue
  ) {
    switch (tabValue) {
      case 0:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Responsable</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['name']"
                  label="Nombre *"
                  onChange={handleChange}
                  value={values.responsible.name}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    (touched.responsible !== undefined
                      ? touched.responsible.name
                      : false) &&
                    (errors.responsible !== undefined ? errors.responsible.name : "")
                  }
                  error={Boolean(
                    (touched.responsible !== undefined
                      ? touched.responsible.name
                      : false) &&
                      (errors.responsible !== undefined ? errors.responsible.name : false)
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['last_name']"
                  label="Apellido paterno *"
                  onChange={handleChange}
                  value={values.responsible.last_name}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.last_name &&
                    (errors?.responsible?.last_name || false)
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.last_name &&
                      (errors?.responsible?.last_name || false)
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['last_name_2']"
                  label="Apellido materno (opcional)"
                  onChange={handleChange}
                  value={values.responsible.last_name_2}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.last_name_2 &&
                    errors.responsible?.last_name_2
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.last_name_2 &&
                      (errors.responsible?.last_name_2 || false)
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['phone']"
                  label="Teléfono *"
                  onChange={(e) => {
                    !isNaN(e.target.value) && handleChange(e);
                    !isNaN(e.target.value) &&
                      setFieldValue("main_contact['cellphone']", e.target.value);
                  }}
                  value={values.responsible.phone}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.phone &&
                    (errors.responsible?.phone || false)
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.phone &&
                      (errors.responsible?.phone || false)
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['landline']"
                  label="Teléfono casas (opcional)"
                  onChange={handleChange}
                  value={values.responsible.landline}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.landline &&
                    (errors.responsible?.landline || false)
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.landline &&
                      (errors.responsible?.landline || false)
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['email']"
                  label="Correo electónico *"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("main_contact['email']", e.target.value);
                  }}
                  value={values.responsible.email}
                  fullWidth
                  onBlur={(e) => {
                    handleBlur(e);
                    handleValidateEmail(e);
                  }}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.email &&
                    (errors?.responsible?.email || false)
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.email &&
                      (errors?.responsible?.email || false)
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} style={{ marginBottom: "25px" }}>
                <TextField
                  name="responsible['membership_number']"
                  label="Número de Socio *"
                  onChange={handleChange}
                  value={values.responsible.membership_number}
                  fullWidth
                  onBlur={handleBlur}
                  helperText={
                    touched.responsible !== undefined &&
                    touched.responsible.membership_number &&
                    errors.responsible.membership_number
                  }
                  error={Boolean(
                    touched.responsible !== undefined &&
                      touched.responsible.membership_number &&
                      errors.responsible.membership_number
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(
                    touched?.responsible?.client_type && errors?.responsible?.client_type
                  )}
                >
                  <InputLabel id="client_type">Tipo de Cliente *</InputLabel>
                  <Select
                    labelId="client_type"
                    value={values.responsible.client_type}
                    onChange={handleChange}
                    name={`responsible['client_type']`}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                  </Select>
                  <FormHelperText>
                    {touched?.responsible?.client_type &&
                      errors?.responsible?.client_type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <SimpleDropdown
                  name="find_out_id"
                  value={values.find_out_id}
                  onChange={handleChange}
                  label="Cómo te enteraste de Aquara"
                  options={findoutOptions}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography>Dirección</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['street_name']"
                  label="Calle y número (opcional)"
                  onChange={handleChange}
                  value={values.responsible.street_name}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['number']"
                  label="Número int (opcional)"
                  onChange={handleChange}
                  value={values.responsible.number}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['suburb']"
                  label="Colonia (opcional)"
                  onChange={handleChange}
                  value={values.responsible.suburb}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['district']"
                  label="Delegación (opcional)"
                  onChange={handleChange}
                  value={values.responsible.district}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="responsible['postal_code']"
                  label="Código Postal"
                  onChange={handleChange}
                  value={values.responsible.postal_code}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="state">Estado (opcional)</InputLabel>
                  <Select
                    labelId="state"
                    name={`responsible['state']`}
                    value={values.responsible.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {States.map((b) => (
                      <MenuItem key={b.value} value={b.value}>
                        {b.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginBottom: "25px" }}>
                <TextField
                  name="responsible['country']"
                  label="País (opcional)"
                  onChange={handleChange}
                  value={values.responsible.country}
                  fullWidth
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            {values.responsible.client_type && (
              <>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    {mode === "creating" ? (
                      <>
                        <Typography>¿El responsable es el alumno?</Typography>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <Checkbox
                            color="primary"
                            checked={values.responsible.isStudent}
                            onChange={() =>
                              asStudent(
                                values.responsible,
                                values.clients,
                                setFieldValue,
                                values
                              )
                            }
                            name="responsible['isStudent']"
                          />
                          <Person
                            color="primary"
                            fontSize="large"
                            style={{
                              marginRight: 10,
                            }}
                          />
                          <span>
                            {values.responsible.name} {values.responsible.last_name}
                          </span>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                    {values.clients.map((student, i) => (
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 10,
                        }}
                      >
                        {values.responsible.isStudent && i === 0 ? (
                          ""
                        ) : (
                          <Typography>
                            Alumno {i + 1}
                            <IconButton
                              onClick={() =>
                                deleteStudent(
                                  i,
                                  values.clients,
                                  values.deletedClients,
                                  setFieldValue
                                )
                              }
                            >
                              <Delete />
                            </IconButton>
                          </Typography>
                        )}

                        <Grid container spacing={2}>
                          {i > 0 ? (
                            <>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['name']`}
                                  label="Nombre *"
                                  onChange={handleChange}
                                  value={values.clients[i].name}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['last_name']`}
                                  label="Apellido paterno *"
                                  onChange={handleChange}
                                  value={values.clients[i].last_name}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['last_name_2']`}
                                  label="Apellido materno"
                                  onChange={handleChange}
                                  value={values.clients[i].last_name_2}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            </>
                          ) : i === 0 && !values.responsible.isStudent ? (
                            <>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['name']`}
                                  label="Nombre *"
                                  onChange={handleChange}
                                  value={values.clients[i].name}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['last_name']`}
                                  label="Apellido paterno *"
                                  onChange={handleChange}
                                  value={values.clients[i].last_name}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name={`clients["${i}"]['last_name_2']`}
                                  label="Apellido materno"
                                  onChange={handleChange}
                                  value={values.clients[i].last_name_2}
                                  fullWidth
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            </>
                          ) : (
                            ""
                          )}
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name={`clients["${i}"]['birthdate']`}
                              type="date"
                              label="Fecha de nacimiento *"
                              onChange={handleChange}
                              value={values.clients[i].birthdate}
                              fullWidth
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          {values.clients[i].birthdate ? (
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{
                                marginTop: "3px",
                                paddingLeft: "31px",
                              }}
                            >
                              Edad
                              <Typography variant="h6">
                                {calculateDate(values.clients[i].birthdate)} Años
                              </Typography>
                            </Grid>
                          ) : (
                            ""
                          )}
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                              <InputLabel id="gender">Género *</InputLabel>
                              <Select
                                labelId="gender"
                                value={values.clients[i].gender}
                                onChange={handleChange}
                                name={`clients["${i}"]['gender']`}
                              >
                                <MenuItem value="femenino">Femenino</MenuItem>
                                <MenuItem value="masculino">Masculino</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="standard">
                              <InputLabel id="blood_type">
                                Tipo de Sangre (opcional)
                              </InputLabel>
                              <Select
                                labelId="blood_type"
                                value={values.clients[i].blood_type}
                                onChange={handleChange}
                                name={`clients["${i}"]['blood_type']`}
                              >
                                <MenuItem value="o+">O+</MenuItem>
                                <MenuItem value="o-">O-</MenuItem>
                                <MenuItem value="a+">A+</MenuItem>
                                <MenuItem value="a-">A-</MenuItem>
                                <MenuItem value="b+">B+</MenuItem>
                                <MenuItem value="b-">B-</MenuItem>
                                <MenuItem value="ab+">AB+</MenuItem>
                                <MenuItem value="ab-">AB-</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleDropdown
                              label="Sucursal *"
                              value={values.clients[i].location_id}
                              onChange={handleChange}
                              name={`clients["${i}"]['location_id']`}
                              options={locations.data.map(({ id, name }) => ({
                                value: id,
                                text: name,
                              }))}
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <FormControl
                              fullWidth
                              margin="normal"
                              variant="standard"
                              style={{
                                marginTop: -3,
                              }}
                            >
                              <InputLabel htmlFor="group">Expectativas</InputLabel>
                              <Select
                                value={values.clients[i].expectative_id}
                                onChange={handleChange}
                                name={`clients["${i}"]['expectative_id']`}
                                multiple
                              >
                                {expectations.data.length >= 1 ? (
                                  expectations.data.map((expectation) => (
                                    <MenuItem
                                      key={expectation.value}
                                      value={expectation.value}
                                    >
                                      {expectation.text}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem>No hay resultados</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <SimpleCheckbox
                              label="Aplicar Examen de ubicación"
                              labelPos="end"
                              name={`clients["${i}"]['bubication']`}
                              value={values.clients[i].bubication}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <SimpleCheckbox
                              label="Expectetivas diferentes"
                              labelPos="end"
                              name={`clients["${i}"]['bdiff_expectatives']`}
                              value={values.clients[i].bdiff_expectatives}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <SimpleCheckbox
                              label="Descuento por cortesia"
                              labelPos="end"
                              name={`clients["${i}"]['cortesia']`}
                              value={values.clients[i].cortesia}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <SimpleCheckbox
                              label="Descuento tercera edad"
                              labelPos="end"
                              name={`clients["${i}"]['cortesia_edad']`}
                              value={values.clients[i].cortesia_edad}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name={`clients["${i}"]['allergies_observations']`}
                              label="Enfermedades, alergias y medicamentos (opcional)"
                              onChange={handleChange}
                              value={values.clients[i].allergies_observations}
                              fullWidth
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Button onClick={() => addStudent(values.clients, setFieldValue)}>
                        + Agregar alumno
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {values.clients.length > 0 && (
              <>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Typography>
                      Agrega la información de contacto de los alumnos
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={values.share_contact}
                          onChange={handleChange}
                          name="share_contact"
                        />
                      }
                      label="Los alumnos comparten información de contacto"
                    />
                  </Grid>
                  {values.share_contact ? (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>
                            {values.clients &&
                              values.clients.map((student) => student.name).join(", ")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="main_contact['email']"
                            label="Correo electrónico *"
                            onChange={handleChange}
                            value={values.main_contact.email}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="main_contact['cellphone']"
                            label="Teléfono *"
                            onChange={handleChange}
                            value={values.main_contact.cellphone}
                            fullWidth
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="main_contact['landline']"
                            label="Teléfono casa (opcional)"
                            onChange={handleChange}
                            value={values.main_contact.landline}
                            fullWidth
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    values.clients.map((student, i) => (
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography>
                              {student.name} {student.last_name} #
                              {values.responsible.membership_number}-{i + 1}
                              {values.responsible.client_type}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name={`clients["${i}"]['contact']['email']`}
                              label="Correo electrónico *"
                              onChange={handleChange}
                              value={values.clients[i].contact.email}
                              fullWidth
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name={`clients["${i}"]['contact']['postal_code']`}
                              label="Código Postal *"
                              onChange={handleChange}
                              value={values.clients[i].contact.postal_code}
                              fullWidth
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              name={`clients["${i}"]['contact']['phone']`}
                              label="Teléfono *"
                              onChange={handleChange}
                              value={values.clients[i].contact.phone}
                              fullWidth
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{
                              marginBottom: "25px",
                            }}
                          >
                            <TextField
                              name={`clients["${i}"]['contact']['landline']`}
                              label="Teléfono casa (opcional)"
                              onChange={handleChange}
                              value={values.clients[i].contact.landline}
                              fullWidth
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  )}
                </Grid>
              </>
            )}
          </>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Documentos a firmar por alumno</Typography>
              <Grid
                style={{
                  // outline: '2px solid blue',
                  // width: '100%',
                  height: "500px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Typography variant="body">{namePDF.name}</Typography>
                <iframe src={namePDF.url} height="450px" width="100%"></iframe>

                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                  }}
                >
                  {tabPDF === 0 ? (
                    ""
                  ) : (
                    <Button
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginRight: 10,
                      }}
                      onClick={() => handleReturnPDF()}
                      color="primary"
                      variant="contained"
                    >
                      Atrás
                    </Button>
                  )}
                  {tabPDF === 2 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={values.responsible.accept}
                          onChange={handleChange}
                          name="responsible['accept']"
                        />
                      }
                      label="He leído y aceptado el reglamento y avisos de privacidad"
                    />
                  ) : (
                    <Button
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        handleNextPDF();
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Siguiente
                    </Button>
                  )}
                </div>
                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            checked={
                                                values.responsible
                                                    .accept
                                            }
                                            onChange={handleChange}
                                            name="responsible['accept']"
                                        />
                                    }
                                    label='He leído y aceptado el reglamento y avisos de privacidad'
                                /> */}
                {/* </Grid> */}
              </Grid>
            </Grid>
            {/* {values.clients.map((student, i) => (
                            <>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        justifyContent='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Person
                                            color='primary'
                                            fontSize='large'
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        <Grid
                                            container
                                            direction='column'
                                            justifyContent='flex-start'
                                            alignItems='flex-start'
                                            style={{ width: '80%' }}
                                        >
                                            <Typography>
                                                {student.name}{' '}
                                                {student.last_name} #
                                                {
                                                    values.responsible
                                                        .membership_number
                                                }
                                                -{i + 1}
                                                {
                                                    values.responsible
                                                        .client_type
                                                }
                                            </Typography>
                                            <Typography>
                                                {student.birthdate}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ))} */}
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {values.clients.map((student, i) => {
                return (
                  <Fragment key={i}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        Alumno {i + 1}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Person
                          color="primary"
                          fontSize="large"
                          style={{
                            marginRight: 10,
                          }}
                        />
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          style={{
                            width: "80%",
                          }}
                        >
                          <Typography>
                            {student.name} {student.last_name} #
                            {values.responsible.membership_number}-{i + 1}
                            {values.responsible.client_type}
                          </Typography>
                          <Typography>{student.birthdate}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* fecha de inscipcion */}
                    <Grid container xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{
                          marginLeft: "-5px",
                          marginRight: "13px",
                        }}
                      >
                        <TextField
                          name={`clients["${i}"]['inscriptionDate']`}
                          type="date"
                          label="Fecha de Inscripción *"
                          onChange={(e) => {
                            handleChangeDate(
                              values.clients[i].inscriptionDate,
                              e.target.value,
                              i,
                              materialDateInput,
                              setFieldValue
                            );
                          }}
                          value={
                            values.clients[i].inscriptionDate
                              ? values.clients[i].inscriptionDate
                              : handleDate(materialDateInput, i, setFieldValue)
                          }
                          fullWidth
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      {/* cobrar  */}
                      {/* <Grid
                        item
                        xs={12}
                        sm={2}
                        style={{ marginTop: "10px", minWidth: "163px" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.clients[i].inscription}
                              onChange={handleChange}
                              name={`clients["${i}"]['inscription']`}
                            />
                          }
                          label="Cobrar inscripción"
                        />
                      </Grid> */}
                      {!values.clients[i].inscription ? (
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={{
                            marginLeft: "-5px",
                            marginRight: "38px",
                          }}
                        >
                          <TextField
                            name={`clients["${i}"]['reason']`}
                            label="Razon para no cobrar"
                            onChange={handleChange}
                            value={values.clients[i].reason}
                            fullWidth
                            onBlur={handleBlur}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                      {/* <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{ marginTop: "10px", minWidth: "163px" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.clients[i].annuity}
                              onChange={handleChange}
                              name={`clients["${i}"]['annuity']`}
                            />
                          }
                          clients
                          label="Cobrar Anualidad"
                        />
                      </Grid> */}
                    </Grid>
                    {/* tipo de clase */}
                    <Grid
                      container
                      xs={12}
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        style={{
                          padding: "0 25px 0 0",
                        }}
                      >
                        <FormControl fullWidth margin="normal" variant="standard">
                          <InputLabel htmlFor="group">Tipo de clase *</InputLabel>
                          <Select
                            labelId="group"
                            onChange={(e) => {
                              handleChange(e);
                              handleChangeSelectCategory(
                                e.target.value,
                                i,
                                setFieldValue
                              );
                            }}
                          >
                            {groups.data.map((grup) => (
                              <MenuItem key={grup.id} value={grup}>
                                {grup.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      style={{
                        marginBottom: "45px",
                      }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        {values.clients[i].groups && (
                          <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* {values.clients[i].groups.map((group, j) => ( */}

                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {/* DÍAS DE LA SEMANA */}
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  padding: "0 15px 0 0",
                                }}
                              >
                                <FormControl fullWidth margin="normal" variant="standard">
                                  <InputLabel htmlFor="group">Días</InputLabel>
                                  <Select
                                    labelId="group"
                                    value={values.clients[i].days_groups}
                                    name={`clients["${i}"]['days_groups']`}
                                    onBlur={() =>
                                      handleGetHour(values.clients[i], i, setFieldValue)
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleDay(e, setFieldValue);
                                    }}
                                    multiple
                                  >
                                    {daysOfTheWeek.map((day) => (
                                      <MenuItem key={day.value} value={day.value}>
                                        {day.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* HORAS  */}

                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  padding: "0 15px 0 0",
                                }}
                              >
                                <FormControl fullWidth margin="normal" variant="standard">
                                  <InputLabel htmlFor="group">Hora</InputLabel>
                                  <Select
                                    value={values.clients[i].hour_groups}
                                    name={`clients["${i}"]['hour_groups']`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleTimeStart(e, setFieldValue);
                                    }}
                                    onBlur={() =>
                                      handleLevels(values.clients[i], i, setFieldValue)
                                    }
                                    multiple
                                  >
                                    {values.clients[i].list_hour.length >= 1 ? (
                                      values.clients[i].list_hour.map((hour, index) => (
                                        <MenuItem key={index} value={hour.start_time}>
                                          {hour.display}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem>
                                        {loader ? <Loader /> : "No hay resultados"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>

                              {/* NIVELES */}
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  padding: "0 15px 0 0",
                                }}
                              >
                                <FormControl fullWidth margin="normal" variant="standard">
                                  <InputLabel htmlFor="group">Niveles</InputLabel>
                                  <Select
                                    labelId="group"
                                    value={values.clients[i].level_selected}
                                    name={`clients["${i}"]['level_selected']`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleLevel(e, setFieldValue);
                                    }}
                                    onBlur={() =>
                                      handleInstructors(
                                        values.clients[i],
                                        i,
                                        setFieldValue
                                      )
                                    }
                                    multiple
                                  >
                                    {values.clients[i].list_levels.length >= 1 ? (
                                      values.clients[i].list_levels.map(
                                        (level, index) => (
                                          <MenuItem key={index} value={level.id}>
                                            {level?.name}
                                          </MenuItem>
                                        )
                                      )
                                    ) : (
                                      <MenuItem>
                                        {loader ? <Loader /> : "No hay resultados"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* INSTRUCTORES */}
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  padding: "0 15px 0 0",
                                }}
                              >
                                <FormControl fullWidth margin="normal" variant="standard">
                                  <InputLabel htmlFor="group">Instructores</InputLabel>
                                  <Select
                                    labelId="group"
                                    value={values.clients[i].instrutor_selected}
                                    name={`clients["${i}"]['instrutor_selected']`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInstructor(e, setFieldValue);
                                    }}
                                    onBlur={() =>
                                      handleGroups(values.clients[i], i, setFieldValue)
                                    }
                                  >
                                    {values.clients[i].list_instructors.length >= 1 ? (
                                      values.clients[i].list_instructors.map(
                                        (instructor, index) => (
                                          <MenuItem key={index} value={instructor}>
                                            {instructor?.name} {instructor?.last_name}
                                          </MenuItem>
                                        )
                                      )
                                    ) : (
                                      <MenuItem>
                                        {loader ? <Loader /> : "No hay resultados"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* CLASES DISPONIBLES */}
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  padding: "0 15px 0 0",
                                }}
                              >
                                <FormControl fullWidth margin="normal" variant="standard">
                                  <InputLabel htmlFor="group">
                                    Clases disponibles
                                  </InputLabel>
                                  <Select
                                    labelId="group"
                                    value={values.clients[i].selected_groups}
                                    name={`clients["${i}"]['selected_groups']`}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleChangeSelectGroup(
                                        e,
                                        e.target.value,
                                        i,
                                        values,
                                        setFieldValue
                                      );
                                    }}
                                  >
                                    {values.clients[i].list_schedule_groups.length >=
                                    1 ? (
                                      values.clients[i].list_schedule_groups.map(
                                        (group, index) => (
                                          <MenuItem
                                            key={index}
                                            value={group}
                                            disabled={group.cupo === 0 ? true : false}
                                          >
                                            {group?.display}
                                          </MenuItem>
                                        )
                                      )
                                    ) : (
                                      <MenuItem>
                                        {loader ? <Loader /> : "No hay resultados"}
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </div>
                            <div
                              className={classes.cardContainerClient}
                              style={{
                                marginTop: 25,
                              }}
                            >
                              {/* CLASES seleccionadas */}
                              <div className={classes.cardText}>
                                <Grid
                                // item
                                // xs={12}
                                // sm={4}
                                // md={4}
                                // style={{ padding: "0 25px 0 0" }}
                                >
                                  <Typography variant="h6">
                                    Clases seleccionadas
                                  </Typography>
                                  {values.clients[i].groups
                                    ? values.clients[i].groups?.map(
                                        (selected_group, group_index) => (
                                          <>
                                            <p>{selected_group.display}</p>
                                            <IconButton
                                              onClick={() =>
                                                deleteGroup(
                                                  group_index,
                                                  values.clients[i].groups,
                                                  values.deletedGroup,
                                                  setFieldValue,
                                                  values.clients[i],
                                                  i
                                                )
                                              }
                                            >
                                              <Delete />
                                            </IconButton>
                                          </>
                                        )
                                      )
                                    : ""}
                                </Grid>
                              </div>
                              {/*PRECIOS */}
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={3}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 5,
                                  textAlign: "center",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  {" "}
                                  Precio:
                                </Typography>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{
                                    textAlign: "center",
                                    marginTop: "15%",
                                  }}
                                >
                                  {values.clients[i].priceTotal
                                    ? `$${values.clients[i].priceTotal} / mes`
                                    : "$0 / mes"}
                                </Typography>
                              </Grid>
                            </div>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          style={{
                            marginTop: 20,
                          }}
                        >
                          {values?.clients[i]?.cortesia && (
                            <SimpleNumber
                              label="Descuento por cortesía"
                              name={`clients["${i}"]['desc_cortesia']`}
                              value={values?.clients[i].desc_cortesia}
                              type="number"
                              width="50%"
                              isPercentage="percentage"
                              decimals={2}
                              onChange={(e) => {
                                handleChange(e);
                                handleDescount(
                                  e.target,
                                  i,
                                  setFieldValue,
                                  values,
                                  "desc_cortesia"
                                );
                              }}
                            />
                          )}
                          {values?.clients[i]?.cortesia_edad && (
                            <SimpleNumber
                              label=" Descuento por 3ra edad "
                              name={`clients["${i}"]['desc_tercera_edad']`}
                              value={values?.clients[i].desc_tercera_edad}
                              type="number"
                              width="50%"
                              isPercentage="percentage"
                              decimals={2}
                              onChange={(e) => {
                                handleChange(e);
                                handleDescount(
                                  e.target,
                                  i,
                                  setFieldValue,
                                  values,
                                  "desc_tercera_edad"
                                );
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                marginTop: "10px",
                minWidth: "163px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={values.responsible.accept}
                    onChange={handleChange}
                    name="responsible['accept']"
                  />
                }
                clients
                label="Datos de Facturacion"
              />
            </Grid>
            {values.billing_information ? (
              <>
                <Divider />
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: "15px",
                      }}
                    >
                      Datos de Facturacion
                    </Typography>
                  </Grid>
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="invoice_person_type" required>
                          Tipo de Persona
                        </InputLabel>
                        <Select
                          labelId="invoice_person_type"
                          value={values.invoice_information.invoice_person_type}
                          onChange={handleChange}
                          name={`invoice_information['invoice_person_type']`}
                        >
                          <MenuItem value="Moral">Moral</MenuItem>
                          <MenuItem value="Fisica">Fisica</MenuItem>
                        </Select>
                        <FormHelperText></FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        name={`invoice_information['name']`}
                        label={
                          values.invoice_information.invoice_person_type === "Fisica"
                            ? "Nombre Persona Fisica "
                            : "Nombre Persona Moral "
                        }
                        onChange={handleChange}
                        value={values.invoice_information.name}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['rfc']`}
                        label="RFC "
                        required
                        onChange={handleChange}
                        value={values.invoice_information.rfc}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['email']`}
                        label="Email"
                        required
                        onChange={handleChange}
                        value={values.invoice_information.email}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['street']`}
                        label="Calle"
                        required
                        onChange={handleChange}
                        value={values.invoice_information.street}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['outdoor_number']`}
                        label="Número ext "
                        required
                        onChange={handleChange}
                        value={values.invoice_information.outdoor_number}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['interior_number']`}
                        label="Número int (opcional)"
                        onChange={handleChange}
                        value={values.invoice_information.interior_number}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['suburb']`}
                        label="Colonia"
                        required
                        onChange={handleChange}
                        value={values.invoice_information.suburb}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['postal_code']`}
                        label="Codigo Postal"
                        required
                        onChange={handleChange}
                        value={values.invoice_information.postal_code}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="state" required>
                          Estado{" "}
                        </InputLabel>
                        <Select
                          labelId="state"
                          name={`invoice_information['state']`}
                          value={values.invoice_information.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {States.map((b) => (
                            <MenuItem key={b.value} value={b.value}>
                              {b.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['municipality']`}
                        label="Municipio"
                        required
                        onChange={handleChange}
                        value={values.invoice_information.municipality}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name={`invoice_information['locality']`}
                        label="Localidad "
                        onChange={handleChange}
                        value={values.invoice_information.locality}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        name={`invoice_information['country']`}
                        label="País"
                        onChange={handleChange}
                        value={values.invoice_information.country}
                        fullWidth
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="regimen_fiscal" required>
                          Régimen fiscal " "
                        </InputLabel>
                        <Select
                          labelId="regimen_fiscal"
                          value={values.invoice_information.regimen_fiscal}
                          onChange={handleChange}
                          name={`invoice_information['regimen_fiscal']`}
                        >
                          {fiscal.map((fisc) => {
                            return (
                              <MenuItem key={fisc.value} value={fisc.value}>
                                {fisc.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText></FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="cfdi_use" required>
                          Uso de CFDI{" "}
                        </InputLabel>
                        <Select
                          labelId="cfdi_use"
                          value={values.invoice_information.cfdi_use}
                          onChange={handleChange}
                          name={`invoice_information['cfdi_use']`}
                        >
                          {cdfis.map((cdfi) => {
                            return (
                              <MenuItem key={cdfi.value} value={cdfi.value}>
                                {cdfi.text}
                              </MenuItem>
                            );
                          })}
                          {/* <MenuItem value="G03">G03 - GASTOS EN GENERAL</MenuItem>
                          <MenuItem value="D10">
                            D10 - PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURA)
                          </MenuItem>
                          <MenuItem value="D02">
                            D02 - GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD
                          </MenuItem>
                          <MenuItem value="D01">
                            D01 - HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS
                          </MenuItem> */}
                        </Select>
                        <FormHelperText></FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        );

      default:
        break;
    }
  }

  function verifyNext(values, errors) {
    let responsibleReqFields = [
      "name",
      "last_name",
      "phone",
      "email",
      // "membership_number",
      "client_type",
    ];
    if (!responsibleReqFields.every((x) => values.responsible[x])) {
      let resp_error_msg = [];
      Object.keys(values.responsible).forEach((key) =>
        responsibleReqFields.forEach((req) =>
          resp_error_msg.push(
            (!values.responsible[req] || values.responsible[req] === undefined) &&
              `Responsable => ${errorMessages.responsible[req]}`
          )
        )
      );
      resp_error_msg = [...new Set(resp_error_msg.filter((msg) => msg))];
      resp_error_msg.length > 0 && notice(resp_error_msg);
      return false;
    }
    if (values.clients.length === 0) {
      notice("Falta agregar alumnos a su responsable");
      return false;
    }
    let obj = {};
    let obj3 = {};
    let requiredKeys = [];
    switch (tabValue) {
      case 0:
        if (values.share_contact && values.responsible) {
          let incomplete = 0;
          requiredKeys = ["email", "cellphone"];
          obj = { ...values.main_contact };
          Object.keys(obj).forEach((k) => !obj[k] && obj[k] === "" && delete obj[k]);
          let students_error_msg = [];
          values.clients.forEach((student, i) => {
            obj3 = { ...student };
            let requiredKeys3 = [
              "name",
              "last_name",
              "birthdate",
              "gender",
              "location_id",
            ];
            if (!requiredKeys3.every((x) => obj3[x])) {
              Object.keys(obj3).forEach((key) =>
                requiredKeys3.forEach(
                  (req) =>
                    (!obj3[req] || obj3[req] === undefined) &&
                    students_error_msg.push(
                      `Alumno ${i + 1} => ${errorMessages.student[req]}`
                    )
                )
              );
              students_error_msg = [...new Set(students_error_msg.filter((msg) => msg))];
            }
            Object.keys(obj3).forEach(
              (k) => !obj3[k] && obj3[k] === "" && delete obj3[k]
            );
            if (!requiredKeys3.every((x) => Object.keys(obj3).includes(x))) incomplete++;
          });
          students_error_msg.length > 0 && notice(students_error_msg);

          if (!requiredKeys.every((x) => Object.keys(obj).includes(x))) {
            let main_contact_msg = [];
            Object.keys(values.main_contact).forEach((key) =>
              requiredKeys.forEach((req) =>
                main_contact_msg.push(
                  (!obj[req] || obj[req] === undefined) &&
                    `Contacto => ${errorMessages.contact[req]}`
                )
              )
            );
            main_contact_msg = [...new Set(main_contact_msg.filter((msg) => msg))];
            main_contact_msg.length > 0 && notice(main_contact_msg);
          }
          return (
            requiredKeys.every((x) => Object.keys(obj).includes(x)) && incomplete === 0
          );
        } else {
          let count_incomplete = 0;
          requiredKeys = ["email", "phone", "postal_code"];
          let requiredStudentFields = [
            "name",
            "last_name",
            "birthdate",
            "gender",
            "location_id",
          ];
          let students_error_msg = [];
          values.clients.forEach((student, i) => {
            if (!requiredStudentFields.every((x) => student[x])) count_incomplete++;
            if (!requiredStudentFields.every((x) => student[x])) {
              Object.keys(student).forEach((key) =>
                requiredStudentFields.forEach(
                  (req) =>
                    (!student[req] || student[req] === undefined) &&
                    students_error_msg.push(
                      `Alumno ${i + 1} => ${errorMessages.student[req]}`
                    )
                )
              );
              students_error_msg = [...new Set(students_error_msg.filter((msg) => msg))];
            }

            if (!requiredKeys.every((x) => student.contact[x])) count_incomplete++;
          });
          return count_incomplete === 0;
        }
      case 1:
        let incomplete = 0;

        if (values.responsible.accept === false) {
          incomplete++;
          notice("Tienes que aceptar las condiciones de uso");
        }

        return incomplete === 0;

      case 2:
        let count_incomplete = 0;
        if (values.billing_information === true) {
          obj = { ...values.invoice_information };
          let requiredKeys = [
            "cfdi_use",
            "country",
            "email",
            "invoice_person_type",
            "locality",
            "municipality",
            "name",
            "outdoor_number",
            "postal_code",
            "rfc",
            "state",
            "street",
            "suburb",
          ];
          Object.keys(obj).forEach((k) => !obj[k] && obj[k] === "" && delete obj[k]);
          if (!requiredKeys.every((x) => Object.keys(obj).includes(x)))
            count_incomplete++;
        }
        values.clients.forEach(
          (student) => student?.groups?.length === 0 && count_incomplete++
        );
        return count_incomplete === 0;

      default:
        break;
    }
  }

  function deleteGroup(index, groups, deletedGroup, setterFunction, values, i) {
    if (groups[index].group_id !== undefined) {
      deletedGroup.push(groups[index].group_id);
      setterFunction("deletedGroup", deletedGroup);
    }
    groups.splice(index, 1);
    setterFunction("groups", groups);

    calculateTotalPrice(values.category_groups, values, setterFunction, i, groups);
  }

  function deleteStudent(index, clients, deletedClients, setterFunction) {
    if (clients[index].id !== undefined) {
      deletedClients.push(clients[index].id);
      setterFunction("deletedClients", deletedClients);
    }
    clients.splice(index, 1);
    setterFunction("clients", clients);
  }
  const [categoryId, setCategoryId] = useState(0);

  const handleChangeSelectCategory = (category, client_index, setterFunction) => {
    setCategoryId(category.category_id);
    setterFunction(`clients["${client_index}"]["category_groups"]`, category);
  };

  const handleChangeSelectGroup = (e, lesson, index, values, setterFunction) => {
    if (lesson) {
      let new_lesson = [
        ...values.clients[index].groups,
        {
          group_id: lesson.group_id,
          name: lesson.group_category_name,
          additional_classes: 0,
          day: lesson.day,
          endTime: lesson.end_time,
          starTime: lesson.start_time,
          group_schedule_id: lesson.group_schedule_id,
          group_price_id: lesson.client_group_price.id,
          display: lesson.display,
        },
      ];
      const filteredArr = new_lesson.reduce((acc, current) => {
        acc.map((item) =>
          item.day === current.day
            ? notice("No se puede agregar más de una clase el mismo día", "warning")
            : ""
        );
        const x = acc.find((item) => item.day === current.day);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      // GRUPO POR CADA ESTUDIANTE
      setterFunction(`clients["${index}"]["groups"]`, filteredArr);
      calculateTotalPrice(
        values.clients[index].category_groups,
        values.clients[index],
        setterFunction,
        index,
        filteredArr
      );
    }
  };
  // ;
  //   const [days, setDays] = useState([]);
  //   const [timeStart, setTimeStart] = useState(0)
  const [filterGroups, setFilterGroups] = useState([]);

  // const [discount, setDiscount] = useState(0);

  const handleDescount = (e, index, setterFunction, values, descountName) => {
    const { name, value } = e;
    setterFunction(name, value);
    calculateTotalPriceByDescount(
      values.clients[index].category_groups, //id
      descountName, //desc_cortesia o desc_tercera_edad
      value,
      setterFunction,
      index,
      values.clients[index].groups
    );
  };

  const daysOfTheWeek = [
    { value: "Lunes", name: "Lunes" },
    { value: "Martes", name: "Martes" },
    { value: "Miercoles", name: "Miércoles" },
    { value: "Jueves", name: "Jueves" },
    { value: "Viernes", name: "Viernes" },
    { value: "Sabado", name: "Sábado" },
  ];

  //DÍA
  const handleDay = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(name, value);
  };
  //HORA
  const handleTimeStart = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(name, value);
  };
  //Instructor
  const handleInstructor = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(name, value);
  };
  //level
  const handleLevel = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(name, value);
  };
  //HORARIO
  const handleSchedule = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(name, value);
  };
  //HORAS POR CATGORIA Y DIA
  const handleGetHour = async (filterParams, index, setterFunction) => {
    setLoader(!loader);
    let days = filterParams.days_groups;
    // let timeStart = filterParams.hour_groups;
    let category_id = categoryId;
    if (days && category_id) {
      try {
        clientsCRUD.getScheduleByDay(days, category_id).then((res) => {
          setLoader(false);
          if (res.Error) {
            setterFunction(`clients["${index}"]["list_hour"]`, []);
          } else if (res.Success) {
            if (!res.Success.length) {
              setterFunction(`clients["${index}"]["list_hour"]`, []);
            } else {
              setterFunction(`clients["${index}"]["list_hour"]`, res.Success[0].data);
            }
            setFilterGroups(res.result);
          } else {
            setterFunction(`clients["${index}"]["list_hour"]`, []);
          }
        });
      } catch (e) {
        setLoader(false);
        setterFunction(`clients["${index}"]["list_hour"]`, []);
      }
    }
  };
  const handleLevels = (filterParams, index, setterFunction) => {
    // values.clients[i]=filterParams\
    setLoader(!loader);

    let hour = filterParams.hour_groups;
    let days = filterParams.days_groups;
    let category_id = categoryId;
    // let instructor_id = filterParams.instrutor_selected.id;
    if (hour && days && category_id) {
      try {
        clientsCRUD.getAllLevels(days, category_id, hour).then((res) => {
          setLoader(false);

          if (res.Error) {
            setterFunction(`clients["${index}"]["list_levels"]`, []);
          } else if (res.Success) {
            if (!res.Success.length) {
              setterFunction(`clients["${index}"]["list_levels"]`, []);
            } else {
              setterFunction(
                `clients["${index}"]["list_levels"]`,
                res.Success[0].data.levels
              );
            }
            setFilterGroups(res.Success);
          } else {
            setterFunction(`clients["${index}"]["list_levels"]`, []);
          }
        });
      } catch (e) {
        setterFunction(`clients["${index}"]["list_levels"]`, []);
      }
    }
  };
  //GRUPOS POR HORA Y DIA
  const handleInstructors = (filterParams, index, setterFunction) => {
    setLoader(!loader);
    let hour = filterParams.hour_groups;
    let days = filterParams.days_groups;
    let category_id = categoryId;
    let level_id = filterParams.level_selected;
    if (days && category_id && hour && level_id.length > 0) {
      try {
        clientsCRUD
          .getAllInstructorsByLevel(days, category_id, hour, level_id)
          .then((res) => {
            setLoader(false);

            if (res.Error) {
              setterFunction(`clients["${index}"]["list_instructors"]`, []);
              setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
            } else if (res.Success) {
              if (!res.Success.length) {
                setterFunction(`clients["${index}"]["list_instructors"]`, []);
                setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
              } else {
                setterFunction(
                  `clients["${index}"]["list_instructors"]`,
                  res.Success[0].data.instructores
                );
                setterFunction(
                  `clients["${index}"]["list_schedule_groups"]`,
                  res.Success[0].data.data
                );
              }
            } else {
              setLoader(false);

              setterFunction(`clients["${index}"]["list_instructors"]`, []);
              setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
            }
          });
      } catch (e) {
        setterFunction(`clients["${index}"]["list_instructors"]`, []);
        setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
      }
    }
  };

  const handleGroups = (filterParams, index, setterFunction) => {
    // values.clients[i]=filterParams
    setLoader(!loader);

    let timeStart = filterParams.hour_groups;
    let days = filterParams.days_groups;
    let category_id = categoryId;
    let instructor_id = filterParams.instrutor_selected.id;
    let level_id = filterParams.level_selected;

    if (timeStart && days && category_id && instructor_id && level_id) {
      try {
        clientsCRUD
          .getAllGroupsByInstructorAndLevel(
            category_id,
            days,
            timeStart,
            instructor_id,
            level_id
          )
          .then((res) => {
            setLoader(false);

            if (res.Error) {
              setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
            } else if (res.Success) {
              if (!res.Success.length) {
                setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
              } else {
                // let sortByQuota = res.Success[0].data.sort((a, b) => {
                //   return b.cupo - a.cupo;
                // });
                setterFunction(
                  `clients["${index}"]["list_schedule_groups"]`,
                  res.Success[0].data.data
                );
              }
              setFilterGroups(res.Success);
            } else {
              setLoader(false);

              setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
            }
          });
      } catch (e) {
        setterFunction(`clients["${index}"]["list_schedule_groups"]`, []);
      }
    }
  };
  const handleScheduleLessons = (filterParams, index, setterFunction) => {
    let group = filterParams.schedule_group.group_id;
    let schedule = filterParams.schedule_group.group_shedule_id;

    if (group && schedule) {
      try {
        clientsCRUD.getScheduleByLesson(group, schedule).then((res) => {
          if (res.Error) {
            setterFunction(`clients["${index}"]["list_schedule_lesson"]`, []);
          } else if (res.Success) {
            if (!res.Success.length) {
              setterFunction(`clients["${index}"]["list_schedule_lesson"]`, []);
            } else {
              setterFunction(
                `clients["${index}"]["list_schedule_lesson"]`,
                res.Success[0].data
              );
            }
          } else {
            setterFunction(`clients["${index}"]["list_schedule_lesson"]`, []);
          }
        });
      } catch (e) {
        setterFunction(`clients["${index}"]["list_levels"]`, []);
      }
    }
  };
  const handleChangeDate = (inscriptionDate, value, i, date, setterFunction) => {
    if (date !== value) {
      setterFunction(`clients["${i}"]['inscriptionDate']`, value);
    }
  };

  const handleDate = (date, i, setterFunction) => {
    if (date) {
      setterFunction(`clients["${i}"]['inscriptionDate']`, date);
    }
  };

  const cancel = () => {
    setDialog("closed");
    setTabValue(0);
    // window.location.reload(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => {
        setDialog("closed");
        setTabValue(0);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <Stepper activeStep={tabValue} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={i}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Formik
          initialValues={{
            responsible: {
              name: client.responsible.name,
              last_name: client.responsible.last_name,
              last_name_2: client.responsible.last_name_2,
              phone: client.responsible.phone,
              email: client.responsible.email,
              membership_number: client.responsible.membership_number,
              isStudent: client.responsible.isStudent,
              street_name: client.responsible.street_name,
              number: client.responsible.number,
              suburb: client.responsible.suburb,
              district: client.responsible.district,
              state: client.responsible.state,
              country: client.responsible.country,
              accept: client.responsible.accept,
              postal_code: client.responsible.postal_code,
            },
            clients: client.clients,
            main_contact: {
              email: client.main_contact.email,
              cellphone: client.main_contact.cellphone,
              landline: client.main_contact.landline,
            },
            invoice_information: {
              invoice_person_type: client.invoice_information.invoice_person_type,
              name: client.invoice_information.name,
              street: client.invoice_information.street,
              interior_number: client.invoice_information.interior_number,
              outdoor_number: client.invoice_information.outdoor_number,
              suburb: client.invoice_information.suburb,
              municipality: client.invoice_information.municipality,
              country: client.invoice_information.country,
              state: client.invoice_information.state,
              postal_code: client.invoice_information.postal_code,
              email: client.invoice_information.email,
              rfc: client.invoice_information.rfc,
              locality: client.invoice_information.locality,
              regimen_fiscal: client.invoice_information.regimen_fiscal,
              cfdi_use: client.invoice_information.cfdi_use,
            },
            share_contact: true,
            billing_information: false,
            find_out_id: "",
            deletedClients: [],
            deletedGroup: [],
          }}
          validate={(values) => {
            let errors = {
              responsible: {
                name: "",
                last_name: "",
                phone: "",
                email: "",
                membership_number: "",
              },
            };
            if (values.responsible.name === "" || !values.responsible.name)
              errors.responsible.name = "Campo requerido";

            if (values.responsible.last_name === "")
              errors.responsible.last_name = "Campo requerido";

            if (values.responsible.phone === "")
              errors.responsible.phone = "Campo requerido";

            if (values.responsible.phone.length !== 10)
              errors.responsible.phone = "Campo invalido";

            if (
              values.responsible.membership_number === "" ||
              !values.responsible.membership_number
            )
              errors.responsible.membership_number = "Campo requerido";

            if (!values.responsible.client_type)
              errors.responsible.client_type = "Campo requerido";

            if (values.responsible.email === "" || !values.responsible.email)
              errors.responsible.email = "Campo requerido";
            if (validateEmail) {
              errors.responsible.email = "Correo ya existe";
            }
            if (
              values.responsible.email !== "" &&
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                values.responsible.email
              ) === false
            )
              errors.responsible.email = "Correo inválido";
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
          }) => (
            <div className="kt-form">
              <div className="form-group">
                {renderInputs(
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue
                )}
              </div>
              <div className="btn-dialog">
                <Button onClick={() => cancel(values, setFieldValue)} color="primary">
                  Cancelar
                </Button>
                {tabValue === 2 ? (
                  <>
                    <Button
                      style={{
                        marginRight: 10,
                      }}
                      onClick={() => setTabValue(tabValue - 1)}
                      color="primary"
                      variant="contained"
                    >
                      Atrás
                    </Button>
                    <button
                      id="kt_login_signin_submit"
                      type="button"
                      disabled={loading || !verifyNext(values, errors)}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                        }
                      )}`}
                      style={loadingButtonStyle}
                      onClick={() => createClient(values)}
                    >
                      {button_txt}
                    </button>
                  </>
                ) : (
                  <>
                    {tabValue === 0 ? (
                      ""
                    ) : (
                      <Button
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          marginRight: 10,
                        }}
                        onClick={() => setTabValue(tabValue - 1)}
                        color="primary"
                        variant="contained"
                      >
                        Atrás
                      </Button>
                    )}
                    <Button
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      onClick={() =>
                        verifyNext(values, errors) && setTabValue(tabValue + 1)
                      }
                      color="primary"
                      variant="contained"
                    >
                      Siguiente
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (store) => ({
  clients: store.clients.clients,
  client: store.clients.client,
});

export default connect(mapStateToProps, clients.actions)(ClientDialog);
