import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import FullTable from "../../../../../common/components/FullTable";
import { columnInstructorEdit, defaultFilter } from "../../utils";
import { useDispatch } from "react-redux";
import {
  getInstructorsListByReport,
  getInstructorReportData,
} from "../../../../../crud/instructors.crud";
import { FormReportEdit } from "../formReport/FormReportEdit";
import notice from "../../../../../utils/notice";
import { setLoader } from "../../../../../store/ducks/loader.duck";

const ReportDialogEdit = ({ setDialog, idReport, locationsInput }) => {
  const dispatch = useDispatch();
  const [seletcId, setSelectId] = useState(0);
  const [filter, setFilter] = useState(defaultFilter);
  const [dataInstructor, setDataInstructor] = useState({});
  const [allInstructors, setAllInstructors] = useState([]);

  useEffect(() => {
    if (idReport) {
      dispatch(setLoader(true));
      getInstructorsListByReport(idReport)
        .then((res) => {
          setAllInstructors(res);
        })
        .catch(() => notice("No se pudo cargar la lista de instructores", "warning"))
        .finally(dispatch(setLoader(false)));
    }
  }, [idReport]);

  const handleSelectId = (id) => {
    setSelectId(id);
    allInstructors.map((instructor) => {
      if (instructor.instructor_id === id) {
        setDataInstructor(instructor);
      }
    });
  };
  // const handleChange = ({ target }) => {
  //   const { name, value } = target;
  //   setFilter({ ...filter, [name]: value });
  // };
  // const handleFilter = () => {
  //   let location_id;
  //   locationsInput.map((location) => {
  //     if (location.text === filter.client_location) {
  //       location_id = location.id;
  //     }
  //   });
  //   if (location_id) {
  //     getInstructorsReportFilter(location_id).then((res) => setAllInstructors(res));
  //   }
  // };

  //-------------------------------------

  return (
    <Dialog
      open={() => setDialog("editing")}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Editar</DialogTitle>

      <DialogContent>
        {/* {!date && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <UtilInputs
              formValues={filter}
              onValuesChange={handleChange}
              inputValues={filtersForm(locationsInput)}
              className="mb-3"
            />
            <CustomButton
              title="Filtrar"
              onClick={handleFilter}
              style={{ marginRight: 3, width: 100, height: 40 }}
            />
          </div>
        )} */}

        <FullTable
          title={"Instructores"}
          data={allInstructors}
          columns={columnInstructorEdit(handleSelectId)}
        />
        <Grid
          container
          style={{
            marginTop: 40,
            padding: 10,
          }}
        >
          <FormReportEdit
            setDialog={setDialog}
            seletcId={seletcId}
            dataInstructor={dataInstructor}
            // idReport={idReport}
            setDataInstructor={setDataInstructor}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialogEdit;
