import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Grid, Button } from "@material-ui/core";
import CustomButton from "../../../../../common/components/CustomButton";
import FullTable from "../../../../../common/components/FullTable";
import {
  columnInstructorNew,
  filtersForm,
  defaultReport,
  filtersReport,
} from "../../utils";
import UtilInputs from "../../../../../common/components/UtilInputs";
import { useDispatch } from "react-redux";
import {
  getInstructorsListByReport,
  getInstructorsReportFilter,
  getInstructorReportData,
  getReportPDF,
} from "../../../../../crud/instructors.crud";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import { FormReportNew } from "../formReport/FormReportNew";
import notice from "../../../../../utils/notice";
import moment from "moment";

const ReportDialogNew = ({ setDialog, locationsInput, setNewReport }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState(defaultReport);
  const [dataInstructor, setDataInstructor] = useState({});
  const [allInstructors, setAllInstructors] = useState([]);

  useEffect(() => {
    dispatch(setLoader(true));
    let location_id;
    getInstructorsReportFilter(location_id)
      .then((res) => {
        let dataInstructor = res.data_instructor.concat(res.data_instructor_guardado);
        setAllInstructors(dataInstructor);
      })
      .finally(dispatch(setLoader(false)));
  }, []);

  useEffect(() => {
    dispatch(setLoader(true));
    let location_id;
    locationsInput.map((location) => {
      if (location.text === filter.client_location) {
        location_id = location.id;
      }
    });
    getInstructorsReportFilter(location_id)
      .then((res) => {
        let dataInstructor = res.data_instructor.concat(res.data_instructor_guardado);
        setAllInstructors(dataInstructor);
      })
      .finally(() => {
        setStatus("");
        dispatch(setLoader(false));
      });
  }, [status]);

  const handleSelectId = (id) => {
    allInstructors.map((instructor) => {
      if (instructor.instructor_id === id) {
        setDataInstructor(instructor);
      }
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFilter({ ...filter, [name]: value });
  };

  const handleFilter = () => {
    dispatch(setLoader(true));
    let location_id;
    locationsInput.map((location) => {
      if (location.text === filter.client_location) {
        location_id = location.id;
      }
    });
    getInstructorsReportFilter(location_id)
      .then((res) => {
        let dataInstructor = res.data_instructor.concat(res.data_instructor_guardado);
        setAllInstructors(dataInstructor);
      })
      .catch(() => notice("Ocurrio un error", "error"))
      .finally(dispatch(setLoader(false)));
  };

  function download(blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Reporte";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const getReport = () => {
    dispatch(setLoader(true));

    let location_id;
    locationsInput.map((location) => {
      if (location.text === filter.client_location) {
        location_id = location.id;
      }
    });
    let dateStart = moment(filter.start_date).format("YYYY-MM-DD"); //fecha innicio
    let dateEnd = moment(filter.end_date).format("YYYY-MM-DD");
    if (location_id && dateStart !== "Fecha inválida" && dateEnd !== "Fecha inválida") {
      getReportPDF(location_id, dateStart, dateEnd)
        .then((res) => {
          if (res.status === 200) {
            res.blob().then((blob) => download(blob));
          } else if (res.status === 500) {
            notice("Ocurrio un error", "error");
          }
        })
        .catch((err) => notice("Ocurrio un error", "error"))
        .finally(() => {
          setNewReport("new");
          dispatch(setLoader(false));
        });
    } else {
      notice("Faltan datos", "warning");
    }
  };

  return (
    <Dialog
      open={() => setDialog("creating")}
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setDialog("closed")}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Nuevo reporte</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: 10,
          }}
        >
          <UtilInputs
            formValues={filter}
            onValuesChange={handleChange}
            inputValues={filtersReport(locationsInput)}
            className="mb-3"
          />
          <CustomButton
            title="Filtrar"
            onClick={handleFilter}
            style={{ marginRight: 3, width: 100, height: 40 }}
          />
          <CustomButton title="Generar reporte" onClick={getReport} />
        </div>
        <FullTable
          title={"Instructores"}
          data={allInstructors}
          columns={columnInstructorNew(handleSelectId)}
        />
        <Grid
          container
          style={{
            marginTop: 40,
            padding: 10,
          }}
        >
          <FormReportNew
            setDialog={setDialog}
            // seletcId={seletcId}
            dataInstructor={dataInstructor}
            // setSelectedInstructors={setSelectedInstructors}
            // selectedInstructors={selectedInstructors}
            setDataInstructor={setDataInstructor}
            setStatus={setStatus}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialogNew;
