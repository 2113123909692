import { currencyFormat } from "../../../utils/data_formatters";

export const columns = (openDialog) => [
  {
    name: "status",
    label: "Estatus",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data) =>
        !data ? "" : data === "approved" ? "Aprovada" : "Rechazada",
    },
  },
  {
    name: "concepto",
    label: "Concepto",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "monto",
    label: "Monto",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (data) => currencyFormat.format(data),
    },
  },
  // {
  //   name: "id",
  //   label: "Realizar pago",
  //   options: {
  //     filter: false,
  //     sort: false,
  //     setCellHeaderProps: value => ({className: 'text-center'}),
  //     setCellProps: value => ({className: 'text-center'}),
  //     customBodyRender: (data, dataIndex, rowIndex) => ActionsColumn(data, openDialog)
  //   },
  // },
];

export const defaultPayment = {
  amount: "",
  client: "",
  product: "",
  // campos requeridos en Punto de venta
  store_name: "aquara",
  client_id: "",
  location_id: "",
  payment_method: "",
  customer: "",
  additional_discount_type: "",
  additional_discount: "",
  additional_discount_amount: "",
  amount_with_discount: "",
  // meses del cliente
  payment_groups: [],
  // otros adeudos como insripción o anualidad
  other_fees: [],
  // productos de tienda
  products: [],
};

export const paymentForm = (
  packages = [],
  paymentTypes = [],
  paymentType = false,
  periods = []
) => [
  {
    name: "payment_type",
    label: "Tipo de pago",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: paymentTypes,
  },
  {
    name: "package",
    label: "Paquete",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: packages,
    disabled: paymentType !== "monthly",
  },
  {
    name: "period",
    label: "Fechas",
    type: "dropdown",
    variant: "outlined",
    width: "50%",
    options: periods,
    disabled: paymentType !== "monthly",
  },
  {
    name: "amount",
    label: "Monto",
    type: "number",
    width: "50%",
    variant: "outlined",
    isPrice: true,
    decimals: 2,
    disabled: true,
  },
  {
    name: "note",
    label: "Notas adicionales",
    type: "text",
    variant: "outlined",
    multiline: true,
    rows: 4,
    width: "100%",
  },
];
