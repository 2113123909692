import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import moment from "moment";
import BaseModal from "../../../../common/components/BaseModal";
import SimpleSwitch from "../../../../common/components/SimpleSwitch";
import AntTabs from "../../../../common/components/AntTabs";
import InfoMeetTable from "./InfoMeetTable";
import InfoMeetSchedule from "./InfoMeetSchedule";
import { setLoader } from "../../../../store/ducks/loader.duck";
import * as configCRUD from "../../../../crud/config.crud";
import notice from "../../../../utils/notice";

const InfoMeetDialog = ({ open, setOpen, group, students, fetchEvents }) => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [inactiveGroup, setInactiveGroup] = useState(false);

  const inactive_group = (id) => {
    dispatch(setLoader(true));
    configCRUD
      .desactiveGroup(id)
      .then(() => {
        notice("Clase desactivada con exito", "success");
        close();
      })
      .catch((e) =>
        notice(
          Array.isArray(e.errors)
            ? e.errors.map((err) => err.title).join("\n")
            : "Error con su solicitud"
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const close = () => {
    fetchEvents();
    setInactiveGroup(false);
    setOpen("closed");
  };
  return (
    <>
      <BaseModal
        open={open}
        onCloseButton={false}
        onClose={() => {
          setInactiveGroup(false);
          setOpen("closed");
        }}
        maxWidth="md"
        hidden={inactiveGroup}
        hideCloseButton
      >
        <div className="d-flex justify-content-between">
          {group && (
            <div>
              <h5>Información de grupo: {group.name}</h5>
              <p>
                Fecha de inicio: {moment(group.start_date).format("DD/MM/YY")} - Fecha de
                fin: {moment(group.end_date).format("DD/MM/YY")}
              </p>
              <p>
                Grupo de enseñanza: {group.category} - Nivel: {group.levels}
              </p>
              <p>Instructor: {group?.instructor}</p>
            </div>
          )}
          {group && (
            <div>
              <SimpleSwitch
                label="Activo"
                value={group.active}
                onChange={({ target }) => setInactiveGroup(true)}
              />
            </div>
          )}
        </div>
        <Divider />
        <AntTabs
          value={tabValue}
          onChange={setTabValue}
          className="mb-3"
          options={[
            { value: 0, label: "Lista de alumnos" },
            { value: 1, label: "Horario y alumnos" },
          ]}
        />
        {tabValue === 0 && group && <InfoMeetTable group={group} />}
        {tabValue === 1 && group && (
          <InfoMeetSchedule close={close} group={group} students={students} />
        )}
      </BaseModal>
      <BaseModal
        open={inactiveGroup}
        confirmColor="secondary"
        onCancel={() => setInactiveGroup(false)}
        onConfirm={() => inactive_group(group.id)}
        title="Esta seguro que desea desactivar este grupo"
      />
    </>
  );
};

export default InfoMeetDialog;
