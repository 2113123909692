import store from "../store/store";

export function permit(permissionsToValidate) {
  const permissions = store.getState().auth.user.user.permissions;

  let permits = [];
  for (let i = 0; i < permissionsToValidate.length; i++) {
    permits.push(permissions.includes(permissionsToValidate[i]));
  }

  return permits;
}

export function menuPermissions() {
  const [
    configRead,
    suggestionsRead,
    usersRead,
    reportsRead,
    instructorsRead,
    responsiblesRead,
    salesPointRead,
    productsRead,
    calendarRead,
    assistannceRead,
    studentsRead,
    helpRead,
    incomesRead,
    cutsRead,
    minboxRead,
    dischargesRead,
  ] = permit([
    "config:read",
    "suggestions:read",
    "users:read",
    "reports:read",
    "instructors:read",
    "responsibles:read",
    "sale_point:read",
    "products:read",
    "calendar:read",
    "assistance:read",
    "students:read",
    "help:read",
    "incomes:read",
    "cuts:read",
    "minbox:read",
    "discharge:read",
  ]);
  return {
    configRead: configRead,
    suggestionsRead: suggestionsRead,
    usersRead: usersRead,
    reportsRead: reportsRead,
    instructorsRead: instructorsRead,
    responsiblesRead: responsiblesRead,
    salesPointRead: salesPointRead,
    productsRead: productsRead,
    calendarRead: calendarRead,
    assistannceRead,
    studentsRead,
    helpRead,
    incomesRead,
    cutsRead,
    minboxRead,
    dischargesRead,
  };
}

export function configPermissions() {
  const [
    rolesRead,
    rolesUpdate,
    rolesCreate,
    locationsRead,
    locationsUpdate,
    locationsCreate,
    suggestionsRead,
    incomesRead,
    suggestionsTypes,
    suggestionsStatuses,
    suggestionsSources,
    areasRead,
    areasUpdate,
    areasCreate,
    categoriesRead,
    categoriesUpdate,
    categoriesCreate,
    programsRead,
    programsUpdate,
    programsCreate,
    levelsRead,
    levelsUpdate,
    levelsCreate,
    poolsRead,
    poolsUpdate,
    poolsCreate,
    poolsTypes,
    storeCategoriesRead,
    storeCategoriesUpdate,
    storeCategoriesCreate,
    paymentMethodsRead,
    paymentMethodsUpdate,
    paymentMethodsCreate,
  ] = permit([
    "config:roles:read",
    "config:roles:update",
    "config:roles:create",
    "config:locations:read",
    "config:incomes:read",
    "config:locations:update",
    "config:locations:create",
    "config:suggestions:read",
    "config:suggestions:types",
    "config:suggestions:statuses",
    "config:suggestions:sources",
    "config:areas:read",
    "config:areas:update",
    "config:areas:create",
    "config:categories:read",
    "config:categories:update",
    "config:categories:create",
    "config:programs:read",
    "config:programs:update",
    "config:programs:create",
    "config:levels:read",
    "config:levels:update",
    "config:levels:create",
    "config:pools:read",
    "config:pools:update",
    "config:pools:create",
    "config:pools:types",
    "config:store_categories:read",
    "config:store_categories:update",
    "config:store_categories:create",
    "config:payment_methods:read",
    "config:payment_methods:update",
    "config:payment_methods:create",
  ]);
  return {
    rolesRead: rolesRead,
    rolesUpdate: rolesUpdate,
    rolesCreate: rolesCreate,
    locationsRead: locationsRead,
    locationsUpdate: locationsUpdate,
    locationsCreate: locationsCreate,
    suggestionsRead: suggestionsRead,
    incomesRead: incomesRead,
    suggestionsTypes: suggestionsTypes,
    suggestionsStatuses: suggestionsStatuses,
    suggestionsSources: suggestionsSources,
    areasRead: areasRead,
    areasUpdate: areasUpdate,
    areasCreate: areasCreate,
    categoriesRead: categoriesRead,
    categoriesUpdate: categoriesUpdate,
    categoriesCreate: categoriesCreate,
    programsRead: programsRead,
    programsUpdate: programsUpdate,
    programsCreate: programsCreate,
    levelsRead: levelsRead,
    levelsUpdate: levelsUpdate,
    levelsCreate: levelsCreate,
    poolsRead: poolsRead,
    poolsUpdate: poolsUpdate,
    poolsCreate: poolsCreate,
    poolsTypes: poolsTypes,
    storeCategoriesRead: storeCategoriesRead,
    storeCategoriesUpdate: storeCategoriesUpdate,
    storeCategoriesCreate: storeCategoriesCreate,
    paymentMethodsRead: paymentMethodsRead,
    paymentMethodsUpdate: paymentMethodsUpdate,
    paymentMethodsCreate: paymentMethodsCreate,
  };
}

export function suggestionPermissions() {
  const [suggestionsUpdate, suggestionsCreate] = permit([
    "suggestions:update",
    "suggestions:create",
  ]);
  return {
    suggestionsUpdate: suggestionsUpdate,
    suggestionsCreate: suggestionsCreate,
  };
}

export function userPermissions() {
  const [usersCreate, usersUpdate] = permit(["users:create", "users:update"]);
  return {
    usersCreate: usersCreate,
    usersUpdate: usersUpdate,
  };
}

export function maintPermissions() {
  const [reportsCreate, reportsUpdate] = permit(["reports:create", "reports:update"]);
  return {
    reportsCreate: reportsCreate,
    reportsUpdate: reportsUpdate,
  };
}

export function instructorPermissions() {
  const [instructorsCreate, instructorsUpdate] = permit([
    "instructors:create",
    "instructors:update",
  ]);
  return {
    instructorsCreate: instructorsCreate,
    instructorsUpdate: instructorsUpdate,
  };
}

export function responsiblePermissions() {
  const [responsiblesCreate, responsiblesUpdate] = permit([
    "responsibles:create",
    "responsibles:update",
  ]);

  return {
    responsiblesCreate: responsiblesCreate,
    responsiblesUpdate: responsiblesUpdate,
  };
}

export function salesPointPermissions() {
  const [salesPointCreate] = permit(["sale_point:create"]);

  return {
    salesPointCreate: salesPointCreate,
  };
}

export function productPermissions() {
  const [productsCreate, productsUpdate] = permit(["products:create", "products:update"]);

  return {
    productsCreate: productsCreate,
    productsUpdate: productsUpdate,
  };
}

export function classDeactivate() {
  const [deactivateClass] = permit(["class:desactivate"]);

  return {
    deactivateClass: deactivateClass,
  };
}
