import React from "react";
import FullTable from "../../../../common/components/FullTable";
import { currencyFormat } from "../../../../utils/data_formatters";
import { formatDate } from "../../../../utils/dates";

const AccountStatusTable = ({ account }) => {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "folder_mercado_pago",
      label: "Folio",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "date",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) =>
          data ? formatDate("PP", new Date(data)) : "-",
      },
    },
    {
      name: "type",
      label: "Tipo de pago",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "product",
      label: "Producto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "client",
      label: "Alumno",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Monto",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) => currencyFormat.format(data),
      },
    },
    {
      name: "payment_type",
      label: "Metodo de pago",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) =>
          !data ? "" : data === "card" ? "Tarjeta" : "Efectivo",
      },
    },
    {
      name: "status",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) =>
          data ? (
            <span
              style={{ backgroundColor: "#baecc2" }}
              className="badge badge-pill text-success"
            >
              Pagado
            </span>
          ) : (
            <span
              style={{ backgroundColor: "#f0cbcb" }}
              className="badge badge-pill text-danger"
            >
              No pagado
            </span>
          ),
      },
    },
  ];

  return (
    <>
      <FullTable title={"Estado de cuenta"} data={account} columns={columns} />
    </>
  );
};

export default AccountStatusTable;
