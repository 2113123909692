import moment from "moment";
import { StatusColumn } from "../components/StatusColumn"

export const lessons_columns = [
  {
    name: "group",
    label: "Grupo",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.age",
    label: "Edad",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.level",
    label: "Nivel",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "client.category",
    label: "Categoria",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "instructor",
    label: "Instructor",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "group_schedule",
    label: "Horario",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "registered_at",
    label: "Fecha de registro",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (data) => `${moment(data).format('L LT')}`
    }
  },
  {
    name: "status",
    label: "Asistencia",
    options: {
      filter: true,
      sort: true,
      customBodyRender: StatusColumn
    }
  },
];