export const BASE_URL = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/api/v1/`
  : "https://aquara-api.herokuapp.com/api/v1/";
export const BASIC_URL =
  process.env.REACT_APP_API_URL || "https://aquara-api.herokuapp.com";
export const CORTES_URL = BASE_URL + "cortes";
export const DISCHARGES_URL = BASE_URL + "discharges";

export const EXPECTATION_URL = BASE_URL + "catalogo_expectativas";

export const ROLES_URL = BASE_URL + "roles";
export const PERMISSIONS_URL = BASE_URL + "permissions";
export const LOGIN_URL = BASE_URL + "login";
export const ME_URL = BASE_URL + "me";
export const LOCATIONS_URL = BASE_URL + "locations";
export const SUGGESTIONS_TYPES_URL = BASE_URL + "suggestion_types";
export const SUGGESTIONS_STATUSES_URL = BASE_URL + "suggestion_statuses";
export const SUGGESTIONS_SOURCES_URL = BASE_URL + "suggestion_sources";
export const AREAS_URL = BASE_URL + "areas";
export const SUGGESTIONS_URL = BASE_URL + "suggestions";
export const RESET_PASSWORD_URL = BASE_URL + "password";
export const HELP_URL = BASE_URL + "help";
export const CATEGORIES_URL = BASE_URL + "categories";
export const PROGRAMS_URL = BASE_URL + "programs";
export const LEVELS_URL = BASE_URL + "levels";
export const USERS_URL = BASE_URL + "users";
export const LEVEL_REQUIREMENTS_URL = BASE_URL + "level_requirements";
export const POOLS_URL = BASE_URL + "pools";
export const POOL_TYPES_URL = BASE_URL + "pool_types";
export const DAILY_REPORTS_URL = BASE_URL + "daily_reports";
export const BIWEEKLY_REPORTS_URL = BASE_URL + "biweekly_reports";
export const CLIENTS_URL = BASE_URL + "clients";
export const CLIENTS_WPAG_URL = BASE_URL + "clients/without-pagination";
export const GROUPS_URL = BASE_URL + "groups";
export const STUDENTS_URL = BASE_URL + "students";
export const INSTRUCTOR_URL = BASE_URL + "instructors";
export const RESPONSIBLE_URL = BASE_URL + "responsibles";
export const RESPONSIBLE_LITE_URL = BASE_URL + "responsibles/lite";
export const PRODUCTS_URL = BASE_URL + "products";
export const STORE_CATEGORY_URL = BASE_URL + "store_categories";
export const GROUP_PRICE_URL = BASE_URL + "group_prices";
export const GROUP_SCHEDULE = BASE_URL + "group_schedule_students";
export const CLIENTS_ATTENDANCE = BASE_URL + "clients_attendances";
export const DEBTORS_URL = CLIENTS_URL + "/debtors";
export const PAYMENTS_URL = BASE_URL + "payments";
export const MINOR_URL = BASE_URL;

export const FINDOUTS_URL = BASE_URL + "find_outs";
export const MP_CONCILIATION_URL = BASE_URL + "conciliacion-mercado-pago";
export const INSTRUCTORS_URL = BASE_URL + "instructors";
export const INSTRUCTORS_REPORT_URL = BASE_URL + "report_instructors";

export const VARIANTS_URL = BASE_URL + "variants";
