import React from "react";
import { Cancel } from "@material-ui/icons";
import BaseModal from "../../../../common/components/BaseModal";
import CustomButton from "../../../../common/components/CustomButton";

const PaymentDeclainedProduct = ({ open, setOpen }) => {
  return (
    <BaseModal open={open} onClose={() => setOpen(false)} hideCloseButton>
      <div className="text-center">
        <h2>¡Pago rechazado!</h2>
        <Cancel style={{ fontSize: "xxx-large" }} className="text-danger" />
        <h4 className="text-muted">
          El proveedor de pagos ha rechazado tu pago
          <br />
          Razon: Debes indicar al menos un articulo para procesar esta compra
        </h4>
        <CustomButton title="Regresar" onClick={() => setOpen(false)} />
      </div>
    </BaseModal>
  );
};

export default PaymentDeclainedProduct;
