import { currencyFormat } from "../../../utils/data_formatters";
import moment from "moment";

import InvoiceColumn from "./components/InvoiceColumn";

export const IncomeColumns = (
  setDownloadInvoice,
  setInvoice,
  loadInvoice,
  handleInvoicePDF,
  params,
  permissions
) => [
  {
    name: "id_payment",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "id_responsible",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "id_quickbooks",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "full_name_responsible",
    label: "Responsable",
    options: {
      filter: false,
      sort: false,
      // sortDirection: "asc",
    },
  },
  {
    name: "client",
    label: "Alumno",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "type_client",
    label: "Tipo de cliente",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "client_location",
    label: "Ubicación",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Tipo de pago",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "product",
    label: "Producto",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "amount",
    label: "Monto",
    options: {
      filter: false,
      sort: false,
      // customBodyRender: (data) => {
      //   console.log(typeof data);
      //   console.log(data);
      //   return currencyFormat.format(Number(data));
      // },
    },
  },
  {
    name: "payment_type",
    label: "Método de pago",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) =>
        data
          ? data === "card"
            ? "Tarjeta"
            : data === "cash"
            ? "Efectivo"
            : "Transferencia"
          : "",
    },
  },
  {
    name: "id_quickbooks",
    label: "Facturación",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => (data ? "Facturado" : "Sin Factura"),
    },
  },
  {
    name: "invoice_information",
    label: "Facturación",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data, dataIndex) =>
        InvoiceColumn(
          data,
          dataIndex,
          setDownloadInvoice,
          setInvoice,
          loadInvoice,
          handleInvoicePDF,
          params,
          permissions
        ),
    },
  },
];

export const customOptions = {
  filter: false,
  sort: false,
  search: false,
};

export const defaultFilter = {
  start_date: null,
  end_date: null,
  type_client: null,
  client_location: null,
  type_factura: null,
  client_name: null,
};

export const incomeFilters = (locations = []) => [
  // {
  //   name: "month",
  //   label: "Mes",
  //   type: "date",
  //   variant: "filled",
  //   views: ["month"],
  //   format: "MMMM",
  //   mask: false,
  //   placeholder: "Mes",
  // },
  {
    name: "client_name",
    label: "Cliente",
    type: "text",
    variant: "filled",
  },
  {
    name: "start_date",
    label: "Fecha Inicio",
    variant: "filled",
    type: "date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data, dataIndex, rowIndex) => {
        return `${moment(data).format("DD/MM/YY")}`;
      },
    },
  },
  {
    name: "end_date",
    label: "Fecha Fin",
    type: "date",
    variant: "filled",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data, dataIndex, rowIndex) => {
        return `${moment(data).format("DD/MM/YY")}`;
      },
    },
  },
  {
    name: "client_location",
    label: "Ubicación",
    type: "dropdown",
    variant: "filled",
    options: locations,
  },
  {
    name: "type_client",
    label: "Tipo de cliente",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "A", text: "A" },
      { value: "B", text: "B" },
    ],
  },

  {
    name: "type_factura",
    label: "Facturación",
    type: "dropdown",
    variant: "filled",
    options: [
      { value: "todos", text: "Todos" },
      { value: "facturados", text: "Facturados" },
      { value: "pendiente", text: "Pendiente de facturación" },
    ],
  },
];

export const defaultInvoice = {
  invoice_person_type: "",
  name: "",
  email: "",
  rfc: "",
  country: "",
  state: "",
  suburb: "",
  municipality: "",
  street: "",
  postal_code: "",
  outdoor_number: "",
  interior_number: "",
  locality: "",
  regimen_fiscal: "",
  cfdi_use: "",
  // Selected client data
  full_name_responsible: "",
  client: "",
  type_client: "",
  client_location: "",
  type: "",
  product: "",
  amount: "",
  payment_type: "",
};

export const invoiceInputs = (fiscal = [], cdfis = []) => [
  {
    name: "full_name_responsible",
    label: "Responsable",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "client",
    label: "Alumno",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "type_client",
    label: "Tipo de cliente",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "client_location",
    label: "Ubicación",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "type",
    label: "Tipo de pago",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "product",
    label: "Producto",
    type: "text",
    disabled: true,
    variant: "outlined",
  },
  {
    name: "amount",
    label: "Monto",
    type: "number",
    disabled: true,
    variant: "outlined",
    isPrice: true,
    decimals: 2,
  },
  {
    name: "payment_type",
    label: "Método de pago",
    type: "dropdown",
    disabled: true,
    variant: "outlined",
    options: [
      { value: "card", text: "Tarjeta" },
      { value: "cash", text: "Efectivo" },
      { value: "transfer", text: "Transferencia" },
    ],
  },
  {
    title: "Datos de facturación",
    type: "line-break",
  },
  {
    name: "invoice_person_type",
    label: "Tipo de persona",
    type: "dropdown",
    required: true,
    options: [
      { value: "Persona Fisica", text: "Persona Fisica" },
      { value: "Persona Moral", text: "Persona Moral" },
    ],
  },
  {
    name: "name",
    label: "Nombre de Persona",
    type: "text",
    required: true,
  },
  {
    name: "rfc",
    label: "RFC",
    type: "text",
    required: true,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    required: true,
  },
  {
    name: "street",
    label: "Calle",
    type: "text",
    required: true,
  },
  {
    name: "outdoor_number",
    label: "Número exterior",
    type: "text",
    required: true,
  },
  {
    name: "interior_number",
    label: "Número interior",
    type: "text",
  },
  {
    name: "suburb",
    label: "Colonia",
    type: "text",
    required: true,
  },
  {
    name: "postal_code",
    label: "Código postal",
    type: "text",
    required: true,
  },

  {
    name: "state",
    label: "Estado",
    type: "dropdown",
    required: true,
    options: [
      { value: "Aguascalientes", text: "Aguascalientes" },
      { value: "Baja California", text: "Baja California" },
      { value: "Baja California Sur", text: "Baja California Sur" },
      { value: "Campeche", text: "Campeche" },
      { value: "Chiapas", text: "Chiapas" },
      { value: "Coahuila", text: "Coahuila" },
      { value: "Colima", text: "Colima" },
      { value: "Ciudad de México", text: "Ciudad de México" },
      { value: "Durango", text: "Durango" },
      { value: "Guanajuato", text: "Guanajuato" },
      { value: "Guerrero", text: "Guerrero" },
      { value: "Hidalgo", text: "Hidalgo" },
      { value: "Jalisco", text: "Jalisco" },
      { value: "Estado de Mexico", text: "Estado de Mexico" },
      { value: "Michoacan", text: "Michoacan" },
      { value: "Morelos", text: "Morelos" },
      { value: "Nayarit", text: "Nayarit" },
      { value: "Nuevo Leon", text: "Nuevo Leon" },
      { value: "Oaxaca", text: "Oaxaca" },
      { value: "Puebla", text: "Puebla" },
      { value: "Queretaro", text: "Queretaro" },
      { value: "Quintana Roo", text: "Quintana Roo" },
      { value: "San Luis Potosi", text: "San Luis Potosi" },
      { value: "Sinaloa", text: "Sinaloa" },
      { value: "Sonora", text: "Sonora" },
      { value: "Tabasco", text: "Tabasco" },
      { value: "Tamaulipas", text: "Tamaulipas" },
      { value: "Tlaxcala", text: "Tlaxcala" },
      { value: "Veracruz", text: "Veracruz" },
      { value: "Yucatan", text: "Yucatan" },
      { value: "Zacatecas", text: "Zacatecas" },
    ],
  },

  {
    name: "municipality",
    label: "Municipio",
    type: "text",
    required: true,
  },

  {
    name: "locality",
    label: "Localidad",
    type: "text",
    required: true,
  },
  {
    name: "country",
    label: "Pais",
    type: "text",
    required: true,
    // disabled: true,
  },
  {
    name: "regimen_fiscal",
    label: "Régimen Fiscal",
    type: "dropdown",
    required: true,
    options: fiscal,
  },
  {
    name: "cfdi_use",
    label: "Uso de CFDI",
    type: "dropdown",
    required: true,
    options: cdfis,
    // options: [
    //   { value: "G03", text: "GASTOS EN GENERAL", text2: "G03" },
    //   {
    //     value: "D10",
    //     text: "PAGOS POR SERVICIOS EDUCATIVOS (COLEGIATURA)",
    //     text2: "D10",
    //   },
    //   {
    //     value: "D02",
    //     text: "GASTOS MEDICOS POR INCAPACIDAD O DISCAPACIDAD",
    //     text2: "D02",
    //   },
    //   {
    //     value: "D01",
    //     text: "HONORARIOS MEDICOS, DENTALES Y GASTOS HOSPITALARIOS",
    //     text2: "D01",
    //   },
    // ],
  },
];
