import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Button, Grid, FormControl, InputLabel, Select, MenuItem, Tabs, Tab, TextField } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Formik } from 'formik';
import clsx from 'clsx';

import * as maint from "../../../../store/ducks/maint.duck";
import * as maintCRUD from "../../../../crud/maint.crud";

import { MantInfo } from './MantInfo';
import { MantTransparency } from './MantTransparency';
import { MantDissolvedSolids } from './MantDissolvedSolids';
import { MantWaterSupply } from './MantWaterSupply';
import ConfirmDialog from "../../../../utils/ConfirmDialog"
import notice from '../../../../utils/notice';

const MantDailyDialog = ({mode, setDialog, daily_report, updateDailyReports, pools, users, pool_types, deleteDailyReports}) => {
  const open = mode === 'editing' || mode === 'creating'
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({paddingRight: "2.5rem"});
  const [ tabValue, setTabValue ] = useState(0);
  const title = daily_report.id ? 'Editar reporte' : 'Nuevo reporte'

  function renderComponent(values, handleChange){
    let component
    switch (tabValue) {
      case 0:
        component = <MantInfo users={users} values={values} handleChange={handleChange} />
      break;
      case 1:
        component = <MantTransparency users={users} values={values} handleChange={handleChange} />
      break;
      case 2:
        component = <MantDissolvedSolids users={users} values={values} handleChange={handleChange} />
      break;
      case 3:
        component = <MantWaterSupply users={users} values={values} handleChange={handleChange} />
      break;

      default:
        component = <MantInfo />
    }
    return component
  }

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function create(values) {
    const report = {
      report_date: values.report_date,
      pool_id: values.pool,
      comments: values.comments,
      water_supplies: values.water_supplies,
      total_dissolved_solid: values.total_dissolved_solid,
      water_transparencies: values.water_transparencies,
      general_data: values.general_data,
    }
    maintCRUD.saveDailyReport(report, updateDailyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento guardado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  function update(values) {
    const report = {
      id: daily_report.id,
      report_date: values.report_date,
      pool_id: values.pool.id,
      comments: values.comments,
      water_supplies: values.water_supplies,
      total_dissolved_solid: values.total_dissolved_solid,
      water_transparencies: values.water_transparencies,
      general_data: values.general_data,
    }
    maintCRUD.updateDailyReport(report, updateDailyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento actualizado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  let selectedDate = new Date(daily_report.report_date)
  selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset())

  const confirmDeleteReport = () => {
    setOpenConfirm(false)
    maintCRUD.deleteDailyReport(daily_report.id, deleteDailyReports)
    .then(() => {
      setDialog('closed')
      notice("Mantenimiento eliminado", "success")
    })
    .catch(e => notice(e.join('\n') || "Error con su solicitud"))
    .finally(disableLoading)
  }

  return (
    <Dialog
      open={open}
      fullWidth maxWidth={'md'}
      onClose={() => setDialog('closed')}
      aria-labelledby="form-dialog-title"
      >
      <DialogTitle>
        <div className='modal-title'>
          {title}
          {mode !== 'creating' ? 
          <Button variant="outlined" onClick={() => setOpenConfirm(true)}>
            <DeleteOutlineIcon/>
          </Button>: null
          }
        </div>
      </DialogTitle>
      <DialogContent dividers>
      <ConfirmDialog
        openConfirm={openConfirm} 
        setConfirm={() => setOpenConfirm(false)} 
        functionConfirm={() => confirmDeleteReport()}
      />
        <Formik
          initialValues={{
            report_date: (mode !== 'creating' ?selectedDate : daily_report.report_date),
            pool: daily_report.pool,
            comments: daily_report.comments,
            water_supplies: daily_report.water_supplies,
            total_dissolved_solid: daily_report.total_dissolved_solid,
            water_transparencies: daily_report.water_transparencies,
            general_data: daily_report.general_data
          }}
          validate={values => {
            const errors = {}
            
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            enableLoading()
            daily_report.id ? update(values) : create(values)
          }}
        >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors }) => (
            <form
              noValidate={true}
              autoComplete='off'
              onSubmit={handleSubmit}
              className='kt-form'
            >
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <FormControl fullWidth margin='normal' disabled={mode !== 'creating'}>
                        <InputLabel htmlFor="simple-select-label">Alberca</InputLabel>
                        <Select
                          labelId="simple-select-label"
                          name='pool'
                          value={values.pool.id ? values.pool.id : values.pool}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                        {pools.data.map(pool => (
                          <MenuItem key={pool.id} value={pool.id}>{pool.name}</MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth margin='normal' disabled>
                      <InputLabel htmlFor="pool-type">Tipo de alberca</InputLabel>
                      <Select
                        labelId="pool-type"
                        value={values.pool.pool_type_id ? values.pool.pool_type_id : values.pool}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                      {pool_types.data.map(poolType => (
                        <MenuItem key={poolType.id} value={poolType.id}>{poolType.name}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth margin='normal' disabled>
                      <InputLabel htmlFor="pool-dimension">Dimensiones</InputLabel>
                        <Select
                          labelId="pool-dimension"
                          value={values.pool.id ? values.pool.id : values.pool}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                        {pools.data.map(pool => (
                          <MenuItem key={pool.id} value={pool.id}>{pool.dimensions}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth margin='normal' disabled>
                      <InputLabel htmlFor="pool-capacity">Capacidad</InputLabel>
                      <Select
                        labelId="pool-capacity"
                        value={values.pool.id ? values.pool.id : values.pool}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                      {pools.data.map(pool => (
                        <MenuItem key={pool.id} value={pool.id}>{pool.capacity}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        disabled={mode !== 'creating'}
                        margin="normal"
                        id="mui-pickers-date"
                        label="Fecha"
                        name='report_date'
                        format='yyyy/MM/dd'
                        value={values.report_date}
                        onChange={(newDate) => handleChange({target: { value: newDate, name:'report_date' }}) }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>

                      <Tabs
                        value={tabValue}
                        onChange={(e,value) => setTabValue(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                      >
                        <Tab label="Datos generales" />
                        <Tab label="Transparencia del agua" />
                        <Tab label="Solidos disuletos totales" />
                        <Tab label="Sumnistro de agua" />
                      </Tabs>
                      {renderComponent(values, handleChange)}

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="comments"
                      fullWidth
                      variant='outlined'
                      multiline
                      rowsMax="4"
                      label='Comentarios'
                      defaultValue={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className='btn-dialog'>
                <Button onClick={() => setDialog('closed')} color="primary">
                  Cancelar
                </Button>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                  }
                  )}`}
                  style={loadingButtonStyle}
                >
                  {daily_report.id ? 'Editar' : 'Crear'}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = store => ({
  daily_report: store.maint.daily_report,
  users: store.user.users,
  pools: store.config.pools
})

export default connect(mapStateToProps, maint.actions)(MantDailyDialog)