import React from "react";
import { Delete, Edit } from "@material-ui/icons";
import IconButton from "../../../../../../common/components/IconButton";

const ActionsColumn = (data, setID, openDialog) => {
  return (
    <div className="d-flex justify-content-around">
      <IconButton
        tooltip="Borrar nivel"
        color="danger"
        Icon={Delete}
        onClick={() => setID(data)}
      />
      <IconButton
        tooltip="Editar nivel"
        color="primary"
        Icon={Edit}
        onClick={() => openDialog("editing", data)}
      />
    </div>
  );
};

export default ActionsColumn;
