import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Responsable from "./Responsable";
import Notices from "./Notices";
import Inscription from "./Inscription";
import Lessons from "./Lessons";
import AccountStatus from "./AccountStatus";
import Payment from "./Payment";
import InvoiceInformation from "./InvoiceInformation";
import Avatar from "./avatar.jpg";
import * as clients from "../../../../store/ducks/clients.duck";
import * as clientsCRUD from "../../../../crud/clients.crud";
import * as config from "../../../../store/ducks/config.duck";
import { getLocations, getLevels } from "../../../../crud/config.crud";
import { bindActionCreators } from "redux";
import { BASE_URL } from "../../../../crud/helpers/routes";
import AntTabs from "../../../../common/components/AntTabs";
import notice from "../../../../utils/notice";
import moment from "moment";
import "moment/locale/es";

const ClientProfile = ({
  clientesData,
  locations,
  responsible,
  responsiblesWithClients,
  responsibleContact,
  clientActions,
  configActions,
  invoiceInformation,
  levels,
}) => {
  const [loader, setLoader] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [clientPayment, setClientPayment] = useState();
  const [saldo, setSaldo] = useState();
  const id = useParams();
  useEffect(() => {
    setLoader(true);
    //OBTENER FECHA
    clientsCRUD
      .getClientPayments(id?.id)
      .then((res) => {
        setClientPayment(res[0]?.payments[0]);
      })
      .catch((e) => notice("Error con su solicitud"))
      .finally(() => setLoader(false));
    //OBTENER SALDO PENDIENTE
    clientsCRUD
      .getResponsibleSaldoPendiente(id?.id)
      .then((res) => {
        setSaldo(res?.saldo);
      })
      .catch((e) => notice("Error con su solicitud"))
      .finally(() => setLoader(false));
  }, [id]);

  const fetchData = async () => {
    try {
      setLoader(true);
      await clientsCRUD.getResponsible(clientActions.setResponsible, Object.values(id));
      await clientsCRUD.getResponsiblesWithClients(
        clientActions.setResponsiblesWithClients,
        Object.values(id)
      );
      await clientsCRUD.getInvoiceInformation(
        clientActions.setInvoiceInformation,
        Object.values(id)
      );
      await clientsCRUD.getResponsibleContact(
        clientActions.setResponsibleContact,
        Object.values(id)
      );
      await getLocations(configActions.setLocations);
      await getLevels(configActions.setLevels);
    } catch (error) {
      notice(
        error.errors && Array.isArray(error.errors)
          ? error.errors
          : "Ha ocurrido un error con su solicitud"
      );
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // }, [clientActions.setInvoiceInformation, clientActions.setResponsible, clientActions.setResponsibleContact, clientActions.setResponsiblesWithClients, configActions.setLevels, configActions.setLocations, id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!responsible)
    return (
      <Paper className="text-center">
        <h2>El usuario no pudo ser consultado</h2>
      </Paper>
    );
  const renderComponent = () => {
    let component;
    switch (tabValue) {
      case 1:
        component = (
          <Responsable
            responsible={responsible}
            setResponsiblesWithClients={clientActions.setResponsible}
            responsibleContact={responsibleContact}
            updateResponsibleContact={clientActions.updateResponsibleContact}
            locations={locations}
            updateClients={clientActions.setResponsiblesWithClients}
            responsiblesWithClients={responsiblesWithClients}
          />
        );
        break;
      case 2:
        component = (
          <InvoiceInformation
            invoiceInformation={invoiceInformation}
            setInvoiceInformation={clientActions.setInvoiceInformation}
            responsible={responsible}
          />
        );
        break;
      case 3:
        component = (
          <Notices
            clients={responsiblesWithClients.responsible.clients}
            responsiblesWithClients={responsiblesWithClients}
            locations={locations}
            updateClients={clientActions.setResponsiblesWithClients}
          />
        );
        break;
      case 4:
        component = <Inscription responsible={responsible} />;
        break;
      case 5:
        component = (
          <Lessons
            clients={responsiblesWithClients?.responsible?.clients}
            levels={levels?.data}
            updateLevel={clientActions?.updateLevel}
            responsible={responsible}
          />
        );
        break;
      case 6:
        component = (
          <AccountStatus
            // clients={responsiblesWithClients.responsible.clients}
            clientPayment={clientPayment}
            responsible={responsible}
          />
        );
        break;
      case 7:
        component = (
          <Payment
            responsible={responsible}
            setResponsibles={clientActions.setResponsible}
            deletePaymentMethod={clientActions.deletePaymentMethod}
          />
        );
        break;
      default:
        component = (
          <Responsable
            responsible={responsible}
            setResponsiblesWithClients={clientActions.setResponsiblesWithClients}
          />
        );
    }
    return component;
  };
  let createdDate = moment(clientPayment?.created_at, "DD-MM-YYYY").format(
    "D [de] MMMM [de] YYYY"
  );
  return (
    <>
      <Backdrop open={loader} style={{ zIndex: 10 }}>
        <CircularProgress />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper>
            {responsible && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ display: "flex", padding: "27px" }}
              >
                <Grid item xs={6} sm={2} md={2} xl={1} style={{ maxWidth: "155px" }}>
                  {responsible.responsible.profile_image ? (
                    <img
                      alt=""
                      src={`${BASE_URL}${responsible.responsible.profile_image}`}
                      style={{
                        borderRadius: "10px",
                        height: "155px",
                        width: "155px",
                        border: "1px solid darkgrey",
                      }}
                    />
                  ) : (
                    <img
                      alt=""
                      src={Avatar}
                      style={{
                        borderRadius: "10px",
                        height: "155px",
                        width: "155px",
                        border: "1px solid darkgrey",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6} sm={5} md={6} xl={8}>
                  <Grid style={{ paddingLeft: "10px" }}>
                    <Typography variant="h6">
                      {responsible.responsible.name} {responsible.responsible.last_name}
                    </Typography>
                    <br />
                    <Typography variant="h6">
                      Socio: #{responsible.responsible.membership_number}
                    </Typography>
                    <Typography variant="h6">Inscripcion: {createdDate}</Typography>
                    <Typography variant="h6">Anualidad: Vigente</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4}
                  xl={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "30px",
                  }}
                >
                  <Typography variant="h6" style={{ paddingRight: "10px" }}>
                    Saldo pendiente
                  </Typography>
                  <Divider orientation="vertical" style={{ background: "#001979" }} />
                  <Typography variant="h6" style={{ paddingLeft: "10px" }}>
                    {`$${saldo}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <AntTabs
                scroll="auto"
                variant="scrollable"
                value={tabValue}
                onChange={setTabValue}
                options={[
                  { value: 1, label: "Información General" },
                  { value: 2, label: "Datos de Facturación" },
                  { value: 3, label: "Avisos" },
                  { value: 4, label: "Inscripción" },
                  { value: 5, label: "Clases" },
                  { value: 6, label: "Estados de cuenta" },
                  { value: 7, label: "Información de pago" },
                ]}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {renderComponent()}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (store) => ({
  clientesData: store,
  clients: store.clients.clients,
  client: store.clients.client,
  responsible: store.clients.responsible,
  responsiblesWithClients: store.clients.responsiblesWithClients,
  invoiceInformation: store.clients.invoiceInformation,
  responsibleContact: store.clients.responsibleContact,
  locations: store.config.locations,
  levels: store.config.levels,
});

function mapDispatchToProps(dispatch) {
  return {
    clientActions: bindActionCreators(clients.actions, dispatch),
    configActions: bindActionCreators(config.actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
