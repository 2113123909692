import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultSuggestion } from '../../crud/suggestions.crud'

export const actionTypes = {
  GetSuggestions: "[Config] GET_SUGGESTIONS",
  UpdateSuggestions: "[Config] UPDATE_SUGGESTIONS",
  GetSuggestion: "[Config] GET_SUGGESTION",
};

const initialConfigState = {
  suggestions: {data: [], count:0, isFetched: false},
  suggestion: defaultSuggestion,
};

export const reducer = persistReducer(
  { storage, key: "sugg", whitelist: ["suggestion_types", "suggestion_statuses", "suggestion_sources"] },
  (state = initialConfigState, action) => {
    switch (action.type) {

      // ================= SUGGESTIONS ================= 
      case actionTypes.GetSuggestions: {

        return { ...state, suggestions: {data: action.payload.data, count:action.payload.count, isFetched: true} };
      }
      case actionTypes.UpdateSuggestions: {
        let newData;
        let count = state.suggestions.count
        const index = state.suggestions.data.findIndex(x=> x.id === action.payload.id)
        if (index === -1) { // index === -1 when findIndex didn't find anything, which means we created a new element
          newData = [action.payload, ...state.suggestions.data]
          count++;
        } else {
          newData = state.suggestions.data;
          newData[index] = action.payload
        }

        return { ...state, suggestions: {data: newData, count: count, isFetched: true} };
      }
      case actionTypes.GetSuggestion: {

        return { ...state, suggestion: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setSuggestions: suggestions => ({ type: actionTypes.GetSuggestions, payload: suggestions}),
  updateSuggestions: suggestions => ({ type: actionTypes.UpdateSuggestions, payload: suggestions}),
  setSuggestion: suggestion => ({ type: actionTypes.GetSuggestion, payload: suggestion }),
};