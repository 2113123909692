import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { defaultClient } from "../../crud/clients.crud";

export const actionTypes = {
  GetClients: "[Client] GET_CLIENTS",
  GetClient: "[Client] GET_CLIENT",
  UpdateClients: "[Client] UPDATE_CLIENTS",
  GetResponsibles: "[Client] GET_RESPONSIBLES",
  GetStudents: "[Client] GET_STUDENTS",
  GetResponsible: "[Client] GET_RESPONSIBLE",
  GetResponsiblesWithClients: "[Client] GET_RESPONSIBLES_WITH_CLIENTS",
  GetInvoiceInformation: "[Client] GET_INVOICE_INFORMATION",
  GetResponsibleContact: "[Client] GET_RESPONSIBLE_CONTACT",
  UpdateResponsibles: "[Client] UPDATE_RESPONSIBLES",
  UpdateInvoiceInformation: "[Client] UPDATE_INVOICE_INFORMATION",
  UpdateLevel: "[Client] UPDATE_LEVEL",
  UpdateResponsibleContact: "[Client] UPDATE_RESPONSIBLES_CONTACT",
  DeletePaymentMethod: "[Client] DELETE_PAYMENT_METHOD",
};

const initialClientState = {
  clients: { data: [], count: 0, isFetched: false },
  client: defaultClient,
  responsibles: { data: [], count: 0, isFetched: false },
  students: { data: [], isFetched: false },
  invoiceInformation: defaultClient.invoice_information,
};

export const reducer = persistReducer(
  { storage, key: "clients", whitelist: [] },
  (state = initialClientState, action) => {
    switch (action.type) {
      case actionTypes.GetClients: {
        return {
          ...state,
          clients: {
            data: action.payload.clients,
            count: action.payload.meta.pagination.total_objects,
            isFetched: true,
          },
        };
      }

      case actionTypes.GetStudents: {
        return {
          ...state,
          students: { data: action.payload.clients, isFetched: true },
        };
      }

      case actionTypes.GetClient: {
        return { ...state, client: action.payload };
      }

      case actionTypes.GetResponsibles: {
        return {
          ...state,
          responsibles: {
            count: action.payload?.meta?.pagination?.total_objects,
            data: action.payload.responsibles,
            isFetched: true,
          },
        };
      }

      case actionTypes.UpdateResponsibles: {
        let newData = [...state.responsibles.data];
        let responsible = action.payload;

        const index = state.responsibles.data.findIndex((x) => x.id === responsible.id);

        if (index === -1) {
          newData.push(responsible);
        } else {
          newData[index] = responsible;
        }

        return {
          ...state,
          responsibles: { data: newData, isFetched: true },
        };
      }

      case actionTypes.GetResponsible: {
        return { ...state, responsible: action.payload };
      }

      case actionTypes.GetResponsiblesWithClients: {
        return { ...state, responsiblesWithClients: action.payload };
      }

      case actionTypes.GetInvoiceInformation: {
        return { ...state, invoiceInformation: action.payload };
      }

      case actionTypes.UpdateInvoiceInformation: {
        let newData;
        const index = state.invoiceInformation.data.findIndex(
          (x) => x.id === action.payload.invoiceInformation.id
        );
        if (index === -1) {
          newData = [action.payload.invoiceInformation, ...state.invoiceInformation.data];
        } else {
          newData = state.invoiceInformation.data;
          newData[index] = action.payload.user;
        }

        return {
          ...state,
          invoiceInformation: { data: newData, isFetched: true },
        };
      }

      case actionTypes.GetResponsibleContact: {
        return { ...state, responsibleContact: action.payload };
      }

      case actionTypes.UpdateResponsibleContact: {
        return { ...state, responsibleContact: action.payload };
      }

      case actionTypes.UpdateLevel: {
        return { ...state, level: action.payload };
      }

      case actionTypes.DeletePaymentMethod: {
        let newData = { ...state.responsible };
        let id = action.payload;
        newData.responsible.payment_methods = state.responsible.responsible.payment_methods.filter(
          function(e) {
            return e.id !== id;
          }
        );

        return { ...state, responsible: newData };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setClients: (clients) => ({ type: actionTypes.GetClients, payload: clients }),
  setClient: (client) => ({ type: actionTypes.GetClient, payload: client }),
  updateClients: (clients) => ({
    type: actionTypes.UpdateClients,
    payload: clients,
  }),
  setResponsibles: (responsibles) => ({
    type: actionTypes.GetResponsibles,
    payload: responsibles,
  }),
  updateResponsibles: (responsible) => ({
    type: actionTypes.UpdateResponsibles,
    payload: responsible,
  }),
  updateInvoiceInformation: (invoiceInformation) => ({
    type: actionTypes.UpdateInvoiceInformation,
    payload: invoiceInformation,
  }),
  updateLevel: (level) => ({ type: actionTypes.UpdateLevel, payload: level }),
  updateResponsibleContact: (responsibleContact) => ({
    type: actionTypes.UpdateResponsibleContact,
    payload: responsibleContact,
  }),
  setResponsible: (responsible) => ({
    type: actionTypes.GetResponsible,
    payload: responsible,
  }),
  setStudents: (students) => ({
    type: actionTypes.GetStudents,
    payload: students,
  }),
  setResponsiblesWithClients: (responsiblesWithClients) => ({
    type: actionTypes.GetResponsiblesWithClients,
    payload: responsiblesWithClients,
  }),
  setInvoiceInformation: (invoiceInformation) => ({
    type: actionTypes.GetInvoiceInformation,
    payload: invoiceInformation,
  }),
  setResponsibleContact: (responsibleContact) => ({
    type: actionTypes.GetResponsibleContact,
    payload: responsibleContact,
  }),
  deletePaymentMethod: (id) => ({
    type: actionTypes.DeletePaymentMethod,
    payload: id,
  }),
};
