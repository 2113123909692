import React from "react";
import SimpleNumber from "../../../../../common/components/SimpleNumber";
import { Grid } from "@material-ui/core";

const Discounts = ({ setCourtesyDesc, courtesyDesc, setAgeDesc, ageDesc }) => {
  const handleAddCourtesy = (e) => {
    setCourtesyDesc(e.target.value);
  };
  const handleAddAgeDescount = (e) => {
    setAgeDesc(e.target.value);
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          marginTop: 20,
        }}
      >
        <SimpleNumber
          label="Descuento por cortesía"
          name="courtesyDesc"
          value={courtesyDesc}
          type="number"
          width="50%"
          isPercentage="percentage"
          decimals={2}
          onChange={handleAddCourtesy}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          marginTop: 20,
          paddingLeft: 20,
        }}
      >
        <SimpleNumber
          label=" Descuento por 3ra edad "
          name="edageDescad"
          value={ageDesc}
          type="number"
          width="50%"
          isPercentage="percentage"
          decimals={2}
          onChange={handleAddAgeDescount}
        />
      </Grid>
    </>
  );
};
export default Discounts;
