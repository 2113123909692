import { RESPONSIBLE_URL, BASE_URL } from "./helpers/routes";
import { getParams, handleError, handleResponse } from "./helpers/validate";

//INGRESOS
// export const getIncomeReportPdf = async (start, end, ubication) => {
//   return fetch(
//     `${BASE_URL}/cortes/cuotas_pdf.pdf?&dia_start=${start}&dia_end=${end}&ubicacion=${ubication}`,
//     getParams("GET")
//   );
// };

export const getIncomeReportPdf = async (start, end, ubication, type) => {
  return fetch(
    `${BASE_URL}cortes/cuotas_pdf.pdf?dia_start=${start}&dia_end=${end}&ubicacion=${ubication}&tipo_cliente=${type}`,
    getParams("GET")
  );
};
//catalogos
export const getCatalogCFDI = async () => {
  return fetch(`${BASE_URL}catalago_cdfis`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getCatalogFiscal = async () => {
  return fetch(`${BASE_URL}catalago_fiscals`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
//todos los ingresos
export const getIncomes = async (limit = 10, page = 1, params) => {
  return fetch(`${RESPONSIBLE_URL}/incomes?filtro=todos`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
//filtro por tipo de cliente
export const getIncomesByTypeClient = async (type) => {
  return fetch(`${RESPONSIBLE_URL}/incomes?type=${type}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
//filtro por ubicacion
export const getIncomesByUbication = async (ubication) => {
  return fetch(`${RESPONSIBLE_URL}/incomes?ubicacion=${ubication}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

//filtrar por todos
export const getIncomesFilter = async (
  client,
  ubication,
  typeClient,
  end,
  start,
  factura,
  locationId
) => {
  let clientNameQuery = client ? `&client_name=${client}` : "";
  let ubicationQuery = ubication ? `&ubicacion=${ubication}` : "";
  let clientTypeQuery = typeClient ? `&tipo_cliente=${typeClient}` : "";
  let facturaQuery = factura ? `&facturacion=${factura}` : "";
  let fechaQuery = start && end ? `&fecha_incio=${start}&fecha_fin=${end}` : "";
  let locationIdQuery = locationId ? `&location_id=${locationId}` : "";
  let url = `incomes?${clientNameQuery +
    ubicationQuery +
    clientTypeQuery +
    facturaQuery +
    fechaQuery +
    locationIdQuery}`;
  url = url.slice(0, 8) + url.slice(9);

  return fetch(`${RESPONSIBLE_URL}/${url}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};
export const getIncomesURLString = async (code, state, realmid) => {
  return fetch(
    `${RESPONSIBLE_URL}/invoice_public?code=${code}&state=${state}&realmId=${realmid}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getIncomesURLWithLocation = async (code, state, realmid, ubication) => {
  return fetch(
    `${RESPONSIBLE_URL}/invoice_public?code=${code}&state=${state}&realmId=${realmid}&ubicacion=${ubication}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};

export const createInvoice = (data) => {
  return fetch(
    `${RESPONSIBLE_URL}/create_invoice_information`,
    getParams("POST", JSON.stringify(data))
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getQuickbooksURL = () => {
  return fetch(`${RESPONSIBLE_URL}/quickbooksURL`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getQuickbooksAuth = (params) => {
  return fetch(`${RESPONSIBLE_URL}/quickbooksAuth${params}`, getParams("GET"))
    .then(handleResponse)
    .catch(handleError);
};

export const getInvoicePDF = (responsible_id, quickbooks_id, params) => {
  return fetch(
    `${RESPONSIBLE_URL}/invoicePDF${params}&responsible_id=${responsible_id}&quickbooks=${quickbooks_id}`,
    getParams("GET")
  )
    .then(handleResponse)
    .catch(handleError);
};
