export const columns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "category_name",
    label: "Categoría",
    options: {
      filter: false,
      sort: false,
    },
  },
  // {
  //   name: "description",
  //   label: "Descripción",
  //   options: {
  //     filter: false,
  //     sort: false,
  //   },
  // },
  {
    name: "account",
    label: "Cuenta",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "price_per_class",
    label: "Precio",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `$${data}`,
    },
  },

  {
    name: "first_discount",
    label: "2 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },
  {
    name: "second_discount",
    label: "3 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },
  {
    name: "third_discount",
    label: "4 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },
  {
    name: "fourth_discount",
    label: "5 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },
  {
    name: "fifth_discount",
    label: "6 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },

  {
    name: "sixth_discount",
    label: "7 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => `${data}%`,
    },
  },
  {
    name: "seventh_discount",
    label: "8 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => (data ? `${data}%` : "0%"),
    },
  },
  {
    name: "eighth_discount",
    label: "9 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => (data ? `${data}%` : "0%"),
    },
  },
  {
    name: "nineth_discount",
    label: "10 x semana",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (data) => (data ? `${data}%` : "0%"),
    },
  },
];

export const paymentForm = (category = [], id) => [
  // {
  //   name: "name",
  //   label: "Nombre del grupo",
  //   type: "text",
  //   width: "100%",
  // },
  // {
  //   name: "description",
  //   label: "Descripción",
  //   type: "text",
  //   width: "100%",
  // },
  {
    name: "account",
    label: "Cuenta",
    type: "dropdown",
    width: "50%",
    options: [
      { value: "Aquara", text: "Aquara" },
      { value: "Aquara Kids", text: "Aquara Kids" },
    ],
  },
  id && {
    name: "category_name",
    label: "Categoría seleccionada",
    type: "text",
    width: "100%",
    disabled: true,
  },
  {
    name: "price_per_class",
    label: "Precio mensual por una clase a la semana",
    type: "number",
    width: "50%",
    isPrice: true,
    decimals: 2,
  },
  {
    name: "category_id",
    label: "Categoría",
    type: "dropdown",
    width: "50%",
    options: category,
  },
  {
    name: "is_verano",
    label: "Curso de verano",
    type: "checkbox",
    // disabled,
  },
  {
    type: "line-break",
    title: "Descuentos",
  },
];
