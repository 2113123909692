import ActionsColumn from "../components/ActionsColumn";

export const class_columns = (
  setID,
  setOpenDialogDelete
  // openDialogDelete,
  // list,
  // setChangeList
) => [
  {
    name: "group_name",
    label: "Nombre del grupo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "group_category_name",
    label: "Categoría",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Nivel_descripcion",
    label: "Nivel",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "day",
    label: "Día",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "start_time",
    label: "Hora de inicio",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "end_time",
    label: "Hora de terminación",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "group_schedule_id",
    label: "Acciones",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({ className: "text-center" }),
      customBodyRender: (data) =>
        ActionsColumn(
          data,
          setID,
          setOpenDialogDelete
          // openDialogDelete,
          // // dataIndex,
          // list,
          // setChangeList
        ),
    },
  },
];
