import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AccountStatusTable from "./AccountStatusTable";
import AntTabs from "../../../../common/components/AntTabs";
import ClientQuota from "./ClientQuota";
import { getClientPayments } from "../../../../crud/clients.crud";
import notice from "../../../../utils/notice";
import Loading from "../../../../utils/Loading";
import { useSelector } from "react-redux";

const AccountStatus = ({ clientPayment, responsible }) => {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [studentPayments, setStudentPayments] = useState([]);
  const studentOptions = studentPayments?.map((st) => ({ value: st.id, label: st.name }));
  const index = studentPayments.findIndex((account) => account.id === tabValue);
  const [loading, setLoading] = useState(false);
  const permiso = useSelector((store) => store.auth.user.user.role.name);
  let admin = permiso === "Admin";
  //   let admin = permiso === "Admin"&& { value: 1, label: "Cuota" };
  useEffect(() => {
    setLoading(true);
    getClientPayments(id)
      .then((res) => {
        if (res) setStudentPayments(res);
        else throw new Error({ errors: ["Error con su solicitud"] });
      })
      .catch((e) => notice("Error con su solicitud"))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loading />;
  return (
    <>
      <Paper className="d-flex mb-4 pl-4 align-items-center justify-content-between">
        <h5 className="text-primary">Estado de cuenta</h5>
        <AntTabs
          value={tabValue}
          onChange={setTabValue}
          scroll="auto"
          variant="scrollable"
          options={[{ value: 0, label: "Todos" }, ...studentOptions]}
          // options={[{ value: 0, label: "Todos" }, admin, ...studentOptions]}
        />
      </Paper>
      {tabValue === 0 ? (
        studentPayments.map((account) => (
          <div className="mb-3" key={account.id}>
            <AccountStatusTable account={account.payments} />
          </div>
        ))
      ) : (
        // : tabValue === 1 ? (
        //   <ClientQuota clientPayment={clientPayment} responsible={responsible} />
        // )
        <AccountStatusTable account={studentPayments[index].payments} />
      )}
    </>
  );
};
export default AccountStatus;
