import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import SuggestionsDialog from './SuggestionsDialog';
import CustomButton from '../../../common/components/CustomButton';
import FullTable from '../../../common/components/FullTable';

import { suggestionPermissions } from '../../../utils/permissions';
import * as sugg from '../../../store/ducks/suggestions.duck';
import * as suggestionsCRUD from '../../../crud/suggestions.crud';
import {
    columns,
    defaultFilter,
    options,
    filtersForm,
} from './utils';
import { setLoader } from '../../../store/ducks/loader.duck';
import notice from '../../../utils/notice';
// import { getLocations } from "../../../crud/config.crud";
import { Button } from '@material-ui/core';

import UtilInputs from '../../../common/components/UtilInputs';

function Sugerencias({
    suggestions,
    setSuggestion,
    user,
    areas,
    suggestion_types,
    suggestion_statuses,
    suggestion_sources,
    locations,
    setSuggestions,
    users,
}) {
    const dispatch = useDispatch();
    const [dialog, setDialog] = useState('closed'); // closed, editing, creating
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    // const [filter, setFilter] = useState(defaultFilter);
    const fetchSuggestions = (query) => {
        let sucursalName;
        if (query === undefined) {
            sucursalName = '';
        } else if (query.length > 0) {
            locations.data.map((location) => {
                if (location.name === query.toUpperCase()) {
                    sucursalName = location.id;
                }
            });
        } else {
            sucursalName = query;
        }
        dispatch(setLoader(true));
        suggestionsCRUD
            .getSuggestions(page - 1, sucursalName)
            .then(setSuggestions)
            .catch((e) =>
                notice(
                    Array.isArray(e.errors)
                        ? e.errors.map((err) => err.title)
                        : 'Ha ocurrido un error con el servidor'
                )
            )
            .finally(() => dispatch(setLoader(false)));
    };

    useEffect(() => {
        fetchSuggestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, dispatch]);

    function openDialog(mode, id = null) {
        switch (mode) {
            case 'editing':
                suggestionsCRUD
                    .getSuggestion(setSuggestion, id)
                    .then(() => setDialog(mode));
                break;
            case 'creating':
                setSuggestion(suggestionsCRUD.defaultSuggestion);
                setDialog(mode);
                break;
            case 'closed':
                setDialog(mode);
                setTimeout(() => {
                    setSuggestion(suggestionsCRUD.defaultSuggestion);
                }, 200);
                break;
            default:
                console.error('Invalid dialog mode');
        }
    }
    // const handleChange = ({ target }) => {
    //     const { name, value } = target;
    //     setFilter({ ...filter, [name]: value });
    // };

    // const handleFilter = () => {
    //     let location;
    //     dispatch(setLoader(true));

    //     locations.map((loc) => {
    //         if (loc.text === filter.client_location) {
    //             location = loc.id;
    //         }
    //     });
    //     if (location) {
    //         console.log(location);
    //     }
    // };

    const permit = suggestionPermissions();
    return (
        <>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <UtilInputs
                    formValues={filter}
                    onValuesChange={handleChange}
                    inputValues={filtersForm(locations)}
                    className='mb-3'
                />
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleFilter}
                    style={{ height: 40 }}
                >
                    Filtrar
                </Button>
            </div> */}
            <div className='text-right mb-4'>
                {permit.suggestionsCreate && (
                    <CustomButton
                        title='Crear Sugerencia'
                        onClick={() => openDialog('creating')}
                    />
                )}
            </div>
            <FullTable
                title={'Sugerencias'}
                data={suggestions.data}
                columns={columns(
                    areas.data,
                    suggestion_types.data,
                    suggestion_statuses.data,
                    suggestion_sources.data,
                    users,
                    locations.data,
                    user
                )}
                options={options(
                    page,
                    setPage,
                    suggestions.count,
                    openDialog,
                    searchText,
                    setSearchText,
                    fetchSuggestions,
                    areas.data,
                    suggestion_types.data,
                    suggestion_statuses.data,
                    suggestion_sources.data,
                    users,
                    locations.data
                )}
            />
            <SuggestionsDialog
                mode={dialog}
                setDialog={openDialog}
                permit={permit}
            />
        </>
    );
}

const mapStateToProps = (store) => ({
    suggestions: store.sugg.suggestions,
    permissions: store.config.permissions,
    user: store.auth.user.user,
    areas: store.config.areas,
    suggestion_types: store.config.suggestion_types,
    suggestion_statuses: store.config.suggestion_statuses,
    suggestion_sources: store.config.suggestion_sources,
    locations: store.config.locations,
    users: store.user.users.data,
});

export default connect(mapStateToProps, sugg.actions)(Sugerencias);
