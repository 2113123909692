import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../../common/components/CustomButton";
import FullTable from "../../../../../common/components/FullTable";
import FindOutModal from "./Components/FindOutModal";
import { setLoader } from "../../../../../store/ducks/loader.duck";
import ConfirmDialog from "../../../../../utils/ConfirmDialog";
import { customOptions, defaultFindout, FindoutColumns } from "./utils";
import {
  findoutList,
  removeFindout,
  singleFindout,
} from "../../../../../crud/findout.crud";
import notice from "../../../../../utils/notice";

const FindOut = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [removeId, setRemoveId] = useState(false);
  const [findoutModal, setFindoutModal] = useState(false);
  const [findout, setFindout] = useState(defaultFindout);

  const findoutFetch = useCallback(() => {
    dispatch(setLoader(true));
    return findoutList()
      .then(({ find_outs }) => setList(find_outs))
      .catch((e) => {
        notice("Ha ocurrido un error, por favor vuelva a intentarlo");
      })
      .finally(() => dispatch(setLoader(false)));
  }, [dispatch]);

  useEffect(() => {
    findoutFetch();
  }, [findoutFetch]);

  const openDialog = (id) => {
    dispatch(setLoader(true));
    return singleFindout(id)
      .then(({ find_out }) => {
        setFindout(find_out);
        setFindoutModal(true);
      })
      .catch((e) => {
        notice("Ha ocurrido un error, por favor vuelva a intentarlo");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleOpenRemoveModal = (id) => setRemoveId(id);

  const handleRemove = () => {
    dispatch(setLoader(true));
    return removeFindout(removeId)
      .then(async () => {
        await findoutFetch();
        setRemoveId(false);
      })
      .catch((e) => {
        notice("Ha ocurrido un error, por favor vuelva a intentarlo");
      })
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <CustomButton title="Nuevo" onClick={() => setFindoutModal(true)} />
      </div>
      <FullTable
        title="Como te enteraste"
        data={list}
        columns={FindoutColumns(openDialog, handleOpenRemoveModal)}
        options={customOptions}
      />
      <FindOutModal
        open={findoutModal}
        setOpen={setFindoutModal}
        findout={findout}
        setFindout={setFindout}
        findoutfetch={findoutFetch}
      />
      <ConfirmDialog
        openConfirm={removeId}
        setConfirm={() => setRemoveId(false)}
        functionConfirm={handleRemove}
      />
    </>
  );
};

export default FindOut;
