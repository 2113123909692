import notice from '../utils/notice';
import { HELP_URL } from './helpers/routes'
import { getParams, handleResponse } from './helpers/validate'

export function getHelp(setterFunction) {

  return fetch(HELP_URL, getParams('GET'))
          .then(response => handleResponse(response) )
          .then( json => setterFunction(json))
          .catch( e => notice(e.errors))
}